import { useState, useEffect } from 'react'

export const useSessionStorage = (
  key: string,
  initialValue: string,
  ttlSeconds: number
) => {
  const [sessionData, setSessionData] = useState(() => {
    // Retrieve data from session storage or use initialValue
    const storedValue = sessionStorage.getItem(key)
    if (storedValue) {
      const { data, expiry } = JSON.parse(storedValue)
      if (ttlSeconds === null || expiry > Date.now()) {
        return data
      }
    }
    return initialValue
  })

  // Effect to save data to session storage whenever the value changes
  useEffect(() => {
    const dataToStore = {
      data: sessionData,
      expiry: ttlSeconds ? Date.now() + ttlSeconds * 1000 : null,
    }
    if (key) {
      sessionStorage.setItem(key, JSON.stringify(dataToStore))
    }
  }, [key, sessionData, ttlSeconds])

  return [sessionData, setSessionData]
}
