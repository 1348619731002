import DOMPurify from 'dompurify'

export const removeInlineStyles = (htmlString: string) => {
  const htmlNode = document.createElement('div')
  htmlNode.innerHTML = htmlString
  htmlNode.querySelectorAll('*').forEach(function (node) {
    node.removeAttribute('style')
  })
  return htmlNode.innerHTML
}

const NBSP = '&nbsp;'
const SPACE = ' '

export const sanitizeHtmlString = (htmlString: string) =>
  // LCS-5871 replace `&nbsp` with a white space so that word wrapping works properly
  DOMPurify.sanitize(htmlString).replaceAll(NBSP, SPACE)
