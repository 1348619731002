import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  AWSDate: any
  AWSDateTime: any
  AWSEmail: any
  AWSIPAddress: any
  AWSJSON: any
  AWSPhone: any
  AWSTime: any
  AWSTimestamp: any
  AWSURL: any
  Long: any
}

export type AddClubListingInput = {
  clubId: Scalars['ID']
  description?: Maybe<Scalars['String']>
  isSubstituteListing?: Maybe<Scalars['Boolean']>
  programs: Array<Scalars['String']>
  times?: Maybe<Array<TimePreferenceInput>>
}

export type AddClubListingResponse = ClubListingResult | ErrorResult

export type AddEmergencyContactResponse =
  | AddEmergencyContactResult
  | ErrorResult

export type AddEmergencyContactResult = {
  __typename?: 'AddEmergencyContactResult'
  result?: Maybe<EmergencyContact>
}

export type Address = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  location?: Maybe<GeoPoint>
  postalCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  stateCode?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  streetName?: Maybe<Scalars['String']>
  streetNumber?: Maybe<Scalars['String']>
}

export type AddressInput = {
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  location?: Maybe<GeoPointInput>
  postalCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  stateCode?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  streetName?: Maybe<Scalars['String']>
  streetNumber?: Maybe<Scalars['String']>
}

export type AddToShortlistInput = {
  userId: Scalars['String']
}

export type AddToShortlistResponse = AddToShortlistResult | ErrorResult

export type AddToShortlistResult = {
  __typename?: 'AddToShortlistResult'
  shortlist?: Maybe<Array<InstructorShortlist>>
}

export type AddTrainingOutcomeInput = {
  attendanceStatus: AttendedStatusEnum
  developmentSheet?: Maybe<DevelopmentSheetInput>
  outcomeStatus?: Maybe<OutcomeEnum>
  trainingJourneyId: Scalars['String']
}

export type AddTrainingOutcomeResponse = AddTrainingOutcomeResult | ErrorResult

export type AddTrainingOutcomeResult = {
  __typename?: 'AddTrainingOutcomeResult'
  developmentSheet?: Maybe<DevelopmentSheet>
  isSuccess?: Maybe<Scalars['Boolean']>
}

export type Affiliation = {
  __typename?: 'Affiliation'
  actionedDate?: Maybe<Scalars['AWSDateTime']>
  clubId: Scalars['ID']
  id: Scalars['ID']
  isDigitalOnly?: Maybe<Scalars['Boolean']>
  latestActionInitiator?: Maybe<UserRoleType>
  pendingStatus?: Maybe<AffiliationStatus>
  requestedBy: UserRoleType
  requestedDate: Scalars['AWSDateTime']
  role: AffiliationRole
  status: AffiliationStatus
  userId: Scalars['ID']
}

export type AffiliationAsyncResponse = AffiliationAsyncResult | ErrorResult

export type AffiliationAsyncResult = {
  __typename?: 'AffiliationAsyncResult'
  affilation?: Maybe<Affiliation>
  hasPendingAffiliations?: Maybe<Scalars['Boolean']>
  status: Scalars['String']
}

export type AffiliationInput = {
  actionedDate?: Maybe<Scalars['AWSDateTime']>
  clubId: Scalars['ID']
  id: Scalars['ID']
  requestedBy: UserRoleType
  requestedDate: Scalars['AWSDateTime']
  role: AffiliationRole
  status: AffiliationStatus
  userId: Scalars['ID']
}

export enum AffiliationRole {
  Gfm = 'GFM',
  Instructor = 'INSTRUCTOR',
}

export enum AffiliationStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Removed = 'REMOVED',
  Setup = 'SETUP',
}

export type AgreementDocument = {
  __typename?: 'AgreementDocument'
  content: Scalars['String']
  id: Scalars['ID']
  market: Scalars['String']
  type: AgreementType
  version: Scalars['String']
}

export type AgreementDocumentResponse = AgreementDocumentResult | ErrorResult

export type AgreementDocumentResult = {
  __typename?: 'AgreementDocumentResult'
  content?: Maybe<Scalars['String']>
  id: Scalars['ID']
  signedOn?: Maybe<Scalars['AWSDateTime']>
  type: AgreementType
  version: Scalars['String']
}

export enum AgreementType {
  DataPrivacy = 'DATA_PRIVACY',
  InstructorAgreement = 'INSTRUCTOR_AGREEMENT',
}

export enum AnalyticsEvent {
  NavigationAssessments = 'NAVIGATION_ASSESSMENTS',
  NavigationBuyReleases = 'NAVIGATION_BUY_RELEASES',
  NavigationCertifications = 'NAVIGATION_CERTIFICATIONS',
  NavigationEventsTraining = 'NAVIGATION_EVENTS_TRAINING',
  NavigationExploreProgrammes = 'NAVIGATION_EXPLORE_PROGRAMMES',
  NavigationFindEventsAndTraining = 'NAVIGATION_FIND_EVENTS_AND_TRAINING',
  NavigationHome = 'NAVIGATION_HOME',
  NavigationInstructorsFindInstructors = 'NAVIGATION_INSTRUCTORS_FIND_INSTRUCTORS',
  NavigationInstructorsYourClubProfile = 'NAVIGATION_INSTRUCTORS_YOUR_CLUB_PROFILE',
  NavigationInstructorsYourPeople = 'NAVIGATION_INSTRUCTORS_YOUR_PEOPLE',
  NavigationManageMyPartnership = 'NAVIGATION_MANAGE_MY_PARTNERSHIP',
  NavigationMoreContactSupport = 'NAVIGATION_MORE_CONTACT_SUPPORT',
  NavigationMoreCookieDeclaration = 'NAVIGATION_MORE_COOKIE_DECLARATION',
  NavigationMoreEqualOpportunities = 'NAVIGATION_MORE_EQUAL_OPPORTUNITIES',
  NavigationMoreFaq = 'NAVIGATION_MORE_FAQ',
  NavigationMoreGiveFeedback = 'NAVIGATION_MORE_GIVE_FEEDBACK',
  NavigationMorePrivacyPolicy = 'NAVIGATION_MORE_PRIVACY_POLICY',
  NavigationMoreWebsiteTerms = 'NAVIGATION_MORE_WEBSITE_TERMS',
  NavigationMyBookedTraining = 'NAVIGATION_MY_BOOKED_TRAINING',
  NavigationMyEventsAndTraining = 'NAVIGATION_MY_EVENTS_AND_TRAINING',
  NavigationMyReleases = 'NAVIGATION_MY_RELEASES',
  NavigationProfileLogOut = 'NAVIGATION_PROFILE_LOG_OUT',
  NavigationProfileSwitchAccount = 'NAVIGATION_PROFILE_SWITCH_ACCOUNT',
  NavigationProfileViewProfile = 'NAVIGATION_PROFILE_VIEW_PROFILE',
  NavigationProfileYourAccount = 'NAVIGATION_PROFILE_YOUR_ACCOUNT',
  NavigationPrograms = 'NAVIGATION_PROGRAMS',
  NavigationReleases = 'NAVIGATION_RELEASES',
  NavigationTeachingFindClasses = 'NAVIGATION_TEACHING_FIND_CLASSES',
  NavigationTeachingFindWork = 'NAVIGATION_TEACHING_FIND_WORK',
  NavigationTeachingMyFacilities = 'NAVIGATION_TEACHING_MY_FACILITIES',
  NavigationTeachingMyProfile = 'NAVIGATION_TEACHING_MY_PROFILE',
  NavigationTrainer = 'NAVIGATION_TRAINER',
}

export type AppMetadataResponse = AppMetadataResult | ErrorResult

export type AppMetadataResult = {
  __typename?: 'AppMetadataResult'
  isSuccess: Scalars['Boolean']
}

export type AssessmentVideo = {
  __typename?: 'AssessmentVideo'
  assessmentId: Scalars['ID']
  comment?: Maybe<Scalars['String']>
  extensionRequested: Scalars['Boolean']
  fileKey?: Maybe<Scalars['String']>
  playback?: Maybe<Playback>
  recordType: RecordType
  submitted: Scalars['Boolean']
  submittedDate?: Maybe<Scalars['String']>
  videoNotWorking: Scalars['Boolean']
}

export type AtHomeProductTile = {
  __typename?: 'AtHomeProductTile'
  buttons?: Maybe<Array<Link>>
  description?: Maybe<Scalars['String']>
  image: ImageSet
  onlineProgram: ClubOnlineProgram
  programs?: Maybe<Array<ClubProgram>>
  title: Scalars['String']
}

export enum AtHomeProgramNameEnum {
  LesMillsGritAthletic = 'LES_MILLS_GRIT_ATHLETIC',
  LesMillsGritCardio = 'LES_MILLS_GRIT_CARDIO',
  LesMillsGritStrength = 'LES_MILLS_GRIT_STRENGTH',
  Mindfullness = 'MINDFULLNESS',
  Stretch = 'STRETCH',
  TheTripImmersive = 'THE_TRIP_IMMERSIVE',
  Wellness = 'WELLNESS',
}

export enum AtHomeSpecificProgramCodeEnum {
  Ga = 'GA',
  Gc = 'GC',
  Gs = 'GS',
  Mf = 'MF',
  St = 'ST',
  Trl = 'TRL',
  Trv = 'TRV',
  W = 'W',
}

export enum AttendedStatusEnum {
  Attended = 'ATTENDED',
  DidNotAttend = 'DID_NOT_ATTEND',
  Incomplete = 'INCOMPLETE',
}

export type AttendTrainingStep = Step & {
  __typename?: 'AttendTrainingStep'
  activeStepName: Scalars['String']
  activeStepNumber: Scalars['Int']
  attended?: Maybe<AttendedStatusEnum>
  cancellationPolicy: Scalars['AWSJSON']
  cecCertificationUrl?: Maybe<Scalars['String']>
  contactUsEmail: Scalars['String']
  deliveryMethod: DeliveryMethodEnum
  details?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  endDate: Scalars['String']
  eventName: Scalars['String']
  fulfillment?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  onlineTrainingLink?: Maybe<Scalars['String']>
  passPendingGuide: Scalars['AWSJSON']
  programName: Scalars['String']
  requirements?: Maybe<Scalars['String']>
  schedule?: Maybe<Scalars['String']>
  startDate: Scalars['String']
  stepName: Scalars['String']
  stepNumber: Scalars['Int']
  timezone: Scalars['String']
  trackAllocation?: Maybe<Scalars['String']>
  trainerEmail?: Maybe<Scalars['String']>
  trainerName?: Maybe<Scalars['String']>
  venueAddress?: Maybe<Scalars['String']>
}

export enum BilledCertificateActionEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
}

export type BilledCertificationInput = {
  actionType: BilledCertificateActionEnum
  id: Scalars['ID']
}

export type BookedEvent = {
  __typename?: 'BookedEvent'
  event: Event
  numberOfBookedInstructors: Scalars['Int']
}

export type BookedEventResponse = BookedEventResult | ErrorResult

export type BookedEventResult = {
  __typename?: 'BookedEventResult'
  bookedInstructors?: Maybe<Array<InstructorDetails>>
  eventDetails: EventDetailsResult
}

export type BookedEventsResponse = BookedEventsResult | ErrorResult

export type BookedEventsResult = {
  __typename?: 'BookedEventsResult'
  bookedEvents?: Maybe<Array<BookedEvent>>
}

export type BookEventInput = {
  eventId: Scalars['String']
  instructorIds: Array<Scalars['String']>
  ticketId: Scalars['String']
}

export type BookEventResponse = BookEventResult | ErrorResult

export type BookEventResult = {
  __typename?: 'BookEventResult'
  magentoLink: Scalars['String']
}

export type BookQwsInput = {
  eventId: Scalars['String']
  instructorAndSessions: Array<InstructorAndSessions>
  ticketId: Scalars['String']
}

export type BusinessSupportSection = {
  __typename?: 'BusinessSupportSection'
  id: Scalars['ID']
  sectionTiles?: Maybe<Array<BusinessSupportTile>>
  sectionTitle: Scalars['String']
}

export type BusinessSupportTile = {
  __typename?: 'BusinessSupportTile'
  id: Scalars['ID']
  image: Image
  subtitle: Scalars['String']
  title: Scalars['String']
  url: Url
}

export type Certificate = {
  __typename?: 'Certificate'
  assessmentLevel?: Maybe<Scalars['Float']>
  certificateDetailUrl: Scalars['String']
  certificateUrl: Scalars['String']
  id: Scalars['ID']
  level?: Maybe<CertificationStatusEnum>
  name: Scalars['String']
  programId: Scalars['String']
  subscribeStatus: Scalars['Boolean']
  type?: Maybe<Scalars['String']>
}

export type CertificatesResponse = CertificatesResult | ErrorResult

export type CertificatesResult = {
  __typename?: 'CertificatesResult'
  certificates?: Maybe<Array<Certificate>>
}

export type Certification = {
  __typename?: 'Certification'
  active: Scalars['Boolean']
  billedClubId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastPurchase?: Maybe<Scalars['String']>
  link: Link
  lmqLevel: Scalars['Float']
  pricePerQuarter?: Maybe<CertificationPrice>
  program: Program
  releaseStatus?: Maybe<ReleaseStatusEnum>
  snapshotState?: Maybe<SnapshotStateEnum>
  status: CertificationStatusEnum
  steps?: Maybe<Array<CertificationStep>>
  transactionStatus?: Maybe<TransactionStatusEnum>
  transactionTimestamp?: Maybe<Scalars['Long']>
}

export type CertificationPrice = {
  __typename?: 'CertificationPrice'
  amount: Scalars['Float']
  currency: Currency
}

export enum CertificationStatusEnum {
  Advanced = 'ADVANCED',
  Aim_1Complete = 'AIM_1_COMPLETE',
  Certified = 'CERTIFIED',
  Elite = 'ELITE',
  NotCertified = 'NOT_CERTIFIED',
}

export type CertificationStep = {
  __typename?: 'CertificationStep'
  action?: Maybe<Link>
  id: Scalars['ID']
  label: Scalars['String']
  status: CertificationStepStatusEnum
}

export type CertificationStepInput = {
  action?: Maybe<LinkCertificationInput>
  id?: Maybe<Scalars['ID']>
  label?: Maybe<Scalars['String']>
  status?: Maybe<CertificationStepStatusEnum>
}

export enum CertificationStepStatusEnum {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Todo = 'TODO',
}

export type CertifiedStep = {
  __typename?: 'CertifiedStep'
  activeStepName: Scalars['String']
  activeStepNumber: Scalars['Int']
  attended?: Maybe<AttendedStatusEnum>
  cancellationPolicy: Scalars['AWSJSON']
  contactUsEmail: Scalars['String']
  deliveryMethod: DeliveryMethodEnum
  details?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  endDate: Scalars['String']
  eventName: Scalars['String']
  fulfillment?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  onlineTrainingLink?: Maybe<Scalars['String']>
  passPendingGuide: Scalars['AWSJSON']
  programName: Scalars['String']
  requirements?: Maybe<Scalars['String']>
  schedule?: Maybe<Scalars['String']>
  startDate: Scalars['String']
  stepName: Scalars['String']
  stepNumber: Scalars['Int']
  timezone: Scalars['String']
  trackAllocation?: Maybe<Scalars['String']>
  trainerEmail?: Maybe<Scalars['String']>
  trainerName?: Maybe<Scalars['String']>
  venueAddress?: Maybe<Scalars['String']>
}

export type ChainAffiliation = {
  __typename?: 'ChainAffiliation'
  actionedDate?: Maybe<Scalars['AWSDateTime']>
  clubId: Scalars['ID']
  clubName: Scalars['String']
  id: Scalars['ID']
  latestActionInitiator?: Maybe<UserRoleType>
  pendingStatus?: Maybe<AffiliationStatus>
  requestedBy: UserRoleType
  requestedDate: Scalars['AWSDateTime']
  role: AffiliationRole
  status: AffiliationStatus
  userId: Scalars['ID']
}

export type ChainAffiliationsRemoveResponse =
  | ChainAffiliationsRemoveRresult
  | ErrorResult

export type ChainAffiliationsRemoveRresult = {
  __typename?: 'ChainAffiliationsRemoveRresult'
  receivedAffiliationIds?: Maybe<Array<Scalars['ID']>>
  removedAffiliationIds?: Maybe<Array<Scalars['ID']>>
  removedUserId: Scalars['ID']
}

export type ChainAffiliationsResponse = ChainAffiliationsResult | ErrorResult

export type ChainAffiliationsResult = {
  __typename?: 'ChainAffiliationsResult'
  successfulAffiliations?: Maybe<Array<ChainAffiliation>>
}

export type ChainUserResponse = ErrorResult | GetChainUser

export type Club = {
  __typename?: 'Club'
  accountId: Scalars['ID']
  affiliations?: Maybe<Array<Affiliation>>
  createdAt: Scalars['AWSDateTime']
  currency?: Maybe<Currency>
  email?: Maybe<EmailClubType>
  id: Scalars['ID']
  market: Market
  name: Scalars['String']
  parent?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  profile: UserProfileClub
}

export enum Club_Sort_By {
  Nearest = 'NEAREST',
}

export type ClubAffiliates = {
  __typename?: 'ClubAffiliates'
  actionedDate?: Maybe<Scalars['AWSDateTime']>
  clubId: Scalars['String']
  distance?: Maybe<Scalars['Float']>
  distanceUnit?: Maybe<LocationDistanceUnit>
  id: Scalars['ID']
  isDigitalOnly?: Maybe<Scalars['Boolean']>
  pendingStatus?: Maybe<AffiliationStatus>
  profile: UserProfileClub
  requestedBy: UserRoleType
  requestedDate: Scalars['AWSDateTime']
  status: AffiliationStatus
}

export type ClubAffiliationsResult = {
  __typename?: 'ClubAffiliationsResult'
  affiliations?: Maybe<Array<Affiliation>>
}

export type ClubAvailabilityOnListing = {
  __typename?: 'ClubAvailabilityOnListing'
  clubAddress?: Maybe<Address>
  clubId: Scalars['ID']
  clubName: Scalars['String']
  clubProfileImage?: Maybe<Image>
  isAlreadyListed?: Maybe<Scalars['Boolean']>
  isClubLicensed?: Maybe<Scalars['Boolean']>
}

export type ClubEdge = {
  __typename?: 'ClubEdge'
  cursor: Scalars['String']
  node: SearchClubProfileSingleResult
}

export type ClubFilterBy = {
  location: ClubLocationFilter
  name?: Maybe<Scalars['String']>
  programs: Array<Scalars['ID']>
}

export type ClubListing = ClubMultiListing | ClubSingleListing

export type ClubListingCommon = {
  clubInfo: UserProfileClub
  createdAt: Scalars['AWSDateTime']
  description?: Maybe<Scalars['String']>
  enquiries: Array<ClubListingEnquiry>
  enquiriesCount: Scalars['Int']
  expiringAt: Scalars['AWSDateTime']
  id: Scalars['ID']
  searchAppearances?: Maybe<Scalars['Int']>
  status: ClubListingStatus
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  views: Scalars['Int']
}

export type ClubListingEnquiry = {
  __typename?: 'ClubListingEnquiry'
  club: UserProfileClub
  createdAt: Scalars['AWSDateTime']
  instructor: UserProfileTeaching
  listing: ClubListing
  message?: Maybe<Scalars['String']>
  program: Program
}

export type ClubListingLocationFilter = {
  coordinate: GeoPointInput
  distanceUnit: LocationDistanceUnit
}

export type ClubListingResult = {
  __typename?: 'ClubListingResult'
  results?: Maybe<Array<ClubListing>>
}

export type ClubListingsEdge = {
  __typename?: 'ClubListingsEdge'
  cursor: Scalars['String']
  node: SearchClubJobListingsSingleResult
}

export type ClubListingsEndCursor = {
  __typename?: 'ClubListingsEndCursor'
  listingId: Scalars['String']
  sortValues: Array<Scalars['String']>
}

export type ClubListingsFilterBy = {
  location?: Maybe<ClubListingLocationFilter>
  name?: Maybe<Scalars['String']>
  programs: Array<Scalars['ID']>
  substituteListingFilter?: Maybe<SearchClubListingsSubstitute>
}

export type ClubListingsPageInfo = {
  __typename?: 'ClubListingsPageInfo'
  endCursor?: Maybe<ClubListingsEndCursor>
  hasNextPage: Scalars['Boolean']
  total?: Maybe<Scalars['Int']>
}

export type ClubListingsSearchAfterInput = {
  listingId: Scalars['String']
  sortValues: Array<Scalars['String']>
}

export enum ClubListingStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Expired = 'EXPIRED',
}

export type ClubListingUnknown = {
  __typename?: 'ClubListingUnknown'
  clubInfo: UserProfileClub
  createdAt?: Maybe<Scalars['AWSDateTime']>
  description?: Maybe<Scalars['String']>
  expiringAt?: Maybe<Scalars['AWSDateTime']>
  id: Scalars['ID']
  program?: Maybe<ClubProgram>
  searchAppearances?: Maybe<Scalars['Int']>
  status?: Maybe<ClubListingStatus>
  times?: Maybe<Array<TimePreference>>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  views?: Maybe<Scalars['Int']>
}

export type ClubLocationFilter = {
  near?: Maybe<ClubLocationNearInput>
  within?: Maybe<LocationWithinInput>
}

export type ClubLocationNearInput = {
  coordinate: GeoPointInput
  distance?: Maybe<Scalars['Int']>
  distanceUnit?: Maybe<LocationDistanceUnit>
}

export type ClubMultiListing = ClubListingCommon & {
  __typename?: 'ClubMultiListing'
  clubInfo: UserProfileClub
  createdAt: Scalars['AWSDateTime']
  description?: Maybe<Scalars['String']>
  enquiries: Array<ClubListingEnquiry>
  enquiriesCount: Scalars['Int']
  expiringAt: Scalars['AWSDateTime']
  id: Scalars['ID']
  programs: Array<ClubProgram>
  programsId: Array<Scalars['String']>
  searchAppearances?: Maybe<Scalars['Int']>
  status: ClubListingStatus
  times: Array<TimePreference>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  views: Scalars['Int']
}

export type ClubOnlineProgram = {
  __typename?: 'ClubOnlineProgram'
  activeSince?: Maybe<Scalars['AWSDateTime']>
  id: Scalars['ID']
  lastEnquiredOn?: Maybe<Scalars['AWSDateTime']>
}

export type ClubPageInfo = {
  __typename?: 'ClubPageInfo'
  endCursor?: Maybe<ClubSearchAfter>
  hasNextPage: Scalars['Boolean']
  total?: Maybe<Scalars['Int']>
}

export type ClubPartnershipBusinessSupportContent = {
  __typename?: 'ClubPartnershipBusinessSupportContent'
  description: Scalars['String']
  link: Link
  title: Scalars['String']
}

export type ClubPartnershipProgramStat = {
  __typename?: 'ClubPartnershipProgramStat'
  icon: Image
  total: Scalars['Int']
  type: ProgramTypeEnum
}

export type ClubPartnershipProgramStats = {
  __typename?: 'ClubPartnershipProgramStats'
  programsStats?: Maybe<Array<ClubPartnershipProgramStat>>
}

export type ClubPartnershipStandardContent = {
  __typename?: 'ClubPartnershipStandardContent'
  image: ImageSet
  link: Link
  subtitle: Scalars['String']
  title: Scalars['String']
}

export type ClubProfile = {
  __typename?: 'ClubProfile'
  bio?: Maybe<Scalars['String']>
  hiring?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  links?: Maybe<Array<ProfileLink>>
  media?: Maybe<Array<Image>>
  openToNewPrograms: Scalars['Boolean']
  profileImage?: Maybe<Image>
  programs?: Maybe<Array<ClubProgram>>
  shortlist?: Maybe<Array<InstructorShortlist>>
  strength?: Maybe<Scalars['Int']>
}

export type ClubProfileInput = {
  address: AddressInput
  bio?: Maybe<Scalars['String']>
  createdAt: Scalars['AWSDateTime']
  createdAtLabel?: Maybe<Scalars['String']>
  hiring: Scalars['Boolean']
  links?: Maybe<Array<ProfileLinkInput>>
  media?: Maybe<Array<ImageInput>>
  strength?: Maybe<Scalars['Int']>
}

export type ClubProgram = ProgramInterface & {
  __typename?: 'ClubProgram'
  code: Scalars['String']
  displayName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  initials: Scalars['String']
  instructorCount?: Maybe<ProgramInstructorCount>
  name: Scalars['String']
  programType?: Maybe<ProgramTypeEnum>
  status?: Maybe<ProgramStatus>
}

export type ClubProgramContent = ClubProgram | ClubProgramInStudioEmptyState

export type ClubProgramDetails = {
  __typename?: 'ClubProgramDetails'
  instructorsCount: Scalars['Int']
  lastEnquiredOn?: Maybe<Scalars['String']>
  program: Scalars['String']
  status: Scalars['String']
}

export type ClubProgramInStudioEmptyState = {
  __typename?: 'ClubProgramInStudioEmptyState'
  content?: Maybe<InStudioEmptyState>
  programType?: Maybe<ProgramTypeEnum>
}

export type ClubProgramsResult = {
  __typename?: 'ClubProgramsResult'
  clubProgramsDetails?: Maybe<Array<ClubProgramDetails>>
  exploreProgramsDetails?: Maybe<Array<ExploreProgramDetails>>
}

export type ClubSearchAfter = {
  __typename?: 'ClubSearchAfter'
  clubId: Scalars['String']
  sortValues: Array<Scalars['String']>
}

export type ClubSearchAfterInput = {
  clubId: Scalars['String']
  sortValues: Array<Scalars['String']>
}

export type ClubSingleListing = ClubListingCommon & {
  __typename?: 'ClubSingleListing'
  clubInfo: UserProfileClub
  createdAt: Scalars['AWSDateTime']
  description?: Maybe<Scalars['String']>
  enquiries: Array<ClubListingEnquiry>
  enquiriesCount: Scalars['Int']
  expiringAt: Scalars['AWSDateTime']
  id: Scalars['ID']
  isSubstituteListing: Scalars['Boolean']
  program: ClubProgram
  programId: Scalars['String']
  searchAppearances?: Maybe<Scalars['Int']>
  status: ClubListingStatus
  times: Array<TimePreference>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  views: Scalars['Int']
}

export type ClubWithAffiliation = {
  __typename?: 'ClubWithAffiliation'
  clubId: Scalars['ID']
  clubName: Scalars['String']
  isDigitalOnly?: Maybe<Scalars['Boolean']>
  latestAffiliation?: Maybe<Affiliation>
}

export type CmsPaginationInput = {
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
}

export type ContactUsStep = {
  __typename?: 'ContactUsStep'
  activeStepName: Scalars['String']
  activeStepNumber: Scalars['Int']
  contactUsEmail: Scalars['String']
  stepName: Scalars['String']
  stepNumber: Scalars['Int']
}

export type ContentField = {
  __typename?: 'ContentField'
  contentText: Scalars['AWSJSON']
  subHeading: Scalars['String']
  title: Scalars['String']
}

export type Country = {
  __typename?: 'Country'
  currency: Currency
  locale: Locale
  name: Scalars['String']
}

export enum CountryEnum {
  Armenia = 'ARMENIA',
  Austria = 'AUSTRIA',
  Azerbaijan = 'AZERBAIJAN',
  Bahamas = 'BAHAMAS',
  Bahrain = 'BAHRAIN',
  Bangladesh = 'BANGLADESH',
  Belarus = 'BELARUS',
  Bermuda = 'BERMUDA',
  Brazil = 'BRAZIL',
  Bulgaria = 'BULGARIA',
  CarribeanIslands = 'CARRIBEAN_ISLANDS',
  China = 'CHINA',
  Czechia = 'CZECHIA',
  Denmark = 'DENMARK',
  Estonia = 'ESTONIA',
  Finland = 'FINLAND',
  Georgia = 'GEORGIA',
  Germany = 'GERMANY',
  India = 'INDIA',
  Iran = 'IRAN',
  Iraq = 'IRAQ',
  Ireland = 'IRELAND',
  Japan = 'JAPAN',
  Jordan = 'JORDAN',
  Kazakhstan = 'KAZAKHSTAN',
  Kenya = 'KENYA',
  Kuwait = 'KUWAIT',
  Kyrgyzstan = 'KYRGYZSTAN',
  Latvia = 'LATVIA',
  Lithuania = 'LITHUANIA',
  Moldova = 'MOLDOVA',
  Nigeria = 'NIGERIA',
  Norway = 'NORWAY',
  Oman = 'OMAN',
  Pakistan = 'PAKISTAN',
  Poland = 'POLAND',
  PuertoRico = 'PUERTO_RICO',
  Qatar = 'QATAR',
  Romania = 'ROMANIA',
  Russia = 'RUSSIA',
  SaudiArabia = 'SAUDI_ARABIA',
  Slovakia = 'SLOVAKIA',
  SouthAfrica = 'SOUTH_AFRICA',
  StLucia = 'ST_LUCIA',
  Sweden = 'SWEDEN',
  Switzerland = 'SWITZERLAND',
  Syria = 'SYRIA',
  Tajikistan = 'TAJIKISTAN',
  Ukraine = 'UKRAINE',
  UnitedArabEmirates = 'UNITED_ARAB_EMIRATES',
  UnitedKingdom = 'UNITED_KINGDOM',
  UnitedStates = 'UNITED_STATES',
  UsVirginIslands = 'US_VIRGIN_ISLANDS',
  Uzbekistan = 'UZBEKISTAN',
  Zambia = 'ZAMBIA',
}

export type CountryInput = {
  currency: Currency
  locale: Locale
  name: Scalars['String']
}

export type CreateAffiliationInput = {
  clubId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type CreateAffiliationsInput = {
  clubIds: Array<Scalars['ID']>
  userId: Scalars['ID']
}

export type CreateClubListingsInput = {
  clubIds: Array<Scalars['ID']>
  description?: Maybe<Scalars['String']>
  isSubstituteListing?: Maybe<Scalars['Boolean']>
  programId?: Maybe<Scalars['String']>
  programName?: Maybe<Scalars['String']>
  times: Array<TimePreferenceInput>
}

export type CreateClubListingsResponse = CreateClubListingsResult | ErrorResult

export type CreateClubListingsResult = {
  __typename?: 'CreateClubListingsResult'
  successfulListings?: Maybe<Array<ClubSingleListing>>
}

export type CreateInstructorAccountInput = {
  country?: Maybe<CountryEnum>
  dateOfBirth?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  eventId?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  hasPreReqFitnessQualification?: Maybe<Scalars['Boolean']>
  isOptInCorrespondence?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  recaptchaToken?: Maybe<Scalars['String']>
  state?: Maybe<StatesEnum>
}

export type CreateInstructorAccountResponse =
  | CreateInstructorAccountResult
  | ErrorResult

export type CreateInstructorAccountResult = {
  __typename?: 'CreateInstructorAccountResult'
  success?: Maybe<Scalars['Boolean']>
}

export type CreateNotificationInput = {
  body: Scalars['String']
  link?: Maybe<LinkInput>
  recipient?: Maybe<CreateNotificationRecipientInput>
  title: Scalars['String']
  type: NotificationType
}

export type CreateNotificationRecipientInput = {
  id: Scalars['ID']
  type: UserRoleType
}

export enum Currency {
  Aed = 'AED',
  Brl = 'BRL',
  Cny = 'CNY',
  Czk = 'CZK',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Inr = 'INR',
  Jpy = 'JPY',
  Nok = 'NOK',
  Nzd = 'NZD',
  Pln = 'PLN',
  Rub = 'RUB',
  Sar = 'SAR',
  Sek = 'SEK',
  Twd = 'TWD',
  Usd = 'USD',
  Zar = 'ZAR',
}

export type DashboardMyTools = {
  __typename?: 'DashboardMyTools'
  id: Scalars['ID']
  imageSet: ImageSet
  subtitle: Scalars['String']
  title: Scalars['String']
  url: Url
}

export type DashboardPageClubResponse = {
  __typename?: 'DashboardPageClubResponse'
  id: Scalars['ID']
  links?: Maybe<Array<LinkTile>>
  myTools?: Maybe<Array<DashboardMyTools>>
  partnership?: Maybe<Array<PartnershipContent>>
  promotions?: Maybe<Array<Promotion>>
}

export type DashboardPageInstructorResponse = {
  __typename?: 'DashboardPageInstructorResponse'
  certifications?: Maybe<Array<Certification>>
  id: Scalars['ID']
  links?: Maybe<Array<LinkTile>>
  promotions?: Maybe<Array<Promotion>>
  socialMedia: SocialMediaSection
  translation: DashboardPageTranslation
}

export type DashboardPageTranslation = {
  __typename?: 'DashboardPageTranslation'
  title: Scalars['String']
}

export type DateTimeFilter = {
  __typename?: 'DateTimeFilter'
  limit: Scalars['Int']
  type: DateTimeFilterType
  value: Scalars['AWSDateTime']
}

export type DateTimeFilteredResponse = {
  filter: DateTimeFilter
  totalCount: Scalars['Int']
}

export type DateTimeFilterInput = {
  limit: Scalars['Int']
  type: DateTimeFilterType
  value: Scalars['String']
}

export enum DateTimeFilterType {
  After = 'AFTER',
  Before = 'BEFORE',
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type DeleteAffiliationInput = {
  id: Scalars['String']
}

export type DeleteAffiliationsInput = {
  affiliationIds: Array<Scalars['ID']>
  userId: Scalars['ID']
}

export type DeleteClubListingInput = {
  clubId: Scalars['ID']
  listingId: Scalars['ID']
}

export type DeleteClubListingResponse = DeleteClubListingResult | ErrorResult

export type DeleteClubListingResult = {
  __typename?: 'DeleteClubListingResult'
  clubId: Scalars['ID']
  listingId: Scalars['ID']
  results: Array<ClubListing>
  status: Scalars['String']
}

export type DeleteClubListingsInput = {
  clubIds?: Maybe<Array<Scalars['ID']>>
  isSubstituteListing?: Maybe<Scalars['Boolean']>
  programId?: Maybe<Scalars['String']>
  programName?: Maybe<Scalars['String']>
}

export type DeleteClubListingsResponse = DeleteClubListingsResult | ErrorResult

export type DeleteClubListingsResult = {
  __typename?: 'DeleteClubListingsResult'
  success?: Maybe<Array<ClubSingleListing>>
}

export type DeleteCurrentUserPortfolioImageInput = {
  id: Scalars['ID']
}

export type DeleteCurrentUserPortfolioImageResponse =
  | DeleteCurrentUserPortfolioImageResult
  | ErrorResult

export type DeleteCurrentUserPortfolioImageResult = {
  __typename?: 'DeleteCurrentUserPortfolioImageResult'
  status: Scalars['String']
}

export type DeleteNotificationInput = {
  id: Scalars['ID']
}

export type DeleteNotificationResponse = DeleteNotificationResult | ErrorResult

export type DeleteNotificationResult = {
  __typename?: 'DeleteNotificationResult'
  id: Scalars['ID']
  result: Scalars['Boolean']
}

export enum DeliveryMethodEnum {
  All = 'ALL',
  InHouse = 'IN_HOUSE',
  InPerson = 'IN_PERSON',
  Online = 'ONLINE',
  Webinar = 'WEBINAR',
}

export type DevelopmentSheet = {
  __typename?: 'DevelopmentSheet'
  filename: Scalars['String']
  url: Scalars['String']
}

export type DevelopmentSheetInput = {
  contentType: Scalars['String']
  fileBase64String: Scalars['String']
  filename: Scalars['String']
}

export type DynamoMigrateInput = {
  requireFilter: RequireFilter
  schemaProperty: Scalars['String']
  showRecords: Scalars['Boolean']
  subProperty?: Maybe<Scalars['String']>
  tableName: Scalars['String']
  updateRecords: Scalars['Boolean']
}

export type DynamoMigrateResponse = DynamoMigrateResult | ErrorResult

export type DynamoMigrateResult = {
  __typename?: 'DynamoMigrateResult'
  numOfChangedRecords?: Maybe<Scalars['Int']>
  numOfTotalRecords?: Maybe<Scalars['Int']>
  report: Scalars['String']
  schema: Scalars['String']
  status: Scalars['String']
}

export type EmailClubType = {
  __typename?: 'EmailClubType'
  affiliations?: Maybe<Scalars['AWSEmail']>
  jobListings?: Maybe<Scalars['AWSEmail']>
  main?: Maybe<Scalars['AWSEmail']>
}

export type EmailClubTypeInput = {
  affiliations?: Maybe<Scalars['AWSEmail']>
  jobListings?: Maybe<Scalars['AWSEmail']>
  main?: Maybe<Scalars['AWSEmail']>
}

export enum EmailStatusEnum {
  Available = 'AVAILABLE',
  DeactivatedAccount = 'DEACTIVATED_ACCOUNT',
  EmailInUse = 'EMAIL_IN_USE',
  GfmAccount = 'GFM_ACCOUNT',
}

export enum EmailTemplateEnum {
  ClubAffiliationApproved = 'CLUB_AFFILIATION_APPROVED',
  ClubAffiliationDeclined = 'CLUB_AFFILIATION_DECLINED',
  ClubAffiliationRemoved = 'CLUB_AFFILIATION_REMOVED',
  ClubAffiliationRequest = 'CLUB_AFFILIATION_REQUEST',
  ClubListingEnquiryMulti = 'CLUB_LISTING_ENQUIRY_MULTI',
  ClubListingEnquirySingle = 'CLUB_LISTING_ENQUIRY_SINGLE',
  ClubListingRenewalMulti = 'CLUB_LISTING_RENEWAL_MULTI',
  ClubListingRenewalSingle = 'CLUB_LISTING_RENEWAL_SINGLE',
  InstructorAffiliationApproved = 'INSTRUCTOR_AFFILIATION_APPROVED',
  InstructorAffiliationDeclined = 'INSTRUCTOR_AFFILIATION_DECLINED',
  InstructorAffiliationRemoved = 'INSTRUCTOR_AFFILIATION_REMOVED',
  InstructorAffiliationRequest = 'INSTRUCTOR_AFFILIATION_REQUEST',
  InstructorCertificateClubBillingAdd = 'INSTRUCTOR_CERTIFICATE_CLUB_BILLING_ADD',
  InstructorCertificateClubBillingRemove = 'INSTRUCTOR_CERTIFICATE_CLUB_BILLING_REMOVE',
  InstructorClubListingAlert = 'INSTRUCTOR_CLUB_LISTING_ALERT',
  InstructorClubMessage = 'INSTRUCTOR_CLUB_MESSAGE',
  InstructorClubPaymentStart = 'INSTRUCTOR_CLUB_PAYMENT_START',
  InstructorClubPaymentStop = 'INSTRUCTOR_CLUB_PAYMENT_STOP',
  InstructorCreditCardExpiry = 'INSTRUCTOR_CREDIT_CARD_EXPIRY',
  InstructorEmailChangeFyi = 'INSTRUCTOR_EMAIL_CHANGE_FYI',
  ReportClubProfile = 'REPORT_CLUB_PROFILE',
  ReportJobListingMultiCx = 'REPORT_JOB_LISTING_MULTI_CX',
  ReportJobListingSingleCx = 'REPORT_JOB_LISTING_SINGLE_CX',
  ReportListing = 'REPORT_LISTING',
  UnknownAffiliationRemoved = 'UNKNOWN_AFFILIATION_REMOVED',
  UserEmailNotUpdated = 'USER_EMAIL_NOT_UPDATED',
}

export type EmergencyContact = {
  __typename?: 'EmergencyContact'
  address?: Maybe<Scalars['String']>
  name: Scalars['String']
  phone: Scalars['String']
}

export type EmergencyContactInput = {
  address?: Maybe<Scalars['String']>
  name: Scalars['String']
  phone: Scalars['String']
  trainingId: Scalars['ID']
}

export type EnquireAboutExploreProgramsInput = {
  description?: Maybe<Scalars['String']>
  pickListValue: Scalars['String']
  programId: Scalars['String']
  programName: Scalars['String']
}

export type EnquireAboutExploreProgramsResponse =
  | EnquireAboutExploreProgramsResult
  | ErrorResult

export type EnquireAboutExploreProgramsResult = {
  __typename?: 'EnquireAboutExploreProgramsResult'
  lastEnquiredOn?: Maybe<Scalars['AWSDateTime']>
  programId: Scalars['String']
}

export type EnquireAboutInStudioProgramsInput = {
  description?: Maybe<Scalars['String']>
}

export type EnquireAboutInStudioProgramsResponse =
  | EnquireAboutInStudioProgramsResult
  | ErrorResult

export type EnquireAboutInStudioProgramsResult = {
  __typename?: 'EnquireAboutInStudioProgramsResult'
  isSuccess: Scalars['Boolean']
}

export type EnquireAboutOnlineProgramInput = {
  description?: Maybe<Scalars['String']>
  onlineProgramid: Scalars['String']
  pickListValue?: Maybe<Scalars['String']>
}

export type EnquireAboutOnlineProgramResponse =
  | EnquireAboutOnlineProgramResult
  | ErrorResult

export type EnquireAboutOnlineProgramResult = {
  __typename?: 'EnquireAboutOnlineProgramResult'
  lastEnquiredOn?: Maybe<Scalars['AWSDateTime']>
  onlineProgramid: Scalars['String']
}

export type EnquireAboutProgramInput = {
  description?: Maybe<Scalars['String']>
  pickListValue?: Maybe<Scalars['String']>
  programId: Scalars['String']
}

export type EnquireAboutProgramResponse =
  | EnquireAboutProgramResult
  | ErrorResult

export type EnquireAboutProgramResult = {
  __typename?: 'EnquireAboutProgramResult'
  lastEnquiredOn?: Maybe<Scalars['AWSDateTime']>
  programId: Scalars['String']
}

export enum EnquiryCategoryEnum {
  Enquire = 'ENQUIRE',
  Manage = 'MANAGE',
}

export type EnquiryClubListingSingleResult = SearchClubListingsResultCommon & {
  __typename?: 'EnquiryClubListingSingleResult'
  distance?: Maybe<Scalars['Float']>
  distanceUnit?: Maybe<LocationDistanceUnit>
  enquiredOn?: Maybe<Scalars['AWSDateTime']>
  hasBeenReported?: Maybe<Scalars['Boolean']>
  listing: EnquiryHistoryClubListing
  messageSentOn?: Maybe<Scalars['AWSDateTime']>
  onWatchlist?: Maybe<Scalars['Boolean']>
}

export type EnquiryHistoryClubListing =
  | ClubListingUnknown
  | ClubMultiListing
  | ClubSingleListing

export type EnquiryInput = {
  id: Scalars['String']
  syncId: Scalars['String']
}

export type EnsureUserProfileExistsInput = {
  id?: Maybe<Scalars['ID']>
  type: UserProfileType
}

export type EnsureUserProfileExistsResponse = ErrorResult | UserResult

export enum Error {
  AffiliationActionNotAllowed = 'AFFILIATION_ACTION_NOT_ALLOWED',
  AffiliationExists = 'AFFILIATION_EXISTS',
  AffiliationNotFound = 'AFFILIATION_NOT_FOUND',
  AffiliationValidation = 'AFFILIATION_VALIDATION',
  AreaSearchGeoPointsValidation = 'AREA_SEARCH_GEO_POINTS_VALIDATION',
  AssessmentNotFound = 'ASSESSMENT_NOT_FOUND',
  AssessmentOutcomesNotFound = 'ASSESSMENT_OUTCOMES_NOT_FOUND',
  AuthExpiredToken = 'AUTH_EXPIRED_TOKEN',
  AuthHeaderMissing = 'AUTH_HEADER_MISSING',
  AuthInternalError = 'AUTH_INTERNAL_ERROR',
  AuthInvalidHeader = 'AUTH_INVALID_HEADER',
  AuthInvalidRole = 'AUTH_INVALID_ROLE',
  AuthMissingData = 'AUTH_MISSING_DATA',
  AuthUnverifiedEmail = 'AUTH_UNVERIFIED_EMAIL',
  BillingUpdateInProgress = 'BILLING_UPDATE_IN_PROGRESS',
  CertificationNotFound = 'CERTIFICATION_NOT_FOUND',
  ClubListingNotFound = 'CLUB_LISTING_NOT_FOUND',
  ClubNotFound = 'CLUB_NOT_FOUND',
  ConfigError = 'CONFIG_ERROR',
  ContentNotFound = 'CONTENT_NOT_FOUND',
  DashboardEdgesUndefined = 'DASHBOARD_EDGES_UNDEFINED',
  DashboardFieldsUndefined = 'DASHBOARD_FIELDS_UNDEFINED',
  DuplicateAffiliationAction = 'DUPLICATE_AFFILIATION_ACTION',
  EmailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
  EnquiryNotFound = 'ENQUIRY_NOT_FOUND',
  EventAssessmentNotFound = 'EVENT_ASSESSMENT_NOT_FOUND',
  EventNotFound = 'EVENT_NOT_FOUND',
  EventbridgeClientError = 'EVENTBRIDGE_CLIENT_ERROR',
  FileNotFound = 'FILE_NOT_FOUND',
  InputParameterMissing = 'INPUT_PARAMETER_MISSING',
  IntegrationApiError = 'INTEGRATION_API_ERROR',
  InvalidProgramListing = 'INVALID_PROGRAM_LISTING',
  InvalidRequest = 'INVALID_REQUEST',
  LocationNearRequired = 'LOCATION_NEAR_REQUIRED',
  NoAffiliations = 'NO_AFFILIATIONS',
  NotEnoughCapacity = 'NOT_ENOUGH_CAPACITY',
  NotificationNotFound = 'NOTIFICATION_NOT_FOUND',
  PreWorkContentNotFound = 'PRE_WORK_CONTENT_NOT_FOUND',
  PriceNotFound = 'PRICE_NOT_FOUND',
  PrismicError = 'PRISMIC_ERROR',
  ProgramNotFound = 'PROGRAM_NOT_FOUND',
  S3SignedurlTokenNotFound = 'S3SIGNEDURL_TOKEN_NOT_FOUND',
  ServerError = 'SERVER_ERROR',
  SessionNotFound = 'SESSION_NOT_FOUND',
  SessionRegistrationNotFound = 'SESSION_REGISTRATION_NOT_FOUND',
  TicketNotFound = 'TICKET_NOT_FOUND',
  TrainingNotFound = 'TRAINING_NOT_FOUND',
  UnauthorizedEmail = 'UNAUTHORIZED_EMAIL',
  UserNotFound = 'USER_NOT_FOUND',
  UserProfileNotFound = 'USER_PROFILE_NOT_FOUND',
  UserSettingsNotFound = 'USER_SETTINGS_NOT_FOUND',
  VideoExisting = 'VIDEO_EXISTING',
}

export type ErrorResult = {
  __typename?: 'ErrorResult'
  component?: Maybe<Scalars['String']>
  logGroup?: Maybe<Scalars['String']>
  logStream?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  requestId?: Maybe<Scalars['String']>
  type: Error
}

export type Event = {
  __typename?: 'Event'
  deliveryMethod: DeliveryMethodEnum
  endDate: Scalars['String']
  eventId: Scalars['String']
  eventName: Scalars['String']
  programCode: Scalars['String']
  programName: Scalars['String']
  programNames?: Maybe<Array<Scalars['String']>>
  spacesAvailable: Scalars['Int']
  startDate: Scalars['String']
  timezone: Scalars['String']
  type: Scalars['String']
  venueAddress?: Maybe<Scalars['String']>
}

export enum Event_Sort_By {
  Nearest = 'NEAREST',
  Soonest = 'SOONEST',
}

export type EventAssessments = {
  __typename?: 'EventAssessments'
  attended?: Maybe<AttendedStatusEnum>
  developmentSheet?: Maybe<DevelopmentSheet>
  emergencyContact?: Maybe<EmergencyContact>
  instructorInfo: InstructorInformation
  outcome?: Maybe<OutcomeEnum>
  outcomeTrack?: Maybe<OutcomeTrack>
  presentationTrack?: Maybe<PresentationTrack>
  sessionRegistrationId: Scalars['String']
  trackAllocation?: Maybe<Scalars['String']>
  trainingJourneyId: Scalars['String']
}

export type EventDetailsResponse = ErrorResult | EventDetailsResult

export type EventDetailsResult = {
  __typename?: 'EventDetailsResult'
  cancellationPolicy?: Maybe<Scalars['AWSJSON']>
  details?: Maybe<Scalars['String']>
  event: Event
  requirements?: Maybe<Scalars['String']>
  schedule?: Maybe<Scalars['String']>
  tickets: Array<Ticket>
  trainerDetails?: Maybe<TrainerDetails>
}

export type EventEdge = {
  __typename?: 'EventEdge'
  cursor: Scalars['String']
  node: Event
}

export type EventLocationFilter = {
  near?: Maybe<EventLocationNearInput>
}

export type EventLocationNearInput = {
  coordinate: GeoPointInput
}

export type EventPageInfo = {
  __typename?: 'EventPageInfo'
  endCursor?: Maybe<SearchAfter>
  hasNextPage: Scalars['Boolean']
}

export type EventsResult = {
  __typename?: 'EventsResult'
  edges: Array<Maybe<EventEdge>>
  pageInfo: EventPageInfo
}

export enum EventStatusEnum {
  AwaitingOutcomes = 'AWAITING_OUTCOMES',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  OutcomesCompleted = 'OUTCOMES_COMPLETED',
  Published = 'PUBLISHED',
  RegistrationClosed = 'REGISTRATION_CLOSED',
}

export type EventSummary = {
  __typename?: 'EventSummary'
  deliveryMethod: DeliveryMethodEnum
  endDate: Scalars['AWSDate']
  eventId: Scalars['String']
  eventName: Scalars['String']
  eventStatus: EventStatusEnum
  programName: Scalars['String']
  startDate: Scalars['AWSDate']
  trainingJourneyCount: Scalars['Int']
  type: Scalars['String']
  venueAddress?: Maybe<Scalars['String']>
}

export type ExploreProgramDetails = {
  __typename?: 'ExploreProgramDetails'
  instructorsCount: Scalars['Int']
  lastEnquiredOn?: Maybe<Scalars['String']>
  program: Scalars['String']
}

export type ExploreProgramsPage = {
  __typename?: 'ExploreProgramsPage'
  products?: Maybe<Array<Maybe<InStudioExploreProgramsTile>>>
}

export type FeatureFlag = {
  __typename?: 'FeatureFlag'
  enabled: Scalars['Boolean']
  featureFlag: Scalars['String']
}

export type FeatureFlagQueryInput = {
  featureFlags: Array<Scalars['String']>
}

export type FeatureFlagResponse = ErrorResult | FeatureFlagResult

export type FeatureFlagResult = {
  __typename?: 'FeatureFlagResult'
  flags?: Maybe<Array<FeatureFlag>>
}

export type FilterBy = {
  deliveryMethod: DeliveryMethodEnum
  eventTypes?: Maybe<Array<Maybe<Scalars['String']>>>
  location: EventLocationFilter
  programCode?: Maybe<Array<ProgramCodeEnum>>
  programNames?: Maybe<Array<Scalars['String']>>
  spacesAvailable?: Maybe<Scalars['Int']>
}

export type FilterClubListingsWithFilterInput = {
  filterBy: ClubListingsFilterBy
}

export type FilterClubsInput = {
  filterBy: ClubFilterBy
  sort: Club_Sort_By
}

export type FilterEventsInput = {
  filterBy: FilterBy
  sort: Event_Sort_By
}

export type FinalizeUploadInput = {
  assessmentId: Scalars['String']
  comment?: Maybe<Scalars['String']>
  filename: Scalars['String']
  parts: Array<Part>
  trainingJourneyId: Scalars['String']
  uploadId: Scalars['String']
}

export type FinalizeUploadResponse = ErrorResult | FinalizeUploadResult

export type FinalizeUploadResult = {
  __typename?: 'FinalizeUploadResult'
  comment?: Maybe<Scalars['String']>
  isSuccess: Scalars['Boolean']
  playback: Playback
}

export type GeoPoint = {
  __typename?: 'GeoPoint'
  lat: Scalars['Float']
  lon: Scalars['Float']
}

export type GeoPointInput = {
  lat: Scalars['Float']
  lon: Scalars['Float']
}

export type GeoPolygon = {
  __typename?: 'GeoPolygon'
  points: Array<GeoPoint>
}

export type GeoPolygonInput = {
  points: Array<GeoPointInput>
}

export type GetAccountSettingsResponse = ErrorResult | UserAccountSettingsResult

export type GetAtHomeProductTilesResult = {
  __typename?: 'GetAtHomeProductTilesResult'
  active?: Maybe<Array<AtHomeProductTile>>
  inactive?: Maybe<Array<AtHomeProductTile>>
}

export type GetChainAffiliationsInput = {
  userId: Scalars['ID']
}

export type GetChainAffiliationsResponse =
  | ErrorResult
  | GetChainAffiliationsResult

export type GetChainAffiliationsResult = {
  __typename?: 'GetChainAffiliationsResult'
  clubs?: Maybe<Array<ClubWithAffiliation>>
}

export type GetChainInstructorAffiliatesInput = {
  clubIds?: Maybe<Array<Scalars['ID']>>
}

export type GetChainInstructorAffiliatesResponse =
  | ErrorResult
  | GetChainInstructorAffiliatesResult

export type GetChainInstructorAffiliatesResult = {
  __typename?: 'GetChainInstructorAffiliatesResult'
  affiliates?: Maybe<Array<InstructorChainAffiliate>>
  hasPendingAffiliations?: Maybe<Scalars['Boolean']>
  requests?: Maybe<Array<Maybe<InstructorChainRequest>>>
}

export type GetChainUser = {
  __typename?: 'GetChainUser'
  chainLocations?: Maybe<Array<UserProfileClub>>
  isChainUser?: Maybe<Scalars['Boolean']>
}

export type GetChainUserClubListingsInput = {
  clubIds: Array<Scalars['ID']>
}

export type GetChainUserClubListingsResponse =
  | ErrorResult
  | GetChainUserClubListingsResult

export type GetChainUserClubListingsResult = {
  __typename?: 'GetChainUserClubListingsResult'
  multiListings?: Maybe<Array<ClubMultiListing>>
  singleListings?: Maybe<Array<ClubSingleListing>>
}

export type GetClubAffiliationsInput = {
  accountId: Scalars['ID']
}

export type GetClubAffiliationsResponse = ClubAffiliationsResult | ErrorResult

export type GetClubByIdInput = {
  id: Scalars['ID']
}

export type GetClubByIdResponse = ErrorResult | UserProfileClub

export type GetClubInstructorAffiliatesInput = {
  id: Scalars['ID']
}

export type GetClubInstructorAffiliatesResponse =
  | ErrorResult
  | GetClubInstructorAffiliatesResult

export type GetClubInstructorAffiliatesResult = {
  __typename?: 'GetClubInstructorAffiliatesResult'
  affiliates?: Maybe<Array<InstructorAffiliate>>
  requests?: Maybe<Array<InstructorRequest>>
}

export type GetClubListingByIdResponse =
  | ErrorResult
  | SearchClubJobListingsSingleResult

export type GetClubListingsByClubIdInput = {
  filters?: Maybe<GetClubListingsByClubIdInputFilters>
  id: Scalars['ID']
}

export type GetClubListingsByClubIdInputFilters = {
  programs?: Maybe<Array<Scalars['ID']>>
  status?: Maybe<ClubListingStatus>
}

export type GetClubListingsByClubIdResponse =
  | ErrorResult
  | GetClubListingsByClubIdResult

export type GetClubListingsByClubIdResult = {
  __typename?: 'GetClubListingsByClubIdResult'
  results: Array<ClubListing>
}

export type GetClubNotificationsFilter = {
  __typename?: 'GetClubNotificationsFilter'
  clubId: Scalars['ID']
  dateTimeFilter: DateTimeFilter
}

export type GetClubNotificationsInput = {
  clubId: Scalars['ID']
  dateTimeFilter: DateTimeFilterInput
}

export type GetClubNotificationsResponse =
  | ErrorResult
  | GetClubNotificationsResult

export type GetClubNotificationsResult = {
  __typename?: 'GetClubNotificationsResult'
  filter: GetClubNotificationsFilter
  items?: Maybe<Array<Notification>>
  totalCount: Scalars['Int']
  translation?: Maybe<GetNotificationTranslation>
}

export type GetClubProgramsResponse = ClubProgramsResult | ErrorResult

export type GetClubsAvailabilityOnListingInput = {
  isSubstituteListing: Scalars['Boolean']
  programId?: Maybe<Scalars['String']>
  programName?: Maybe<Scalars['String']>
}

export type GetClubsAvailabilityOnListingResponse =
  | ErrorResult
  | GetClubsAvailabilityOnListingResult

export type GetClubsAvailabilityOnListingResult = {
  __typename?: 'GetClubsAvailabilityOnListingResult'
  allClubs?: Maybe<Array<ClubAvailabilityOnListing>>
  clubsWithExistingListing?: Maybe<Array<ClubAvailabilityOnListing>>
}

export type GetClubSettingsInput = {
  id: Scalars['ID']
}

export type GetClubSettingsResponse = ErrorResult | GetClubSettingsResult

export type GetClubSettingsResult = {
  __typename?: 'GetClubSettingsResult'
  address?: Maybe<Address>
  email?: Maybe<EmailClubType>
  id: Scalars['ID']
  name: Scalars['String']
  phone?: Maybe<Scalars['String']>
}

export type GetContactUsContentInput = {
  market: Scalars['String']
  userType: UserProfileType
}

export type GetContactUsContentResponse =
  | ErrorResult
  | GetContactUsContentResult

export type GetContactUsContentResult = {
  __typename?: 'GetContactUsContentResult'
  result: Array<SectionContentResult>
}

export type GetCurrentUserPhotoUploadDataResponse =
  | ErrorResult
  | GetCurrentUserPhotoUploadDataResult

export type GetCurrentUserPhotoUploadDataResult = {
  __typename?: 'GetCurrentUserPhotoUploadDataResult'
  token: Scalars['String']
  url: Scalars['String']
}

export type GetCurrentUserPortfolioUploadDataInput = {
  photoCount: Scalars['Int']
}

export type GetCurrentUserPortfolioUploadDataResponse =
  | ErrorResult
  | GetCurrentUserPortfolioUploadDataResult

export type GetCurrentUserPortfolioUploadDataResult = {
  __typename?: 'GetCurrentUserPortfolioUploadDataResult'
  items: Array<PortfolioUploadItem>
}

export type GetDashboardResponse =
  | DashboardPageClubResponse
  | DashboardPageInstructorResponse
  | ErrorResult

export type GetEqualOpportunitiesPolicyInput = {
  market: Scalars['String']
}

export type GetEqualOpportunitiesPolicyResponse =
  | ErrorResult
  | GetEqualOpportunitiesPolicyResult

export type GetEqualOpportunitiesPolicyResult = {
  __typename?: 'GetEqualOpportunitiesPolicyResult'
  result: Array<SectionContentResult>
}

export type GetExploreProgramsPageResult = ErrorResult | ExploreProgramsPage

export type GetFrequentlyAskedQuestionsInput = {
  market: Scalars['String']
  userType: UserProfileType
}

export type GetFrequentlyAskedQuestionsResponse =
  | ErrorResult
  | GetFrequentlyAskedQuestionsResult

export type GetFrequentlyAskedQuestionsResult = {
  __typename?: 'GetFrequentlyAskedQuestionsResult'
  result: Array<SectionContentResult>
}

export type GetHeaderInput = {
  keys?: Maybe<Array<Scalars['String']>>
}

export type GetHeaderResponse = ErrorResult | GetHeaderResult

export type GetHeaderResult = {
  __typename?: 'GetHeaderResult'
  results: Array<Header>
}

export type GetInstructorClubAffiliatesInput = {
  id: Scalars['ID']
}

export type GetInstructorClubAffiliatesResponse =
  | ErrorResult
  | GetInstructorClubAffiliatesResult

export type GetInstructorClubAffiliatesResult = {
  __typename?: 'GetInstructorClubAffiliatesResult'
  affiliations?: Maybe<Array<ClubAffiliates>>
}

export type GetInStudioToolkitResult = {
  __typename?: 'GetInStudioToolkitResult'
  tiles?: Maybe<Array<Maybe<InStudioToolkitTile>>>
}

export type GetNavigationInput = {
  market: Scalars['String']
  role: UserProfileType
  subroles?: Maybe<Array<InstructorRole>>
}

export type GetNavigationResponse = ErrorResult | Navigation

export type GetNotificationResponse = ErrorResult | Notification

export type GetNotificationsResponse = ErrorResult | GetNotificationsResult

export type GetNotificationsResult = DateTimeFilteredResponse & {
  __typename?: 'GetNotificationsResult'
  filter: DateTimeFilter
  items?: Maybe<Array<Notification>>
  totalCount: Scalars['Int']
  translation?: Maybe<GetNotificationTranslation>
}

export type GetNotificationTranslation = {
  __typename?: 'GetNotificationTranslation'
  footer: Scalars['String']
  title: Scalars['String']
  totalNew: Scalars['String']
}

export type GetPickListInput = {
  keys?: Maybe<Array<Scalars['String']>>
}

export type GetPickListResponse = ErrorResult | GetPickListResult

export type GetPickListResult = {
  __typename?: 'GetPickListResult'
  results: Array<PickList>
}

export type GetPresignedUrlInput = {
  assessmentId: Scalars['String']
  filename: Scalars['String']
  totalPartNumber: Scalars['Int']
  trainingJourneyId: Scalars['String']
  uploadId: Scalars['String']
}

export type GetPresignedUrlResponse = ErrorResult | GetPresignedUrlResult

export type GetPresignedUrlResult = {
  __typename?: 'GetPresignedUrlResult'
  partSignedUrlList: Array<PartSignedUrl>
}

export type GetPrivacySettingValueResult = {
  __typename?: 'GetPrivacySettingValueResult'
  description: Scalars['String']
  title: Scalars['String']
  value: PrivacySettingValue
}

export type GetPrivacySettingValuesResponse =
  | ErrorResult
  | GetPrivacySettingValuesResult

export type GetPrivacySettingValuesResult = {
  __typename?: 'GetPrivacySettingValuesResult'
  values: Array<GetPrivacySettingValueResult>
}

export type GetProfileByIdInput = {
  id: Scalars['ID']
  type: UserProfileType
}

export type GetProfileByIdResponse = ErrorResult | GetProfileByIdResult

export type GetProfileByIdResult = {
  __typename?: 'GetProfileByIdResult'
  hasBeenReported?: Maybe<Scalars['Boolean']>
  hasExistingAffiliation?: Maybe<Scalars['Boolean']>
  hasRecentlyDeclinedAffiliation?: Maybe<Scalars['Boolean']>
  instructorAffiliation?: Maybe<Affiliation>
  lastClubMessageAt?: Maybe<Scalars['AWSDateTime']>
  lastClubMessageIsIn48Hours?: Maybe<Scalars['Boolean']>
  lastMessageIn48HoursSentAt?: Maybe<Scalars['AWSDateTime']>
  onWatchlist?: Maybe<Scalars['Boolean']>
  profile?: Maybe<UserProfile>
  profileUser?: Maybe<User>
  withinDistance?: Maybe<Scalars['Boolean']>
}

export type GetSavedInstructorSearchDataInput = {
  id?: Maybe<Scalars['ID']>
}

export type GetSavedInstructorSearchDataResponse =
  | ErrorResult
  | GetSavedInstructorSearchDataResult

export type GetSavedInstructorSearchDataResult = {
  __typename?: 'GetSavedInstructorSearchDataResult'
  results: Array<SavedInstructorSearch>
}

export type GetSharedNavigation = ErrorResult | SharedNavigation

export type GetSignedAgreementInput = {
  type: AgreementType
  version?: Maybe<Scalars['String']>
}

export type GetUserByAccountIdInput = {
  accountId: Scalars['ID']
}

export type GetUserByEmailInput = {
  email: Scalars['AWSEmail']
}

export type GetUserByIdInput = {
  id: Scalars['ID']
}

export type GetUserEnquiriesResponse = ErrorResult | GetUserEnquiriesResult

export type GetUserEnquiriesResult = {
  __typename?: 'GetUserEnquiriesResult'
  enquiries?: Maybe<Array<UserEnquiry>>
}

export type GetUserResponse = ErrorResult | UserResult

export type GetUserSessionResponse = ErrorResult | GetUserSessionResult

export type GetUserSessionResult = {
  __typename?: 'GetUserSessionResult'
  country?: Maybe<CountryEnum>
  eventId: Scalars['String']
  market?: Maybe<Scalars['String']>
  state?: Maybe<StatesEnum>
}

export type GetVideoGuidelinesResponse = ErrorResult | GetVideoGuidelinesResult

export type GetVideoGuidelinesResult = {
  __typename?: 'GetVideoGuidelinesResult'
  contactUsEmail: Scalars['String']
  result: Array<SectionContentResult>
}

export type GetWalkthroughResponse = ErrorResult | Walkthrough

export type Header = {
  __typename?: 'Header'
  image?: Maybe<ImageSet>
  key: Scalars['String']
  subHeading?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type Image = {
  __typename?: 'Image'
  alt?: Maybe<Scalars['String']>
  bucket?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  key?: Maybe<Scalars['String']>
  url: Scalars['String']
  width?: Maybe<Scalars['Int']>
}

export type ImageInput = {
  alt?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['ID']>
  url?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
}

export type ImageSet = {
  __typename?: 'ImageSet'
  desktop: Image
  id: Scalars['ID']
  mobile: Image
}

export type IncrementViewClubListingCountInput = {
  clubId: Scalars['ID']
  listingId: Scalars['ID']
}

export type IncrementViewClubListingCountResponse =
  | ClubListingResult
  | ErrorResult

export type InitializeUploadInput = {
  assessmentId: Scalars['String']
  contentType: Scalars['String']
  filename: Scalars['String']
  trainingJourneyId: Scalars['String']
}

export type InitializeUploadResponse = ErrorResult | InitializeUploadResult

export type InitializeUploadResult = {
  __typename?: 'InitializeUploadResult'
  uploadId: Scalars['String']
}

export type InstructorAffiliate = {
  __typename?: 'InstructorAffiliate'
  actionedDate?: Maybe<Scalars['AWSDateTime']>
  clubId: Scalars['String']
  id: Scalars['ID']
  profile: UserProfileTeaching
  purchaseOrder?: Maybe<Scalars['String']>
  requestedBy: UserRoleType
  requestedDate: Scalars['AWSDateTime']
  status: AffiliationStatus
  user: User
}

export type InstructorAndSessions = {
  instructorId: Scalars['String']
  sessions: Array<Scalars['String']>
}

export type InstructorAvailability = {
  __typename?: 'InstructorAvailability'
  availableToSubstitute: Scalars['Boolean']
  description: Scalars['String']
  title: Scalars['String']
  value: InstructorAvailabilityValue
}

export enum InstructorAvailabilityValue {
  Available = 'AVAILABLE',
  Open = 'OPEN',
  Unavailable = 'UNAVAILABLE',
}

export type InstructorCertificate = {
  __typename?: 'InstructorCertificate'
  certificate?: Maybe<Certification>
  instructorName?: Maybe<Scalars['String']>
  transactionStatus?: Maybe<TransactionStatusEnum>
}

export type InstructorCertificatesInput = {
  certificates?: Maybe<Array<BilledCertificationInput>>
  purchaseOrder?: Maybe<Scalars['String']>
  userId: Scalars['ID']
}

export type InstructorCertificationInput = {
  active: Scalars['Boolean']
  billedClubId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isBlockedFromAutoship?: Maybe<Scalars['Boolean']>
  lastPurchase?: Maybe<Scalars['String']>
  link: LinkCertificationInput
  lmqLevel: Scalars['Float']
  price?: Maybe<Scalars['String']>
  program: ProgramInput
  purchaseOrder?: Maybe<Scalars['String']>
  releaseActivityStatus?: Maybe<ReleaseActivityStatusEnum>
  snapshotState?: Maybe<SnapshotStateEnum>
  status: CertificationStatusEnum
  steps?: Maybe<Array<CertificationStepInput>>
  subscribed?: Maybe<Scalars['Boolean']>
}

export type InstructorChainAffiliate = {
  __typename?: 'InstructorChainAffiliate'
  clubAffiliates?: Maybe<Array<ClubAffiliates>>
  profile: UserProfileTeaching
  purchaseOrder?: Maybe<Scalars['String']>
  user: User
}

export type InstructorChainRequest = {
  __typename?: 'InstructorChainRequest'
  clubId: Scalars['String']
  clubName: Scalars['String']
  distance?: Maybe<Scalars['Float']>
  distanceUnit?: Maybe<LocationDistanceUnit>
  id: Scalars['ID']
  pendingStatus?: Maybe<AffiliationStatus>
  profile: UserProfileTeaching
  requestedBy: UserRoleType
  requestedDate: Scalars['AWSDateTime']
  status: AffiliationStatus
  user: User
}

export type InstructorDetails = {
  __typename?: 'InstructorDetails'
  id: Scalars['String']
  name: Scalars['String']
  profileImage?: Maybe<Image>
}

export type InstructorInformation = {
  __typename?: 'InstructorInformation'
  address?: Maybe<Scalars['String']>
  email: Scalars['String']
  name: Scalars['String']
  phone: Scalars['String']
}

export type InstructorPreferences = {
  __typename?: 'InstructorPreferences'
  programs?: Maybe<Array<Program>>
  times?: Maybe<Array<TimePreference>>
  travel?: Maybe<TravelPreference>
}

export type InstructorPreferencesInput = {
  programs?: Maybe<Array<Scalars['ID']>>
  times?: Maybe<Array<TimePreferenceInput>>
  travel?: Maybe<TravelPreferenceInput>
}

export type InstructorRequest = {
  __typename?: 'InstructorRequest'
  clubId: Scalars['String']
  distance?: Maybe<Scalars['Float']>
  distanceUnit?: Maybe<LocationDistanceUnit>
  id: Scalars['ID']
  pendingStatus?: Maybe<AffiliationStatus>
  profile: UserProfileTeaching
  requestedBy: UserRoleType
  requestedDate: Scalars['AWSDateTime']
  status: AffiliationStatus
  user: User
}

export enum InstructorRole {
  Assessor = 'ASSESSOR',
  Instructor = 'INSTRUCTOR',
  Presenter = 'PRESENTER',
  Trainer = 'TRAINER',
}

export type InstructorShortlist = {
  __typename?: 'InstructorShortlist'
  distance?: Maybe<Scalars['Float']>
  distanceUnit?: Maybe<LocationDistanceUnit>
  hasExistingAffiliation?: Maybe<Scalars['Boolean']>
  hasRecentlyDeclinedAffiliation?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  instructorAffiliation?: Maybe<Affiliation>
  lastMessageIn48HoursSentAt?: Maybe<Scalars['AWSDateTime']>
  profile: UserProfileTeaching
  user: User
}

export type InstructorWatchlist = {
  __typename?: 'InstructorWatchlist'
  watchlist?: Maybe<Array<InstructorWatchlistItem>>
}

export type InstructorWatchlistItem = {
  __typename?: 'InstructorWatchlistItem'
  clubId: Scalars['String']
  listingId?: Maybe<Scalars['String']>
}

export type InstructorWatchlistItemInput = {
  clubId: Scalars['String']
  listingId?: Maybe<Scalars['String']>
}

export type InStudioDetailsView = {
  __typename?: 'InStudioDetailsView'
  description: Scalars['String']
  moreURL?: Maybe<Url>
  typeInfo?: Maybe<Array<Maybe<ProgramTypeInfo>>>
  videoContentId?: Maybe<Scalars['String']>
}

export type InStudioEmptyState = {
  __typename?: 'InStudioEmptyState'
  link: Link
  subtitle: Scalars['String']
  title: Scalars['String']
}

export type InStudioExploreProgramsTile = {
  __typename?: 'InStudioExploreProgramsTile'
  detailView?: Maybe<InStudioDetailsView>
  enquirySent?: Maybe<Scalars['AWSDateTime']>
  genre?: Maybe<Scalars['String']>
  image?: Maybe<Image>
  name?: Maybe<Scalars['String']>
  numberOfCertifiedInstructors?: Maybe<Scalars['Int']>
  productCategories?: Maybe<Array<Maybe<ProgramTypeEnum>>>
  productId: Scalars['String']
}

export type InStudioExploreProgramsTileCardResult = {
  __typename?: 'InStudioExploreProgramsTileCardResult'
  image?: Maybe<Image>
}

export type InStudioToolkitTile = {
  __typename?: 'InStudioToolkitTile'
  image: Image
  link: Link
  subTitle: Scalars['String']
  title: Scalars['String']
}

export enum LicenseTypeEnum {
  Licensed = 'LICENSED',
  Unlicensed = 'UNLICENSED',
}

export type Link = {
  __typename?: 'Link'
  id: Scalars['ID']
  isPrimary?: Maybe<Scalars['Boolean']>
  text: Scalars['String']
  url: Url
}

export type LinkCertificationInput = {
  id: Scalars['ID']
  text: Scalars['String']
  url: UrlInput
}

export type LinkInput = {
  text: Scalars['String']
  url: UrlInput
}

export type LinkTile = {
  __typename?: 'LinkTile'
  id: Scalars['ID']
  imageSet: ImageSet
  subtitle: Scalars['String']
  title: Scalars['String']
  url: Url
}

export enum LinkTypeIcon {
  Chevron = 'CHEVRON',
  ExternalLink = 'EXTERNAL_LINK',
}

export enum Locale {
  DeDe = 'de_DE',
  EnGb = 'en_GB',
  EnPh = 'en_PH',
  EnSe = 'en_SE',
  EnUk = 'en_UK',
  EnUs = 'en_US',
  JaJp = 'ja_JP',
  PtBr = 'pt_BR',
  RuRu = 'ru_RU',
  ZhCn = 'zh_CN',
}

export enum LocationDistanceUnit {
  Km = 'KM',
  Miles = 'MILES',
}

export type LocationFilter = {
  __typename?: 'LocationFilter'
  near?: Maybe<LocationNear>
  point?: Maybe<GeoPoint>
  within?: Maybe<LocationWithin>
}

export type LocationFilterInput = {
  near?: Maybe<LocationNearInput>
  point?: Maybe<GeoPointInput>
  within?: Maybe<LocationWithinInput>
}

export type LocationNear = {
  __typename?: 'LocationNear'
  address?: Maybe<Address>
  distance: Scalars['Int']
  distanceUnit: LocationDistanceUnit
}

export type LocationNearInput = {
  address?: Maybe<AddressInput>
  distance: Scalars['Int']
  distanceUnit: LocationDistanceUnit
}

export type LocationWithin = {
  __typename?: 'LocationWithin'
  address?: Maybe<Address>
  area: GeoPolygon
}

export type LocationWithinInput = {
  address?: Maybe<AddressInput>
  area: GeoPolygonInput
}

export enum LogoIcon {
  DigitalHubBlack = 'DIGITAL_HUB_BLACK',
  DigitalHubWhite = 'DIGITAL_HUB_WHITE',
}

export type MagentoStoreProfileInput = {
  customerId?: Maybe<Scalars['String']>
  storeName?: Maybe<Scalars['String']>
}

export type Market = {
  __typename?: 'Market'
  countries?: Maybe<Array<Country>>
  distanceUnit: LocationDistanceUnit
  isSupported?: Maybe<Scalars['Boolean']>
  locale: Locale
  name: Scalars['String']
  programs?: Maybe<Array<Program>>
}

export type MarketInput = {
  countries?: Maybe<Array<CountryInput>>
  distanceUnit: LocationDistanceUnit
  locale: Locale
  name: Scalars['String']
  programs?: Maybe<Array<ProgramInput>>
}

export type Menu = {
  __typename?: 'Menu'
  id: Scalars['ID']
  items: Array<RootMenuItem>
  name: Scalars['String']
}

export type MenuItem = {
  __typename?: 'MenuItem'
  analyticsEvent?: Maybe<AnalyticsEvent>
  id: Scalars['ID']
  label: Scalars['String']
  url: Url
}

export enum MenuItemIcon {
  Assessor = 'ASSESSOR',
  Goals = 'GOALS',
  Home = 'HOME',
  ManageMyPartnership = 'MANAGE_MY_PARTNERSHIP',
  More = 'MORE',
  Plans = 'PLANS',
  Profile = 'PROFILE',
  Releases = 'RELEASES',
  Trainer = 'TRAINER',
}

export type Message = {
  __typename?: 'Message'
  clubId: Scalars['ID']
  clubName: Scalars['String']
  id: Scalars['ID']
  listingId?: Maybe<Scalars['ID']>
  message: Scalars['String']
  profileId: Scalars['ID']
  readAt?: Maybe<Scalars['AWSDateTime']>
  requestedBy: UserRoleType
  sentAt: Scalars['AWSDateTime']
}

export enum MessageBusNotificationType {
  ClubAffiliationRemoved = 'CLUB_AFFILIATION_REMOVED',
  ClubAffiliationRequestApproved = 'CLUB_AFFILIATION_REQUEST_APPROVED',
  ClubAffiliationRequestDeclined = 'CLUB_AFFILIATION_REQUEST_DECLINED',
  ClubDataWarehouseSync = 'CLUB_DATA_WAREHOUSE_SYNC',
  ClubJobListingEnquiry = 'CLUB_JOB_LISTING_ENQUIRY',
  ClubJobListingEnquiryFromProfile = 'CLUB_JOB_LISTING_ENQUIRY_FROM_PROFILE',
  ClubNewAffiliationRequest = 'CLUB_NEW_AFFILIATION_REQUEST',
  InstructorAffiliationRemoved = 'INSTRUCTOR_AFFILIATION_REMOVED',
  InstructorAffiliationRequestApproved = 'INSTRUCTOR_AFFILIATION_REQUEST_APPROVED',
  InstructorAffiliationRequestDeclined = 'INSTRUCTOR_AFFILIATION_REQUEST_DECLINED',
  InstructorClubListingAlert = 'INSTRUCTOR_CLUB_LISTING_ALERT',
  InstructorCreditCardExpiry = 'INSTRUCTOR_CREDIT_CARD_EXPIRY',
  InstructorDataWarehouseSync = 'INSTRUCTOR_DATA_WAREHOUSE_SYNC',
  InstructorJobListingClubHiring = 'INSTRUCTOR_JOB_LISTING_CLUB_HIRING',
  InstructorNewAffiliationRequest = 'INSTRUCTOR_NEW_AFFILIATION_REQUEST',
  SystemGeneratedNotification = 'SYSTEM_GENERATED_NOTIFICATION',
  UnknownAffiliationRemoved = 'UNKNOWN_AFFILIATION_REMOVED',
}

export type Mutation = {
  __typename?: 'Mutation'
  _root?: Maybe<Scalars['String']>
  addClubListing?: Maybe<AddClubListingResponse>
  addEmergencyContact: AddEmergencyContactResponse
  addToShortlist?: Maybe<AddToShortlistResponse>
  addTrainingOutcome: AddTrainingOutcomeResponse
  bookEvent: BookEventResponse
  bookQuarterlyWorkshopEvent: BookEventResponse
  createAffiliation: AffiliationAsyncResponse
  createAffiliations: ChainAffiliationsResponse
  createClubListings?: Maybe<CreateClubListingsResponse>
  createInstructorAccount: CreateInstructorAccountResponse
  createNotification?: Maybe<GetNotificationResponse>
  deleteAffiliation: AffiliationAsyncResponse
  deleteClubListing?: Maybe<DeleteClubListingResponse>
  deleteClubListings?: Maybe<DeleteClubListingsResponse>
  deleteCurrentUserPortfolioImage: DeleteCurrentUserPortfolioImageResponse
  deleteNotification?: Maybe<DeleteNotificationResponse>
  enquireAboutInStudioPrograms?: Maybe<EnquireAboutInStudioProgramsResponse>
  enquireAboutOnlineProgram?: Maybe<EnquireAboutOnlineProgramResponse>
  enquireAboutProgram?: Maybe<EnquireAboutProgramResponse>
  enquireAboutProgramInExplorePrograms?: Maybe<EnquireAboutExploreProgramsResponse>
  finalizeUpload?: Maybe<FinalizeUploadResponse>
  incrementViewClubListingCount?: Maybe<IncrementViewClubListingCountResponse>
  initializeUpload?: Maybe<InitializeUploadResponse>
  payForCertification?: Maybe<PayForCertificationResponse>
  removeAffiliations: ChainAffiliationsRemoveResponse
  removeFromShortlist?: Maybe<RemoveFromShortlistResponse>
  reportClubListing?: Maybe<ReportClubListingResponse>
  reportClubProfile?: Maybe<ReportClubProfileResponse>
  requestForExtension: RequestForExtensionResponse
  resendPasswordResetEmail: ResendPasswordResetEmailResponse
  revertAppMetadata: AppMetadataResponse
  sendMessage?: Maybe<SendMessageResponse>
  setAppMetadata: AppMetadataResponse
  setClubSettings?: Maybe<SetClubSettingsResponse>
  setInstructorAvailability?: Maybe<SetInstructorAvailabilityResponse>
  setInstructorTravelPreferences?: Maybe<SetInstructorTravelPreferencesResponse>
  setInstructorWatchlist?: Maybe<SetInstructorWatchlistResponse>
  setPrivacySettings?: Maybe<SetPrivacySettingsResponse>
  setQwsSessionAttendances: SetQwsSessionAttendancesResponse
  setSavedInstructorSearchData?: Maybe<SetSavedInstructorSearchDataResponse>
  setSignedAgreementData?: Maybe<AgreementDocumentResponse>
  setWalkthroughToSeen?: Maybe<SetWalkthroughToSeenResponse>
  testPostUpsertClub?: Maybe<TestPostUpsertClubResponse>
  testPostUpsertUser?: Maybe<TestPostUpsertUserResponse>
  toggleNotificationsRead?: Maybe<ToggleNotificationReadResponse>
  updateAffiliation: AffiliationAsyncResponse
  updateClubListing?: Maybe<UpdateClubListingResponse>
  updateClubProfile: UpdateClubProfileResponse
  updateCurrentUserPortfolioUpload: UpdateCurrentUserPortfolioUploadResponse
  updateTeachingProfile: UpdateProfileResponse
  updateUserAccount: UpdateAccountSettingsResponse
  updateUserPhoto?: Maybe<UpdateUserPhotoResponse>
  upsertAgreement: UpsertAgreementResponse
  upsertClub: UpsertClubResponse
  upsertPricebook: UpsertPricebookResponse
  upsertUser: UpsertUserResponse
}

export type Mutation_RootArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type MutationAddClubListingArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: AddClubListingInput
}

export type MutationAddEmergencyContactArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: EmergencyContactInput
}

export type MutationAddToShortlistArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: AddToShortlistInput
  role: UserRoleInput
}

export type MutationAddTrainingOutcomeArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: AddTrainingOutcomeInput
}

export type MutationBookEventArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: BookEventInput
  role: UserRoleInput
}

export type MutationBookQuarterlyWorkshopEventArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: BookQwsInput
  role: UserRoleInput
}

export type MutationCreateAffiliationArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: CreateAffiliationInput
  role: UserRoleInput
}

export type MutationCreateAffiliationsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: CreateAffiliationsInput
}

export type MutationCreateClubListingsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: CreateClubListingsInput
}

export type MutationCreateInstructorAccountArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: CreateInstructorAccountInput
}

export type MutationCreateNotificationArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: CreateNotificationInput
}

export type MutationDeleteAffiliationArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: DeleteAffiliationInput
  role: UserRoleInput
}

export type MutationDeleteClubListingArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: DeleteClubListingInput
}

export type MutationDeleteClubListingsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: DeleteClubListingsInput
}

export type MutationDeleteCurrentUserPortfolioImageArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: DeleteCurrentUserPortfolioImageInput
  role: UserRoleInput
}

export type MutationDeleteNotificationArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: DeleteNotificationInput
}

export type MutationEnquireAboutInStudioProgramsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: EnquireAboutInStudioProgramsInput
  role: UserRoleInput
}

export type MutationEnquireAboutOnlineProgramArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: EnquireAboutOnlineProgramInput
  role: UserRoleInput
}

export type MutationEnquireAboutProgramArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: EnquireAboutProgramInput
  role: UserRoleInput
}

export type MutationEnquireAboutProgramInExploreProgramsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: EnquireAboutExploreProgramsInput
  role: UserRoleInput
}

export type MutationFinalizeUploadArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input?: Maybe<FinalizeUploadInput>
}

export type MutationIncrementViewClubListingCountArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: IncrementViewClubListingCountInput
}

export type MutationInitializeUploadArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input?: Maybe<InitializeUploadInput>
}

export type MutationPayForCertificationArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: PayForCertificationInput
}

export type MutationRemoveAffiliationsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: DeleteAffiliationsInput
}

export type MutationRemoveFromShortlistArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: RemoveFromShortlistInput
  role: UserRoleInput
}

export type MutationReportClubListingArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: ReportClubListingInput
}

export type MutationReportClubProfileArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: ReportClubProfileInput
}

export type MutationRequestForExtensionArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: RequestForExtensionInput
}

export type MutationResendPasswordResetEmailArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: ResendPasswordResetEmailInput
}

export type MutationRevertAppMetadataArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: RevertAppMetadataInput
}

export type MutationSendMessageArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: SendMessageInput
}

export type MutationSetAppMetadataArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: SetAppMetadataInput
}

export type MutationSetClubSettingsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: SetClubSettingsInput
}

export type MutationSetInstructorAvailabilityArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: SetInstructorAvailabilityInput
}

export type MutationSetInstructorTravelPreferencesArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: SetInstructorTravelPreferencesInput
}

export type MutationSetInstructorWatchlistArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: SetInstructorWatchlistInput
  role: UserRoleInput
}

export type MutationSetPrivacySettingsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: SetPrivacySettingsInput
}

export type MutationSetQwsSessionAttendancesArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: SetQwsSessionAttendancesInput
}

export type MutationSetSavedInstructorSearchDataArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: SetSavedInstructorSearchDataInput
}

export type MutationSetSignedAgreementDataArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: SetSignedAgreementDataInput
}

export type MutationSetWalkthroughToSeenArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  role: UserRoleInput
}

export type MutationTestPostUpsertClubArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: TestPostUpsertClubInput
}

export type MutationTestPostUpsertUserArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: TestPostUpsertUserInput
}

export type MutationToggleNotificationsReadArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: ToggleNotificationReadInput
  role: UserRoleInput
}

export type MutationUpdateAffiliationArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: UpdateAffiliationInput
  role: UserRoleInput
}

export type MutationUpdateClubListingArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: UpdateClubListingInput
}

export type MutationUpdateClubProfileArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: UpdateClubProfileInput
}

export type MutationUpdateCurrentUserPortfolioUploadArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: UpdateCurrentUserPortfolioUploadInput
  role: UserRoleInput
}

export type MutationUpdateTeachingProfileArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: UpdateTeachingProfileInput
}

export type MutationUpdateUserAccountArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: UpdateUserAccountInput
}

export type MutationUpdateUserPhotoArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: UpdateUserPhotoInput
}

export type MutationUpsertAgreementArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: UpsertAgreementInput
}

export type MutationUpsertClubArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: UpsertClubInput
  metadata?: Maybe<SyncMetadata>
}

export type MutationUpsertPricebookArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: UpsertPricebookInput
}

export type MutationUpsertUserArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: UpsertUserInput
  metadata?: Maybe<SyncMetadata>
}

export type Navigation = {
  __typename?: 'Navigation'
  appMenu: Menu
  userMenu: RootMenuItem
}

export type NavItem = {
  __typename?: 'NavItem'
  description: Scalars['String']
  image?: Maybe<Image>
  key?: Maybe<NavKey>
  title?: Maybe<Scalars['String']>
  url: Url
}

export enum NavKey {
  ClubPortal = 'CLUB_PORTAL',
  InstructorPortal = 'INSTRUCTOR_PORTAL',
  LearningStudio = 'LEARNING_STUDIO',
  LesmillsEquipmentClub = 'LESMILLS_EQUIPMENT_CLUB',
  LesmillsEquipmentInstructor = 'LESMILLS_EQUIPMENT_INSTRUCTOR',
  LesmillsPlus = 'LESMILLS_PLUS',
  MarketingStudio = 'MARKETING_STUDIO',
}

export type Notification = {
  __typename?: 'Notification'
  body?: Maybe<Scalars['String']>
  contentType?: Maybe<MessageBusNotificationType>
  createdAt: Scalars['String']
  createdAtLabel: Scalars['String']
  createdAtTs: Scalars['Long']
  id: Scalars['ID']
  isNew: Scalars['Boolean']
  link?: Maybe<Link>
  params?: Maybe<NotificationParams>
  readAt?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type: NotificationType
  updatedAt: Scalars['String']
  updatedAtTs: Scalars['Long']
}

export type NotificationParams = {
  __typename?: 'NotificationParams'
  userName?: Maybe<Scalars['String']>
}

export enum NotificationType {
  Plans = 'PLANS',
  Positions = 'POSITIONS',
  Releases = 'RELEASES',
}

export enum OutcomeEnum {
  Pass = 'PASS',
  PassPending = 'PASS_PENDING',
  ReSubmitRequired = 'RE_SUBMIT_REQUIRED',
  ReTrain = 'RE_TRAIN',
  SendNewVideo = 'SEND_NEW_VIDEO',
}

export type OutcomeTrack = {
  __typename?: 'OutcomeTrack'
  comment?: Maybe<Scalars['String']>
  playback: Playback
  submittedAt?: Maybe<Scalars['String']>
}

export type OutLink = {
  __typename?: 'OutLink'
  _metadata?: Maybe<OutlinkMetedata>
  title: Scalars['String']
  url: Scalars['String']
}

export type OutlinkMetedata = {
  __typename?: 'OutlinkMetedata'
  urlSource?: Maybe<OutlinkSourceEnum>
}

export enum OutlinkSourceEnum {
  Magento = 'MAGENTO',
  Prismic = 'PRISMIC',
}

export type Part = {
  eTag: Scalars['String']
  partNumber: Scalars['Int']
}

export type PartnershipContent =
  | ClubPartnershipBusinessSupportContent
  | ClubPartnershipProgramStats
  | ClubPartnershipStandardContent

export type PartSignedUrl = {
  __typename?: 'PartSignedUrl'
  partNumber: Scalars['Int']
  url: Scalars['String']
}

export type PayForCertificationInput = {
  billedClubId: Scalars['ID']
  instructors: Array<InstructorCertificatesInput>
}

export type PayForCertificationResponse =
  | ErrorResult
  | PayForCertificationResult

export type PayForCertificationResult = {
  __typename?: 'PayForCertificationResult'
  results?: Maybe<Array<InstructorCertificate>>
}

export type PickList = {
  __typename?: 'PickList'
  items: Array<Scalars['String']>
  key: Scalars['String']
}

export type Playback = {
  __typename?: 'Playback'
  playbackUrl: Scalars['String']
  token: Scalars['String']
}

export type PortfolioUploadItem = {
  __typename?: 'PortfolioUploadItem'
  token: Scalars['String']
  url: Scalars['String']
}

export type PresentationTrack = {
  __typename?: 'PresentationTrack'
  comment?: Maybe<Scalars['String']>
  playback: Playback
  submittedAt?: Maybe<Scalars['String']>
}

export type PreworkContent = {
  __typename?: 'PreworkContent'
  contentList?: Maybe<Array<PreworkContentItem>>
}

export type PreworkContentItem = {
  __typename?: 'PreworkContentItem'
  title: Scalars['String']
  type: PreworkContentType
  url: Url
}

export enum PreworkContentType {
  Link = 'LINK',
  Pdf = 'PDF',
  Video = 'VIDEO',
}

export type PreworkStep = Step & {
  __typename?: 'PreworkStep'
  activeStepName: Scalars['String']
  activeStepNumber: Scalars['Int']
  assessmentVideo?: Maybe<AssessmentVideo>
  attended?: Maybe<AttendedStatusEnum>
  cancellationPolicy: Scalars['AWSJSON']
  contactUsEmail: Scalars['String']
  deliveryMethod: DeliveryMethodEnum
  details?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  endDate: Scalars['String']
  eventName: Scalars['String']
  fulfillment?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  onlineTrainingLink?: Maybe<Scalars['String']>
  passPendingGuide: Scalars['AWSJSON']
  preworkContent?: Maybe<PreworkContent>
  programName: Scalars['String']
  releaseUrl?: Maybe<Link>
  requirements?: Maybe<Scalars['String']>
  schedule?: Maybe<Scalars['String']>
  startDate: Scalars['String']
  stepName: Scalars['String']
  stepNumber: Scalars['Int']
  timezone: Scalars['String']
  trackAllocation?: Maybe<Scalars['String']>
  trainerEmail?: Maybe<Scalars['String']>
  trainerName?: Maybe<Scalars['String']>
  venueAddress?: Maybe<Scalars['String']>
}

export type PrivacySettingsInstructor = {
  __typename?: 'PrivacySettingsInstructor'
  EMAIL: PrivacySettingValue
  HIDDEN: Scalars['Boolean']
  LMQ_LEVEL: PrivacySettingValue
  PHONE_NUMBER: PrivacySettingValue
  PROFILE_PHOTO: PrivacySettingValue
  SOCIAL_LINKS: PrivacySettingValue
}

export type PrivacySettingsInstructorInput = {
  EMAIL?: Maybe<PrivacySettingValue>
  HIDDEN?: Maybe<Scalars['Boolean']>
  LMQ_LEVEL?: Maybe<PrivacySettingValue>
  PHONE_NUMBER?: Maybe<PrivacySettingValue>
  PROFILE_PHOTO?: Maybe<PrivacySettingValue>
  SOCIAL_LINKS?: Maybe<PrivacySettingValue>
}

export enum PrivacySettingTypeValueInstructor {
  Email = 'EMAIL',
  LmqLevel = 'LMQ_LEVEL',
  PhoneNumber = 'PHONE_NUMBER',
  ProfilePhoto = 'PROFILE_PHOTO',
  SocialLinks = 'SOCIAL_LINKS',
}

export enum PrivacySettingValue {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Restricted = 'RESTRICTED',
}

export type ProfileLink = {
  __typename?: 'ProfileLink'
  type: ProfileLinkType
  url: Scalars['String']
}

export type ProfileLinkInput = {
  type: ProfileLinkType
  url: Scalars['String']
}

export enum ProfileLinkType {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  PersonalWebsite = 'PERSONAL_WEBSITE',
  Tiktok = 'TIKTOK',
  Youtube = 'YOUTUBE',
}

export type Program = ProgramInterface & {
  __typename?: 'Program'
  code: Scalars['String']
  displayName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  initials: Scalars['String']
  name: Scalars['String']
  programType?: Maybe<ProgramTypeEnum>
}

export enum ProgramCodeEnum {
  Ba = 'BA',
  Bb = 'BB',
  Bc = 'BC',
  Bf = 'BF',
  Bj = 'BJ',
  Bm = 'BM',
  Bme = 'BME',
  Bms = 'BMS',
  Bp = 'BP',
  Br = 'BR',
  Bs = 'BS',
  Cr = 'CR',
  Dn = 'DN',
  Fs = 'FS',
  Gr = 'GR',
  Ls = 'LS',
  Rp = 'RP',
  Sb = 'SB',
  Sd = 'SD',
  Sh = 'SH',
  Sp = 'SP',
  Tn = 'TN',
  Tr = 'TR',
}

export type ProgramInput = {
  code?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  id: Scalars['String']
  initials?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type ProgramInstructorCount = {
  __typename?: 'ProgramInstructorCount'
  belowRecommendedAmount?: Maybe<Scalars['Boolean']>
  lastEnquiredOn?: Maybe<Scalars['AWSDateTime']>
  numberOfInstructors?: Maybe<Scalars['Int']>
}

export type ProgramInterface = {
  code: Scalars['String']
  displayName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  initials: Scalars['String']
  name: Scalars['String']
  programType?: Maybe<ProgramTypeEnum>
}

export enum ProgramName {
  Bodyattack = 'BODYATTACK',
  Bodybalance = 'BODYBALANCE',
  Bodycombat = 'BODYCOMBAT',
  Bodyflow = 'BODYFLOW',
  Bodyjam = 'BODYJAM',
  Bodypump = 'BODYPUMP',
  Bodystep = 'BODYSTEP',
  BornToMove = 'BORN_TO_MOVE',
  BtmEarlyYears = 'BTM_EARLY_YEARS',
  BtmSchoolYears = 'BTM_SCHOOL_YEARS',
  LesMillsBarre = 'LES_MILLS_BARRE',
  LesMillsCore = 'LES_MILLS_CORE',
  LesMillsDance = 'LES_MILLS_DANCE',
  LesMillsFunctionalStrength = 'LES_MILLS_FUNCTIONAL_STRENGTH',
  LesMillsGrit = 'LES_MILLS_GRIT',
  LesMillsShapes = 'LES_MILLS_SHAPES',
  LesMillsTone = 'LES_MILLS_TONE',
  LmiStep = 'LMI_STEP',
  Rpm = 'RPM',
  Shbam = 'SHBAM',
  Sprint = 'SPRINT',
  StrengthDevelopment = 'STRENGTH_DEVELOPMENT',
  TheTrip = 'THE_TRIP',
}

export type ProgramsIdInput = {
  id: Scalars['String']
  status: Scalars['String']
}

export enum ProgramStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  InCancellation = 'IN_CANCELLATION',
  InProgress = 'IN_PROGRESS',
  NotMapped = 'NOT_MAPPED',
}

export enum ProgramTypeEnum {
  AtHome = 'AT_HOME',
  Live = 'LIVE',
  Virtual = 'VIRTUAL',
}

export type ProgramTypeInfo = {
  __typename?: 'ProgramTypeInfo'
  description?: Maybe<Scalars['String']>
  title: Scalars['String']
}

export type Promotion = {
  __typename?: 'Promotion'
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  image: ImageSet
  link?: Maybe<Link>
  title: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  _root?: Maybe<Scalars['String']>
  bookedEvent: BookedEventResponse
  bookedEvents: BookedEventsResponse
  dynamoMigrate: DynamoMigrateResponse
  eventDetails: EventDetailsResponse
  getAgreementDocument: AgreementDocumentResponse
  getCertificates: CertificatesResponse
  getChainAffiliations: GetChainAffiliationsResponse
  getChainInstructorAffiliates: GetChainInstructorAffiliatesResponse
  getChainUser?: Maybe<ChainUserResponse>
  getChainUserClubListings?: Maybe<GetChainUserClubListingsResponse>
  getClubAffiliations?: Maybe<GetClubAffiliationsResponse>
  getClubById?: Maybe<GetClubByIdResponse>
  getClubInstructorAffiliates?: Maybe<GetClubInstructorAffiliatesResponse>
  getClubListingById: GetClubListingByIdResponse
  getClubListingsByClubId?: Maybe<GetClubListingsByClubIdResponse>
  getClubNotifications?: Maybe<GetClubNotificationsResponse>
  getClubPrograms?: Maybe<GetClubProgramsResponse>
  getClubsAvailabilityOnListing?: Maybe<GetClubsAvailabilityOnListingResponse>
  getClubSettings: GetClubSettingsResponse
  getContactUsContent: GetContactUsContentResponse
  getCurrentUser?: Maybe<GetUserResponse>
  getCurrentUserPhotoUploadData?: Maybe<GetCurrentUserPhotoUploadDataResponse>
  getCurrentUserPortfolioUploadData: GetCurrentUserPortfolioUploadDataResponse
  getDashboard?: Maybe<GetDashboardResponse>
  getEqualOpportunitiesPolicy: GetEqualOpportunitiesPolicyResponse
  getExploreProgramsPage?: Maybe<GetExploreProgramsPageResult>
  getFeatureFlag?: Maybe<FeatureFlagResponse>
  getFrequentlyAskedQuestions: GetFrequentlyAskedQuestionsResponse
  getHeaders?: Maybe<GetHeaderResponse>
  getInstructorClubAffiliates: GetInstructorClubAffiliatesResponse
  getInstructorWatchlist?: Maybe<SearchClubListingsResponse>
  getNavigation?: Maybe<GetNavigationResponse>
  getNotifications?: Maybe<GetNotificationsResponse>
  getPickLists?: Maybe<GetPickListResponse>
  getPresignedUrl?: Maybe<GetPresignedUrlResponse>
  getPrivacySettingValues?: Maybe<GetPrivacySettingValuesResponse>
  getProfileById?: Maybe<GetProfileByIdResponse>
  getSavedInstructorSearchData?: Maybe<GetSavedInstructorSearchDataResponse>
  getSharedNavigation?: Maybe<GetSharedNavigation>
  getUserAccount: GetAccountSettingsResponse
  getUserEnquiries?: Maybe<GetUserEnquiriesResponse>
  getUserSession: GetUserSessionResponse
  getVideoGuidelines: GetVideoGuidelinesResponse
  getWalkthrough?: Maybe<GetWalkthroughResponse>
  qwsBookedEvent: QwsBookedEventResponse
  qwsEventDetails: QwsEventDetailsResponse
  qwsImtInstructorBookings: QwsImtInstructorBookingsResponse
  qwsImtTrainerBookings: QwsImtTrainerBookingsResponse
  qwsTrainerBookedEvent: QwsTrainerBookedEventResponse
  searchClubListings?: Maybe<SearchClubListingsResponse>
  searchClubListingsWithFilter: SearchClubListingsWithFilterResponse
  searchClubs?: Maybe<SearchClubsResponse>
  searchClubsWithFilter: SearchClubsWithFilterResponse
  searchEvents: SearchEventsResponse
  searchInstructors?: Maybe<SearchInstructorsResponse>
  trainerBooking: TrainerBookingResponse
  trainerBookings: TrainerBookingsResponse
  trainingJourney: TrainingJourneyResponse
  trainingJourneys: TrainingJourneysResponse
}

export type Query_RootArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type QueryBookedEventArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  eventId: Scalars['String']
  role: UserRoleInput
}

export type QueryBookedEventsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  clubId: Scalars['String']
}

export type QueryDynamoMigrateArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: DynamoMigrateInput
}

export type QueryEventDetailsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  eventId: Scalars['String']
  role: UserRoleInput
}

export type QueryGetAgreementDocumentArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetSignedAgreementInput
}

export type QueryGetCertificatesArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type QueryGetChainAffiliationsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetChainAffiliationsInput
}

export type QueryGetChainInstructorAffiliatesArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input?: Maybe<GetChainInstructorAffiliatesInput>
}

export type QueryGetChainUserArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type QueryGetChainUserClubListingsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetChainUserClubListingsInput
}

export type QueryGetClubAffiliationsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetClubAffiliationsInput
}

export type QueryGetClubByIdArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetClubByIdInput
}

export type QueryGetClubInstructorAffiliatesArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetClubInstructorAffiliatesInput
}

export type QueryGetClubListingByIdArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  listingId: Scalars['String']
}

export type QueryGetClubListingsByClubIdArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetClubListingsByClubIdInput
}

export type QueryGetClubNotificationsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  filter: GetClubNotificationsInput
}

export type QueryGetClubProgramsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  clubId: Scalars['ID']
}

export type QueryGetClubsAvailabilityOnListingArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetClubsAvailabilityOnListingInput
}

export type QueryGetClubSettingsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetClubSettingsInput
}

export type QueryGetContactUsContentArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetContactUsContentInput
}

export type QueryGetCurrentUserArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type QueryGetCurrentUserPhotoUploadDataArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type QueryGetCurrentUserPortfolioUploadDataArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetCurrentUserPortfolioUploadDataInput
}

export type QueryGetDashboardArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  role: UserRoleInput
}

export type QueryGetEqualOpportunitiesPolicyArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetEqualOpportunitiesPolicyInput
}

export type QueryGetExploreProgramsPageArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  programName?: Maybe<ProgramName>
  role: UserRoleInput
}

export type QueryGetFeatureFlagArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: FeatureFlagQueryInput
}

export type QueryGetFrequentlyAskedQuestionsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetFrequentlyAskedQuestionsInput
}

export type QueryGetHeadersArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetHeaderInput
}

export type QueryGetInstructorClubAffiliatesArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetInstructorClubAffiliatesInput
}

export type QueryGetInstructorWatchlistArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  role: UserRoleInput
}

export type QueryGetNavigationArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input?: Maybe<GetNavigationInput>
}

export type QueryGetNotificationsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  filter: DateTimeFilterInput
  role: UserRoleInput
}

export type QueryGetPickListsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetPickListInput
}

export type QueryGetPresignedUrlArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input?: Maybe<GetPresignedUrlInput>
}

export type QueryGetPrivacySettingValuesArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type QueryGetProfileByIdArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetProfileByIdInput
  role: UserRoleInput
}

export type QueryGetSavedInstructorSearchDataArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetSavedInstructorSearchDataInput
}

export type QueryGetSharedNavigationArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type QueryGetUserAccountArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: GetUserByIdInput
  role: UserRoleInput
}

export type QueryGetUserEnquiriesArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  filter: UserEnquiriesFilter
  role: UserRoleInput
}

export type QueryGetUserSessionArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type QueryGetVideoGuidelinesArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type QueryGetWalkthroughArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  role: UserRoleInput
}

export type QueryQwsBookedEventArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  eventId: Scalars['String']
  role: UserRoleInput
}

export type QueryQwsEventDetailsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  eventId: Scalars['String']
  role: UserRoleInput
}

export type QueryQwsImtInstructorBookingsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type QueryQwsImtTrainerBookingsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type QueryQwsTrainerBookedEventArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  eventId: Scalars['String']
}

export type QuerySearchClubListingsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  filter: SearchClubListingsFilter
  options?: Maybe<SearchClubListingsOptions>
}

export type QuerySearchClubListingsWithFilterArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  filterInfo: FilterClubListingsWithFilterInput
  searchInfo: SearchClubListingsWithFilterInput
}

export type QuerySearchClubsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  filter: SearchClubsFilter
  options?: Maybe<SearchClubsOptions>
}

export type QuerySearchClubsWithFilterArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  filterInfo: FilterClubsInput
  searchInfo: SearchClubsWithFilterInput
}

export type QuerySearchEventsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  filterInfo: FilterEventsInput
  searchInfo: SearchEventsInput
}

export type QuerySearchInstructorsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  filter: SearchInstructorsFilter
  options?: Maybe<SearchInstructorsOptions>
  role: UserRoleInput
}

export type QueryTrainerBookingArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: TrainerBookingInput
}

export type QueryTrainerBookingsArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type QueryTrainingJourneyArgs = {
  _cacheKey?: Maybe<Scalars['String']>
  input: TrainingJourneyInput
  role?: Maybe<UserRoleInput>
}

export type QueryTrainingJourneysArgs = {
  _cacheKey?: Maybe<Scalars['String']>
}

export type QwsBookedEventResponse = ErrorResult | QwsBookedEventResult

export type QwsBookedEventResult = {
  __typename?: 'QwsBookedEventResult'
  bookedInstructors?: Maybe<Array<InstructorDetails>>
  eventDetails: QwsEventDetailsResult
  sessions?: Maybe<Array<SessionDetails>>
}

export type QwsEvent = {
  __typename?: 'QWSEvent'
  deliveryMethod: DeliveryMethodEnum
  endDate: Scalars['String']
  eventId: Scalars['String']
  eventName: Scalars['String']
  sessions: Array<Session>
  startDate: Scalars['String']
  timezone: Scalars['String']
  venueAddress?: Maybe<Scalars['String']>
}

export type QwsEventDetailsResponse = ErrorResult | QwsEventDetailsResult

export type QwsEventDetailsResult = {
  __typename?: 'QWSEventDetailsResult'
  cancellationPolicy?: Maybe<Scalars['AWSJSON']>
  details?: Maybe<Scalars['String']>
  event: QwsEvent
  requirements?: Maybe<Scalars['String']>
  schedule?: Maybe<Scalars['String']>
  tickets: Array<Ticket>
}

export type QwsImtInstructorBookingsResponse =
  | ErrorResult
  | QwsImtInstructorBookingsResult

export type QwsImtInstructorBookingsResult = {
  __typename?: 'QwsImtInstructorBookingsResult'
  eventsAndTrainingUrl: Scalars['String']
  qwsEvents?: Maybe<Array<QwsInstructorBookedEventSummary>>
  trainings?: Maybe<Array<TrainingSummary>>
}

export type QwsImtTrainerBookingsResponse =
  | ErrorResult
  | QwsImtTrainerBookingsResult

export type QwsImtTrainerBookingsResult = {
  __typename?: 'QwsImtTrainerBookingsResult'
  qwsEvents?: Maybe<Array<QwsTrainerBookedEventSummary>>
  trainingJourneys?: Maybe<Array<EventSummary>>
}

export type QwsInstructorBookedEventSummary = {
  __typename?: 'QWSInstructorBookedEventSummary'
  deliveryMethod: DeliveryMethodEnum
  endDate: Scalars['AWSDate']
  eventId: Scalars['String']
  eventName: Scalars['String']
  eventStatus: EventStatusEnum
  startDate: Scalars['AWSDate']
  type: Scalars['String']
  venueAddress?: Maybe<Scalars['String']>
}

export type QwsTrainerBookedEventResponse =
  | ErrorResult
  | QwsTrainerBookedEventResult

export type QwsTrainerBookedEventResult = {
  __typename?: 'QwsTrainerBookedEventResult'
  qwsTrainerBookedEventSummary: QwsTrainerBookedEventSummary
  sessions?: Maybe<Array<QwsTrainerBookedSessionDetails>>
}

export type QwsTrainerBookedEventSummary = {
  __typename?: 'QWSTrainerBookedEventSummary'
  deliveryMethod: DeliveryMethodEnum
  endDate: Scalars['AWSDate']
  eventId: Scalars['String']
  eventName: Scalars['String']
  eventStatus: EventStatusEnum
  instructorCount: Scalars['Int']
  sessionCount: Scalars['Int']
  startDate: Scalars['AWSDate']
  type: Scalars['String']
  venueAddress?: Maybe<Scalars['String']>
}

export type QwsTrainerBookedSessionDetails = {
  __typename?: 'QwsTrainerBookedSessionDetails'
  endTime: Scalars['String']
  instructorCount: Scalars['Int']
  instructors?: Maybe<Array<SessionInstructorDetails>>
  programName: Scalars['String']
  sessionId: Scalars['String']
  sessionName: Scalars['String']
  startTime: Scalars['String']
  timezone: Scalars['String']
}

export type ReceivedCertificationOutcomeStep = Step & {
  __typename?: 'ReceivedCertificationOutcomeStep'
  activeStepName: Scalars['String']
  activeStepNumber: Scalars['Int']
  attended?: Maybe<AttendedStatusEnum>
  cancellationPolicy: Scalars['AWSJSON']
  certificationOutcomes?: Maybe<Array<TrainingOutcome>>
  certificationUrl?: Maybe<Scalars['String']>
  contactUsEmail: Scalars['String']
  deliveryMethod: DeliveryMethodEnum
  details?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  endDate: Scalars['String']
  eventName: Scalars['String']
  fulfillment?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  onlineTrainingLink?: Maybe<Scalars['String']>
  passPendingGuide: Scalars['AWSJSON']
  programName: Scalars['String']
  requirements?: Maybe<Scalars['String']>
  resubmitDueDate?: Maybe<Scalars['String']>
  schedule?: Maybe<Scalars['String']>
  startDate: Scalars['String']
  stepName: Scalars['String']
  stepNumber: Scalars['Int']
  timezone: Scalars['String']
  trackAllocation?: Maybe<Scalars['String']>
  trainerEmail?: Maybe<Scalars['String']>
  trainerName?: Maybe<Scalars['String']>
  venueAddress?: Maybe<Scalars['String']>
}

export type ReceivedTrainingOutcomeStep = Step & {
  __typename?: 'ReceivedTrainingOutcomeStep'
  activeStepName: Scalars['String']
  activeStepNumber: Scalars['Int']
  assessmentOutcomes?: Maybe<Array<TrainingOutcome>>
  attended?: Maybe<AttendedStatusEnum>
  cancellationPolicy: Scalars['AWSJSON']
  cecCertificationUrl?: Maybe<Scalars['String']>
  contactUsEmail: Scalars['String']
  deliveryMethod: DeliveryMethodEnum
  details?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  endDate: Scalars['String']
  eventName: Scalars['String']
  extendedDueDate?: Maybe<Scalars['String']>
  fulfillment?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  onlineTrainingLink?: Maybe<Scalars['String']>
  passPendingGuide: Scalars['AWSJSON']
  programName: Scalars['String']
  requirements?: Maybe<Scalars['String']>
  schedule?: Maybe<Scalars['String']>
  startDate: Scalars['String']
  stepName: Scalars['String']
  stepNumber: Scalars['Int']
  timezone: Scalars['String']
  trackAllocation?: Maybe<Scalars['String']>
  trainerEmail?: Maybe<Scalars['String']>
  trainerName?: Maybe<Scalars['String']>
  venueAddress?: Maybe<Scalars['String']>
}

export enum RecordType {
  Initial = 'INITIAL',
  LimitedEdition = 'LIMITED_EDITION',
  OneTrack = 'ONE_TRACK',
  OutcomeTrack = 'OUTCOME_TRACK',
  PresentationTrack = 'PRESENTATION_TRACK',
}

export enum ReleaseActivityStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Na = 'NA',
}

export enum ReleaseBundleTypeEnum {
  Discounted = 'DISCOUNTED',
  DiscountedChain = 'DISCOUNTED_CHAIN',
  Mega = 'MEGA',
  Nomura = 'NOMURA',
  Oasis = 'OASIS',
  OgSport = 'OG_SPORT',
  Plus = 'PLUS',
  Shine = 'SHINE',
  Standard = 'STANDARD',
  Sunflower = 'SUNFLOWER',
  Super = 'SUPER',
}

export enum ReleaseStatusEnum {
  OnHold = 'ON_HOLD',
  Subscribed = 'SUBSCRIBED',
  Unsubscribed = 'UNSUBSCRIBED',
}

export type RemoveFromShortlistInput = {
  userId: Scalars['String']
}

export type RemoveFromShortlistResponse =
  | ErrorResult
  | RemoveFromShortlistResult

export type RemoveFromShortlistResult = {
  __typename?: 'RemoveFromShortlistResult'
  shortlist?: Maybe<Array<InstructorShortlist>>
}

export type ReportClubListingInput = {
  clubId: Scalars['ID']
  listingId: Scalars['ID']
  message: Scalars['String']
  reason: ReportClubListingReasonEnum
}

export enum ReportClubListingReasonEnum {
  BullyingDiscriminationHarassment = 'BULLYING_DISCRIMINATION_HARASSMENT',
  InappropriateContent = 'INAPPROPRIATE_CONTENT',
  MisleadingFalseInformation = 'MISLEADING_FALSE_INFORMATION',
}

export type ReportClubListingResponse =
  | ClubMultiListing
  | ClubSingleListing
  | ErrorResult

export type ReportClubProfileInput = {
  clubId: Scalars['ID']
  message: Scalars['String']
  reason: ReportClubProfileReasonEnum
}

export enum ReportClubProfileReasonEnum {
  BullyingDiscriminationHarassment = 'BULLYING_DISCRIMINATION_HARASSMENT',
  InappropriateContent = 'INAPPROPRIATE_CONTENT',
  MisleadingFalseInformation = 'MISLEADING_FALSE_INFORMATION',
}

export type ReportClubProfileResponse = ClubProfile | ErrorResult

export type RequestForExtensionInput = {
  assessmentId: Scalars['String']
  reason: Scalars['String']
  trainingJourneyId: Scalars['String']
}

export type RequestForExtensionResponse =
  | ErrorResult
  | RequestForExtensionResult

export type RequestForExtensionResult = {
  __typename?: 'RequestForExtensionResult'
  isSuccess?: Maybe<Scalars['Boolean']>
}

export enum RequireFilter {
  All = 'ALL',
  Default = 'DEFAULT',
  Only = 'ONLY',
}

export type ResendPasswordResetEmailInput = {
  email: Scalars['String']
}

export type ResendPasswordResetEmailResponse =
  | ErrorResult
  | ResendPasswordResetEmailResult

export type ResendPasswordResetEmailResult = {
  __typename?: 'ResendPasswordResetEmailResult'
  success?: Maybe<Scalars['Boolean']>
}

export type RevertAppMetadataInput = {
  email: Scalars['String']
}

export enum Role {
  Club = 'CLUB',
  Instructor = 'INSTRUCTOR',
}

export type RootMenuItem = {
  __typename?: 'RootMenuItem'
  analyticsEvent?: Maybe<AnalyticsEvent>
  icon: MenuItemIcon
  id: Scalars['ID']
  items?: Maybe<Array<MenuItem>>
  label: Scalars['String']
  url?: Maybe<Url>
}

export type SavedClubSearch = {
  __typename?: 'SavedClubSearch'
  filter?: Maybe<SavedClubSearchFilter>
  sort?: Maybe<SearchClubsSort>
}

export type SavedClubSearchFilter = {
  __typename?: 'SavedClubSearchFilter'
  name?: Maybe<Scalars['String']>
  programs?: Maybe<Array<Scalars['ID']>>
}

export type SavedInstructorSearch = {
  __typename?: 'SavedInstructorSearch'
  filter?: Maybe<SavedSearchClubListingsFilter>
  sort?: Maybe<SearchClubListingsSort>
}

export type SavedSearchClubListingsFilter = {
  __typename?: 'SavedSearchClubListingsFilter'
  location?: Maybe<LocationFilter>
  name?: Maybe<Scalars['String']>
  programs?: Maybe<Array<Scalars['ID']>>
  status?: Maybe<ClubListingStatus>
  substituteListingFilter?: Maybe<SearchClubListingsSubstitute>
}

export type SearchAfter = {
  __typename?: 'searchAfter'
  eventId: Scalars['String']
  sortValues: Array<Scalars['String']>
}

export type SearchAfterInput = {
  eventId: Scalars['String']
  sortValues: Array<Scalars['String']>
}

export type SearchClubJobListingsSingleResult =
  SearchClubListingsResultCommon & {
    __typename?: 'SearchClubJobListingsSingleResult'
    distance?: Maybe<Scalars['Float']>
    distanceUnit?: Maybe<LocationDistanceUnit>
    enquiredOn?: Maybe<Scalars['AWSDateTime']>
    hasBeenReported?: Maybe<Scalars['Boolean']>
    listing: ClubListing
    messageSentOn?: Maybe<Scalars['AWSDateTime']>
    onWatchlist?: Maybe<Scalars['Boolean']>
  }

export type SearchClubListingsFilter = {
  location?: Maybe<LocationFilterInput>
  name?: Maybe<Scalars['String']>
  programs?: Maybe<Array<Scalars['ID']>>
  status?: Maybe<ClubListingStatus>
  substituteListingFilter?: Maybe<SearchClubListingsSubstitute>
}

export type SearchClubListingsOptions = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<SearchClubListingsSort>
}

export type SearchClubListingsResponse = ErrorResult | SearchClubListingsResult

export type SearchClubListingsResult = {
  __typename?: 'SearchClubListingsResult'
  results?: Maybe<Array<SearchClubListingsSingleResult>>
  total: Scalars['Int']
}

export type SearchClubListingsResultCommon = {
  distance?: Maybe<Scalars['Float']>
  distanceUnit?: Maybe<LocationDistanceUnit>
  hasBeenReported?: Maybe<Scalars['Boolean']>
  onWatchlist?: Maybe<Scalars['Boolean']>
}

export type SearchClubListingsSingleResult =
  | SearchClubJobListingsSingleResult
  | SearchClubProfileSingleResult

export enum SearchClubListingsSort {
  MostRecent = 'MOST_RECENT',
  Nearest = 'NEAREST',
  Relevance = 'RELEVANCE',
}

export enum SearchClubListingsSubstitute {
  All = 'ALL',
  OnlyNonSubstitute = 'ONLY_NON_SUBSTITUTE',
  OnlySubstitute = 'ONLY_SUBSTITUTE',
}

export type SearchClubListingsWithFilterInput = {
  after?: Maybe<ClubListingsSearchAfterInput>
  limit: Scalars['Int']
}

export type SearchClubListingsWithFilterResponse =
  | ErrorResult
  | SearchClubListingsWithFilterResult

export type SearchClubListingsWithFilterResult = {
  __typename?: 'SearchClubListingsWithFilterResult'
  edges: Array<Maybe<ClubListingsEdge>>
  pageInfo: ClubListingsPageInfo
}

export type SearchClubProfileSingleResult = SearchClubListingsResultCommon & {
  __typename?: 'SearchClubProfileSingleResult'
  distance?: Maybe<Scalars['Float']>
  distanceUnit?: Maybe<LocationDistanceUnit>
  hasBeenReported?: Maybe<Scalars['Boolean']>
  onWatchlist?: Maybe<Scalars['Boolean']>
  profile: UserProfileClub
}

export type SearchClubsFilter = {
  location?: Maybe<LocationFilterInput>
  name?: Maybe<Scalars['String']>
  programs?: Maybe<Array<Scalars['ID']>>
  status?: Maybe<ClubListingStatus>
}

export type SearchClubsOptions = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<SearchClubsSort>
}

export type SearchClubsResponse = ErrorResult | SearchClubsResult

export type SearchClubsResult = {
  __typename?: 'SearchClubsResult'
  results: Array<SearchClubsSingleResult>
  total: Scalars['Int']
}

export type SearchClubsSingleResult = {
  __typename?: 'SearchClubsSingleResult'
  clubId: Scalars['String']
  distance?: Maybe<Scalars['Float']>
  distanceUnit?: Maybe<LocationDistanceUnit>
  hasBeenReported?: Maybe<Scalars['Boolean']>
  instructorAffiliation?: Maybe<Affiliation>
  onWatchlist?: Maybe<Scalars['Boolean']>
  profile: UserProfileClub
}

export enum SearchClubsSort {
  Alphabetical = 'ALPHABETICAL',
  Nearest = 'NEAREST',
  Relevance = 'RELEVANCE',
}

export type SearchClubsWithFilterInput = {
  after?: Maybe<ClubSearchAfterInput>
  limit: Scalars['Int']
}

export type SearchClubsWithFilterResponse =
  | ErrorResult
  | SearchClubsWithFilterResult

export type SearchClubsWithFilterResult = {
  __typename?: 'SearchClubsWithFilterResult'
  edges: Array<Maybe<ClubEdge>>
  pageInfo: ClubPageInfo
}

export type SearchEventsInput = {
  after?: Maybe<SearchAfterInput>
  limit: Scalars['Int']
}

export type SearchEventsResponse = ErrorResult | EventsResult

export type SearchInstructorsFilter = {
  location?: Maybe<LocationFilterInput>
  name?: Maybe<Scalars['String']>
  onlyAvailableToSubstitute?: Maybe<Scalars['Boolean']>
  programs?: Maybe<Array<Scalars['ID']>>
}

export type SearchInstructorsOptions = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<SearchInstructorsSort>
}

export type SearchInstructorsResponse = ErrorResult | SearchInstructorsResult

export type SearchInstructorsResult = {
  __typename?: 'SearchInstructorsResult'
  isMatchingFilter?: Maybe<Scalars['Boolean']>
  results?: Maybe<Array<SearchInstructorsSingleResult>>
  total: Scalars['Int']
}

export type SearchInstructorsSingleResult = {
  __typename?: 'SearchInstructorsSingleResult'
  distance?: Maybe<Scalars['Float']>
  distanceUnit?: Maybe<LocationDistanceUnit>
  hasExistingAffiliation?: Maybe<Scalars['Boolean']>
  hasRecentlyDeclinedAffiliation?: Maybe<Scalars['Boolean']>
  instructorAffiliation?: Maybe<Affiliation>
  inTravelZone?: Maybe<Scalars['Boolean']>
  lastMessageIn48HoursSentAt?: Maybe<Scalars['AWSDateTime']>
  lastMessageSentAt?: Maybe<Scalars['AWSDateTime']>
  messageSentOn?: Maybe<Scalars['AWSDateTime']>
  profile: UserProfileTeaching
  user: User
}

export enum SearchInstructorsSort {
  Alphabetical = 'ALPHABETICAL',
  Nearest = 'NEAREST',
  Relevance = 'RELEVANCE',
}

export type SectionContentResult = {
  __typename?: 'SectionContentResult'
  content: Array<ContentField>
  section: Scalars['String']
}

export type SendMessageInput = {
  clubId: Scalars['ID']
  isFromClubProfile?: Maybe<Scalars['Boolean']>
  listingId?: Maybe<Scalars['ID']>
  message: Scalars['String']
  profileId: Scalars['ID']
  requestedBy: UserRoleType
}

export type SendMessageResponse = ErrorResult | SendMessageResult

export type SendMessageResult = {
  __typename?: 'SendMessageResult'
  readAt?: Maybe<Scalars['AWSDateTime']>
  sentAt: Scalars['AWSDateTime']
}

export type Session = {
  __typename?: 'Session'
  endTime: Scalars['String']
  programName: Scalars['String']
  sessionId: Scalars['String']
  sessionName: Scalars['String']
  spacesAvailable: Scalars['Int']
  startTime: Scalars['String']
  trainerDetails?: Maybe<Array<TrainerDetails>>
}

export type SessionAttendance = {
  hasAttended: Scalars['Boolean']
  registrationId: Scalars['String']
}

export type SessionDetails = {
  __typename?: 'SessionDetails'
  bookedInstructors?: Maybe<Array<InstructorDetails>>
  endTime: Scalars['String']
  programName: Scalars['String']
  sessionId: Scalars['String']
  sessionName: Scalars['String']
  spacesAvailable: Scalars['Int']
  startTime: Scalars['String']
  trainers?: Maybe<Array<TrainerDetails>>
}

export type SessionInstructorDetails = {
  __typename?: 'SessionInstructorDetails'
  email: Scalars['String']
  firstName: Scalars['String']
  hasAttended?: Maybe<Scalars['Boolean']>
  instructorId: Scalars['String']
  lastName: Scalars['String']
  profileImage?: Maybe<Image>
  registrationId: Scalars['String']
}

export type SetAppMetadataInput = {
  email: Scalars['String']
}

export type SetClubSettingsInput = {
  address?: Maybe<AddressInput>
  email?: Maybe<EmailClubTypeInput>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type SetClubSettingsResponse = ErrorResult | SetClubSettingsResult

export type SetClubSettingsResult = {
  __typename?: 'SetClubSettingsResult'
  address?: Maybe<Address>
  email?: Maybe<EmailClubType>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type SetInstructorAvailabilityInput = {
  availability: InstructorAvailabilityValue
  availableToSubstitute: Scalars['Boolean']
}

export type SetInstructorAvailabilityResponse =
  | ErrorResult
  | InstructorAvailability

export type SetInstructorTravelPreferencesInput = {
  address?: Maybe<AddressInput>
  distance?: Maybe<Scalars['Int']>
  distanceUnit?: Maybe<LocationDistanceUnit>
  id?: Maybe<Scalars['ID']>
}

export type SetInstructorTravelPreferencesResponse =
  | ErrorResult
  | InstructorPreferences

export type SetInstructorWatchlistInput = {
  watchlist: Array<InstructorWatchlistItemInput>
}

export type SetInstructorWatchlistResponse = ErrorResult | InstructorWatchlist

export type SetPrivacySettingInput = {
  name: PrivacySettingTypeValueInstructor
  value: PrivacySettingValue
}

export type SetPrivacySettingsInput = {
  hidden?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  settings: Array<SetPrivacySettingInput>
  type: UserProfileType
}

export type SetPrivacySettingsResponse = ErrorResult | SetPrivacySettingsResult

export type SetPrivacySettingsResult = {
  __typename?: 'SetPrivacySettingsResult'
  settings: PrivacySettingsInstructor
}

export type SetQwsSessionAttendancesInput = {
  attendances: Array<SessionAttendance>
  eventId: Scalars['String']
  sessionId: Scalars['String']
}

export type SetQwsSessionAttendancesResponse =
  | ErrorResult
  | SetQwsSessionAttendancesResult

export type SetQwsSessionAttendancesResult = {
  __typename?: 'SetQwsSessionAttendancesResult'
  success: Scalars['Boolean']
}

export type SetSavedClubSearchDataInput = {
  searches: Array<SetSavedClubSearchDataSingleInput>
}

export type SetSavedClubSearchDataResponse =
  | ErrorResult
  | SetSavedClubSearchDataResult

export type SetSavedClubSearchDataResult = {
  __typename?: 'SetSavedClubSearchDataResult'
  results: Array<SavedClubSearch>
}

export type SetSavedClubSearchDataSingleInput = {
  filter: SearchClubsFilter
  sort?: Maybe<SearchClubsSort>
}

export type SetSavedInstructorSearchDataInput = {
  searches: Array<SetSavedInstructorSearchDataSingleInput>
}

export type SetSavedInstructorSearchDataResponse =
  | ErrorResult
  | SetSavedInstructorSearchDataResult

export type SetSavedInstructorSearchDataResult = {
  __typename?: 'SetSavedInstructorSearchDataResult'
  results: Array<SavedInstructorSearch>
}

export type SetSavedInstructorSearchDataSingleInput = {
  filter: SearchClubListingsFilter
  sort?: Maybe<SearchClubListingsSort>
}

export type SetSignedAgreementDataInput = {
  type: AgreementType
  version: Scalars['String']
}

export type SetWalkthroughToSeenResponse =
  | ErrorResult
  | SetWalkthroughToSeenResult

export type SetWalkthroughToSeenResult = {
  __typename?: 'SetWalkthroughToSeenResult'
  success?: Maybe<Scalars['Boolean']>
}

export type SharedNavigation = {
  __typename?: 'SharedNavigation'
  clubNav?: Maybe<SharedNavItem>
  instructorNav?: Maybe<SharedNavItem>
  websiteLink: Url
}

export type SharedNavItem = {
  __typename?: 'SharedNavItem'
  navItems?: Maybe<Array<Maybe<NavItem>>>
  role?: Maybe<Role>
}

export type SignedAgreement = {
  __typename?: 'SignedAgreement'
  signedOn: Scalars['AWSDateTime']
  type: AgreementType
  version: Scalars['String']
}

export enum SnapshotStateEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type SocialMediaContent = {
  __typename?: 'SocialMediaContent'
  type: SocialMediaContentType
  value: Scalars['String']
}

export enum SocialMediaContentType {
  Image = 'IMAGE',
  Text = 'TEXT',
  Video = 'VIDEO',
}

export type SocialMediaPost = {
  __typename?: 'SocialMediaPost'
  content: SocialMediaContent
  createdAt: Scalars['AWSDateTime']
  createdAtLabel: Scalars['String']
  id: Scalars['ID']
  type: SocialMediaType
  url: Scalars['String']
}

export type SocialMediaSection = {
  __typename?: 'SocialMediaSection'
  items?: Maybe<Array<SocialMediaPost>>
  title: Scalars['String']
}

export enum SocialMediaType {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Tiktok = 'TIKTOK',
  Youtube = 'YOUTUBE',
}

export enum StatesEnum {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  California = 'CALIFORNIA',
  Colorado = 'COLORADO',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Hawaii = 'HAWAII',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Maine = 'MAINE',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  NewHampshire = 'NEW_HAMPSHIRE',
  NewJersey = 'NEW_JERSEY',
  NewMexico = 'NEW_MEXICO',
  NewYork = 'NEW_YORK',
  NorthCarolina = 'NORTH_CAROLINA',
  NorthDakota = 'NORTH_DAKOTA',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Oregon = 'OREGON',
  Pennsylvania = 'PENNSYLVANIA',
  RhodeIsland = 'RHODE_ISLAND',
  SouthCarolina = 'SOUTH_CAROLINA',
  SouthDakota = 'SOUTH_DAKOTA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Utah = 'UTAH',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  Washington = 'WASHINGTON',
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING',
}

export type Step = {
  activeStepName: Scalars['String']
  activeStepNumber: Scalars['Int']
  attended?: Maybe<AttendedStatusEnum>
  cancellationPolicy: Scalars['AWSJSON']
  contactUsEmail: Scalars['String']
  deliveryMethod: DeliveryMethodEnum
  details?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  endDate: Scalars['String']
  eventName: Scalars['String']
  fulfillment?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  onlineTrainingLink?: Maybe<Scalars['String']>
  passPendingGuide: Scalars['AWSJSON']
  programName: Scalars['String']
  requirements?: Maybe<Scalars['String']>
  schedule?: Maybe<Scalars['String']>
  startDate: Scalars['String']
  stepName: Scalars['String']
  stepNumber: Scalars['Int']
  timezone: Scalars['String']
  trackAllocation?: Maybe<Scalars['String']>
  trainerEmail?: Maybe<Scalars['String']>
  trainerName?: Maybe<Scalars['String']>
  venueAddress?: Maybe<Scalars['String']>
}

export type SubmitCertificationVideoStep = Step & {
  __typename?: 'SubmitCertificationVideoStep'
  activeStepName: Scalars['String']
  activeStepNumber: Scalars['Int']
  assessmentVideo?: Maybe<AssessmentVideo>
  attended?: Maybe<AttendedStatusEnum>
  cancellationPolicy: Scalars['AWSJSON']
  contactUsEmail: Scalars['String']
  deliveryMethod: DeliveryMethodEnum
  details?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  endDate: Scalars['String']
  eventName: Scalars['String']
  extendedDueDate?: Maybe<Scalars['String']>
  fulfillment?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  onlineTrainingLink?: Maybe<Scalars['String']>
  passPendingGuide: Scalars['AWSJSON']
  programName: Scalars['String']
  requirements?: Maybe<Scalars['String']>
  schedule?: Maybe<Scalars['String']>
  startDate: Scalars['String']
  stepName: Scalars['String']
  stepNumber: Scalars['Int']
  timezone: Scalars['String']
  trackAllocation?: Maybe<Scalars['String']>
  trainerEmail?: Maybe<Scalars['String']>
  trainerName?: Maybe<Scalars['String']>
  venueAddress?: Maybe<Scalars['String']>
}

export type SubmitOutcomeTrackStep = Step & {
  __typename?: 'SubmitOutcomeTrackStep'
  activeStepName: Scalars['String']
  activeStepNumber: Scalars['Int']
  assessmentVideo?: Maybe<AssessmentVideo>
  attended?: Maybe<AttendedStatusEnum>
  cancellationPolicy: Scalars['AWSJSON']
  cecCertificationUrl?: Maybe<Scalars['String']>
  contactUsEmail: Scalars['String']
  deliveryMethod: DeliveryMethodEnum
  details?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  endDate: Scalars['String']
  eventName: Scalars['String']
  extendedDueDate?: Maybe<Scalars['String']>
  fulfillment?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  onlineTrainingLink?: Maybe<Scalars['String']>
  passPendingGuide: Scalars['AWSJSON']
  programName: Scalars['String']
  requirements?: Maybe<Scalars['String']>
  schedule?: Maybe<Scalars['String']>
  startDate: Scalars['String']
  stepName: Scalars['String']
  stepNumber: Scalars['Int']
  timezone: Scalars['String']
  trackAllocation?: Maybe<Scalars['String']>
  trainerEmail?: Maybe<Scalars['String']>
  trainerName?: Maybe<Scalars['String']>
  venueAddress?: Maybe<Scalars['String']>
}

export type Subscription = {
  __typename?: 'Subscription'
  _root?: Maybe<Scalars['String']>
}

export type SyncMetadata = {
  force_sync?: Maybe<Scalars['Boolean']>
}

export type TestPostUpsertClubFilterInput = {
  ids?: Maybe<Array<Scalars['ID']>>
}

export type TestPostUpsertClubInput = {
  filter?: Maybe<TestPostUpsertClubFilterInput>
}

export type TestPostUpsertClubResponse = ErrorResult | TestPostUpsertClubResult

export type TestPostUpsertClubResult = {
  __typename?: 'TestPostUpsertClubResult'
  done: Array<Scalars['String']>
  failed: Array<Scalars['String']>
}

export type TestPostUpsertUserFilterInput = {
  emails?: Maybe<Array<Scalars['String']>>
  ids?: Maybe<Array<Scalars['ID']>>
}

export type TestPostUpsertUserInput = {
  filter?: Maybe<TestPostUpsertUserFilterInput>
}

export type TestPostUpsertUserResponse = ErrorResult | TestPostUpsertUserResult

export type TestPostUpsertUserResult = {
  __typename?: 'TestPostUpsertUserResult'
  done: Array<Scalars['String']>
  failed: Array<Scalars['String']>
}

export type Ticket = {
  __typename?: 'Ticket'
  canBeUsedWithDiscountCode: Scalars['Boolean']
  currencyIsoCode: Scalars['String']
  endDate: Scalars['String']
  isDefaultTicket: Scalars['Boolean']
  name: Scalars['String']
  price: Scalars['String']
  startDate: Scalars['String']
  ticketId: Scalars['String']
}

export type TimePreference = {
  __typename?: 'TimePreference'
  afternoon?: Maybe<Scalars['Boolean']>
  evening?: Maybe<Scalars['Boolean']>
  morning?: Maybe<Scalars['Boolean']>
}

export type TimePreferenceInput = {
  afternoon?: Maybe<Scalars['Boolean']>
  evening?: Maybe<Scalars['Boolean']>
  morning?: Maybe<Scalars['Boolean']>
}

export type TimeSlot = {
  __typename?: 'TimeSlot'
  day: DayOfWeek
  slots: Array<TimeSlotValue>
}

export type TimeSlotInput = {
  day: DayOfWeek
  slots: Array<TimeSlotValue>
}

export enum TimeSlotValue {
  Afternoon = 'AFTERNOON',
  Evening = 'EVENING',
  Morning = 'MORNING',
}

export type ToggleNotificationReadInput = {
  id: Array<Scalars['ID']>
  read: Scalars['Boolean']
}

export type ToggleNotificationReadResponse =
  | ErrorResult
  | ToggleNotificationReadResult

export type ToggleNotificationReadResult = {
  __typename?: 'ToggleNotificationReadResult'
  failure: Array<Scalars['ID']>
  success: Array<Scalars['ID']>
}

export type TrainerBookingInput = {
  eventId: Scalars['String']
}

export type TrainerBookingResponse = ErrorResult | TrainerBookingResult

export type TrainerBookingResult = {
  __typename?: 'TrainerBookingResult'
  eventAssessments: Array<EventAssessments>
  eventSummary: EventSummary
}

export type TrainerBookingsResponse = ErrorResult | TrainerBookingsResult

export type TrainerBookingsResult = {
  __typename?: 'TrainerBookingsResult'
  result: Array<EventSummary>
}

export type TrainerDetails = {
  __typename?: 'TrainerDetails'
  email: Scalars['String']
  name: Scalars['String']
}

export type TrainingBookedStep = Step & {
  __typename?: 'TrainingBookedStep'
  activeStepName: Scalars['String']
  activeStepNumber: Scalars['Int']
  attended?: Maybe<AttendedStatusEnum>
  cancellationPolicy: Scalars['AWSJSON']
  contactUsEmail: Scalars['String']
  deliveryMethod: DeliveryMethodEnum
  details?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  emergencyContact?: Maybe<EmergencyContact>
  endDate: Scalars['String']
  eventName: Scalars['String']
  fulfillment?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  onlineTrainingLink?: Maybe<Scalars['String']>
  parqForm?: Maybe<Scalars['String']>
  passPendingGuide: Scalars['AWSJSON']
  programName: Scalars['String']
  requirements?: Maybe<Scalars['String']>
  schedule?: Maybe<Scalars['String']>
  startDate: Scalars['String']
  stepName: Scalars['String']
  stepNumber: Scalars['Int']
  timezone: Scalars['String']
  trackAllocation?: Maybe<Scalars['String']>
  trainerEmail?: Maybe<Scalars['String']>
  trainerName?: Maybe<Scalars['String']>
  venueAddress?: Maybe<Scalars['String']>
}

export type TrainingJourney = {
  __typename?: 'TrainingJourney'
  steps?: Maybe<Array<TrainingStep>>
  type: Scalars['String']
}

export type TrainingJourneyInput = {
  trainingId: Scalars['ID']
}

export type TrainingJourneyResponse = ErrorResult | TrainingJourneyResult

export type TrainingJourneyResult = {
  __typename?: 'TrainingJourneyResult'
  result?: Maybe<TrainingJourney>
}

export type TrainingJourneysResponse = ErrorResult | TrainingJourneysResult

export type TrainingJourneysResult = {
  __typename?: 'TrainingJourneysResult'
  eventsAndTrainingUrl: Scalars['String']
  trainings?: Maybe<Array<TrainingSummary>>
}

export type TrainingOutcome = {
  __typename?: 'TrainingOutcome'
  assessmentVideo?: Maybe<AssessmentVideo>
  developmentSheet?: Maybe<Scalars['String']>
  outcome?: Maybe<OutcomeEnum>
  submitDate?: Maybe<Scalars['String']>
  trainingNotes?: Maybe<Scalars['String']>
}

export type TrainingStep =
  | AttendTrainingStep
  | CertifiedStep
  | ContactUsStep
  | PreworkStep
  | ReceivedCertificationOutcomeStep
  | ReceivedTrainingOutcomeStep
  | SubmitCertificationVideoStep
  | SubmitOutcomeTrackStep
  | TrainingBookedStep

export type TrainingSummary = {
  __typename?: 'TrainingSummary'
  activeStepName: Scalars['String']
  activeStepNumber: Scalars['Int']
  cecCertificationUrl?: Maybe<Scalars['String']>
  dateCertified?: Maybe<Scalars['String']>
  deliveryMethod: DeliveryMethodEnum
  dueDate?: Maybe<Scalars['String']>
  endDate: Scalars['String']
  eventName: Scalars['String']
  id: Scalars['ID']
  isContactUs: Scalars['Boolean']
  programName: Scalars['String']
  startDate: Scalars['String']
  timezone?: Maybe<Scalars['String']>
  type: Scalars['String']
  venueAddress?: Maybe<Scalars['String']>
}

export enum TransactionStatusEnum {
  Done = 'DONE',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
}

export type TravelPreference = {
  __typename?: 'TravelPreference'
  address?: Maybe<Address>
  distance?: Maybe<Scalars['Int']>
  distanceUnit?: Maybe<LocationDistanceUnit>
}

export type TravelPreferenceInput = {
  address?: Maybe<AddressInput>
  distance?: Maybe<Scalars['Int']>
}

export type UpdateAccountSettingsResponse =
  | ErrorResult
  | UserAccountSettingsResult

export type UpdateAffiliationInput = {
  id: Scalars['String']
  status: AffiliationStatus
}

export type UpdateClubListingInput = {
  clubId: Scalars['ID']
  description?: Maybe<Scalars['String']>
  expiringAt?: Maybe<Scalars['AWSDateTime']>
  id: Scalars['ID']
  programs?: Maybe<Array<Scalars['String']>>
  status?: Maybe<ClubListingStatus>
  times?: Maybe<Array<TimePreferenceInput>>
}

export type UpdateClubListingResponse = ClubListingResult | ErrorResult

export type UpdateClubProfileInput = {
  address?: Maybe<AddressInput>
  bio?: Maybe<Scalars['String']>
  hidden?: Maybe<Scalars['Boolean']>
  hiring?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  links?: Maybe<Array<ProfileLinkInput>>
  media?: Maybe<Array<ImageInput>>
  openToNewPrograms?: Maybe<Scalars['Boolean']>
}

export type UpdateClubProfileResponse = ErrorResult | UserResult

export type UpdateCurrentUserPortfolioUploadInput = {
  cancelTokens?: Maybe<Array<Scalars['String']>>
  confirmTokens?: Maybe<Array<Scalars['String']>>
  removeImages?: Maybe<Array<Scalars['ID']>>
}

export type UpdateCurrentUserPortfolioUploadResponse =
  | ErrorResult
  | UpdateCurrentUserPortfolioUploadResult

export type UpdateCurrentUserPortfolioUploadResult = {
  __typename?: 'UpdateCurrentUserPortfolioUploadResult'
  profile: UserProfile
  url: Array<Scalars['String']>
}

export type UpdateProfileResponse = ErrorResult | UserResult

export type UpdateTeachingProfileInput = {
  address?: Maybe<AddressInput>
  availability?: Maybe<InstructorAvailabilityValue>
  availableToSubstitute?: Maybe<Scalars['Boolean']>
  bio?: Maybe<Scalars['String']>
  interests?: Maybe<Array<Scalars['String']>>
  links?: Maybe<Array<ProfileLinkInput>>
  media?: Maybe<Array<ImageInput>>
  preferences?: Maybe<InstructorPreferencesInput>
  privacySettings?: Maybe<PrivacySettingsInstructorInput>
}

export type UpdateTeachingProfileResponse = ErrorResult | UserResult

export type UpdateUserAccountInput = {
  address?: Maybe<AddressInput>
  dateOfBirth?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type UpdateUserPhotoInput = {
  profileId: Scalars['ID']
  profileType: UserProfileType
  token: Scalars['String']
}

export type UpdateUserPhotoResponse = ErrorResult | UpdateUserPhotoResult

export type UpdateUserPhotoResult = {
  __typename?: 'UpdateUserPhotoResult'
  profile: UserProfile
  url: Scalars['AWSURL']
}

export type UpsertAgreementInput = {
  content: Scalars['String']
  market: Scalars['String']
  type: AgreementType
  version: Scalars['String']
}

export type UpsertAgreementResponse = ErrorResult | UpsertAgreementResult

export type UpsertAgreementResult = {
  __typename?: 'UpsertAgreementResult'
  result: AgreementDocument
}

export type UpsertClubInput = {
  accountId: Scalars['ID']
  address: AddressInput
  affiliations: Array<AffiliationInput>
  currency: Currency
  customerId?: Maybe<Scalars['String']>
  email?: Maybe<EmailClubTypeInput>
  enquiries?: Maybe<Array<EnquiryInput>>
  hiring?: Maybe<Scalars['Boolean']>
  location: GeoPointInput
  market: Scalars['String']
  name: Scalars['String']
  parent?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  products?: Maybe<Array<UpsertClubProductsInput>>
  programs?: Maybe<Array<UpsertClubProgramsInput>>
  programsIds?: Maybe<Array<ProgramsIdInput>>
  releaseBundleType?: Maybe<ReleaseBundleTypeEnum>
  status?: Maybe<Scalars['String']>
  totalLicensedPrograms?: Maybe<Scalars['Int']>
}

export type UpsertClubProductsInput = {
  category?: Maybe<ProgramTypeEnum>
  displayName: Scalars['String']
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  startDate: Scalars['AWSDateTime']
  status: ProgramStatus
}

export type UpsertClubProgramsInput = {
  category?: Maybe<ProgramTypeEnum>
  displayName: Scalars['String']
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  startDate?: Maybe<Scalars['AWSDateTime']>
  status: ProgramStatus
}

export type UpsertClubResponse = ErrorResult | UpsertClubResult

export type UpsertClubResult = {
  __typename?: 'UpsertClubResult'
  club: Club
}

export type UpsertPricebookInput = {
  filename: Scalars['String']
}

export type UpsertPricebookResponse = ErrorResult | UpsertPricebookResult

export type UpsertPricebookResult = {
  __typename?: 'UpsertPricebookResult'
  success?: Maybe<Scalars['Boolean']>
}

export type UpsertUserInput = {
  accountId: Scalars['String']
  address: AddressInput
  affiliations: Array<AffiliationInput>
  billing?: Maybe<UserBillingInput>
  certifications?: Maybe<Array<InstructorCertificationInput>>
  dateOfBirth?: Maybe<Scalars['String']>
  email: Scalars['AWSEmail']
  firstName: Scalars['String']
  lastName: Scalars['String']
  location?: Maybe<GeoPointInput>
  magentoStoreProfile?: Maybe<MagentoStoreProfileInput>
  market: Scalars['String']
  personContactId: Scalars['String']
  phone?: Maybe<Scalars['String']>
  recordType?: Maybe<Scalars['String']>
  roles?: Maybe<Array<InstructorRole>>
}

export type UpsertUserResponse = ErrorResult | UpsertUserResult

export type UpsertUserResult = {
  __typename?: 'UpsertUserResult'
  user: User
}

export type Url = {
  __typename?: 'URL'
  type: UrlType
  value: Scalars['String']
}

export type UrlInput = {
  type: UrlType
  value: Scalars['String']
}

export enum UrlType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
}

export type User = {
  __typename?: 'User'
  accountId: Scalars['ID']
  affiliations?: Maybe<Array<Affiliation>>
  email?: Maybe<Scalars['AWSEmail']>
  external?: Maybe<UserExternalInfo>
  firstName: Scalars['String']
  id: Scalars['ID']
  instructorProfile?: Maybe<UserProfileTeaching>
  isInstructorLead?: Maybe<Scalars['Boolean']>
  lastName: Scalars['String']
  market: Market
  phone?: Maybe<Scalars['String']>
  recordType?: Maybe<Scalars['String']>
  signedAgreements?: Maybe<Array<SignedAgreement>>
  userMetaData?: Maybe<UserMetadata>
}

export type UserAccountSettingsResult = {
  __typename?: 'UserAccountSettingsResult'
  address?: Maybe<Address>
  dateOfBirth?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  outlinks?: Maybe<Array<OutLink>>
  phone?: Maybe<Scalars['String']>
}

export type UserBillingInput = {
  creditCardExpiry?: Maybe<Scalars['String']>
  creditCardExpiryDate?: Maybe<Scalars['AWSDateTime']>
  paymentMethod?: Maybe<Scalars['String']>
}

export type UserEnquiriesFilter = {
  programs?: Maybe<Array<Scalars['String']>>
  sortBy?: Maybe<UserEnquiriesSort>
  status?: Maybe<Array<ClubListingStatus>>
}

export enum UserEnquiriesSort {
  ClubName = 'CLUB_NAME',
  MostRecent = 'MOST_RECENT',
}

export type UserEnquiry = {
  __typename?: 'UserEnquiry'
  listing: EnquiryClubListingSingleResult
  message: Message
}

export type UserExternalInfo = {
  __typename?: 'UserExternalInfo'
  accountId: Scalars['ID']
  contactId?: Maybe<Scalars['String']>
}

export type UserMetadata = {
  __typename?: 'UserMetadata'
  walkthroughRequired: WalkthroughRequired
}

export type UserProfile = UserProfileClub | UserProfileTeaching

export type UserProfileClub = {
  __typename?: 'UserProfileClub'
  address: Address
  atHomeProducts?: Maybe<GetAtHomeProductTilesResult>
  bio?: Maybe<Scalars['String']>
  businessSupport?: Maybe<Array<BusinessSupportSection>>
  createdAt: Scalars['AWSDateTime']
  createdAtLabel?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['AWSEmail']>
  enquiries?: Maybe<Array<ClubListingEnquiry>>
  exploreProgramsCard?: Maybe<InStudioExploreProgramsTileCardResult>
  hiring?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  inStudioToolkit?: Maybe<GetInStudioToolkitResult>
  isDigitalOnly?: Maybe<Scalars['Boolean']>
  jobListingEmail?: Maybe<Scalars['String']>
  links?: Maybe<Array<ProfileLink>>
  listings?: Maybe<Array<ClubListing>>
  media?: Maybe<Array<Image>>
  name: Scalars['String']
  openToNewPrograms: Scalars['Boolean']
  phone?: Maybe<Scalars['String']>
  profileImage?: Maybe<Image>
  programIds?: Maybe<Array<Scalars['String']>>
  programs?: Maybe<Array<ClubProgramContent>>
  shortlist?: Maybe<Array<InstructorShortlist>>
  strength?: Maybe<Scalars['Int']>
}

export type UserProfileTeaching = {
  __typename?: 'UserProfileTeaching'
  address: Address
  availability?: Maybe<InstructorAvailabilityValue>
  availableToSubstitute?: Maybe<Scalars['Boolean']>
  bio?: Maybe<Scalars['String']>
  certifications?: Maybe<Array<Certification>>
  createdAt: Scalars['AWSDateTime']
  createdAtLabel?: Maybe<Scalars['String']>
  enquiries?: Maybe<Array<ClubListingEnquiry>>
  id: Scalars['ID']
  interests?: Maybe<Array<Scalars['String']>>
  links?: Maybe<Array<ProfileLink>>
  media?: Maybe<Array<Image>>
  preferences?: Maybe<InstructorPreferences>
  privacySettings?: Maybe<PrivacySettingsInstructor>
  profileImage?: Maybe<Image>
  roles?: Maybe<Array<InstructorRole>>
  savedSearches?: Maybe<Array<SavedClubSearch>>
  shippingAddress: Address
  strength?: Maybe<Scalars['Int']>
  watchlist?: Maybe<Array<InstructorWatchlistItem>>
}

export enum UserProfileType {
  Club = 'CLUB',
  Instructor = 'INSTRUCTOR',
  Unknown = 'UNKNOWN',
}

export type UserResult = {
  __typename?: 'UserResult'
  distance?: Maybe<Scalars['Float']>
  distanceUnit?: Maybe<LocationDistanceUnit>
  id: Scalars['ID']
  profiles: Array<UserProfile>
  settings: UserSettings
  user: User
}

export type UserRoleInput = {
  id: Scalars['String']
  roleType: UserRoleType
}

export enum UserRoleType {
  Club = 'CLUB',
  Instructor = 'INSTRUCTOR',
  Unknown = 'UNKNOWN',
}

export type UserSettings = {
  __typename?: 'UserSettings'
  _debugIsAdmin?: Maybe<Scalars['Boolean']>
  _debugIsAffiliated?: Maybe<Scalars['Boolean']>
  locale: Locale
}

export type Walkthrough = {
  __typename?: 'Walkthrough'
  pages: Array<WalkthroughPage>
  totalPages: Scalars['Int']
}

export type WalkthroughPage = {
  __typename?: 'WalkthroughPage'
  body: Array<Maybe<Scalars['AWSJSON']>>
  image: ImageSet
  pageNumber: Scalars['Int']
  title: Scalars['String']
}

export type WalkthroughRequired = {
  __typename?: 'WalkthroughRequired'
  club: Scalars['Boolean']
  instructor: Scalars['Boolean']
}

export type SearchClubListingsWithFilterQueryVariables = Exact<{
  searchInfo: SearchClubListingsWithFilterInput
  filterInfo: FilterClubListingsWithFilterInput
}>

export type SearchClubListingsWithFilterQuery = { __typename?: 'Query' } & {
  searchClubListingsWithFilter:
    | { __typename?: 'ErrorResult' }
    | ({ __typename?: 'SearchClubListingsWithFilterResult' } & {
        edges: Array<
          Maybe<
            { __typename?: 'ClubListingsEdge' } & Pick<
              ClubListingsEdge,
              'cursor'
            > & {
                node: {
                  __typename?: 'SearchClubJobListingsSingleResult'
                } & Pick<
                  SearchClubJobListingsSingleResult,
                  | 'distance'
                  | 'distanceUnit'
                  | 'messageSentOn'
                  | 'enquiredOn'
                  | 'onWatchlist'
                  | 'hasBeenReported'
                > & {
                    listing:
                      | ({ __typename?: 'ClubMultiListing' } & Pick<
                          ClubMultiListing,
                          'programsId'
                        > &
                          MultiListingFieldsFragment)
                      | ({ __typename?: 'ClubSingleListing' } & Pick<
                          ClubSingleListing,
                          'programId'
                        > &
                          SingleListingFieldsFragment)
                  }
              }
          >
        >
        pageInfo: { __typename?: 'ClubListingsPageInfo' } & Pick<
          ClubListingsPageInfo,
          'hasNextPage'
        > & {
            endCursor?: Maybe<
              { __typename?: 'ClubListingsEndCursor' } & Pick<
                ClubListingsEndCursor,
                'listingId' | 'sortValues'
              >
            >
          }
      })
}

export type SearchClubsWithFilterQueryVariables = Exact<{
  searchInfo: SearchClubsWithFilterInput
  filterInfo: FilterClubsInput
}>

export type SearchClubsWithFilterQuery = { __typename?: 'Query' } & {
  searchClubsWithFilter:
    | { __typename?: 'ErrorResult' }
    | ({ __typename?: 'SearchClubsWithFilterResult' } & {
        edges: Array<
          Maybe<
            { __typename?: 'ClubEdge' } & Pick<ClubEdge, 'cursor'> & {
                node: { __typename?: 'SearchClubProfileSingleResult' } & Pick<
                  SearchClubProfileSingleResult,
                  | 'distance'
                  | 'distanceUnit'
                  | 'onWatchlist'
                  | 'hasBeenReported'
                > & {
                    profile: { __typename?: 'UserProfileClub' } & Pick<
                      UserProfileClub,
                      'programIds'
                    > &
                      ClubProfileFieldsFragment
                  }
              }
          >
        >
        pageInfo: { __typename?: 'ClubPageInfo' } & Pick<
          ClubPageInfo,
          'hasNextPage'
        > & {
            endCursor?: Maybe<
              { __typename?: 'ClubSearchAfter' } & Pick<
                ClubSearchAfter,
                'clubId' | 'sortValues'
              >
            >
          }
      })
}

export type InStudioClubProgramFragment = { __typename?: 'ClubProgram' } & Pick<
  ClubProgram,
  'id' | 'code' | 'name' | 'status' | 'initials' | 'programType'
> & {
    instructorCount?: Maybe<
      { __typename?: 'ProgramInstructorCount' } & Pick<
        ProgramInstructorCount,
        'numberOfInstructors' | 'belowRecommendedAmount' | 'lastEnquiredOn'
      >
    >
  }

export type InStudioEmptyStateFragment = {
  __typename?: 'ClubProgramInStudioEmptyState'
} & Pick<ClubProgramInStudioEmptyState, 'programType'> & {
    content?: Maybe<
      { __typename?: 'InStudioEmptyState' } & Pick<
        InStudioEmptyState,
        'subtitle' | 'title'
      > & { link: { __typename?: 'Link' } & LinkFieldsFragment }
    >
  }

export type InStudioToolkitTileFragment = {
  __typename?: 'InStudioToolkitTile'
} & Pick<InStudioToolkitTile, 'subTitle' | 'title'> & {
    image: { __typename?: 'Image' } & ImageFieldsFragment
    link: { __typename?: 'Link' } & LinkFieldsFragment
  }

export type GetClubProgramsForInStudioPageQueryVariables = Exact<{
  input: GetClubByIdInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetClubProgramsForInStudioPageQuery = { __typename?: 'Query' } & {
  getClubById?: Maybe<
    | ({ __typename?: 'ErrorResult' } & Pick<ErrorResult, 'type' | 'message'>)
    | ({ __typename?: 'UserProfileClub' } & {
        programs?: Maybe<
          Array<
            | ({ __typename?: 'ClubProgram' } & InStudioClubProgramFragment)
            | ({
                __typename?: 'ClubProgramInStudioEmptyState'
              } & InStudioEmptyStateFragment)
          >
        >
        inStudioToolkit?: Maybe<
          { __typename?: 'GetInStudioToolkitResult' } & {
            tiles?: Maybe<
              Array<
                Maybe<
                  {
                    __typename?: 'InStudioToolkitTile'
                  } & InStudioToolkitTileFragment
                >
              >
            >
          }
        >
        exploreProgramsCard?: Maybe<
          { __typename?: 'InStudioExploreProgramsTileCardResult' } & {
            image?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment>
          }
        >
      })
  >
}

export type AddTrainingOutcomeMutationVariables = Exact<{
  input: AddTrainingOutcomeInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type AddTrainingOutcomeMutation = { __typename?: 'Mutation' } & {
  addTrainingOutcome:
    | ({ __typename?: 'AddTrainingOutcomeResult' } & Pick<
        AddTrainingOutcomeResult,
        'isSuccess'
      > & {
          developmentSheet?: Maybe<
            { __typename?: 'DevelopmentSheet' } & Pick<
              DevelopmentSheet,
              'filename' | 'url'
            >
          >
        })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type BookEventMutationVariables = Exact<{
  input: BookEventInput
  role: UserRoleInput
}>

export type BookEventMutation = { __typename?: 'Mutation' } & {
  bookEvent:
    | ({ __typename?: 'BookEventResult' } & Pick<
        BookEventResult,
        'magentoLink'
      >)
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type BookQwsEventMutationVariables = Exact<{
  input: BookQwsInput
  role: UserRoleInput
}>

export type BookQwsEventMutation = { __typename?: 'Mutation' } & {
  bookQuarterlyWorkshopEvent:
    | ({ __typename?: 'BookEventResult' } & Pick<
        BookEventResult,
        'magentoLink'
      >)
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type BookedEventQueryVariables = Exact<{
  eventId: Scalars['String']
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type BookedEventQuery = { __typename?: 'Query' } & {
  bookedEvent:
    | ({ __typename?: 'BookedEventResult' } & {
        eventDetails: { __typename?: 'EventDetailsResult' } & Pick<
          EventDetailsResult,
          'schedule' | 'details' | 'requirements' | 'cancellationPolicy'
        > & {
            event: { __typename?: 'Event' } & Pick<
              Event,
              | 'eventId'
              | 'type'
              | 'eventName'
              | 'startDate'
              | 'endDate'
              | 'deliveryMethod'
              | 'spacesAvailable'
              | 'venueAddress'
              | 'programName'
              | 'timezone'
            >
            trainerDetails?: Maybe<
              { __typename?: 'TrainerDetails' } & Pick<
                TrainerDetails,
                'name' | 'email'
              >
            >
          }
        bookedInstructors?: Maybe<
          Array<
            { __typename?: 'InstructorDetails' } & Pick<
              InstructorDetails,
              'name'
            > & {
                profileImage?: Maybe<
                  { __typename?: 'Image' } & Pick<Image, 'url'>
                >
              }
          >
        >
      })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type BookedEventsQueryVariables = Exact<{
  clubId: Scalars['String']
  _cacheKey?: Maybe<Scalars['String']>
}>

export type BookedEventsQuery = { __typename?: 'Query' } & {
  bookedEvents:
    | ({ __typename?: 'BookedEventsResult' } & {
        bookedEvents?: Maybe<
          Array<
            { __typename?: 'BookedEvent' } & Pick<
              BookedEvent,
              'numberOfBookedInstructors'
            > & {
                event: { __typename?: 'Event' } & Pick<
                  Event,
                  | 'eventId'
                  | 'type'
                  | 'eventName'
                  | 'startDate'
                  | 'endDate'
                  | 'deliveryMethod'
                  | 'spacesAvailable'
                  | 'venueAddress'
                  | 'programName'
                >
              }
          >
        >
      })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type EventDetailsQueryVariables = Exact<{
  eventId: Scalars['String']
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type EventDetailsQuery = { __typename?: 'Query' } & {
  eventDetails:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'EventDetailsResult' } & Pick<
        EventDetailsResult,
        'schedule' | 'details' | 'requirements' | 'cancellationPolicy'
      > & {
          event: { __typename?: 'Event' } & Pick<
            Event,
            | 'eventId'
            | 'eventName'
            | 'startDate'
            | 'endDate'
            | 'deliveryMethod'
            | 'spacesAvailable'
            | 'venueAddress'
            | 'programName'
            | 'type'
            | 'timezone'
          >
          trainerDetails?: Maybe<
            { __typename?: 'TrainerDetails' } & Pick<
              TrainerDetails,
              'name' | 'email'
            >
          >
          tickets: Array<
            { __typename?: 'Ticket' } & Pick<
              Ticket,
              | 'ticketId'
              | 'name'
              | 'currencyIsoCode'
              | 'price'
              | 'startDate'
              | 'endDate'
              | 'isDefaultTicket'
              | 'canBeUsedWithDiscountCode'
            >
          >
        })
}

export type QwsTrainerBookedEventQueryVariables = Exact<{
  eventId: Scalars['String']
  _cacheKey?: Maybe<Scalars['String']>
}>

export type QwsTrainerBookedEventQuery = { __typename?: 'Query' } & {
  qwsTrainerBookedEvent:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'QwsTrainerBookedEventResult' } & {
        qwsTrainerBookedEventSummary: {
          __typename?: 'QWSTrainerBookedEventSummary'
        } & Pick<
          QwsTrainerBookedEventSummary,
          | 'eventName'
          | 'deliveryMethod'
          | 'sessionCount'
          | 'instructorCount'
          | 'venueAddress'
          | 'startDate'
          | 'endDate'
        >
        sessions?: Maybe<
          Array<
            { __typename?: 'QwsTrainerBookedSessionDetails' } & Pick<
              QwsTrainerBookedSessionDetails,
              | 'sessionId'
              | 'sessionName'
              | 'programName'
              | 'startTime'
              | 'endTime'
              | 'timezone'
              | 'instructorCount'
            > & {
                instructors?: Maybe<
                  Array<
                    { __typename?: 'SessionInstructorDetails' } & Pick<
                      SessionInstructorDetails,
                      | 'registrationId'
                      | 'email'
                      | 'firstName'
                      | 'lastName'
                      | 'hasAttended'
                    > & {
                        profileImage?: Maybe<
                          { __typename?: 'Image' } & ImageFieldsFragment
                        >
                      }
                  >
                >
              }
          >
        >
      })
}

export type InstructorInfoFieldsFragment = {
  __typename?: 'InstructorInformation'
} & Pick<InstructorInformation, 'address' | 'email' | 'name' | 'phone'>

export type DevelopmentSheetFieldsFragment = {
  __typename?: 'DevelopmentSheet'
} & Pick<DevelopmentSheet, 'filename' | 'url'>

export type OutcomeTrackFieldsFragment = { __typename?: 'OutcomeTrack' } & Pick<
  OutcomeTrack,
  'comment' | 'submittedAt'
> & { playback: { __typename?: 'Playback' } & PlaybackFieldsFragment }

export type PresentationTrackFieldsFragment = {
  __typename?: 'PresentationTrack'
} & Pick<PresentationTrack, 'comment' | 'submittedAt'> & {
    playback: { __typename?: 'Playback' } & PlaybackFieldsFragment
  }

export type EventSummaryFieldsFragment = { __typename?: 'EventSummary' } & Pick<
  EventSummary,
  | 'deliveryMethod'
  | 'endDate'
  | 'eventId'
  | 'eventName'
  | 'eventStatus'
  | 'programName'
  | 'startDate'
  | 'trainingJourneyCount'
  | 'venueAddress'
>

export type EventAssessmentFieldsFragment = {
  __typename?: 'EventAssessments'
} & Pick<
  EventAssessments,
  | 'attended'
  | 'outcome'
  | 'sessionRegistrationId'
  | 'trainingJourneyId'
  | 'trackAllocation'
> & {
    developmentSheet?: Maybe<
      { __typename?: 'DevelopmentSheet' } & DevelopmentSheetFieldsFragment
    >
    emergencyContact?: Maybe<
      { __typename?: 'EmergencyContact' } & EmergencyContactFieldsFragment
    >
    instructorInfo: {
      __typename?: 'InstructorInformation'
    } & InstructorInfoFieldsFragment
    outcomeTrack?: Maybe<
      { __typename?: 'OutcomeTrack' } & OutcomeTrackFieldsFragment
    >
    presentationTrack?: Maybe<
      { __typename?: 'PresentationTrack' } & PresentationTrackFieldsFragment
    >
  }

export type TrainerBookingQueryVariables = Exact<{
  input: TrainerBookingInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type TrainerBookingQuery = { __typename?: 'Query' } & {
  trainerBooking:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'TrainerBookingResult' } & {
        eventAssessments: Array<
          { __typename?: 'EventAssessments' } & EventAssessmentFieldsFragment
        >
        eventSummary: { __typename?: 'EventSummary' } & Pick<
          EventSummary,
          | 'type'
          | 'deliveryMethod'
          | 'endDate'
          | 'eventId'
          | 'eventName'
          | 'eventStatus'
          | 'programName'
          | 'startDate'
          | 'trainingJourneyCount'
          | 'venueAddress'
        >
      })
}

export type TrainerBookingsQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>
}>

export type TrainerBookingsQuery = { __typename?: 'Query' } & {
  trainerBookings:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'TrainerBookingsResult' } & {
        result: Array<
          { __typename?: 'EventSummary' } & Pick<
            EventSummary,
            | 'deliveryMethod'
            | 'type'
            | 'endDate'
            | 'eventId'
            | 'eventName'
            | 'programName'
            | 'startDate'
            | 'eventStatus'
            | 'trainingJourneyCount'
            | 'venueAddress'
          >
        >
      })
}

export type PlaybackFieldsFragment = { __typename?: 'Playback' } & Pick<
  Playback,
  'playbackUrl' | 'token'
>

export type AssessmentVideoFieldsFragment = {
  __typename?: 'AssessmentVideo'
} & Pick<
  AssessmentVideo,
  | 'assessmentId'
  | 'comment'
  | 'fileKey'
  | 'submitted'
  | 'submittedDate'
  | 'recordType'
  | 'videoNotWorking'
  | 'extensionRequested'
> & { playback?: Maybe<{ __typename?: 'Playback' } & PlaybackFieldsFragment> }

export type EmergencyContactFieldsFragment = {
  __typename?: 'EmergencyContact'
} & Pick<EmergencyContact, 'address' | 'name' | 'phone'>

export type TrainingOutcomeFieldsFragment = {
  __typename?: 'TrainingOutcome'
} & Pick<
  TrainingOutcome,
  'developmentSheet' | 'outcome' | 'submitDate' | 'trainingNotes'
> & {
    assessmentVideo?: Maybe<
      { __typename?: 'AssessmentVideo' } & AssessmentVideoFieldsFragment
    >
  }

type StepFields_AttendTrainingStep_Fragment = {
  __typename?: 'AttendTrainingStep'
} & Pick<
  AttendTrainingStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'venueAddress'
  | 'contactUsEmail'
>

type StepFields_PreworkStep_Fragment = { __typename?: 'PreworkStep' } & Pick<
  PreworkStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'venueAddress'
  | 'contactUsEmail'
>

type StepFields_ReceivedCertificationOutcomeStep_Fragment = {
  __typename?: 'ReceivedCertificationOutcomeStep'
} & Pick<
  ReceivedCertificationOutcomeStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'venueAddress'
  | 'contactUsEmail'
>

type StepFields_ReceivedTrainingOutcomeStep_Fragment = {
  __typename?: 'ReceivedTrainingOutcomeStep'
} & Pick<
  ReceivedTrainingOutcomeStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'venueAddress'
  | 'contactUsEmail'
>

type StepFields_SubmitCertificationVideoStep_Fragment = {
  __typename?: 'SubmitCertificationVideoStep'
} & Pick<
  SubmitCertificationVideoStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'venueAddress'
  | 'contactUsEmail'
>

type StepFields_SubmitOutcomeTrackStep_Fragment = {
  __typename?: 'SubmitOutcomeTrackStep'
} & Pick<
  SubmitOutcomeTrackStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'venueAddress'
  | 'contactUsEmail'
>

type StepFields_TrainingBookedStep_Fragment = {
  __typename?: 'TrainingBookedStep'
} & Pick<
  TrainingBookedStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'venueAddress'
  | 'contactUsEmail'
>

export type StepFieldsFragment =
  | StepFields_AttendTrainingStep_Fragment
  | StepFields_PreworkStep_Fragment
  | StepFields_ReceivedCertificationOutcomeStep_Fragment
  | StepFields_ReceivedTrainingOutcomeStep_Fragment
  | StepFields_SubmitCertificationVideoStep_Fragment
  | StepFields_SubmitOutcomeTrackStep_Fragment
  | StepFields_TrainingBookedStep_Fragment

export type TrainingBookedStepFieldsFragment = {
  __typename?: 'TrainingBookedStep'
} & Pick<
  TrainingBookedStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'parqForm'
  | 'venueAddress'
  | 'contactUsEmail'
> & {
    emergencyContact?: Maybe<
      { __typename?: 'EmergencyContact' } & EmergencyContactFieldsFragment
    >
  }

export type UrlFieldsFragment = { __typename?: 'URL' } & Pick<
  Url,
  'type' | 'value'
>

export type PreworkContentItemFieldsFragment = {
  __typename?: 'PreworkContentItem'
} & Pick<PreworkContentItem, 'title' | 'type'> & {
    url: { __typename?: 'URL' } & UrlFieldsFragment
  }

export type PreworkContentFieldsFragment = { __typename?: 'PreworkContent' } & {
  contentList?: Maybe<
    Array<
      { __typename?: 'PreworkContentItem' } & PreworkContentItemFieldsFragment
    >
  >
}

export type PreworkStepFieldsFragment = { __typename?: 'PreworkStep' } & Pick<
  PreworkStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'venueAddress'
  | 'contactUsEmail'
> & {
    assessmentVideo?: Maybe<
      { __typename?: 'AssessmentVideo' } & AssessmentVideoFieldsFragment
    >
    preworkContent?: Maybe<
      { __typename?: 'PreworkContent' } & PreworkContentFieldsFragment
    >
    releaseUrl?: Maybe<{ __typename?: 'Link' } & LinkFieldsFragment>
  }

export type AttendTrainingStepFieldsFragment = {
  __typename?: 'AttendTrainingStep'
} & Pick<
  AttendTrainingStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'cecCertificationUrl'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'venueAddress'
  | 'contactUsEmail'
>

export type SubmitOutcomeTrackStepFieldsFragment = {
  __typename?: 'SubmitOutcomeTrackStep'
} & Pick<
  SubmitOutcomeTrackStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'extendedDueDate'
  | 'venueAddress'
  | 'contactUsEmail'
> & {
    assessmentVideo?: Maybe<
      { __typename?: 'AssessmentVideo' } & AssessmentVideoFieldsFragment
    >
  }

export type ReceivedTrainingOutcomeStepFieldsFragment = {
  __typename?: 'ReceivedTrainingOutcomeStep'
} & Pick<
  ReceivedTrainingOutcomeStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'cecCertificationUrl'
  | 'extendedDueDate'
  | 'venueAddress'
  | 'contactUsEmail'
> & {
    assessmentOutcomes?: Maybe<
      Array<{ __typename?: 'TrainingOutcome' } & TrainingOutcomeFieldsFragment>
    >
  }

export type SubmitCertificationVideoStepFieldsFragment = {
  __typename?: 'SubmitCertificationVideoStep'
} & Pick<
  SubmitCertificationVideoStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'extendedDueDate'
  | 'venueAddress'
  | 'contactUsEmail'
> & {
    assessmentVideo?: Maybe<
      { __typename?: 'AssessmentVideo' } & AssessmentVideoFieldsFragment
    >
  }

export type ReceivedCertificationOutcomeStepFieldsFragment = {
  __typename?: 'ReceivedCertificationOutcomeStep'
} & Pick<
  ReceivedCertificationOutcomeStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'passPendingGuide'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'resubmitDueDate'
  | 'certificationUrl'
  | 'venueAddress'
  | 'contactUsEmail'
> & {
    certificationOutcomes?: Maybe<
      Array<{ __typename?: 'TrainingOutcome' } & TrainingOutcomeFieldsFragment>
    >
  }

export type CertifiedStepFieldsFragment = {
  __typename?: 'CertifiedStep'
} & Pick<
  CertifiedStep,
  | 'activeStepName'
  | 'activeStepNumber'
  | 'attended'
  | 'cancellationPolicy'
  | 'deliveryMethod'
  | 'details'
  | 'dueDate'
  | 'endDate'
  | 'eventName'
  | 'fulfillment'
  | 'level'
  | 'onlineTrainingLink'
  | 'passPendingGuide'
  | 'programName'
  | 'requirements'
  | 'schedule'
  | 'startDate'
  | 'stepName'
  | 'stepNumber'
  | 'timezone'
  | 'trackAllocation'
  | 'trainerEmail'
  | 'trainerName'
  | 'contactUsEmail'
  | 'venueAddress'
>

export type TrainingJourneyQueryVariables = Exact<{
  input: TrainingJourneyInput
  role?: Maybe<UserRoleInput>
  _cacheKey?: Maybe<Scalars['String']>
}>

export type TrainingJourneyQuery = { __typename?: 'Query' } & {
  trainingJourney:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'TrainingJourneyResult' } & {
        result?: Maybe<
          { __typename?: 'TrainingJourney' } & Pick<TrainingJourney, 'type'> & {
              steps?: Maybe<
                Array<
                  | ({
                      __typename?: 'AttendTrainingStep'
                    } & AttendTrainingStepFieldsFragment)
                  | ({
                      __typename?: 'CertifiedStep'
                    } & CertifiedStepFieldsFragment)
                  | ({ __typename?: 'ContactUsStep' } & Pick<
                      ContactUsStep,
                      | 'activeStepName'
                      | 'activeStepNumber'
                      | 'stepName'
                      | 'stepNumber'
                      | 'contactUsEmail'
                    >)
                  | ({ __typename?: 'PreworkStep' } & PreworkStepFieldsFragment)
                  | ({
                      __typename?: 'ReceivedCertificationOutcomeStep'
                    } & ReceivedCertificationOutcomeStepFieldsFragment)
                  | ({
                      __typename?: 'ReceivedTrainingOutcomeStep'
                    } & ReceivedTrainingOutcomeStepFieldsFragment)
                  | ({
                      __typename?: 'SubmitCertificationVideoStep'
                    } & SubmitCertificationVideoStepFieldsFragment)
                  | ({
                      __typename?: 'SubmitOutcomeTrackStep'
                    } & SubmitOutcomeTrackStepFieldsFragment)
                  | ({
                      __typename?: 'TrainingBookedStep'
                    } & TrainingBookedStepFieldsFragment)
                >
              >
            }
        >
      })
}

export type TrainingJourneysQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>
}>

export type TrainingJourneysQuery = { __typename?: 'Query' } & {
  trainingJourneys:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'TrainingJourneysResult' } & Pick<
        TrainingJourneysResult,
        'eventsAndTrainingUrl'
      > & {
          trainings?: Maybe<
            Array<
              { __typename?: 'TrainingSummary' } & Pick<
                TrainingSummary,
                | 'id'
                | 'eventName'
                | 'type'
                | 'deliveryMethod'
                | 'startDate'
                | 'endDate'
                | 'timezone'
                | 'cecCertificationUrl'
                | 'venueAddress'
                | 'activeStepName'
                | 'activeStepNumber'
                | 'dueDate'
                | 'programName'
                | 'isContactUs'
                | 'dateCertified'
              >
            >
          >
        })
}

export type QwsBookedEventQueryVariables = Exact<{
  eventId: Scalars['String']
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type QwsBookedEventQuery = { __typename?: 'Query' } & {
  qwsBookedEvent:
    | ({ __typename?: 'ErrorResult' } & Pick<
        ErrorResult,
        'component' | 'logGroup' | 'logStream' | 'message' | 'requestId'
      > & { errorType: ErrorResult['type'] })
    | ({ __typename?: 'QwsBookedEventResult' } & {
        eventDetails: { __typename?: 'QWSEventDetailsResult' } & Pick<
          QwsEventDetailsResult,
          'details' | 'schedule' | 'requirements' | 'cancellationPolicy'
        > & {
            event: { __typename?: 'QWSEvent' } & Pick<
              QwsEvent,
              | 'deliveryMethod'
              | 'eventName'
              | 'eventId'
              | 'startDate'
              | 'endDate'
              | 'timezone'
              | 'venueAddress'
            >
          }
        sessions?: Maybe<
          Array<
            { __typename?: 'SessionDetails' } & Pick<
              SessionDetails,
              | 'sessionId'
              | 'sessionName'
              | 'startTime'
              | 'endTime'
              | 'spacesAvailable'
              | 'programName'
            > & {
                trainers?: Maybe<
                  Array<
                    { __typename?: 'TrainerDetails' } & Pick<
                      TrainerDetails,
                      'name'
                    >
                  >
                >
                bookedInstructors?: Maybe<
                  Array<
                    { __typename?: 'InstructorDetails' } & Pick<
                      InstructorDetails,
                      'id' | 'name'
                    > & {
                        profileImage?: Maybe<
                          { __typename?: 'Image' } & Pick<Image, 'url'>
                        >
                      }
                  >
                >
              }
          >
        >
        bookedInstructors?: Maybe<
          Array<
            { __typename?: 'InstructorDetails' } & Pick<
              InstructorDetails,
              'id' | 'name'
            > & {
                profileImage?: Maybe<
                  { __typename?: 'Image' } & Pick<Image, 'url'>
                >
              }
          >
        >
      })
}

export type QwsEventDetailsQueryVariables = Exact<{
  eventId: Scalars['String']
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type QwsEventDetailsQuery = { __typename?: 'Query' } & {
  qwsEventDetails:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'QWSEventDetailsResult' } & Pick<
        QwsEventDetailsResult,
        'schedule' | 'details' | 'requirements' | 'cancellationPolicy'
      > & {
          event: { __typename?: 'QWSEvent' } & Pick<
            QwsEvent,
            | 'eventId'
            | 'eventName'
            | 'startDate'
            | 'endDate'
            | 'deliveryMethod'
            | 'venueAddress'
            | 'timezone'
          > & {
              sessions: Array<
                { __typename?: 'Session' } & Pick<
                  Session,
                  | 'sessionId'
                  | 'sessionName'
                  | 'startTime'
                  | 'endTime'
                  | 'programName'
                  | 'spacesAvailable'
                > & {
                    trainerDetails?: Maybe<
                      Array<
                        { __typename?: 'TrainerDetails' } & Pick<
                          TrainerDetails,
                          'name' | 'email'
                        >
                      >
                    >
                  }
              >
            }
          tickets: Array<
            { __typename?: 'Ticket' } & Pick<
              Ticket,
              | 'ticketId'
              | 'name'
              | 'currencyIsoCode'
              | 'price'
              | 'startDate'
              | 'endDate'
              | 'isDefaultTicket'
              | 'canBeUsedWithDiscountCode'
            >
          >
        })
}

export type QwsImtTrainerBookingsQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>
}>

export type QwsImtTrainerBookingsQuery = { __typename?: 'Query' } & {
  qwsImtTrainerBookings:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'QwsImtTrainerBookingsResult' } & {
        qwsEvents?: Maybe<
          Array<
            { __typename: 'QWSTrainerBookedEventSummary' } & Pick<
              QwsTrainerBookedEventSummary,
              | 'eventId'
              | 'eventName'
              | 'type'
              | 'deliveryMethod'
              | 'startDate'
              | 'endDate'
              | 'venueAddress'
              | 'sessionCount'
              | 'instructorCount'
              | 'eventStatus'
            >
          >
        >
        trainingJourneys?: Maybe<
          Array<
            { __typename: 'EventSummary' } & Pick<
              EventSummary,
              | 'eventName'
              | 'deliveryMethod'
              | 'endDate'
              | 'eventId'
              | 'eventStatus'
              | 'programName'
              | 'startDate'
              | 'trainingJourneyCount'
              | 'type'
              | 'venueAddress'
            >
          >
        >
      })
}

export type RequestForExtensionMutationVariables = Exact<{
  input: RequestForExtensionInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type RequestForExtensionMutation = { __typename?: 'Mutation' } & {
  requestForExtension:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'RequestForExtensionResult' } & Pick<
        RequestForExtensionResult,
        'isSuccess'
      >)
}

export type SearchEventsQueryVariables = Exact<{
  searchInfo: SearchEventsInput
  filterInfo: FilterEventsInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type SearchEventsQuery = { __typename?: 'Query' } & {
  searchEvents:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'EventsResult' } & {
        edges: Array<
          Maybe<
            { __typename?: 'EventEdge' } & Pick<EventEdge, 'cursor'> & {
                node: { __typename?: 'Event' } & Pick<
                  Event,
                  | 'eventId'
                  | 'eventName'
                  | 'startDate'
                  | 'endDate'
                  | 'type'
                  | 'deliveryMethod'
                  | 'spacesAvailable'
                  | 'venueAddress'
                  | 'programName'
                  | 'programNames'
                >
              }
          >
        >
        pageInfo: { __typename?: 'EventPageInfo' } & Pick<
          EventPageInfo,
          'hasNextPage'
        > & {
            endCursor?: Maybe<
              { __typename?: 'searchAfter' } & Pick<
                SearchAfter,
                'eventId' | 'sortValues'
              >
            >
          }
      })
}

export type AddClubListingMutationVariables = Exact<{
  input: AddClubListingInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type AddClubListingMutation = { __typename?: 'Mutation' } & {
  addClubListing?: Maybe<
    | ({ __typename?: 'ClubListingResult' } & {
        results?: Maybe<
          Array<
            | ({ __typename?: 'ClubMultiListing' } & MultiListingFieldsFragment)
            | ({
                __typename?: 'ClubSingleListing'
              } & SingleListingFieldsFragment)
          >
        >
      })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type AddEmergencyContactMutationVariables = Exact<{
  input: EmergencyContactInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type AddEmergencyContactMutation = { __typename?: 'Mutation' } & {
  addEmergencyContact:
    | ({ __typename?: 'AddEmergencyContactResult' } & {
        result?: Maybe<
          { __typename?: 'EmergencyContact' } & Pick<
            EmergencyContact,
            'address' | 'name' | 'phone'
          >
        >
      })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type AddToShortlistMutationVariables = Exact<{
  input: AddToShortlistInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type AddToShortlistMutation = { __typename?: 'Mutation' } & {
  addToShortlist?: Maybe<
    | ({ __typename?: 'AddToShortlistResult' } & {
        shortlist?: Maybe<
          Array<
            { __typename?: 'InstructorShortlist' } & Pick<
              InstructorShortlist,
              'id' | 'lastMessageIn48HoursSentAt' | 'distanceUnit' | 'distance'
            > & {
                profile: {
                  __typename?: 'UserProfileTeaching'
                } & InstructorProfileFieldsFragment
                user: { __typename?: 'User' } & Pick<
                  User,
                  'id' | 'lastName' | 'firstName'
                >
              }
          >
        >
      })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type CreateAffiliationMutationVariables = Exact<{
  input: CreateAffiliationInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type CreateAffiliationMutation = { __typename?: 'Mutation' } & {
  createAffiliation:
    | ({ __typename?: 'AffiliationAsyncResult' } & Pick<
        AffiliationAsyncResult,
        'status'
      > & {
          affilation?: Maybe<
            { __typename: 'Affiliation' } & Pick<
              Affiliation,
              | 'actionedDate'
              | 'clubId'
              | 'id'
              | 'latestActionInitiator'
              | 'pendingStatus'
              | 'requestedBy'
              | 'requestedDate'
              | 'role'
              | 'status'
              | 'userId'
            >
          >
        })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type CreateClubListingsMutationVariables = Exact<{
  input: CreateClubListingsInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type CreateClubListingsMutation = { __typename?: 'Mutation' } & {
  createClubListings?: Maybe<
    | ({ __typename?: 'CreateClubListingsResult' } & {
        successfulListings?: Maybe<
          Array<
            { __typename?: 'ClubSingleListing' } & SingleListingFieldsFragment
          >
        >
      })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type DeleteAffiliationMutationVariables = Exact<{
  input: DeleteAffiliationInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type DeleteAffiliationMutation = { __typename?: 'Mutation' } & {
  deleteAffiliation:
    | ({ __typename?: 'AffiliationAsyncResult' } & Pick<
        AffiliationAsyncResult,
        'status'
      >)
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type DeleteClubListingMutationVariables = Exact<{
  input: DeleteClubListingInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type DeleteClubListingMutation = { __typename?: 'Mutation' } & {
  deleteClubListing?: Maybe<
    | ({ __typename?: 'DeleteClubListingResult' } & Pick<
        DeleteClubListingResult,
        'clubId' | 'status' | 'listingId'
      > & {
          results: Array<
            | ({ __typename?: 'ClubMultiListing' } & MultiListingFieldsFragment)
            | ({
                __typename?: 'ClubSingleListing'
              } & SingleListingFieldsFragment)
          >
        })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type DeleteCurrentUserPortfolioImageMutationVariables = Exact<{
  input: DeleteCurrentUserPortfolioImageInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type DeleteCurrentUserPortfolioImageMutation = {
  __typename?: 'Mutation'
} & {
  deleteCurrentUserPortfolioImage:
    | ({ __typename?: 'DeleteCurrentUserPortfolioImageResult' } & Pick<
        DeleteCurrentUserPortfolioImageResult,
        'status'
      >)
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type DeleteMultipleJobListingsMutationVariables = Exact<{
  input: DeleteClubListingsInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type DeleteMultipleJobListingsMutation = { __typename?: 'Mutation' } & {
  deleteClubListings?: Maybe<
    | ({ __typename?: 'DeleteClubListingsResult' } & {
        success?: Maybe<
          Array<
            { __typename?: 'ClubSingleListing' } & SingleListingFieldsFragment
          >
        >
      })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type DigitalOnlyInStudioEnquireMutationVariables = Exact<{
  input: EnquireAboutInStudioProgramsInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type DigitalOnlyInStudioEnquireMutation = { __typename?: 'Mutation' } & {
  enquireAboutInStudioPrograms?: Maybe<
    | ({ __typename?: 'EnquireAboutInStudioProgramsResult' } & Pick<
        EnquireAboutInStudioProgramsResult,
        'isSuccess'
      >)
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type EnquireAboutOfferingMutationVariables = Exact<{
  input: EnquireAboutOnlineProgramInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type EnquireAboutOfferingMutation = { __typename?: 'Mutation' } & {
  enquireAboutOnlineProgram?: Maybe<
    | ({ __typename?: 'EnquireAboutOnlineProgramResult' } & Pick<
        EnquireAboutOnlineProgramResult,
        'lastEnquiredOn' | 'onlineProgramid'
      >)
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type EnquireAboutProgramMutationVariables = Exact<{
  input: EnquireAboutProgramInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type EnquireAboutProgramMutation = { __typename?: 'Mutation' } & {
  enquireAboutProgram?: Maybe<
    | ({ __typename?: 'EnquireAboutProgramResult' } & Pick<
        EnquireAboutProgramResult,
        'lastEnquiredOn' | 'programId'
      >)
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type EnquireAboutProgramInExploreProgramsMutationVariables = Exact<{
  input: EnquireAboutExploreProgramsInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type EnquireAboutProgramInExploreProgramsMutation = {
  __typename?: 'Mutation'
} & {
  enquireAboutProgramInExplorePrograms?: Maybe<
    | ({ __typename?: 'EnquireAboutExploreProgramsResult' } & Pick<
        EnquireAboutExploreProgramsResult,
        'lastEnquiredOn' | 'programId'
      >)
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type LinkFieldsFragment = { __typename?: 'Link' } & Pick<
  Link,
  'id' | 'text'
> & { url: { __typename?: 'URL' } & UrlFieldsFragment }

export type InstructorAvailabilityFieldsFragment = {
  __typename?: 'InstructorAvailability'
} & Pick<InstructorAvailability, 'description' | 'title' | 'value'>

export type ImageFieldsFragment = { __typename: 'Image' } & Pick<
  Image,
  'id' | 'url' | 'alt' | 'height' | 'width'
>

export type TimePreferencesFragment = { __typename?: 'TimePreference' } & Pick<
  TimePreference,
  'morning' | 'afternoon' | 'evening'
>

export type TravelPreferencesFragment = {
  __typename?: 'TravelPreference'
} & Pick<TravelPreference, 'distance' | 'distanceUnit'> & {
    address?: Maybe<{ __typename?: 'Address' } & AddressFieldsFragment>
  }

export type ProgramFieldsFragment = { __typename?: 'Program' } & Pick<
  Program,
  'code' | 'id' | 'initials' | 'name'
>

export type PreferenceFieldsFragment = {
  __typename?: 'InstructorPreferences'
} & {
  programs?: Maybe<Array<{ __typename?: 'Program' } & ProgramFieldsFragment>>
  times?: Maybe<
    Array<{ __typename?: 'TimePreference' } & TimePreferencesFragment>
  >
  travel?: Maybe<
    { __typename?: 'TravelPreference' } & TravelPreferencesFragment
  >
}

export type PrivacyFieldsFragment = {
  __typename?: 'PrivacySettingsInstructor'
} & Pick<
  PrivacySettingsInstructor,
  | 'EMAIL'
  | 'PHONE_NUMBER'
  | 'PROFILE_PHOTO'
  | 'SOCIAL_LINKS'
  | 'LMQ_LEVEL'
  | 'HIDDEN'
>

export type InstructorProfileFieldsFragment = {
  __typename: 'UserProfileTeaching'
} & Pick<
  UserProfileTeaching,
  | 'id'
  | 'bio'
  | 'roles'
  | 'strength'
  | 'interests'
  | 'createdAt'
  | 'availability'
  | 'createdAtLabel'
  | 'availableToSubstitute'
> & {
    address: { __typename?: 'Address' } & AddressFieldsFragment
    shippingAddress: { __typename?: 'Address' } & AddressFieldsFragment
    preferences?: Maybe<
      { __typename?: 'InstructorPreferences' } & PreferenceFieldsFragment
    >
    privacySettings?: Maybe<
      { __typename?: 'PrivacySettingsInstructor' } & PrivacyFieldsFragment
    >
    media?: Maybe<Array<{ __typename?: 'Image' } & ImageFieldsFragment>>
    profileImage?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment>
    links?: Maybe<
      Array<{ __typename?: 'ProfileLink' } & Pick<ProfileLink, 'url' | 'type'>>
    >
    certifications?: Maybe<
      Array<
        { __typename?: 'Certification' } & Pick<
          Certification,
          | 'id'
          | 'lastPurchase'
          | 'transactionStatus'
          | 'transactionTimestamp'
          | 'billedClubId'
          | 'releaseStatus'
          | 'status'
          | 'active'
          | 'lmqLevel'
        > & {
            pricePerQuarter?: Maybe<
              { __typename?: 'CertificationPrice' } & Pick<
                CertificationPrice,
                'amount' | 'currency'
              >
            >
            link: { __typename?: 'Link' } & LinkFieldsFragment
            program: { __typename?: 'Program' } & Pick<
              Program,
              'id' | 'name' | 'code' | 'initials'
            >
          }
      >
    >
    watchlist?: Maybe<
      Array<
        { __typename?: 'InstructorWatchlistItem' } & Pick<
          InstructorWatchlistItem,
          'clubId' | 'listingId'
        >
      >
    >
  }

export type ClubProfileFieldsFragment = {
  __typename: 'UserProfileClub'
} & Pick<
  UserProfileClub,
  | 'id'
  | 'bio'
  | 'name'
  | 'email'
  | 'jobListingEmail'
  | 'phone'
  | 'hiring'
  | 'strength'
  | 'createdAt'
  | 'createdAtLabel'
  | 'isDigitalOnly'
  | 'openToNewPrograms'
  | 'programIds'
> & {
    address: { __typename?: 'Address' } & AddressFieldsFragment
    programs?: Maybe<
      Array<
        | ({ __typename: 'ClubProgram' } & Pick<
            ClubProgram,
            'id' | 'name' | 'code' | 'initials' | 'programType'
          >)
        | { __typename?: 'ClubProgramInStudioEmptyState' }
      >
    >
    media?: Maybe<Array<{ __typename?: 'Image' } & ImageFieldsFragment>>
    profileImage?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment>
    links?: Maybe<
      Array<{ __typename?: 'ProfileLink' } & Pick<ProfileLink, 'url' | 'type'>>
    >
  }

export type FullUserFieldFragment = { __typename: 'UserResult' } & Pick<
  UserResult,
  'id' | 'distance' | 'distanceUnit'
> & {
    user: { __typename?: 'User' } & Pick<
      User,
      'id' | 'email' | 'lastName' | 'firstName' | 'accountId' | 'phone'
    > & {
        market: { __typename?: 'Market' } & Pick<
          Market,
          'isSupported' | 'name' | 'locale' | 'distanceUnit'
        > & {
            programs?: Maybe<
              Array<
                { __typename?: 'Program' } & Pick<
                  Program,
                  'id' | 'name' | 'code' | 'initials'
                >
              >
            >
          }
        signedAgreements?: Maybe<
          Array<
            { __typename?: 'SignedAgreement' } & Pick<
              SignedAgreement,
              'type' | 'signedOn' | 'version'
            >
          >
        >
        userMetaData?: Maybe<
          { __typename?: 'UserMetadata' } & {
            walkthroughRequired: { __typename?: 'WalkthroughRequired' } & Pick<
              WalkthroughRequired,
              'club' | 'instructor'
            >
          }
        >
      }
    profiles: Array<
      | ({ __typename?: 'UserProfileClub' } & ClubProfileFieldsFragment)
      | ({
          __typename?: 'UserProfileTeaching'
        } & InstructorProfileFieldsFragment)
    >
    settings: { __typename?: 'UserSettings' } & Pick<UserSettings, 'locale'>
  }

export type SingleListingFieldsFragment = {
  __typename?: 'ClubSingleListing'
} & Pick<
  ClubSingleListing,
  | 'createdAt'
  | 'description'
  | 'expiringAt'
  | 'id'
  | 'searchAppearances'
  | 'updatedAt'
  | 'views'
  | 'isSubstituteListing'
  | 'status'
  | 'enquiriesCount'
> & {
    clubInfo: { __typename?: 'UserProfileClub' } & Pick<
      UserProfileClub,
      'id' | 'name' | 'hiring'
    > & {
        address: { __typename?: 'Address' } & AddressFieldsFragment
        profileImage?: Maybe<{ __typename?: 'Image' } & Pick<Image, 'url'>>
        programs?: Maybe<
          Array<
            | ({ __typename?: 'ClubProgram' } & Pick<ClubProgram, 'name'>)
            | { __typename?: 'ClubProgramInStudioEmptyState' }
          >
        >
      }
    program: { __typename?: 'ClubProgram' } & Pick<
      ClubProgram,
      'code' | 'id' | 'initials' | 'name'
    >
    times: Array<{ __typename?: 'TimePreference' } & TimePreferencesFragment>
  }

export type MultiListingFieldsFragment = {
  __typename?: 'ClubMultiListing'
} & Pick<
  ClubMultiListing,
  | 'createdAt'
  | 'description'
  | 'expiringAt'
  | 'id'
  | 'searchAppearances'
  | 'status'
  | 'updatedAt'
  | 'views'
  | 'enquiriesCount'
> & {
    clubInfo: { __typename?: 'UserProfileClub' } & Pick<
      UserProfileClub,
      'id' | 'name' | 'hiring'
    > & {
        address: { __typename?: 'Address' } & AddressFieldsFragment
        profileImage?: Maybe<{ __typename?: 'Image' } & Pick<Image, 'url'>>
        programs?: Maybe<
          Array<
            | ({ __typename?: 'ClubProgram' } & Pick<ClubProgram, 'name'>)
            | { __typename?: 'ClubProgramInStudioEmptyState' }
          >
        >
      }
    programs: Array<
      { __typename?: 'ClubProgram' } & Pick<
        ClubProgram,
        'code' | 'id' | 'initials' | 'name'
      >
    >
  }

export type SavedInstructorSearchFragment = {
  __typename?: 'SavedInstructorSearch'
} & Pick<SavedInstructorSearch, 'sort'> & {
    filter?: Maybe<
      { __typename?: 'SavedSearchClubListingsFilter' } & Pick<
        SavedSearchClubListingsFilter,
        'name' | 'programs' | 'substituteListingFilter'
      > & {
          location?: Maybe<
            { __typename?: 'LocationFilter' } & {
              within?: Maybe<
                { __typename?: 'LocationWithin' } & {
                  address?: Maybe<
                    { __typename?: 'Address' } & AddressFieldsFragment
                  >
                  area: { __typename?: 'GeoPolygon' } & {
                    points: Array<
                      { __typename?: 'GeoPoint' } & Pick<
                        GeoPoint,
                        'lat' | 'lon'
                      >
                    >
                  }
                }
              >
              near?: Maybe<
                { __typename?: 'LocationNear' } & Pick<
                  LocationNear,
                  'distance' | 'distanceUnit'
                > & {
                    address?: Maybe<
                      { __typename?: 'Address' } & AddressFieldsFragment
                    >
                  }
              >
              point?: Maybe<
                { __typename?: 'GeoPoint' } & Pick<GeoPoint, 'lat' | 'lon'>
              >
            }
          >
        }
    >
  }

export type AddressFieldsFragment = { __typename?: 'Address' } & Pick<
  Address,
  | 'city'
  | 'state'
  | 'street'
  | 'country'
  | 'stateCode'
  | 'postalCode'
  | 'streetName'
  | 'countryCode'
  | 'streetNumber'
> & {
    location?: Maybe<
      { __typename?: 'GeoPoint' } & Pick<GeoPoint, 'lat' | 'lon'>
    >
  }

export type ErrorResultFieldsFragment = { __typename: 'ErrorResult' } & Pick<
  ErrorResult,
  'type' | 'message' | 'requestId' | 'logGroup' | 'logStream'
>

export type ClubFieldsFragment = { __typename?: 'Club' } & Pick<
  Club,
  'accountId' | 'createdAt' | 'currency' | 'id' | 'name' | 'parent' | 'phone'
> & {
    email?: Maybe<
      { __typename?: 'EmailClubType' } & Pick<
        EmailClubType,
        'affiliations' | 'jobListings' | 'main'
      >
    >
    market: { __typename?: 'Market' } & Pick<
      Market,
      'name' | 'locale' | 'distanceUnit'
    > & {
        programs?: Maybe<
          Array<
            { __typename?: 'Program' } & Pick<
              Program,
              'id' | 'name' | 'code' | 'initials'
            >
          >
        >
      }
    profile: { __typename?: 'UserProfileClub' } & ClubProfileFieldsFragment
  }

export type AffiliationFieldsFragment = { __typename?: 'Affiliation' } & Pick<
  Affiliation,
  | 'actionedDate'
  | 'clubId'
  | 'id'
  | 'latestActionInitiator'
  | 'pendingStatus'
  | 'requestedBy'
  | 'requestedDate'
  | 'role'
  | 'status'
  | 'userId'
>

export type ManagePeopleUserAffiliateFieldsFragment = {
  __typename?: 'User'
} & Pick<
  User,
  'id' | 'phone' | 'email' | 'accountId' | 'lastName' | 'firstName'
> & {
    market: { __typename?: 'Market' } & Pick<
      Market,
      'name' | 'locale' | 'distanceUnit'
    >
  }

export type GetAgreementDocumentQueryVariables = Exact<{
  input: GetSignedAgreementInput
  _cacheKey?: Maybe<Scalars['String']>
  versionOnly?: Maybe<Scalars['Boolean']>
}>

export type GetAgreementDocumentQuery = { __typename?: 'Query' } & {
  getAgreementDocument:
    | ({ __typename?: 'AgreementDocumentResult' } & MakeOptional<
        Pick<AgreementDocumentResult, 'version' | 'content'>,
        'content'
      >)
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type GetCertificatesQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetCertificatesQuery = { __typename?: 'Query' } & {
  getCertificates:
    | ({ __typename?: 'CertificatesResult' } & {
        certificates?: Maybe<
          Array<
            { __typename?: 'Certificate' } & Pick<
              Certificate,
              | 'id'
              | 'assessmentLevel'
              | 'name'
              | 'programId'
              | 'level'
              | 'certificateUrl'
              | 'certificateDetailUrl'
              | 'subscribeStatus'
              | 'type'
            >
          >
        >
      })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type ClubWithAffiliationFragment = {
  __typename?: 'ClubWithAffiliation'
} & Pick<ClubWithAffiliation, 'clubId' | 'clubName' | 'isDigitalOnly'> & {
    latestAffiliation?: Maybe<
      { __typename?: 'Affiliation' } & AffiliationFieldsFragment
    >
  }

export type GetChainAffiliationQueryVariables = Exact<{
  input: GetChainAffiliationsInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetChainAffiliationQuery = { __typename?: 'Query' } & {
  getChainAffiliations:
    | ({ __typename?: 'ErrorResult' } & Pick<ErrorResult, 'type' | 'message'>)
    | ({ __typename?: 'GetChainAffiliationsResult' } & {
        clubs?: Maybe<
          Array<
            { __typename?: 'ClubWithAffiliation' } & ClubWithAffiliationFragment
          >
        >
      })
}

export type ManagePeopleClubAffiliatesFieldsFragment = {
  __typename?: 'ClubAffiliates'
} & Pick<
  ClubAffiliates,
  | 'actionedDate'
  | 'clubId'
  | 'distance'
  | 'distanceUnit'
  | 'id'
  | 'pendingStatus'
  | 'requestedBy'
  | 'requestedDate'
  | 'status'
> & { profile: { __typename?: 'UserProfileClub' } & ClubProfileFieldsFragment }

export type GetChainAffiliatedInstructorQueryVariables = Exact<{
  input?: Maybe<GetChainInstructorAffiliatesInput>
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetChainAffiliatedInstructorQuery = { __typename?: 'Query' } & {
  getChainInstructorAffiliates:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'GetChainInstructorAffiliatesResult' } & Pick<
        GetChainInstructorAffiliatesResult,
        'hasPendingAffiliations'
      > & {
          affiliates?: Maybe<
            Array<
              { __typename?: 'InstructorChainAffiliate' } & Pick<
                InstructorChainAffiliate,
                'purchaseOrder'
              > & {
                  clubAffiliates?: Maybe<
                    Array<
                      {
                        __typename?: 'ClubAffiliates'
                      } & ManagePeopleClubAffiliatesFieldsFragment
                    >
                  >
                  profile: {
                    __typename?: 'UserProfileTeaching'
                  } & InstructorProfileFieldsFragment
                  user: {
                    __typename?: 'User'
                  } & ManagePeopleUserAffiliateFieldsFragment
                }
            >
          >
        })
}

export type GetChainInstructorRequestsQueryVariables = Exact<{
  input: GetChainInstructorAffiliatesInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetChainInstructorRequestsQuery = { __typename?: 'Query' } & {
  getChainInstructorAffiliates:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'GetChainInstructorAffiliatesResult' } & Pick<
        GetChainInstructorAffiliatesResult,
        'hasPendingAffiliations'
      > & {
          requests?: Maybe<
            Array<
              Maybe<
                { __typename?: 'InstructorChainRequest' } & Pick<
                  InstructorChainRequest,
                  | 'clubId'
                  | 'clubName'
                  | 'distance'
                  | 'distanceUnit'
                  | 'id'
                  | 'pendingStatus'
                  | 'requestedBy'
                  | 'requestedDate'
                  | 'status'
                > & {
                    profile: {
                      __typename?: 'UserProfileTeaching'
                    } & InstructorProfileFieldsFragment
                    user: {
                      __typename?: 'User'
                    } & ManagePeopleUserAffiliateFieldsFragment
                  }
              >
            >
          >
        })
}

export type GetChainUserQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetChainUserQuery = { __typename?: 'Query' } & {
  getChainUser?: Maybe<
    | ({ __typename?: 'ErrorResult' } & Pick<ErrorResult, 'type' | 'message'>)
    | ({ __typename?: 'GetChainUser' } & Pick<GetChainUser, 'isChainUser'> & {
          chainLocations?: Maybe<
            Array<
              { __typename?: 'UserProfileClub' } & ClubProfileFieldsFragment
            >
          >
        })
  >
}

export type GetChainUserClubListingsQueryVariables = Exact<{
  input: GetChainUserClubListingsInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetChainUserClubListingsQuery = { __typename?: 'Query' } & {
  getChainUserClubListings?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetChainUserClubListingsResult' } & {
        singleListings?: Maybe<
          Array<
            { __typename?: 'ClubSingleListing' } & Pick<
              ClubSingleListing,
              'programId'
            > &
              SingleListingFieldsFragment
          >
        >
        multiListings?: Maybe<
          Array<
            { __typename?: 'ClubMultiListing' } & Pick<
              ClubMultiListing,
              'programsId'
            > &
              MultiListingFieldsFragment
          >
        >
      })
  >
}

export type BusinessSupportSectionContentFragment = {
  __typename?: 'BusinessSupportSection'
} & Pick<BusinessSupportSection, 'id' | 'sectionTitle'> & {
    sectionTiles?: Maybe<
      Array<
        { __typename?: 'BusinessSupportTile' } & Pick<
          BusinessSupportTile,
          'id' | 'title' | 'subtitle'
        > & {
            image: { __typename?: 'Image' } & Pick<Image, 'id' | 'alt' | 'url'>
            url: { __typename?: 'URL' } & Pick<Url, 'type' | 'value'>
          }
      >
    >
  }

export type GetClubBusinessSupportContentQueryVariables = Exact<{
  input: GetClubByIdInput
}>

export type GetClubBusinessSupportContentQuery = { __typename?: 'Query' } & {
  getClubById?: Maybe<
    | ({ __typename?: 'ErrorResult' } & Pick<ErrorResult, 'type' | 'message'>)
    | ({ __typename?: 'UserProfileClub' } & {
        businessSupport?: Maybe<
          Array<
            {
              __typename?: 'BusinessSupportSection'
            } & BusinessSupportSectionContentFragment
          >
        >
      })
  >
}

export type GetClubInstructorAffiliatesQueryVariables = Exact<{
  input: GetClubInstructorAffiliatesInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetClubInstructorAffiliatesQuery = { __typename?: 'Query' } & {
  getClubInstructorAffiliates?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'GetClubInstructorAffiliatesResult' } & {
        affiliates?: Maybe<
          Array<
            { __typename?: 'InstructorAffiliate' } & Pick<
              InstructorAffiliate,
              | 'purchaseOrder'
              | 'actionedDate'
              | 'id'
              | 'requestedBy'
              | 'requestedDate'
              | 'status'
              | 'clubId'
            > & {
                user: { __typename?: 'User' } & Pick<
                  User,
                  | 'id'
                  | 'phone'
                  | 'email'
                  | 'accountId'
                  | 'lastName'
                  | 'firstName'
                > & {
                    market: { __typename?: 'Market' } & Pick<
                      Market,
                      'name' | 'locale' | 'distanceUnit'
                    >
                  }
                profile: {
                  __typename?: 'UserProfileTeaching'
                } & InstructorProfileFieldsFragment
              }
          >
        >
        requests?: Maybe<
          Array<
            { __typename?: 'InstructorRequest' } & Pick<
              InstructorRequest,
              | 'id'
              | 'distance'
              | 'distanceUnit'
              | 'requestedDate'
              | 'requestedBy'
              | 'status'
              | 'pendingStatus'
              | 'clubId'
            > & {
                user: { __typename?: 'User' } & Pick<
                  User,
                  | 'id'
                  | 'phone'
                  | 'email'
                  | 'accountId'
                  | 'lastName'
                  | 'firstName'
                > & {
                    market: { __typename?: 'Market' } & Pick<
                      Market,
                      'name' | 'locale' | 'distanceUnit'
                    >
                  }
                profile: {
                  __typename?: 'UserProfileTeaching'
                } & InstructorProfileFieldsFragment
              }
          >
        >
      })
  >
}

export type GetClubListingByIdQueryVariables = Exact<{
  listingId: Scalars['String']
}>

export type GetClubListingByIdQuery = { __typename?: 'Query' } & {
  getClubListingById:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'SearchClubJobListingsSingleResult' } & Pick<
        SearchClubJobListingsSingleResult,
        | 'distance'
        | 'distanceUnit'
        | 'messageSentOn'
        | 'enquiredOn'
        | 'onWatchlist'
        | 'hasBeenReported'
      > & {
          listing:
            | ({ __typename?: 'ClubMultiListing' } & MultiListingFieldsFragment)
            | ({
                __typename?: 'ClubSingleListing'
              } & SingleListingFieldsFragment)
        })
}

export type GetClubListingsByClubIdQueryVariables = Exact<{
  input: GetClubListingsByClubIdInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetClubListingsByClubIdQuery = { __typename?: 'Query' } & {
  getClubListingsByClubId?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetClubListingsByClubIdResult' } & {
        results: Array<
          | ({ __typename?: 'ClubMultiListing' } & Pick<
              ClubMultiListing,
              | 'createdAt'
              | 'description'
              | 'expiringAt'
              | 'id'
              | 'searchAppearances'
              | 'status'
              | 'updatedAt'
              | 'views'
              | 'enquiriesCount'
              | 'programsId'
            > & {
                clubInfo: { __typename?: 'UserProfileClub' } & Pick<
                  UserProfileClub,
                  'id' | 'name' | 'hiring' | 'programIds'
                > & {
                    address: { __typename?: 'Address' } & AddressFieldsFragment
                    profileImage?: Maybe<
                      { __typename?: 'Image' } & Pick<Image, 'url'>
                    >
                    programs?: Maybe<
                      Array<
                        | ({ __typename?: 'ClubProgram' } & Pick<
                            ClubProgram,
                            'name'
                          >)
                        | { __typename?: 'ClubProgramInStudioEmptyState' }
                      >
                    >
                  }
                programs: Array<
                  { __typename?: 'ClubProgram' } & Pick<
                    ClubProgram,
                    'code' | 'id' | 'initials' | 'name'
                  >
                >
                enquiries: Array<
                  { __typename?: 'ClubListingEnquiry' } & Pick<
                    ClubListingEnquiry,
                    'createdAt'
                  >
                >
              })
          | ({ __typename?: 'ClubSingleListing' } & Pick<
              ClubSingleListing,
              | 'createdAt'
              | 'description'
              | 'expiringAt'
              | 'id'
              | 'searchAppearances'
              | 'updatedAt'
              | 'views'
              | 'isSubstituteListing'
              | 'status'
              | 'enquiriesCount'
              | 'programId'
            > & {
                clubInfo: { __typename?: 'UserProfileClub' } & Pick<
                  UserProfileClub,
                  'id' | 'name' | 'hiring' | 'programIds'
                > & {
                    address: { __typename?: 'Address' } & AddressFieldsFragment
                    profileImage?: Maybe<
                      { __typename?: 'Image' } & Pick<Image, 'url'>
                    >
                    programs?: Maybe<
                      Array<
                        | ({ __typename?: 'ClubProgram' } & Pick<
                            ClubProgram,
                            'name'
                          >)
                        | { __typename?: 'ClubProgramInStudioEmptyState' }
                      >
                    >
                  }
                program: { __typename?: 'ClubProgram' } & Pick<
                  ClubProgram,
                  'code' | 'id' | 'initials' | 'name'
                >
                times: Array<
                  { __typename?: 'TimePreference' } & TimePreferencesFragment
                >
                enquiries: Array<
                  { __typename?: 'ClubListingEnquiry' } & Pick<
                    ClubListingEnquiry,
                    'createdAt'
                  >
                >
              })
        >
      })
  >
}

export type GetClubManageMyPartnershipQueryVariables = Exact<{
  clubId: Scalars['ID']
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetClubManageMyPartnershipQuery = { __typename?: 'Query' } & {
  getClubPrograms?: Maybe<
    | ({ __typename?: 'ClubProgramsResult' } & {
        exploreProgramsDetails?: Maybe<
          Array<
            { __typename?: 'ExploreProgramDetails' } & Pick<
              ExploreProgramDetails,
              'program' | 'instructorsCount' | 'lastEnquiredOn'
            >
          >
        >
        clubProgramsDetails?: Maybe<
          Array<
            { __typename?: 'ClubProgramDetails' } & Pick<
              ClubProgramDetails,
              'program' | 'lastEnquiredOn' | 'status' | 'instructorsCount'
            >
          >
        >
      })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type GetClubProgramsQueryVariables = Exact<{
  clubId: Scalars['ID']
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetClubProgramsQuery = { __typename?: 'Query' } & {
  getClubPrograms?: Maybe<
    | ({ __typename?: 'ClubProgramsResult' } & {
        clubProgramsDetails?: Maybe<
          Array<
            { __typename?: 'ClubProgramDetails' } & Pick<
              ClubProgramDetails,
              'program' | 'lastEnquiredOn' | 'status' | 'instructorsCount'
            >
          >
        >
      })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type GetClubSettingsQueryVariables = Exact<{
  input: GetClubSettingsInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetClubSettingsQuery = { __typename?: 'Query' } & {
  getClubSettings:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetClubSettingsResult' } & Pick<
        GetClubSettingsResult,
        'id' | 'name' | 'phone'
      > & {
          address?: Maybe<
            { __typename?: 'Address' } & Pick<
              Address,
              | 'city'
              | 'state'
              | 'street'
              | 'country'
              | 'stateCode'
              | 'postalCode'
              | 'countryCode'
            > & {
                location?: Maybe<
                  { __typename?: 'GeoPoint' } & Pick<GeoPoint, 'lat' | 'lon'>
                >
              }
          >
          email?: Maybe<
            { __typename?: 'EmailClubType' } & Pick<
              EmailClubType,
              'main' | 'affiliations' | 'jobListings'
            >
          >
        })
}

export type GetContactUsContentQueryVariables = Exact<{
  input: GetContactUsContentInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetContactUsContentQuery = { __typename?: 'Query' } & {
  getContactUsContent:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetContactUsContentResult' } & {
        result: Array<
          { __typename?: 'SectionContentResult' } & Pick<
            SectionContentResult,
            'section'
          > & {
              content: Array<
                { __typename?: 'ContentField' } & Pick<
                  ContentField,
                  'title' | 'subHeading' | 'contentText'
                >
              >
            }
        >
      })
}

export type GetCurrentUserQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetCurrentUserQuery = { __typename?: 'Query' } & {
  getCurrentUser?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'UserResult' } & FullUserFieldFragment)
  >
}

export type GetCurrentUserPhotoUploadDataQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetCurrentUserPhotoUploadDataQuery = { __typename?: 'Query' } & {
  getCurrentUserPhotoUploadData?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'GetCurrentUserPhotoUploadDataResult' } & Pick<
        GetCurrentUserPhotoUploadDataResult,
        'url' | 'token'
      >)
  >
}

export type GetCurrentUserPortfolioUploadDataQueryVariables = Exact<{
  input: GetCurrentUserPortfolioUploadDataInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetCurrentUserPortfolioUploadDataQuery = {
  __typename?: 'Query'
} & {
  getCurrentUserPortfolioUploadData:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetCurrentUserPortfolioUploadDataResult' } & {
        items: Array<
          { __typename?: 'PortfolioUploadItem' } & Pick<
            PortfolioUploadItem,
            'url' | 'token'
          >
        >
      })
}

export type ClubDashboardBusinessSupportContentFragment = {
  __typename?: 'ClubPartnershipBusinessSupportContent'
} & Pick<ClubPartnershipBusinessSupportContent, 'title' | 'description'> & {
    link: { __typename?: 'Link' } & Pick<Link, 'text'> & {
        url: { __typename?: 'URL' } & Pick<Url, 'type' | 'value'>
      }
  }

export type ClubDashboardStandardContentFragment = {
  __typename?: 'ClubPartnershipStandardContent'
} & Pick<ClubPartnershipStandardContent, 'title' | 'subtitle'> & {
    link: { __typename?: 'Link' } & Pick<Link, 'text'> & {
        url: { __typename?: 'URL' } & Pick<Url, 'type' | 'value'>
      }
    image: { __typename?: 'ImageSet' } & {
      mobile: { __typename?: 'Image' } & Pick<Image, 'alt' | 'url'>
      desktop: { __typename?: 'Image' } & Pick<Image, 'alt' | 'url'>
    }
  }

export type ClubDashboardProgramInfoFragment = {
  __typename?: 'ClubPartnershipProgramStat'
} & Pick<ClubPartnershipProgramStat, 'total' | 'type'> & {
    icon: { __typename?: 'Image' } & Pick<Image, 'alt' | 'url'>
  }

export type ClubDashboardProgramStatsFragment = {
  __typename?: 'ClubPartnershipProgramStats'
} & {
  programsStats?: Maybe<
    Array<
      {
        __typename?: 'ClubPartnershipProgramStat'
      } & ClubDashboardProgramInfoFragment
    >
  >
}

export type GetDashboardQueryVariables = Exact<{
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetDashboardQuery = { __typename: 'Query' } & {
  getDashboard?: Maybe<
    | ({ __typename: 'DashboardPageClubResponse' } & Pick<
        DashboardPageClubResponse,
        'id'
      > & {
          links?: Maybe<
            Array<
              { __typename?: 'LinkTile' } & Pick<
                LinkTile,
                'id' | 'title' | 'subtitle'
              > & {
                  imageSet: { __typename?: 'ImageSet' } & Pick<
                    ImageSet,
                    'id'
                  > & {
                      desktop: { __typename?: 'Image' } & Pick<
                        Image,
                        'id' | 'alt' | 'url'
                      >
                      mobile: { __typename?: 'Image' } & Pick<
                        Image,
                        'id' | 'alt' | 'url'
                      >
                    }
                  url: { __typename?: 'URL' } & Pick<Url, 'type' | 'value'>
                }
            >
          >
          promotions?: Maybe<
            Array<
              { __typename?: 'Promotion' } & Pick<
                Promotion,
                'id' | 'title' | 'description'
              > & {
                  image: { __typename?: 'ImageSet' } & Pick<ImageSet, 'id'> & {
                      desktop: { __typename?: 'Image' } & Pick<
                        Image,
                        'id' | 'alt' | 'url' | 'width' | 'height'
                      >
                      mobile: { __typename?: 'Image' } & Pick<
                        Image,
                        'id' | 'alt' | 'url' | 'width' | 'height'
                      >
                    }
                  link?: Maybe<
                    { __typename?: 'Link' } & Pick<Link, 'id' | 'text'> & {
                        url: { __typename?: 'URL' } & Pick<
                          Url,
                          'value' | 'type'
                        >
                      }
                  >
                }
            >
          >
          partnership?: Maybe<
            Array<
              | ({
                  __typename?: 'ClubPartnershipBusinessSupportContent'
                } & ClubDashboardBusinessSupportContentFragment &
                  ClubDashboardBusinessSupportContentFragment)
              | ({
                  __typename?: 'ClubPartnershipProgramStats'
                } & ClubDashboardProgramStatsFragment)
              | ({
                  __typename?: 'ClubPartnershipStandardContent'
                } & ClubDashboardStandardContentFragment)
            >
          >
          myTools?: Maybe<
            Array<
              { __typename?: 'DashboardMyTools' } & Pick<
                DashboardMyTools,
                'id' | 'title' | 'subtitle'
              > & {
                  imageSet: { __typename?: 'ImageSet' } & Pick<
                    ImageSet,
                    'id'
                  > & {
                      desktop: { __typename?: 'Image' } & Pick<
                        Image,
                        'id' | 'alt' | 'url'
                      >
                      mobile: { __typename?: 'Image' } & Pick<
                        Image,
                        'id' | 'alt' | 'url'
                      >
                    }
                  url: { __typename?: 'URL' } & Pick<Url, 'type' | 'value'>
                }
            >
          >
        })
    | ({ __typename: 'DashboardPageInstructorResponse' } & Pick<
        DashboardPageInstructorResponse,
        'id'
      > & {
          translation: { __typename?: 'DashboardPageTranslation' } & Pick<
            DashboardPageTranslation,
            'title'
          >
          socialMedia: { __typename?: 'SocialMediaSection' } & Pick<
            SocialMediaSection,
            'title'
          > & {
              items?: Maybe<
                Array<
                  { __typename?: 'SocialMediaPost' } & Pick<
                    SocialMediaPost,
                    'id' | 'url' | 'type' | 'createdAt' | 'createdAtLabel'
                  > & {
                      content: { __typename?: 'SocialMediaContent' } & Pick<
                        SocialMediaContent,
                        'type' | 'value'
                      >
                    }
                >
              >
            }
          links?: Maybe<
            Array<
              { __typename?: 'LinkTile' } & Pick<
                LinkTile,
                'id' | 'title' | 'subtitle'
              > & {
                  imageSet: { __typename?: 'ImageSet' } & Pick<
                    ImageSet,
                    'id'
                  > & {
                      desktop: { __typename?: 'Image' } & Pick<
                        Image,
                        'id' | 'alt' | 'url'
                      >
                      mobile: { __typename?: 'Image' } & Pick<
                        Image,
                        'id' | 'alt' | 'url'
                      >
                    }
                  url: { __typename?: 'URL' } & Pick<Url, 'type' | 'value'>
                }
            >
          >
          certifications?: Maybe<
            Array<
              { __typename?: 'Certification' } & Pick<
                Certification,
                'id' | 'status' | 'active' | 'lmqLevel'
              > & {
                  link: { __typename?: 'Link' } & Pick<Link, 'id' | 'text'> & {
                      url: { __typename?: 'URL' } & Pick<Url, 'type' | 'value'>
                    }
                  steps?: Maybe<
                    Array<
                      { __typename?: 'CertificationStep' } & Pick<
                        CertificationStep,
                        'id' | 'label' | 'status'
                      > & {
                          action?: Maybe<
                            { __typename?: 'Link' } & Pick<
                              Link,
                              'id' | 'text'
                            > & {
                                url: { __typename?: 'URL' } & Pick<
                                  Url,
                                  'value' | 'type'
                                >
                              }
                          >
                        }
                    >
                  >
                  program: { __typename?: 'Program' } & Pick<
                    Program,
                    'id' | 'name' | 'code' | 'initials'
                  >
                }
            >
          >
          promotions?: Maybe<
            Array<
              { __typename?: 'Promotion' } & Pick<
                Promotion,
                'id' | 'title' | 'description'
              > & {
                  image: { __typename?: 'ImageSet' } & Pick<ImageSet, 'id'> & {
                      desktop: { __typename?: 'Image' } & Pick<
                        Image,
                        'id' | 'alt' | 'url' | 'width' | 'height'
                      >
                      mobile: { __typename?: 'Image' } & Pick<
                        Image,
                        'id' | 'alt' | 'url' | 'width' | 'height'
                      >
                    }
                  link?: Maybe<
                    { __typename?: 'Link' } & Pick<Link, 'id' | 'text'> & {
                        url: { __typename?: 'URL' } & Pick<
                          Url,
                          'value' | 'type'
                        >
                      }
                  >
                }
            >
          >
        })
    | ({ __typename: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type GetEqualOpportunitiesPolicyQueryVariables = Exact<{
  input: GetEqualOpportunitiesPolicyInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetEqualOpportunitiesPolicyQuery = { __typename?: 'Query' } & {
  getEqualOpportunitiesPolicy:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetEqualOpportunitiesPolicyResult' } & {
        result: Array<
          { __typename?: 'SectionContentResult' } & Pick<
            SectionContentResult,
            'section'
          > & {
              content: Array<
                { __typename?: 'ContentField' } & Pick<
                  ContentField,
                  'title' | 'subHeading' | 'contentText'
                >
              >
            }
        >
      })
}

export type GetFrequentlyAskedQuestionsQueryVariables = Exact<{
  input: GetFrequentlyAskedQuestionsInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetFrequentlyAskedQuestionsQuery = { __typename?: 'Query' } & {
  getFrequentlyAskedQuestions:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetFrequentlyAskedQuestionsResult' } & {
        result: Array<
          { __typename?: 'SectionContentResult' } & Pick<
            SectionContentResult,
            'section'
          > & {
              content: Array<
                { __typename?: 'ContentField' } & Pick<
                  ContentField,
                  'title' | 'subHeading' | 'contentText'
                >
              >
            }
        >
      })
}

export type GetHeaderContentQueryVariables = Exact<{
  input: GetHeaderInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetHeaderContentQuery = { __typename?: 'Query' } & {
  getHeaders?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'GetHeaderResult' } & {
        results: Array<
          { __typename?: 'Header' } & Pick<
            Header,
            'key' | 'title' | 'subHeading'
          > & {
              image?: Maybe<
                { __typename?: 'ImageSet' } & Pick<ImageSet, 'id'> & {
                    desktop: { __typename?: 'Image' } & Pick<
                      Image,
                      'id' | 'alt' | 'url'
                    >
                    mobile: { __typename?: 'Image' } & Pick<
                      Image,
                      'id' | 'alt' | 'url'
                    >
                  }
              >
            }
        >
      })
  >
}

export type InStudioManageOptionsFragment = {
  __typename?: 'GetPickListResult'
} & {
  results: Array<{ __typename?: 'PickList' } & Pick<PickList, 'items' | 'key'>>
}

export type GetInStudioManageOptionsQueryVariables = Exact<{
  input: GetPickListInput
}>

export type GetInStudioManageOptionsQuery = { __typename?: 'Query' } & {
  getPickLists?: Maybe<
    | ({ __typename: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'GetPickListResult' } & InStudioManageOptionsFragment)
  >
}

export type GetUserAccountQueryVariables = Exact<{
  input: GetUserByIdInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetUserAccountQuery = { __typename: 'Query' } & {
  getUserAccount:
    | ({ __typename: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'UserAccountSettingsResult' } & Pick<
        UserAccountSettingsResult,
        'id' | 'dateOfBirth' | 'email' | 'firstName' | 'lastName' | 'phone'
      > & {
          address?: Maybe<{ __typename?: 'Address' } & AddressFieldsFragment>
          outlinks?: Maybe<
            Array<
              { __typename?: 'OutLink' } & Pick<OutLink, 'url' | 'title'> & {
                  _metadata?: Maybe<
                    { __typename?: 'OutlinkMetedata' } & Pick<
                      OutlinkMetedata,
                      'urlSource'
                    >
                  >
                }
            >
          >
        })
}

export type GetInstructorEnquiriesQueryVariables = Exact<{
  role: UserRoleInput
  filter: UserEnquiriesFilter
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetInstructorEnquiriesQuery = { __typename?: 'Query' } & {
  getUserEnquiries?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetUserEnquiriesResult' } & {
        enquiries?: Maybe<
          Array<
            { __typename?: 'UserEnquiry' } & {
              listing: { __typename?: 'EnquiryClubListingSingleResult' } & Pick<
                EnquiryClubListingSingleResult,
                | 'distance'
                | 'distanceUnit'
                | 'messageSentOn'
                | 'enquiredOn'
                | 'onWatchlist'
                | 'hasBeenReported'
              > & {
                  listing:
                    | ({ __typename: 'ClubListingUnknown' } & Pick<
                        ClubListingUnknown,
                        'id'
                      > & {
                          clubInfo: { __typename?: 'UserProfileClub' } & Pick<
                            UserProfileClub,
                            'id' | 'name' | 'hiring'
                          > & {
                              address: {
                                __typename?: 'Address'
                              } & AddressFieldsFragment
                              profileImage?: Maybe<
                                { __typename?: 'Image' } & Pick<Image, 'url'>
                              >
                              programs?: Maybe<
                                Array<
                                  | ({ __typename?: 'ClubProgram' } & Pick<
                                      ClubProgram,
                                      'name'
                                    >)
                                  | {
                                      __typename?: 'ClubProgramInStudioEmptyState'
                                    }
                                >
                              >
                            }
                        })
                    | ({ __typename: 'ClubMultiListing' } & Pick<
                        ClubMultiListing,
                        | 'createdAt'
                        | 'description'
                        | 'expiringAt'
                        | 'id'
                        | 'searchAppearances'
                        | 'status'
                        | 'updatedAt'
                        | 'views'
                        | 'enquiriesCount'
                        | 'programsId'
                      > & {
                          clubInfo: { __typename?: 'UserProfileClub' } & Pick<
                            UserProfileClub,
                            'id' | 'name' | 'hiring' | 'programIds'
                          > & {
                              address: {
                                __typename?: 'Address'
                              } & AddressFieldsFragment
                              profileImage?: Maybe<
                                { __typename?: 'Image' } & Pick<Image, 'url'>
                              >
                              programs?: Maybe<
                                Array<
                                  | ({ __typename?: 'ClubProgram' } & Pick<
                                      ClubProgram,
                                      'name'
                                    >)
                                  | {
                                      __typename?: 'ClubProgramInStudioEmptyState'
                                    }
                                >
                              >
                            }
                          programs: Array<
                            { __typename?: 'ClubProgram' } & Pick<
                              ClubProgram,
                              'code' | 'id' | 'initials' | 'name'
                            >
                          >
                        })
                    | ({ __typename: 'ClubSingleListing' } & Pick<
                        ClubSingleListing,
                        | 'createdAt'
                        | 'description'
                        | 'expiringAt'
                        | 'id'
                        | 'searchAppearances'
                        | 'updatedAt'
                        | 'views'
                        | 'isSubstituteListing'
                        | 'status'
                        | 'enquiriesCount'
                        | 'programId'
                      > & {
                          clubInfo: { __typename?: 'UserProfileClub' } & Pick<
                            UserProfileClub,
                            'id' | 'name' | 'hiring' | 'programIds'
                          > & {
                              address: {
                                __typename?: 'Address'
                              } & AddressFieldsFragment
                              profileImage?: Maybe<
                                { __typename?: 'Image' } & Pick<Image, 'url'>
                              >
                              programs?: Maybe<
                                Array<
                                  | ({ __typename?: 'ClubProgram' } & Pick<
                                      ClubProgram,
                                      'name'
                                    >)
                                  | {
                                      __typename?: 'ClubProgramInStudioEmptyState'
                                    }
                                >
                              >
                            }
                          times: Array<
                            {
                              __typename?: 'TimePreference'
                            } & TimePreferencesFragment
                          >
                          program: { __typename?: 'ClubProgram' } & Pick<
                            ClubProgram,
                            'code' | 'id' | 'initials' | 'name'
                          >
                        })
                }
              message: { __typename?: 'Message' } & Pick<
                Message,
                | 'clubId'
                | 'clubName'
                | 'id'
                | 'listingId'
                | 'message'
                | 'profileId'
                | 'readAt'
                | 'requestedBy'
                | 'sentAt'
              >
            }
          >
        >
      })
  >
}

export type GetInstructorFacilitiesQueryVariables = Exact<{
  input: GetInstructorClubAffiliatesInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetInstructorFacilitiesQuery = { __typename?: 'Query' } & {
  getInstructorClubAffiliates:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'GetInstructorClubAffiliatesResult' } & {
        affiliations?: Maybe<
          Array<
            { __typename?: 'ClubAffiliates' } & Pick<
              ClubAffiliates,
              | 'actionedDate'
              | 'clubId'
              | 'distance'
              | 'distanceUnit'
              | 'id'
              | 'requestedDate'
              | 'requestedBy'
              | 'status'
              | 'pendingStatus'
              | 'isDigitalOnly'
            > & {
                profile: {
                  __typename?: 'UserProfileClub'
                } & ClubProfileFieldsFragment
              }
          >
        >
      })
}

export type GetInstructorPortfolioQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetInstructorPortfolioQuery = { __typename?: 'Query' } & {
  getCurrentUser?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'UserResult' } & {
        profiles: Array<
          | ({ __typename?: 'UserProfileClub' } & {
              media?: Maybe<
                Array<{ __typename?: 'Image' } & Pick<Image, 'id' | 'url'>>
              >
            })
          | ({ __typename?: 'UserProfileTeaching' } & {
              media?: Maybe<
                Array<{ __typename?: 'Image' } & Pick<Image, 'id' | 'url'>>
              >
            })
        >
      })
  >
}

export type GetInstructorWatchlistQueryVariables = Exact<{
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetInstructorWatchlistQuery = { __typename?: 'Query' } & {
  getInstructorWatchlist?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'SearchClubListingsResult' } & Pick<
        SearchClubListingsResult,
        'total'
      > & {
          results?: Maybe<
            Array<
              | ({ __typename?: 'SearchClubJobListingsSingleResult' } & Pick<
                  SearchClubJobListingsSingleResult,
                  | 'distance'
                  | 'distanceUnit'
                  | 'messageSentOn'
                  | 'enquiredOn'
                  | 'onWatchlist'
                  | 'hasBeenReported'
                > & {
                    listing:
                      | ({ __typename?: 'ClubMultiListing' } & Pick<
                          ClubMultiListing,
                          'programsId'
                        > &
                          MultiListingFieldsFragment)
                      | ({ __typename?: 'ClubSingleListing' } & Pick<
                          ClubSingleListing,
                          'programId'
                        > &
                          SingleListingFieldsFragment)
                  })
              | ({ __typename?: 'SearchClubProfileSingleResult' } & Pick<
                  SearchClubProfileSingleResult,
                  | 'distance'
                  | 'distanceUnit'
                  | 'onWatchlist'
                  | 'hasBeenReported'
                > & {
                    profile: {
                      __typename?: 'UserProfileClub'
                    } & ClubProfileFieldsFragment
                  })
            >
          >
        })
  >
}

export type GetUserLevelQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetUserLevelQuery = { __typename?: 'Query' } & {
  getCurrentUser?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'UserResult' } & {
        user: { __typename?: 'User' } & Pick<
          User,
          'isInstructorLead' | 'recordType'
        >
      })
  >
}

export type LocationsFragment = {
  __typename?: 'ClubAvailabilityOnListing'
} & Pick<
  ClubAvailabilityOnListing,
  'clubId' | 'clubName' | 'isAlreadyListed' | 'isClubLicensed'
> & {
    clubProfileImage?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment>
    clubAddress?: Maybe<{ __typename?: 'Address' } & AddressFieldsFragment>
  }

export type GetJobListingAvailableLocationsQueryVariables = Exact<{
  input: GetClubsAvailabilityOnListingInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetJobListingAvailableLocationsQuery = { __typename?: 'Query' } & {
  getClubsAvailabilityOnListing?: Maybe<
    | ({ __typename?: 'ErrorResult' } & Pick<ErrorResult, 'type' | 'message'>)
    | ({ __typename?: 'GetClubsAvailabilityOnListingResult' } & {
        clubsWithExistingListing?: Maybe<
          Array<
            { __typename?: 'ClubAvailabilityOnListing' } & LocationsFragment
          >
        >
        allClubs?: Maybe<
          Array<
            { __typename?: 'ClubAvailabilityOnListing' } & LocationsFragment
          >
        >
      })
  >
}

export type GetNavigationQueryVariables = Exact<{
  input: GetNavigationInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetNavigationQuery = { __typename?: 'Query' } & {
  getNavigation?: Maybe<
    | ({ __typename: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'Navigation' } & {
        appMenu: { __typename?: 'Menu' } & Pick<Menu, 'id' | 'name'> & {
            items: Array<
              { __typename?: 'RootMenuItem' } & Pick<
                RootMenuItem,
                'icon' | 'id' | 'label' | 'analyticsEvent'
              > & {
                  url?: Maybe<
                    { __typename?: 'URL' } & Pick<Url, 'type' | 'value'>
                  >
                  items?: Maybe<
                    Array<
                      { __typename?: 'MenuItem' } & Pick<
                        MenuItem,
                        'id' | 'label' | 'analyticsEvent'
                      > & {
                          url: { __typename?: 'URL' } & Pick<
                            Url,
                            'type' | 'value'
                          >
                        }
                    >
                  >
                }
            >
          }
        userMenu: { __typename?: 'RootMenuItem' } & Pick<
          RootMenuItem,
          'icon' | 'id' | 'label' | 'analyticsEvent'
        > & {
            url?: Maybe<{ __typename?: 'URL' } & Pick<Url, 'type' | 'value'>>
            items?: Maybe<
              Array<
                { __typename?: 'MenuItem' } & Pick<
                  MenuItem,
                  'id' | 'label' | 'analyticsEvent'
                > & {
                    url: { __typename?: 'URL' } & Pick<Url, 'type' | 'value'>
                  }
              >
            >
          }
      })
  >
}

export type NotificationItemFragment = { __typename?: 'Notification' } & Pick<
  Notification,
  | 'id'
  | 'type'
  | 'body'
  | 'title'
  | 'createdAt'
  | 'createdAtLabel'
  | 'readAt'
  | 'isNew'
  | 'contentType'
> & {
    params?: Maybe<
      { __typename?: 'NotificationParams' } & Pick<
        NotificationParams,
        'userName'
      >
    >
    link?: Maybe<
      { __typename?: 'Link' } & Pick<Link, 'id' | 'text'> & {
          url: { __typename?: 'URL' } & Pick<Url, 'type' | 'value'>
        }
    >
  }

export type NotificationsResultFragment = {
  __typename?: 'GetNotificationsResult'
} & Pick<GetNotificationsResult, 'totalCount'> & {
    filter: { __typename?: 'DateTimeFilter' } & Pick<
      DateTimeFilter,
      'type' | 'value' | 'limit'
    >
    items?: Maybe<
      Array<{ __typename?: 'Notification' } & NotificationItemFragment>
    >
  }

export type GetNotificationsQueryVariables = Exact<{
  filter: DateTimeFilterInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetNotificationsQuery = { __typename?: 'Query' } & {
  getNotifications?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetNotificationsResult' } & NotificationsResultFragment)
  >
}

export type GetProfileQueryVariables = Exact<{
  input: GetProfileByIdInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetProfileQuery = { __typename?: 'Query' } & {
  getProfileById?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetProfileByIdResult' } & Pick<
        GetProfileByIdResult,
        | 'hasBeenReported'
        | 'lastClubMessageAt'
        | 'lastClubMessageIsIn48Hours'
        | 'lastMessageIn48HoursSentAt'
        | 'hasRecentlyDeclinedAffiliation'
        | 'hasExistingAffiliation'
        | 'onWatchlist'
      > & {
          profile?: Maybe<
            | ({ __typename?: 'UserProfileClub' } & Pick<
                UserProfileClub,
                'programIds'
              > &
                ClubProfileFieldsFragment)
            | ({
                __typename?: 'UserProfileTeaching'
              } & InstructorProfileFieldsFragment)
          >
          instructorAffiliation?: Maybe<
            { __typename?: 'Affiliation' } & Pick<
              Affiliation,
              | 'id'
              | 'clubId'
              | 'pendingStatus'
              | 'actionedDate'
              | 'requestedDate'
              | 'requestedBy'
              | 'role'
              | 'userId'
              | 'status'
            >
          >
          profileUser?: Maybe<
            { __typename?: 'User' } & Pick<
              User,
              'id' | 'email' | 'lastName' | 'firstName' | 'accountId' | 'phone'
            > & {
                market: { __typename?: 'Market' } & Pick<
                  Market,
                  'name' | 'locale' | 'distanceUnit'
                > & {
                    programs?: Maybe<
                      Array<
                        { __typename?: 'Program' } & Pick<
                          Program,
                          'id' | 'name' | 'code' | 'initials'
                        >
                      >
                    >
                  }
              }
          >
        })
  >
}

export type GetProfileMediaQueryVariables = Exact<{
  input: GetProfileByIdInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetProfileMediaQuery = { __typename?: 'Query' } & {
  getProfileById?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetProfileByIdResult' } & {
        profile?: Maybe<
          | ({ __typename?: 'UserProfileClub' } & {
              media?: Maybe<
                Array<{ __typename?: 'Image' } & ImageFieldsFragment>
              >
            })
          | ({ __typename?: 'UserProfileTeaching' } & {
              media?: Maybe<
                Array<{ __typename?: 'Image' } & ImageFieldsFragment>
              >
            })
        >
      })
  >
}

export type GetProfilesQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetProfilesQuery = { __typename?: 'Query' } & {
  getCurrentUser?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'UserResult' } & Pick<UserResult, 'id'> & {
          profiles: Array<
            | ({ __typename?: 'UserProfileClub' } & ClubProfileFieldsFragment)
            | ({
                __typename?: 'UserProfileTeaching'
              } & InstructorProfileFieldsFragment)
          >
        })
  >
}

export type GetSavedInstructorSearchDataQueryVariables = Exact<{
  input: GetSavedInstructorSearchDataInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetSavedInstructorSearchDataQuery = { __typename?: 'Query' } & {
  getSavedInstructorSearchData?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetSavedInstructorSearchDataResult' } & {
        results: Array<
          {
            __typename?: 'SavedInstructorSearch'
          } & SavedInstructorSearchFragment
        >
      })
  >
}

export type GetSharedNavigationQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetSharedNavigationQuery = { __typename?: 'Query' } & {
  getSharedNavigation?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'SharedNavigation' } & {
        instructorNav?: Maybe<
          { __typename?: 'SharedNavItem' } & Pick<SharedNavItem, 'role'> & {
              navItems?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'NavItem' } & Pick<
                      NavItem,
                      'key' | 'title' | 'description'
                    > & {
                        url: { __typename?: 'URL' } & Pick<
                          Url,
                          'type' | 'value'
                        >
                        image?: Maybe<
                          { __typename?: 'Image' } & Pick<
                            Image,
                            'id' | 'url' | 'alt' | 'height' | 'width'
                          >
                        >
                      }
                  >
                >
              >
            }
        >
        clubNav?: Maybe<
          { __typename?: 'SharedNavItem' } & Pick<SharedNavItem, 'role'> & {
              navItems?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'NavItem' } & Pick<
                      NavItem,
                      'key' | 'title' | 'description'
                    > & {
                        url: { __typename?: 'URL' } & Pick<
                          Url,
                          'type' | 'value'
                        >
                        image?: Maybe<
                          { __typename?: 'Image' } & Pick<
                            Image,
                            'id' | 'url' | 'alt' | 'height' | 'width'
                          >
                        >
                      }
                  >
                >
              >
            }
        >
        websiteLink: { __typename?: 'URL' } & Pick<Url, 'type' | 'value'>
      })
  >
}

export type GetClubInstructorShortlistQueryVariables = Exact<{
  input: GetProfileByIdInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetClubInstructorShortlistQuery = { __typename?: 'Query' } & {
  getProfileById?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetProfileByIdResult' } & {
        profile?: Maybe<
          | ({ __typename?: 'UserProfileClub' } & {
              shortlist?: Maybe<
                Array<
                  { __typename?: 'InstructorShortlist' } & Pick<
                    InstructorShortlist,
                    | 'id'
                    | 'lastMessageIn48HoursSentAt'
                    | 'distanceUnit'
                    | 'distance'
                    | 'hasExistingAffiliation'
                    | 'hasRecentlyDeclinedAffiliation'
                  > & {
                      profile: {
                        __typename?: 'UserProfileTeaching'
                      } & InstructorProfileFieldsFragment
                      user: { __typename?: 'User' } & Pick<
                        User,
                        'id' | 'lastName' | 'firstName'
                      >
                      instructorAffiliation?: Maybe<
                        { __typename?: 'Affiliation' } & Pick<
                          Affiliation,
                          | 'id'
                          | 'clubId'
                          | 'pendingStatus'
                          | 'actionedDate'
                          | 'requestedDate'
                          | 'requestedBy'
                          | 'role'
                          | 'userId'
                          | 'status'
                        >
                      >
                    }
                >
              >
            })
          | { __typename?: 'UserProfileTeaching' }
        >
      })
  >
}

export type GetUserQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetUserQuery = { __typename?: 'Query' } & {
  getCurrentUser?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'UserResult' } & Pick<
        UserResult,
        'id' | 'distance' | 'distanceUnit'
      > & {
          user: { __typename?: 'User' } & Pick<
            User,
            'id' | 'email' | 'lastName' | 'firstName' | 'accountId' | 'phone'
          > & {
              market: { __typename?: 'Market' } & Pick<
                Market,
                'name' | 'locale' | 'distanceUnit'
              > & {
                  programs?: Maybe<
                    Array<
                      { __typename?: 'Program' } & Pick<
                        Program,
                        'id' | 'name' | 'code' | 'initials'
                      >
                    >
                  >
                }
              signedAgreements?: Maybe<
                Array<
                  { __typename?: 'SignedAgreement' } & Pick<
                    SignedAgreement,
                    'type' | 'signedOn' | 'version'
                  >
                >
              >
            }
          settings: { __typename?: 'UserSettings' } & Pick<
            UserSettings,
            'locale'
          >
          profiles: Array<
            | ({ __typename: 'UserProfileClub' } & Pick<UserProfileClub, 'id'>)
            | ({ __typename: 'UserProfileTeaching' } & Pick<
                UserProfileTeaching,
                'id'
              >)
          >
        })
  >
}

export type GetVideoGuidelinesQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetVideoGuidelinesQuery = { __typename?: 'Query' } & {
  getVideoGuidelines:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'GetVideoGuidelinesResult' } & Pick<
        GetVideoGuidelinesResult,
        'contactUsEmail'
      > & {
          result: Array<
            { __typename?: 'SectionContentResult' } & Pick<
              SectionContentResult,
              'section'
            > & {
                content: Array<
                  { __typename?: 'ContentField' } & Pick<
                    ContentField,
                    'title' | 'subHeading' | 'contentText'
                  >
                >
              }
          >
        })
}

export type GetWalkthroughContentQueryVariables = Exact<{
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetWalkthroughContentQuery = { __typename?: 'Query' } & {
  getWalkthrough?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'Walkthrough' } & {
        pages: Array<
          { __typename?: 'WalkthroughPage' } & Pick<
            WalkthroughPage,
            'title' | 'body'
          > & {
              image: { __typename?: 'ImageSet' } & {
                desktop: { __typename?: 'Image' } & ImageFieldsFragment
                mobile: { __typename?: 'Image' } & ImageFieldsFragment
              }
            }
        >
      })
  >
}

export type IncrementViewClubListingCountMutationVariables = Exact<{
  input: IncrementViewClubListingCountInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type IncrementViewClubListingCountMutation = {
  __typename?: 'Mutation'
} & {
  incrementViewClubListingCount?: Maybe<
    | { __typename?: 'ClubListingResult' }
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type InitApplicationQueryVariables = Exact<{
  featureFlags: Array<Scalars['String']> | Scalars['String']
  _cacheKey?: Maybe<Scalars['String']>
}>

export type InitApplicationQuery = { __typename?: 'Query' } & {
  getUser?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'UserResult' } & FullUserFieldFragment)
  >
  getFeatureFlag?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'FeatureFlagResult' } & {
        flags?: Maybe<
          Array<
            { __typename?: 'FeatureFlag' } & Pick<
              FeatureFlag,
              'featureFlag' | 'enabled'
            >
          >
        >
      })
  >
}

export type ManagePeopleCreateAffiliationsMutationVariables = Exact<{
  input: CreateAffiliationsInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type ManagePeopleCreateAffiliationsMutation = {
  __typename?: 'Mutation'
} & {
  createAffiliations:
    | ({ __typename?: 'ChainAffiliationsResult' } & {
        successfulAffiliations?: Maybe<
          Array<
            { __typename?: 'ChainAffiliation' } & Pick<
              ChainAffiliation,
              | 'actionedDate'
              | 'clubId'
              | 'id'
              | 'latestActionInitiator'
              | 'pendingStatus'
              | 'requestedBy'
              | 'requestedDate'
              | 'role'
              | 'status'
              | 'userId'
            >
          >
        >
      })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type ManagePeopleRemoveAffiliationsMutationVariables = Exact<{
  input: DeleteAffiliationsInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type ManagePeopleRemoveAffiliationsMutation = {
  __typename?: 'Mutation'
} & {
  removeAffiliations:
    | ({ __typename?: 'ChainAffiliationsRemoveRresult' } & Pick<
        ChainAffiliationsRemoveRresult,
        'receivedAffiliationIds' | 'removedAffiliationIds' | 'removedUserId'
      >)
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type MarkNotificationsAsReadMutationVariables = Exact<{
  id: Array<Scalars['ID']> | Scalars['ID']
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type MarkNotificationsAsReadMutation = { __typename?: 'Mutation' } & {
  toggleNotificationsRead?: Maybe<
    | ({ __typename: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'ToggleNotificationReadResult' } & Pick<
        ToggleNotificationReadResult,
        'success' | 'failure'
      >)
  >
}

export type MarkNotificationsAsUnreadMutationVariables = Exact<{
  id: Array<Scalars['ID']> | Scalars['ID']
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type MarkNotificationsAsUnreadMutation = { __typename?: 'Mutation' } & {
  toggleNotificationsRead?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename: 'ToggleNotificationReadResult' } & Pick<
        ToggleNotificationReadResult,
        'success' | 'failure'
      >)
  >
}

export type AtHomeProductTileFragment = {
  __typename?: 'AtHomeProductTile'
} & Pick<AtHomeProductTile, 'title' | 'description'> & {
    onlineProgram: { __typename?: 'ClubOnlineProgram' } & Pick<
      ClubOnlineProgram,
      'id' | 'activeSince' | 'lastEnquiredOn'
    >
    buttons?: Maybe<
      Array<
        { __typename?: 'Link' } & Pick<Link, 'isPrimary' | 'id' | 'text'> & {
            url: { __typename?: 'URL' } & Pick<Url, 'type' | 'value'>
          }
      >
    >
    image: { __typename?: 'ImageSet' } & Pick<ImageSet, 'id'> & {
        desktop: { __typename?: 'Image' } & Pick<Image, 'id' | 'alt' | 'url'>
        mobile: { __typename?: 'Image' } & Pick<Image, 'id' | 'alt' | 'url'>
      }
    programs?: Maybe<
      Array<
        { __typename?: 'ClubProgram' } & Pick<
          ClubProgram,
          'id' | 'code' | 'name'
        >
      >
    >
  }

export type GetAtHomeItemsQueryVariables = Exact<{
  input: GetClubByIdInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetAtHomeItemsQuery = { __typename?: 'Query' } & {
  getClubById?: Maybe<
    | ({ __typename?: 'ErrorResult' } & Pick<ErrorResult, 'type' | 'message'>)
    | ({ __typename?: 'UserProfileClub' } & {
        atHomeProducts?: Maybe<
          { __typename?: 'GetAtHomeProductTilesResult' } & {
            active?: Maybe<
              Array<
                { __typename?: 'AtHomeProductTile' } & AtHomeProductTileFragment
              >
            >
            inactive?: Maybe<
              Array<
                { __typename?: 'AtHomeProductTile' } & AtHomeProductTileFragment
              >
            >
          }
        >
      })
  >
}

export type ExploreProgramTileFragment = {
  __typename?: 'InStudioExploreProgramsTile'
} & Pick<
  InStudioExploreProgramsTile,
  | 'productId'
  | 'name'
  | 'genre'
  | 'productCategories'
  | 'numberOfCertifiedInstructors'
  | 'enquirySent'
> & {
    image?: Maybe<{ __typename?: 'Image' } & Pick<Image, 'id' | 'alt' | 'url'>>
  }

export type InStudioDetailsViewFragment = {
  __typename?: 'InStudioDetailsView'
} & Pick<InStudioDetailsView, 'description' | 'videoContentId'> & {
    typeInfo?: Maybe<
      Array<
        Maybe<
          { __typename?: 'ProgramTypeInfo' } & Pick<
            ProgramTypeInfo,
            'description' | 'title'
          >
        >
      >
    >
    moreURL?: Maybe<{ __typename?: 'URL' } & Pick<Url, 'type' | 'value'>>
  }

export type DetailedExploreProgramTileFragment = {
  __typename?: 'InStudioExploreProgramsTile'
} & Pick<
  InStudioExploreProgramsTile,
  | 'productId'
  | 'name'
  | 'genre'
  | 'productCategories'
  | 'numberOfCertifiedInstructors'
  | 'enquirySent'
> & {
    image?: Maybe<{ __typename?: 'Image' } & Pick<Image, 'id' | 'alt' | 'url'>>
    detailView?: Maybe<
      { __typename?: 'InStudioDetailsView' } & InStudioDetailsViewFragment
    >
  }

export type GetExploreProgramsPageDetailedProgramQueryVariables = Exact<{
  role: UserRoleInput
  programName?: Maybe<ProgramName>
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetExploreProgramsPageDetailedProgramQuery = {
  __typename?: 'Query'
} & {
  getExploreProgramsPage?: Maybe<
    | ({ __typename?: 'ErrorResult' } & Pick<ErrorResult, 'type' | 'message'>)
    | ({ __typename?: 'ExploreProgramsPage' } & {
        products?: Maybe<
          Array<
            Maybe<
              {
                __typename?: 'InStudioExploreProgramsTile'
              } & DetailedExploreProgramTileFragment
            >
          >
        >
      })
  >
}

export type RemoveFromShortlistMutationVariables = Exact<{
  input: RemoveFromShortlistInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type RemoveFromShortlistMutation = { __typename?: 'Mutation' } & {
  removeFromShortlist?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'RemoveFromShortlistResult' } & {
        shortlist?: Maybe<
          Array<
            { __typename?: 'InstructorShortlist' } & Pick<
              InstructorShortlist,
              'id' | 'lastMessageIn48HoursSentAt' | 'distanceUnit' | 'distance'
            > & {
                profile: {
                  __typename?: 'UserProfileTeaching'
                } & InstructorProfileFieldsFragment
                user: { __typename?: 'User' } & Pick<
                  User,
                  'id' | 'lastName' | 'firstName'
                >
              }
          >
        >
      })
  >
}

export type ReportClubListingMutationVariables = Exact<{
  input: ReportClubListingInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type ReportClubListingMutation = { __typename?: 'Mutation' } & {
  reportClubListing?: Maybe<
    | { __typename: 'ClubMultiListing' }
    | { __typename: 'ClubSingleListing' }
    | ({ __typename: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type ReportClubProfileMutationVariables = Exact<{
  input: ReportClubProfileInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type ReportClubProfileMutation = { __typename?: 'Mutation' } & {
  reportClubProfile?: Maybe<
    | { __typename: 'ClubProfile' }
    | ({ __typename: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type RevertAppMetadataMutationVariables = Exact<{
  input: RevertAppMetadataInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type RevertAppMetadataMutation = { __typename?: 'Mutation' } & {
  revertAppMetadata:
    | ({ __typename?: 'AppMetadataResult' } & Pick<
        AppMetadataResult,
        'isSuccess'
      >)
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type SearchClubListingsQueryVariables = Exact<{
  filter: SearchClubListingsFilter
  options?: Maybe<SearchClubListingsOptions>
  _cacheKey?: Maybe<Scalars['String']>
}>

export type SearchClubListingsQuery = { __typename?: 'Query' } & {
  searchClubListings?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'SearchClubListingsResult' } & Pick<
        SearchClubListingsResult,
        'total'
      > & {
          results?: Maybe<
            Array<
              | ({ __typename?: 'SearchClubJobListingsSingleResult' } & Pick<
                  SearchClubJobListingsSingleResult,
                  | 'distance'
                  | 'distanceUnit'
                  | 'messageSentOn'
                  | 'enquiredOn'
                  | 'onWatchlist'
                  | 'hasBeenReported'
                > & {
                    listing:
                      | ({ __typename?: 'ClubMultiListing' } & Pick<
                          ClubMultiListing,
                          'programsId'
                        > &
                          MultiListingFieldsFragment)
                      | ({ __typename?: 'ClubSingleListing' } & Pick<
                          ClubSingleListing,
                          'programId'
                        > &
                          SingleListingFieldsFragment)
                  })
              | ({ __typename?: 'SearchClubProfileSingleResult' } & Pick<
                  SearchClubProfileSingleResult,
                  | 'distance'
                  | 'distanceUnit'
                  | 'onWatchlist'
                  | 'hasBeenReported'
                > & {
                    profile: {
                      __typename?: 'UserProfileClub'
                    } & ClubProfileFieldsFragment
                  })
            >
          >
        })
  >
}

export type SearchClubsQueryVariables = Exact<{
  filter: SearchClubsFilter
  options?: Maybe<SearchClubsOptions>
  _cacheKey?: Maybe<Scalars['String']>
}>

export type SearchClubsQuery = { __typename?: 'Query' } & {
  searchClubs?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'SearchClubsResult' } & Pick<
        SearchClubsResult,
        'total'
      > & {
          results: Array<
            { __typename?: 'SearchClubsSingleResult' } & Pick<
              SearchClubsSingleResult,
              'clubId' | 'distance' | 'distanceUnit'
            > & {
                profile: {
                  __typename?: 'UserProfileClub'
                } & ClubProfileFieldsFragment
                instructorAffiliation?: Maybe<
                  { __typename?: 'Affiliation' } & Pick<
                    Affiliation,
                    | 'id'
                    | 'userId'
                    | 'clubId'
                    | 'requestedBy'
                    | 'actionedDate'
                    | 'status'
                    | 'pendingStatus'
                    | 'requestedDate'
                    | 'latestActionInitiator'
                  >
                >
              }
          >
        })
  >
}

export type SearchInstructorsSingleResultFragment = {
  __typename?: 'SearchInstructorsSingleResult'
} & Pick<
  SearchInstructorsSingleResult,
  | 'distance'
  | 'distanceUnit'
  | 'messageSentOn'
  | 'inTravelZone'
  | 'hasExistingAffiliation'
  | 'hasRecentlyDeclinedAffiliation'
  | 'lastMessageSentAt'
  | 'lastMessageIn48HoursSentAt'
> & {
    instructorAffiliation?: Maybe<
      { __typename?: 'Affiliation' } & Pick<
        Affiliation,
        | 'id'
        | 'clubId'
        | 'pendingStatus'
        | 'actionedDate'
        | 'requestedDate'
        | 'requestedBy'
        | 'role'
        | 'userId'
        | 'status'
      >
    >
    profile: {
      __typename?: 'UserProfileTeaching'
    } & InstructorProfileFieldsFragment
    user: { __typename?: 'User' } & Pick<
      User,
      'id' | 'email' | 'lastName' | 'firstName' | 'accountId'
    > & {
        market: { __typename?: 'Market' } & Pick<
          Market,
          'name' | 'locale' | 'distanceUnit'
        >
      }
  }

export type SearchInstructorsResultFragment = {
  __typename?: 'SearchInstructorsResult'
} & Pick<SearchInstructorsResult, 'isMatchingFilter' | 'total'> & {
    results?: Maybe<
      Array<
        {
          __typename?: 'SearchInstructorsSingleResult'
        } & SearchInstructorsSingleResultFragment
      >
    >
  }

export type SearchInstructorsQueryVariables = Exact<{
  filter: SearchInstructorsFilter
  options?: Maybe<SearchInstructorsOptions>
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type SearchInstructorsQuery = { __typename?: 'Query' } & {
  searchInstructors?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({
        __typename?: 'SearchInstructorsResult'
      } & SearchInstructorsResultFragment)
  >
}

export type SendMessageMutationVariables = Exact<{
  input: SendMessageInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type SendMessageMutation = { __typename?: 'Mutation' } & {
  sendMessage?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'SendMessageResult' } & Pick<SendMessageResult, 'sentAt'>)
  >
}

export type SetClubSettingsMutationVariables = Exact<{
  input: SetClubSettingsInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type SetClubSettingsMutation = { __typename?: 'Mutation' } & {
  setClubSettings?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'SetClubSettingsResult' } & Pick<
        SetClubSettingsResult,
        'id' | 'name' | 'phone'
      > & {
          address?: Maybe<{ __typename?: 'Address' } & AddressFieldsFragment>
          email?: Maybe<
            { __typename?: 'EmailClubType' } & Pick<
              EmailClubType,
              'main' | 'affiliations' | 'jobListings'
            >
          >
        })
  >
}

export type SetInstructorAvailabilityMutationVariables = Exact<{
  input: SetInstructorAvailabilityInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type SetInstructorAvailabilityMutation = { __typename?: 'Mutation' } & {
  setInstructorAvailability?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'InstructorAvailability' } & Pick<
        InstructorAvailability,
        'value' | 'availableToSubstitute'
      >)
  >
}

export type SetInstructorWatchlistMutationVariables = Exact<{
  input: SetInstructorWatchlistInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type SetInstructorWatchlistMutation = { __typename?: 'Mutation' } & {
  setInstructorWatchlist?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'InstructorWatchlist' } & {
        watchlist?: Maybe<
          Array<
            { __typename?: 'InstructorWatchlistItem' } & Pick<
              InstructorWatchlistItem,
              'clubId' | 'listingId'
            >
          >
        >
      })
  >
}

export type SetSavedInstructorSearchDataMutationVariables = Exact<{
  input: SetSavedInstructorSearchDataInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type SetSavedInstructorSearchDataMutation = {
  __typename?: 'Mutation'
} & {
  setSavedInstructorSearchData?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'SetSavedInstructorSearchDataResult' } & {
        results: Array<
          {
            __typename?: 'SavedInstructorSearch'
          } & SavedInstructorSearchFragment
        >
      })
  >
}

export type SetSignedAgreementDataMutationVariables = Exact<{
  input: SetSignedAgreementDataInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type SetSignedAgreementDataMutation = { __typename?: 'Mutation' } & {
  setSignedAgreementData?: Maybe<
    | ({ __typename?: 'AgreementDocumentResult' } & Pick<
        AgreementDocumentResult,
        'signedOn' | 'version'
      > & { agreementType: AgreementDocumentResult['type'] })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type SetWalkthroughToSeenMutationVariables = Exact<{
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type SetWalkthroughToSeenMutation = { __typename?: 'Mutation' } & {
  setWalkthroughToSeen?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'SetWalkthroughToSeenResult' } & Pick<
        SetWalkthroughToSeenResult,
        'success'
      >)
  >
}

export type UpdateAffiliateBillingMutationVariables = Exact<{
  input: PayForCertificationInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type UpdateAffiliateBillingMutation = { __typename?: 'Mutation' } & {
  payForCertification?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'PayForCertificationResult' } & {
        results?: Maybe<
          Array<
            { __typename?: 'InstructorCertificate' } & Pick<
              InstructorCertificate,
              'instructorName'
            > & {
                certificate?: Maybe<
                  { __typename?: 'Certification' } & Pick<
                    Certification,
                    | 'active'
                    | 'billedClubId'
                    | 'id'
                    | 'lastPurchase'
                    | 'lmqLevel'
                    | 'transactionStatus'
                    | 'releaseStatus'
                    | 'status'
                  > & {
                      pricePerQuarter?: Maybe<
                        { __typename?: 'CertificationPrice' } & Pick<
                          CertificationPrice,
                          'amount' | 'currency'
                        >
                      >
                      program: { __typename?: 'Program' } & Pick<
                        Program,
                        'code' | 'id' | 'initials' | 'name'
                      >
                    }
                >
              }
          >
        >
      })
  >
}

export type UpdateAffiliationMutationVariables = Exact<{
  input: UpdateAffiliationInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type UpdateAffiliationMutation = { __typename?: 'Mutation' } & {
  updateAffiliation:
    | ({ __typename?: 'AffiliationAsyncResult' } & Pick<
        AffiliationAsyncResult,
        'status' | 'hasPendingAffiliations'
      >)
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
}

export type UpdateClubListingMutationVariables = Exact<{
  input: UpdateClubListingInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type UpdateClubListingMutation = { __typename?: 'Mutation' } & {
  updateClubListing?: Maybe<
    | ({ __typename?: 'ClubListingResult' } & {
        results?: Maybe<
          Array<
            | ({ __typename?: 'ClubMultiListing' } & MultiListingFieldsFragment)
            | ({
                __typename?: 'ClubSingleListing'
              } & SingleListingFieldsFragment)
          >
        >
      })
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
  >
}

export type UpdateClubProfileMutationVariables = Exact<{
  input: UpdateClubProfileInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type UpdateClubProfileMutation = { __typename?: 'Mutation' } & {
  updateClubProfile:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'UserResult' } & FullUserFieldFragment)
}

export type FinishPortfolioUpdateMutationVariables = Exact<{
  input: UpdateCurrentUserPortfolioUploadInput
  role: UserRoleInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type FinishPortfolioUpdateMutation = { __typename?: 'Mutation' } & {
  updateCurrentUserPortfolioUpload:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'UpdateCurrentUserPortfolioUploadResult' } & Pick<
        UpdateCurrentUserPortfolioUploadResult,
        'url'
      > & {
          profile:
            | ({ __typename?: 'UserProfileClub' } & ClubProfileFieldsFragment)
            | ({
                __typename?: 'UserProfileTeaching'
              } & InstructorProfileFieldsFragment)
        })
}

export type UpdateTeachingProfileMutationVariables = Exact<{
  input: UpdateTeachingProfileInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type UpdateTeachingProfileMutation = { __typename?: 'Mutation' } & {
  updateTeachingProfile:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'UserResult' } & FullUserFieldFragment)
}

export type UpdateUserAccountMutationVariables = Exact<{
  input: UpdateUserAccountInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type UpdateUserAccountMutation = { __typename?: 'Mutation' } & {
  updateUserAccount:
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'UserAccountSettingsResult' } & Pick<
        UserAccountSettingsResult,
        'dateOfBirth' | 'email' | 'firstName' | 'id' | 'lastName' | 'phone'
      > & {
          address?: Maybe<{ __typename?: 'Address' } & AddressFieldsFragment>
          outlinks?: Maybe<
            Array<{ __typename?: 'OutLink' } & Pick<OutLink, 'url' | 'title'>>
          >
        })
}

export type UpdateUserPhotoMutationVariables = Exact<{
  input: UpdateUserPhotoInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type UpdateUserPhotoMutation = { __typename?: 'Mutation' } & {
  updateUserPhoto?: Maybe<
    | ({ __typename?: 'ErrorResult' } & ErrorResultFieldsFragment)
    | ({ __typename?: 'UpdateUserPhotoResult' } & Pick<
        UpdateUserPhotoResult,
        'url'
      > & {
          profile:
            | ({ __typename?: 'UserProfileClub' } & ClubProfileFieldsFragment)
            | ({
                __typename?: 'UserProfileTeaching'
              } & InstructorProfileFieldsFragment)
        })
  >
}

export type FinalizeUploadMutationVariables = Exact<{
  input: FinalizeUploadInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type FinalizeUploadMutation = { __typename?: 'Mutation' } & {
  finalizeUpload?: Maybe<
    | ({ __typename?: 'ErrorResult' } & Pick<ErrorResult, 'message'>)
    | ({ __typename?: 'FinalizeUploadResult' } & Pick<
        FinalizeUploadResult,
        'isSuccess' | 'comment'
      > & {
          playback: { __typename?: 'Playback' } & Pick<
            Playback,
            'playbackUrl' | 'token'
          >
        })
  >
}

export type GetPresignedUrlQueryVariables = Exact<{
  input: GetPresignedUrlInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type GetPresignedUrlQuery = { __typename?: 'Query' } & {
  getPresignedUrl?: Maybe<
    | ({ __typename?: 'ErrorResult' } & Pick<ErrorResult, 'message'>)
    | ({ __typename?: 'GetPresignedUrlResult' } & {
        partSignedUrlList: Array<
          { __typename?: 'PartSignedUrl' } & Pick<
            PartSignedUrl,
            'partNumber' | 'url'
          >
        >
      })
  >
}

export type InitializeUploadMutationVariables = Exact<{
  input: InitializeUploadInput
  _cacheKey?: Maybe<Scalars['String']>
}>

export type InitializeUploadMutation = { __typename?: 'Mutation' } & {
  initializeUpload?: Maybe<
    | ({ __typename?: 'ErrorResult' } & Pick<ErrorResult, 'message'>)
    | ({ __typename?: 'InitializeUploadResult' } & Pick<
        InitializeUploadResult,
        'uploadId'
      >)
  >
}

export const InStudioClubProgramFragmentDoc = gql`
  fragment InStudioClubProgram on ClubProgram {
    id
    code
    name
    status
    initials
    programType
    instructorCount {
      numberOfInstructors
      belowRecommendedAmount
      lastEnquiredOn
    }
  }
`
export const UrlFieldsFragmentDoc = gql`
  fragment urlFields on URL {
    type
    value
  }
`
export const LinkFieldsFragmentDoc = gql`
  fragment linkFields on Link {
    id
    text
    url {
      ...urlFields
    }
  }
  ${UrlFieldsFragmentDoc}
`
export const InStudioEmptyStateFragmentDoc = gql`
  fragment InStudioEmptyState on ClubProgramInStudioEmptyState {
    programType
    content {
      subtitle
      title
      link {
        ...linkFields
      }
    }
  }
  ${LinkFieldsFragmentDoc}
`
export const ImageFieldsFragmentDoc = gql`
  fragment imageFields on Image {
    __typename
    id
    url
    alt
    height
    width
  }
`
export const InStudioToolkitTileFragmentDoc = gql`
  fragment InStudioToolkitTile on InStudioToolkitTile {
    image {
      ...imageFields
    }
    link {
      ...linkFields
    }
    subTitle
    title
  }
  ${ImageFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
`
export const EventSummaryFieldsFragmentDoc = gql`
  fragment eventSummaryFields on EventSummary {
    deliveryMethod
    endDate
    eventId
    eventName
    eventStatus
    programName
    startDate
    trainingJourneyCount
    venueAddress
  }
`
export const DevelopmentSheetFieldsFragmentDoc = gql`
  fragment developmentSheetFields on DevelopmentSheet {
    filename
    url
  }
`
export const EmergencyContactFieldsFragmentDoc = gql`
  fragment emergencyContactFields on EmergencyContact {
    address
    name
    phone
  }
`
export const InstructorInfoFieldsFragmentDoc = gql`
  fragment instructorInfoFields on InstructorInformation {
    address
    email
    name
    phone
  }
`
export const PlaybackFieldsFragmentDoc = gql`
  fragment playbackFields on Playback {
    playbackUrl
    token
  }
`
export const OutcomeTrackFieldsFragmentDoc = gql`
  fragment outcomeTrackFields on OutcomeTrack {
    comment
    submittedAt
    playback {
      ...playbackFields
    }
  }
  ${PlaybackFieldsFragmentDoc}
`
export const PresentationTrackFieldsFragmentDoc = gql`
  fragment presentationTrackFields on PresentationTrack {
    comment
    submittedAt
    playback {
      ...playbackFields
    }
  }
  ${PlaybackFieldsFragmentDoc}
`
export const EventAssessmentFieldsFragmentDoc = gql`
  fragment eventAssessmentFields on EventAssessments {
    attended
    developmentSheet {
      ...developmentSheetFields
    }
    emergencyContact {
      ...emergencyContactFields
    }
    instructorInfo {
      ...instructorInfoFields
    }
    outcome
    outcomeTrack {
      ...outcomeTrackFields
    }
    presentationTrack {
      ...presentationTrackFields
    }
    sessionRegistrationId
    trainingJourneyId
    trackAllocation
  }
  ${DevelopmentSheetFieldsFragmentDoc}
  ${EmergencyContactFieldsFragmentDoc}
  ${InstructorInfoFieldsFragmentDoc}
  ${OutcomeTrackFieldsFragmentDoc}
  ${PresentationTrackFieldsFragmentDoc}
`
export const StepFieldsFragmentDoc = gql`
  fragment stepFields on Step {
    activeStepName
    activeStepNumber
    attended
    cancellationPolicy
    passPendingGuide
    deliveryMethod
    details
    dueDate
    endDate
    eventName
    fulfillment
    level
    onlineTrainingLink
    programName
    requirements
    schedule
    startDate
    stepName
    stepNumber
    timezone
    trackAllocation
    trainerEmail
    trainerName
    venueAddress
    contactUsEmail
  }
`
export const TrainingBookedStepFieldsFragmentDoc = gql`
  fragment trainingBookedStepFields on TrainingBookedStep {
    activeStepName
    activeStepNumber
    attended
    cancellationPolicy
    passPendingGuide
    deliveryMethod
    details
    dueDate
    endDate
    eventName
    fulfillment
    level
    onlineTrainingLink
    programName
    requirements
    schedule
    startDate
    stepName
    stepNumber
    timezone
    trackAllocation
    trainerEmail
    trainerName
    parqForm
    venueAddress
    contactUsEmail
    emergencyContact {
      ...emergencyContactFields
    }
  }
  ${EmergencyContactFieldsFragmentDoc}
`
export const AssessmentVideoFieldsFragmentDoc = gql`
  fragment assessmentVideoFields on AssessmentVideo {
    assessmentId
    comment
    fileKey
    submitted
    submittedDate
    recordType
    videoNotWorking
    playback {
      ...playbackFields
    }
    extensionRequested
  }
  ${PlaybackFieldsFragmentDoc}
`
export const PreworkContentItemFieldsFragmentDoc = gql`
  fragment PreworkContentItemFields on PreworkContentItem {
    title
    type
    url {
      ...urlFields
    }
  }
  ${UrlFieldsFragmentDoc}
`
export const PreworkContentFieldsFragmentDoc = gql`
  fragment preworkContentFields on PreworkContent {
    contentList {
      ...PreworkContentItemFields
    }
  }
  ${PreworkContentItemFieldsFragmentDoc}
`
export const PreworkStepFieldsFragmentDoc = gql`
  fragment preworkStepFields on PreworkStep {
    activeStepName
    activeStepNumber
    assessmentVideo {
      ...assessmentVideoFields
    }
    attended
    cancellationPolicy
    passPendingGuide
    preworkContent {
      ...preworkContentFields
    }
    deliveryMethod
    details
    dueDate
    endDate
    eventName
    fulfillment
    level
    onlineTrainingLink
    programName
    releaseUrl {
      ...linkFields
    }
    requirements
    schedule
    startDate
    stepName
    stepNumber
    timezone
    trackAllocation
    trainerEmail
    trainerName
    venueAddress
    contactUsEmail
  }
  ${AssessmentVideoFieldsFragmentDoc}
  ${PreworkContentFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
`
export const AttendTrainingStepFieldsFragmentDoc = gql`
  fragment attendTrainingStepFields on AttendTrainingStep {
    activeStepName
    activeStepNumber
    attended
    cancellationPolicy
    cecCertificationUrl
    passPendingGuide
    deliveryMethod
    details
    dueDate
    endDate
    eventName
    fulfillment
    level
    onlineTrainingLink
    programName
    requirements
    schedule
    startDate
    stepName
    stepNumber
    timezone
    trackAllocation
    trainerEmail
    trainerName
    venueAddress
    contactUsEmail
  }
`
export const SubmitOutcomeTrackStepFieldsFragmentDoc = gql`
  fragment submitOutcomeTrackStepFields on SubmitOutcomeTrackStep {
    activeStepName
    activeStepNumber
    attended
    cancellationPolicy
    passPendingGuide
    deliveryMethod
    details
    dueDate
    endDate
    eventName
    fulfillment
    level
    onlineTrainingLink
    programName
    requirements
    schedule
    startDate
    stepName
    stepNumber
    timezone
    trackAllocation
    trainerEmail
    trainerName
    extendedDueDate
    venueAddress
    contactUsEmail
    assessmentVideo {
      ...assessmentVideoFields
    }
  }
  ${AssessmentVideoFieldsFragmentDoc}
`
export const TrainingOutcomeFieldsFragmentDoc = gql`
  fragment trainingOutcomeFields on TrainingOutcome {
    developmentSheet
    outcome
    submitDate
    trainingNotes
    assessmentVideo {
      ...assessmentVideoFields
    }
  }
  ${AssessmentVideoFieldsFragmentDoc}
`
export const ReceivedTrainingOutcomeStepFieldsFragmentDoc = gql`
  fragment receivedTrainingOutcomeStepFields on ReceivedTrainingOutcomeStep {
    activeStepName
    activeStepNumber
    attended
    cancellationPolicy
    passPendingGuide
    deliveryMethod
    details
    dueDate
    endDate
    eventName
    fulfillment
    level
    onlineTrainingLink
    programName
    requirements
    schedule
    startDate
    stepName
    stepNumber
    timezone
    trackAllocation
    trainerEmail
    trainerName
    cecCertificationUrl
    extendedDueDate
    venueAddress
    contactUsEmail
    assessmentOutcomes {
      ...trainingOutcomeFields
    }
  }
  ${TrainingOutcomeFieldsFragmentDoc}
`
export const SubmitCertificationVideoStepFieldsFragmentDoc = gql`
  fragment submitCertificationVideoStepFields on SubmitCertificationVideoStep {
    activeStepName
    activeStepNumber
    attended
    cancellationPolicy
    passPendingGuide
    deliveryMethod
    details
    dueDate
    endDate
    eventName
    fulfillment
    level
    onlineTrainingLink
    programName
    requirements
    schedule
    startDate
    stepName
    stepNumber
    timezone
    trackAllocation
    trainerEmail
    trainerName
    extendedDueDate
    venueAddress
    contactUsEmail
    assessmentVideo {
      ...assessmentVideoFields
    }
  }
  ${AssessmentVideoFieldsFragmentDoc}
`
export const ReceivedCertificationOutcomeStepFieldsFragmentDoc = gql`
  fragment receivedCertificationOutcomeStepFields on ReceivedCertificationOutcomeStep {
    activeStepName
    activeStepNumber
    attended
    cancellationPolicy
    passPendingGuide
    deliveryMethod
    details
    dueDate
    endDate
    eventName
    fulfillment
    level
    onlineTrainingLink
    programName
    requirements
    schedule
    startDate
    stepName
    stepNumber
    timezone
    trackAllocation
    trainerEmail
    trainerName
    resubmitDueDate
    certificationUrl
    venueAddress
    contactUsEmail
    certificationOutcomes {
      ...trainingOutcomeFields
    }
  }
  ${TrainingOutcomeFieldsFragmentDoc}
`
export const CertifiedStepFieldsFragmentDoc = gql`
  fragment certifiedStepFields on CertifiedStep {
    activeStepName
    activeStepNumber
    attended
    cancellationPolicy
    deliveryMethod
    details
    dueDate
    endDate
    eventName
    fulfillment
    level
    onlineTrainingLink
    passPendingGuide
    programName
    requirements
    schedule
    startDate
    stepName
    stepNumber
    timezone
    trackAllocation
    trainerEmail
    trainerName
    contactUsEmail
    venueAddress
  }
`
export const InstructorAvailabilityFieldsFragmentDoc = gql`
  fragment instructorAvailabilityFields on InstructorAvailability {
    description
    title
    value
  }
`
export const AddressFieldsFragmentDoc = gql`
  fragment addressFields on Address {
    city
    state
    street
    country
    stateCode
    postalCode
    streetName
    countryCode
    streetNumber
    location {
      lat
      lon
    }
  }
`
export const ProgramFieldsFragmentDoc = gql`
  fragment programFields on Program {
    code
    id
    initials
    name
  }
`
export const TimePreferencesFragmentDoc = gql`
  fragment timePreferences on TimePreference {
    morning
    afternoon
    evening
  }
`
export const TravelPreferencesFragmentDoc = gql`
  fragment travelPreferences on TravelPreference {
    address {
      ...addressFields
    }
    distance
    distanceUnit
  }
  ${AddressFieldsFragmentDoc}
`
export const PreferenceFieldsFragmentDoc = gql`
  fragment preferenceFields on InstructorPreferences {
    programs {
      ...programFields
    }
    times {
      ...timePreferences
    }
    travel {
      ...travelPreferences
    }
  }
  ${ProgramFieldsFragmentDoc}
  ${TimePreferencesFragmentDoc}
  ${TravelPreferencesFragmentDoc}
`
export const PrivacyFieldsFragmentDoc = gql`
  fragment privacyFields on PrivacySettingsInstructor {
    EMAIL
    PHONE_NUMBER
    PROFILE_PHOTO
    SOCIAL_LINKS
    LMQ_LEVEL
    HIDDEN
  }
`
export const InstructorProfileFieldsFragmentDoc = gql`
  fragment instructorProfileFields on UserProfileTeaching {
    id
    __typename
    id
    bio
    address {
      ...addressFields
    }
    shippingAddress {
      ...addressFields
    }
    roles
    strength
    interests
    createdAt
    availability
    createdAtLabel
    availableToSubstitute
    preferences {
      ...preferenceFields
    }
    privacySettings {
      ...privacyFields
    }
    media {
      ...imageFields
    }
    profileImage {
      ...imageFields
    }
    links {
      url
      type
    }
    certifications {
      id
      lastPurchase
      pricePerQuarter {
        amount
        currency
      }
      transactionStatus
      transactionTimestamp
      billedClubId
      releaseStatus
      status
      active
      lmqLevel
      link {
        ...linkFields
      }
      program {
        id
        name
        code
        initials
      }
    }
    watchlist {
      clubId
      listingId
    }
  }
  ${AddressFieldsFragmentDoc}
  ${PreferenceFieldsFragmentDoc}
  ${PrivacyFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
`
export const ClubProfileFieldsFragmentDoc = gql`
  fragment clubProfileFields on UserProfileClub {
    id
    __typename
    bio
    name
    email
    jobListingEmail
    phone
    hiring
    address {
      ...addressFields
    }
    strength
    createdAt
    createdAtLabel
    programs {
      ... on ClubProgram {
        __typename
        id
        name
        code
        initials
        programType
      }
    }
    media {
      ...imageFields
    }
    profileImage {
      ...imageFields
    }
    links {
      url
      type
    }
    isDigitalOnly
    openToNewPrograms
    programIds
  }
  ${AddressFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
`
export const FullUserFieldFragmentDoc = gql`
  fragment fullUserField on UserResult {
    __typename
    id
    distance
    distanceUnit
    user {
      id
      email
      market {
        isSupported
        name
        locale
        distanceUnit
        programs {
          id
          name
          code
          initials
        }
      }
      lastName
      firstName
      accountId
      phone
      signedAgreements {
        type
        signedOn
        version
      }
      userMetaData {
        walkthroughRequired {
          club
          instructor
        }
      }
    }
    profiles {
      ... on UserProfileTeaching {
        ...instructorProfileFields
      }
      ... on UserProfileClub {
        ...clubProfileFields
      }
    }
    settings {
      locale
    }
  }
  ${InstructorProfileFieldsFragmentDoc}
  ${ClubProfileFieldsFragmentDoc}
`
export const SingleListingFieldsFragmentDoc = gql`
  fragment singleListingFields on ClubSingleListing {
    clubInfo {
      id
      name
      address {
        ...addressFields
      }
      profileImage {
        url
      }
      programs {
        ... on ClubProgram {
          name
        }
      }
      hiring
    }
    createdAt
    description
    expiringAt
    id
    searchAppearances
    updatedAt
    views
    isSubstituteListing
    program {
      code
      id
      initials
      name
    }
    status
    times {
      ...timePreferences
    }
    enquiriesCount
  }
  ${AddressFieldsFragmentDoc}
  ${TimePreferencesFragmentDoc}
`
export const MultiListingFieldsFragmentDoc = gql`
  fragment multiListingFields on ClubMultiListing {
    clubInfo {
      id
      name
      address {
        ...addressFields
      }
      profileImage {
        url
      }
      programs {
        ... on ClubProgram {
          name
        }
      }
      hiring
    }
    createdAt
    description
    expiringAt
    id
    searchAppearances
    programs {
      code
      id
      initials
      name
    }
    status
    updatedAt
    views
    enquiriesCount
  }
  ${AddressFieldsFragmentDoc}
`
export const SavedInstructorSearchFragmentDoc = gql`
  fragment savedInstructorSearch on SavedInstructorSearch {
    filter {
      name
      programs
      substituteListingFilter
      location {
        within {
          address {
            ...addressFields
          }
          area {
            points {
              lat
              lon
            }
          }
        }
        near {
          address {
            ...addressFields
          }
          distance
          distanceUnit
        }
        point {
          lat
          lon
        }
      }
    }
    sort
  }
  ${AddressFieldsFragmentDoc}
`
export const ErrorResultFieldsFragmentDoc = gql`
  fragment errorResultFields on ErrorResult {
    type
    message
    requestId
    logGroup
    logStream
    __typename
  }
`
export const ClubFieldsFragmentDoc = gql`
  fragment clubFields on Club {
    accountId
    createdAt
    currency
    email {
      affiliations
      jobListings
      main
    }
    id
    market {
      name
      locale
      distanceUnit
      programs {
        id
        name
        code
        initials
      }
    }
    name
    parent
    phone
    profile {
      ... on UserProfileClub {
        ...clubProfileFields
      }
    }
  }
  ${ClubProfileFieldsFragmentDoc}
`
export const ManagePeopleUserAffiliateFieldsFragmentDoc = gql`
  fragment managePeopleUserAffiliateFields on User {
    id
    phone
    email
    accountId
    market {
      name
      locale
      distanceUnit
    }
    lastName
    firstName
  }
`
export const AffiliationFieldsFragmentDoc = gql`
  fragment affiliationFields on Affiliation {
    actionedDate
    clubId
    id
    latestActionInitiator
    pendingStatus
    requestedBy
    requestedDate
    role
    status
    userId
  }
`
export const ClubWithAffiliationFragmentDoc = gql`
  fragment clubWithAffiliation on ClubWithAffiliation {
    clubId
    clubName
    latestAffiliation {
      ...affiliationFields
    }
    isDigitalOnly
  }
  ${AffiliationFieldsFragmentDoc}
`
export const ManagePeopleClubAffiliatesFieldsFragmentDoc = gql`
  fragment managePeopleClubAffiliatesFields on ClubAffiliates {
    actionedDate
    clubId
    distance
    distanceUnit
    id
    pendingStatus
    profile {
      ... on UserProfileClub {
        ...clubProfileFields
      }
    }
    requestedBy
    requestedDate
    status
  }
  ${ClubProfileFieldsFragmentDoc}
`
export const BusinessSupportSectionContentFragmentDoc = gql`
  fragment BusinessSupportSectionContent on BusinessSupportSection {
    id
    sectionTitle
    sectionTiles {
      id
      title
      subtitle
      image {
        id
        alt
        url
      }
      url {
        type
        value
      }
    }
  }
`
export const ClubDashboardBusinessSupportContentFragmentDoc = gql`
  fragment ClubDashboardBusinessSupportContent on ClubPartnershipBusinessSupportContent {
    link {
      text
      url {
        type
        value
      }
    }
    title
    description
  }
`
export const ClubDashboardStandardContentFragmentDoc = gql`
  fragment ClubDashboardStandardContent on ClubPartnershipStandardContent {
    title
    subtitle
    link {
      url {
        type
        value
      }
      text
    }
    image {
      mobile {
        alt
        url
      }
      desktop {
        alt
        url
      }
    }
  }
`
export const ClubDashboardProgramInfoFragmentDoc = gql`
  fragment ClubDashboardProgramInfo on ClubPartnershipProgramStat {
    total
    type
    icon {
      alt
      url
    }
  }
`
export const ClubDashboardProgramStatsFragmentDoc = gql`
  fragment ClubDashboardProgramStats on ClubPartnershipProgramStats {
    programsStats {
      ...ClubDashboardProgramInfo
    }
  }
  ${ClubDashboardProgramInfoFragmentDoc}
`
export const InStudioManageOptionsFragmentDoc = gql`
  fragment InStudioManageOptions on GetPickListResult {
    results {
      items
      key
    }
  }
`
export const LocationsFragmentDoc = gql`
  fragment locations on ClubAvailabilityOnListing {
    clubId
    clubName
    isAlreadyListed
    isClubLicensed
    clubProfileImage {
      ...imageFields
    }
    clubAddress {
      ...addressFields
    }
  }
  ${ImageFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`
export const NotificationItemFragmentDoc = gql`
  fragment notificationItem on Notification {
    id
    type
    body
    title
    createdAt
    createdAtLabel
    readAt
    isNew
    contentType
    params {
      userName
    }
    link {
      id
      text
      url {
        type
        value
      }
    }
  }
`
export const NotificationsResultFragmentDoc = gql`
  fragment notificationsResult on GetNotificationsResult {
    filter {
      type
      value
      limit
    }
    totalCount
    items {
      ...notificationItem
    }
  }
  ${NotificationItemFragmentDoc}
`
export const AtHomeProductTileFragmentDoc = gql`
  fragment AtHomeProductTile on AtHomeProductTile {
    title
    description
    onlineProgram {
      id
      activeSince
      lastEnquiredOn
    }
    buttons {
      isPrimary
      id
      text
      url {
        type
        value
      }
    }
    image {
      id
      desktop {
        id
        alt
        url
      }
      mobile {
        id
        alt
        url
      }
    }
    programs {
      id
      code
      name
    }
  }
`
export const ExploreProgramTileFragmentDoc = gql`
  fragment ExploreProgramTile on InStudioExploreProgramsTile {
    productId
    name
    genre
    productCategories
    numberOfCertifiedInstructors
    enquirySent
    image {
      id
      alt
      url
    }
  }
`
export const InStudioDetailsViewFragmentDoc = gql`
  fragment InStudioDetailsView on InStudioDetailsView {
    typeInfo {
      description
      title
    }
    description
    videoContentId
    moreURL {
      type
      value
    }
  }
`
export const DetailedExploreProgramTileFragmentDoc = gql`
  fragment DetailedExploreProgramTile on InStudioExploreProgramsTile {
    productId
    name
    genre
    productCategories
    numberOfCertifiedInstructors
    enquirySent
    image {
      id
      alt
      url
    }
    detailView {
      ...InStudioDetailsView
    }
  }
  ${InStudioDetailsViewFragmentDoc}
`
export const SearchInstructorsSingleResultFragmentDoc = gql`
  fragment searchInstructorsSingleResult on SearchInstructorsSingleResult {
    distance
    distanceUnit
    messageSentOn
    inTravelZone
    hasExistingAffiliation
    hasRecentlyDeclinedAffiliation
    lastMessageSentAt
    lastMessageIn48HoursSentAt
    instructorAffiliation {
      id
      clubId
      pendingStatus
      actionedDate
      requestedDate
      requestedBy
      role
      userId
      status
    }
    profile {
      ... on UserProfileTeaching {
        ...instructorProfileFields
      }
    }
    user {
      id
      email
      lastName
      firstName
      accountId
      market {
        name
        locale
        distanceUnit
      }
    }
  }
  ${InstructorProfileFieldsFragmentDoc}
`
export const SearchInstructorsResultFragmentDoc = gql`
  fragment searchInstructorsResult on SearchInstructorsResult {
    isMatchingFilter
    total
    results {
      ...searchInstructorsSingleResult
    }
  }
  ${SearchInstructorsSingleResultFragmentDoc}
`
export const SearchClubListingsWithFilterDocument = gql`
  query searchClubListingsWithFilter(
    $searchInfo: SearchClubListingsWithFilterInput!
    $filterInfo: FilterClubListingsWithFilterInput!
  ) {
    searchClubListingsWithFilter(
      searchInfo: $searchInfo
      filterInfo: $filterInfo
    ) {
      ... on SearchClubListingsWithFilterResult {
        edges {
          cursor
          node {
            distance
            distanceUnit
            messageSentOn
            enquiredOn
            onWatchlist
            hasBeenReported
            listing {
              ... on ClubSingleListing {
                ...singleListingFields
                programId
              }
              ... on ClubMultiListing {
                ...multiListingFields
                programsId
              }
            }
          }
        }
        pageInfo {
          endCursor {
            listingId
            sortValues
          }
          hasNextPage
        }
      }
    }
  }
  ${SingleListingFieldsFragmentDoc}
  ${MultiListingFieldsFragmentDoc}
`

/**
 * __useSearchClubListingsWithFilterQuery__
 *
 * To run a query within a React component, call `useSearchClubListingsWithFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchClubListingsWithFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchClubListingsWithFilterQuery({
 *   variables: {
 *      searchInfo: // value for 'searchInfo'
 *      filterInfo: // value for 'filterInfo'
 *   },
 * });
 */
export function useSearchClubListingsWithFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchClubListingsWithFilterQuery,
    SearchClubListingsWithFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    SearchClubListingsWithFilterQuery,
    SearchClubListingsWithFilterQueryVariables
  >(SearchClubListingsWithFilterDocument, baseOptions)
}
export function useSearchClubListingsWithFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchClubListingsWithFilterQuery,
    SearchClubListingsWithFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SearchClubListingsWithFilterQuery,
    SearchClubListingsWithFilterQueryVariables
  >(SearchClubListingsWithFilterDocument, baseOptions)
}
export type SearchClubListingsWithFilterQueryHookResult = ReturnType<
  typeof useSearchClubListingsWithFilterQuery
>
export type SearchClubListingsWithFilterLazyQueryHookResult = ReturnType<
  typeof useSearchClubListingsWithFilterLazyQuery
>
export type SearchClubListingsWithFilterQueryResult = Apollo.QueryResult<
  SearchClubListingsWithFilterQuery,
  SearchClubListingsWithFilterQueryVariables
>
export const SearchClubsWithFilterDocument = gql`
  query searchClubsWithFilter(
    $searchInfo: SearchClubsWithFilterInput!
    $filterInfo: FilterClubsInput!
  ) {
    searchClubsWithFilter(searchInfo: $searchInfo, filterInfo: $filterInfo) {
      ... on SearchClubsWithFilterResult {
        edges {
          cursor
          node {
            distance
            distanceUnit
            onWatchlist
            hasBeenReported
            profile {
              ...clubProfileFields
              programIds
            }
          }
        }
        pageInfo {
          endCursor {
            clubId
            sortValues
          }
          hasNextPage
        }
      }
    }
  }
  ${ClubProfileFieldsFragmentDoc}
`

/**
 * __useSearchClubsWithFilterQuery__
 *
 * To run a query within a React component, call `useSearchClubsWithFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchClubsWithFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchClubsWithFilterQuery({
 *   variables: {
 *      searchInfo: // value for 'searchInfo'
 *      filterInfo: // value for 'filterInfo'
 *   },
 * });
 */
export function useSearchClubsWithFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchClubsWithFilterQuery,
    SearchClubsWithFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    SearchClubsWithFilterQuery,
    SearchClubsWithFilterQueryVariables
  >(SearchClubsWithFilterDocument, baseOptions)
}
export function useSearchClubsWithFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchClubsWithFilterQuery,
    SearchClubsWithFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SearchClubsWithFilterQuery,
    SearchClubsWithFilterQueryVariables
  >(SearchClubsWithFilterDocument, baseOptions)
}
export type SearchClubsWithFilterQueryHookResult = ReturnType<
  typeof useSearchClubsWithFilterQuery
>
export type SearchClubsWithFilterLazyQueryHookResult = ReturnType<
  typeof useSearchClubsWithFilterLazyQuery
>
export type SearchClubsWithFilterQueryResult = Apollo.QueryResult<
  SearchClubsWithFilterQuery,
  SearchClubsWithFilterQueryVariables
>
export const GetClubProgramsForInStudioPageDocument = gql`
  query getClubProgramsForInStudioPage(
    $input: GetClubByIdInput!
    $_cacheKey: String
  ) {
    getClubById(input: $input, _cacheKey: $_cacheKey) {
      ... on UserProfileClub {
        programs {
          ... on ClubProgram {
            ...InStudioClubProgram
          }
          ... on ClubProgramInStudioEmptyState {
            ...InStudioEmptyState
          }
        }
        inStudioToolkit {
          tiles {
            ...InStudioToolkitTile
          }
        }
        exploreProgramsCard {
          image {
            ...imageFields
          }
        }
      }
      ... on ErrorResult {
        type
        message
      }
    }
  }
  ${InStudioClubProgramFragmentDoc}
  ${InStudioEmptyStateFragmentDoc}
  ${InStudioToolkitTileFragmentDoc}
  ${ImageFieldsFragmentDoc}
`

/**
 * __useGetClubProgramsForInStudioPageQuery__
 *
 * To run a query within a React component, call `useGetClubProgramsForInStudioPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubProgramsForInStudioPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubProgramsForInStudioPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubProgramsForInStudioPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubProgramsForInStudioPageQuery,
    GetClubProgramsForInStudioPageQueryVariables
  >
) {
  return Apollo.useQuery<
    GetClubProgramsForInStudioPageQuery,
    GetClubProgramsForInStudioPageQueryVariables
  >(GetClubProgramsForInStudioPageDocument, baseOptions)
}
export function useGetClubProgramsForInStudioPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubProgramsForInStudioPageQuery,
    GetClubProgramsForInStudioPageQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetClubProgramsForInStudioPageQuery,
    GetClubProgramsForInStudioPageQueryVariables
  >(GetClubProgramsForInStudioPageDocument, baseOptions)
}
export type GetClubProgramsForInStudioPageQueryHookResult = ReturnType<
  typeof useGetClubProgramsForInStudioPageQuery
>
export type GetClubProgramsForInStudioPageLazyQueryHookResult = ReturnType<
  typeof useGetClubProgramsForInStudioPageLazyQuery
>
export type GetClubProgramsForInStudioPageQueryResult = Apollo.QueryResult<
  GetClubProgramsForInStudioPageQuery,
  GetClubProgramsForInStudioPageQueryVariables
>
export const AddTrainingOutcomeDocument = gql`
  mutation addTrainingOutcome(
    $input: AddTrainingOutcomeInput!
    $_cacheKey: String
  ) {
    addTrainingOutcome(input: $input, _cacheKey: $_cacheKey) {
      ... on AddTrainingOutcomeResult {
        isSuccess
        developmentSheet {
          filename
          url
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type AddTrainingOutcomeMutationFn = Apollo.MutationFunction<
  AddTrainingOutcomeMutation,
  AddTrainingOutcomeMutationVariables
>

/**
 * __useAddTrainingOutcomeMutation__
 *
 * To run a mutation, you first call `useAddTrainingOutcomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrainingOutcomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrainingOutcomeMutation, { data, loading, error }] = useAddTrainingOutcomeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useAddTrainingOutcomeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTrainingOutcomeMutation,
    AddTrainingOutcomeMutationVariables
  >
) {
  return Apollo.useMutation<
    AddTrainingOutcomeMutation,
    AddTrainingOutcomeMutationVariables
  >(AddTrainingOutcomeDocument, baseOptions)
}
export type AddTrainingOutcomeMutationHookResult = ReturnType<
  typeof useAddTrainingOutcomeMutation
>
export type AddTrainingOutcomeMutationResult =
  Apollo.MutationResult<AddTrainingOutcomeMutation>
export type AddTrainingOutcomeMutationOptions = Apollo.BaseMutationOptions<
  AddTrainingOutcomeMutation,
  AddTrainingOutcomeMutationVariables
>
export const BookEventDocument = gql`
  mutation bookEvent($input: BookEventInput!, $role: UserRoleInput!) {
    bookEvent(input: $input, role: $role) {
      ... on BookEventResult {
        magentoLink
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type BookEventMutationFn = Apollo.MutationFunction<
  BookEventMutation,
  BookEventMutationVariables
>

/**
 * __useBookEventMutation__
 *
 * To run a mutation, you first call `useBookEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookEventMutation, { data, loading, error }] = useBookEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useBookEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookEventMutation,
    BookEventMutationVariables
  >
) {
  return Apollo.useMutation<BookEventMutation, BookEventMutationVariables>(
    BookEventDocument,
    baseOptions
  )
}
export type BookEventMutationHookResult = ReturnType<
  typeof useBookEventMutation
>
export type BookEventMutationResult = Apollo.MutationResult<BookEventMutation>
export type BookEventMutationOptions = Apollo.BaseMutationOptions<
  BookEventMutation,
  BookEventMutationVariables
>
export const BookQwsEventDocument = gql`
  mutation bookQWSEvent($input: BookQWSInput!, $role: UserRoleInput!) {
    bookQuarterlyWorkshopEvent(input: $input, role: $role) {
      ... on BookEventResult {
        magentoLink
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type BookQwsEventMutationFn = Apollo.MutationFunction<
  BookQwsEventMutation,
  BookQwsEventMutationVariables
>

/**
 * __useBookQwsEventMutation__
 *
 * To run a mutation, you first call `useBookQwsEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookQwsEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookQwsEventMutation, { data, loading, error }] = useBookQwsEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useBookQwsEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookQwsEventMutation,
    BookQwsEventMutationVariables
  >
) {
  return Apollo.useMutation<
    BookQwsEventMutation,
    BookQwsEventMutationVariables
  >(BookQwsEventDocument, baseOptions)
}
export type BookQwsEventMutationHookResult = ReturnType<
  typeof useBookQwsEventMutation
>
export type BookQwsEventMutationResult =
  Apollo.MutationResult<BookQwsEventMutation>
export type BookQwsEventMutationOptions = Apollo.BaseMutationOptions<
  BookQwsEventMutation,
  BookQwsEventMutationVariables
>
export const BookedEventDocument = gql`
  query bookedEvent(
    $eventId: String!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    bookedEvent(eventId: $eventId, role: $role, _cacheKey: $_cacheKey) {
      ... on BookedEventResult {
        eventDetails {
          event {
            eventId
            type
            eventName
            startDate
            endDate
            deliveryMethod
            spacesAvailable
            venueAddress
            programName
            timezone
          }
          trainerDetails {
            name
            email
          }
          schedule
          details
          requirements
          cancellationPolicy
        }
        bookedInstructors {
          name
          profileImage {
            url
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useBookedEventQuery__
 *
 * To run a query within a React component, call `useBookedEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookedEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookedEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useBookedEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    BookedEventQuery,
    BookedEventQueryVariables
  >
) {
  return Apollo.useQuery<BookedEventQuery, BookedEventQueryVariables>(
    BookedEventDocument,
    baseOptions
  )
}
export function useBookedEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookedEventQuery,
    BookedEventQueryVariables
  >
) {
  return Apollo.useLazyQuery<BookedEventQuery, BookedEventQueryVariables>(
    BookedEventDocument,
    baseOptions
  )
}
export type BookedEventQueryHookResult = ReturnType<typeof useBookedEventQuery>
export type BookedEventLazyQueryHookResult = ReturnType<
  typeof useBookedEventLazyQuery
>
export type BookedEventQueryResult = Apollo.QueryResult<
  BookedEventQuery,
  BookedEventQueryVariables
>
export const BookedEventsDocument = gql`
  query bookedEvents($clubId: String!, $_cacheKey: String) {
    bookedEvents(clubId: $clubId, _cacheKey: $_cacheKey) {
      ... on BookedEventsResult {
        bookedEvents {
          event {
            eventId
            type
            eventName
            startDate
            endDate
            deliveryMethod
            spacesAvailable
            venueAddress
            programName
          }
          numberOfBookedInstructors
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useBookedEventsQuery__
 *
 * To run a query within a React component, call `useBookedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookedEventsQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useBookedEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BookedEventsQuery,
    BookedEventsQueryVariables
  >
) {
  return Apollo.useQuery<BookedEventsQuery, BookedEventsQueryVariables>(
    BookedEventsDocument,
    baseOptions
  )
}
export function useBookedEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookedEventsQuery,
    BookedEventsQueryVariables
  >
) {
  return Apollo.useLazyQuery<BookedEventsQuery, BookedEventsQueryVariables>(
    BookedEventsDocument,
    baseOptions
  )
}
export type BookedEventsQueryHookResult = ReturnType<
  typeof useBookedEventsQuery
>
export type BookedEventsLazyQueryHookResult = ReturnType<
  typeof useBookedEventsLazyQuery
>
export type BookedEventsQueryResult = Apollo.QueryResult<
  BookedEventsQuery,
  BookedEventsQueryVariables
>
export const EventDetailsDocument = gql`
  query eventDetails(
    $eventId: String!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    eventDetails(eventId: $eventId, role: $role, _cacheKey: $_cacheKey) {
      ... on EventDetailsResult {
        event {
          eventId
          eventName
          startDate
          endDate
          deliveryMethod
          spacesAvailable
          venueAddress
          programName
          type
          timezone
        }
        trainerDetails {
          name
          email
        }
        schedule
        details
        requirements
        cancellationPolicy
        tickets {
          ticketId
          name
          currencyIsoCode
          price
          startDate
          endDate
          isDefaultTicket
          canBeUsedWithDiscountCode
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useEventDetailsQuery__
 *
 * To run a query within a React component, call `useEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useEventDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventDetailsQuery,
    EventDetailsQueryVariables
  >
) {
  return Apollo.useQuery<EventDetailsQuery, EventDetailsQueryVariables>(
    EventDetailsDocument,
    baseOptions
  )
}
export function useEventDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventDetailsQuery,
    EventDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<EventDetailsQuery, EventDetailsQueryVariables>(
    EventDetailsDocument,
    baseOptions
  )
}
export type EventDetailsQueryHookResult = ReturnType<
  typeof useEventDetailsQuery
>
export type EventDetailsLazyQueryHookResult = ReturnType<
  typeof useEventDetailsLazyQuery
>
export type EventDetailsQueryResult = Apollo.QueryResult<
  EventDetailsQuery,
  EventDetailsQueryVariables
>
export const QwsTrainerBookedEventDocument = gql`
  query qwsTrainerBookedEvent($eventId: String!, $_cacheKey: String) {
    qwsTrainerBookedEvent(eventId: $eventId, _cacheKey: $_cacheKey) {
      ... on QwsTrainerBookedEventResult {
        qwsTrainerBookedEventSummary {
          eventName
          deliveryMethod
          sessionCount
          instructorCount
          venueAddress
          startDate
          endDate
        }
        sessions {
          sessionId
          sessionName
          programName
          startTime
          endTime
          timezone
          instructorCount
          instructors {
            registrationId
            email
            firstName
            lastName
            profileImage {
              ...imageFields
            }
            hasAttended
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ImageFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useQwsTrainerBookedEventQuery__
 *
 * To run a query within a React component, call `useQwsTrainerBookedEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useQwsTrainerBookedEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQwsTrainerBookedEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useQwsTrainerBookedEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    QwsTrainerBookedEventQuery,
    QwsTrainerBookedEventQueryVariables
  >
) {
  return Apollo.useQuery<
    QwsTrainerBookedEventQuery,
    QwsTrainerBookedEventQueryVariables
  >(QwsTrainerBookedEventDocument, baseOptions)
}
export function useQwsTrainerBookedEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QwsTrainerBookedEventQuery,
    QwsTrainerBookedEventQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    QwsTrainerBookedEventQuery,
    QwsTrainerBookedEventQueryVariables
  >(QwsTrainerBookedEventDocument, baseOptions)
}
export type QwsTrainerBookedEventQueryHookResult = ReturnType<
  typeof useQwsTrainerBookedEventQuery
>
export type QwsTrainerBookedEventLazyQueryHookResult = ReturnType<
  typeof useQwsTrainerBookedEventLazyQuery
>
export type QwsTrainerBookedEventQueryResult = Apollo.QueryResult<
  QwsTrainerBookedEventQuery,
  QwsTrainerBookedEventQueryVariables
>
export const TrainerBookingDocument = gql`
  query trainerBooking($input: TrainerBookingInput!, $_cacheKey: String) {
    trainerBooking(input: $input, _cacheKey: $_cacheKey) {
      ... on TrainerBookingResult {
        eventAssessments {
          ...eventAssessmentFields
        }
        eventSummary {
          ... on EventSummary {
            type
            deliveryMethod
            endDate
            eventId
            eventName
            eventStatus
            programName
            startDate
            trainingJourneyCount
            venueAddress
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${EventAssessmentFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useTrainerBookingQuery__
 *
 * To run a query within a React component, call `useTrainerBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainerBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainerBookingQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useTrainerBookingQuery(
  baseOptions: Apollo.QueryHookOptions<
    TrainerBookingQuery,
    TrainerBookingQueryVariables
  >
) {
  return Apollo.useQuery<TrainerBookingQuery, TrainerBookingQueryVariables>(
    TrainerBookingDocument,
    baseOptions
  )
}
export function useTrainerBookingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrainerBookingQuery,
    TrainerBookingQueryVariables
  >
) {
  return Apollo.useLazyQuery<TrainerBookingQuery, TrainerBookingQueryVariables>(
    TrainerBookingDocument,
    baseOptions
  )
}
export type TrainerBookingQueryHookResult = ReturnType<
  typeof useTrainerBookingQuery
>
export type TrainerBookingLazyQueryHookResult = ReturnType<
  typeof useTrainerBookingLazyQuery
>
export type TrainerBookingQueryResult = Apollo.QueryResult<
  TrainerBookingQuery,
  TrainerBookingQueryVariables
>
export const TrainerBookingsDocument = gql`
  query trainerBookings($_cacheKey: String) {
    trainerBookings(_cacheKey: $_cacheKey) {
      ... on TrainerBookingsResult {
        result {
          deliveryMethod
          type
          endDate
          eventId
          eventName
          programName
          startDate
          eventStatus
          trainingJourneyCount
          venueAddress
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useTrainerBookingsQuery__
 *
 * To run a query within a React component, call `useTrainerBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainerBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainerBookingsQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useTrainerBookingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TrainerBookingsQuery,
    TrainerBookingsQueryVariables
  >
) {
  return Apollo.useQuery<TrainerBookingsQuery, TrainerBookingsQueryVariables>(
    TrainerBookingsDocument,
    baseOptions
  )
}
export function useTrainerBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrainerBookingsQuery,
    TrainerBookingsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    TrainerBookingsQuery,
    TrainerBookingsQueryVariables
  >(TrainerBookingsDocument, baseOptions)
}
export type TrainerBookingsQueryHookResult = ReturnType<
  typeof useTrainerBookingsQuery
>
export type TrainerBookingsLazyQueryHookResult = ReturnType<
  typeof useTrainerBookingsLazyQuery
>
export type TrainerBookingsQueryResult = Apollo.QueryResult<
  TrainerBookingsQuery,
  TrainerBookingsQueryVariables
>
export const TrainingJourneyDocument = gql`
  query trainingJourney(
    $input: TrainingJourneyInput!
    $role: UserRoleInput
    $_cacheKey: String
  ) {
    trainingJourney(input: $input, role: $role, _cacheKey: $_cacheKey) {
      ... on TrainingJourneyResult {
        result {
          steps {
            ... on TrainingBookedStep {
              ...trainingBookedStepFields
            }
            ... on PreworkStep {
              ...preworkStepFields
            }
            ... on AttendTrainingStep {
              ...attendTrainingStepFields
            }
            ... on SubmitOutcomeTrackStep {
              ...submitOutcomeTrackStepFields
            }
            ... on ReceivedTrainingOutcomeStep {
              ...receivedTrainingOutcomeStepFields
            }
            ... on SubmitCertificationVideoStep {
              ...submitCertificationVideoStepFields
            }
            ... on ReceivedCertificationOutcomeStep {
              ...receivedCertificationOutcomeStepFields
            }
            ... on CertifiedStep {
              ...certifiedStepFields
            }
            ... on ContactUsStep {
              activeStepName
              activeStepNumber
              stepName
              stepNumber
              contactUsEmail
            }
          }
          type
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${TrainingBookedStepFieldsFragmentDoc}
  ${PreworkStepFieldsFragmentDoc}
  ${AttendTrainingStepFieldsFragmentDoc}
  ${SubmitOutcomeTrackStepFieldsFragmentDoc}
  ${ReceivedTrainingOutcomeStepFieldsFragmentDoc}
  ${SubmitCertificationVideoStepFieldsFragmentDoc}
  ${ReceivedCertificationOutcomeStepFieldsFragmentDoc}
  ${CertifiedStepFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useTrainingJourneyQuery__
 *
 * To run a query within a React component, call `useTrainingJourneyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingJourneyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingJourneyQuery({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useTrainingJourneyQuery(
  baseOptions: Apollo.QueryHookOptions<
    TrainingJourneyQuery,
    TrainingJourneyQueryVariables
  >
) {
  return Apollo.useQuery<TrainingJourneyQuery, TrainingJourneyQueryVariables>(
    TrainingJourneyDocument,
    baseOptions
  )
}
export function useTrainingJourneyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrainingJourneyQuery,
    TrainingJourneyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    TrainingJourneyQuery,
    TrainingJourneyQueryVariables
  >(TrainingJourneyDocument, baseOptions)
}
export type TrainingJourneyQueryHookResult = ReturnType<
  typeof useTrainingJourneyQuery
>
export type TrainingJourneyLazyQueryHookResult = ReturnType<
  typeof useTrainingJourneyLazyQuery
>
export type TrainingJourneyQueryResult = Apollo.QueryResult<
  TrainingJourneyQuery,
  TrainingJourneyQueryVariables
>
export const TrainingJourneysDocument = gql`
  query trainingJourneys($_cacheKey: String) {
    trainingJourneys(_cacheKey: $_cacheKey) {
      ... on TrainingJourneysResult {
        trainings {
          id
          eventName
          type
          deliveryMethod
          startDate
          endDate
          timezone
          cecCertificationUrl
          venueAddress
          activeStepName
          activeStepNumber
          dueDate
          programName
          isContactUs
          dateCertified
        }
        eventsAndTrainingUrl
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useTrainingJourneysQuery__
 *
 * To run a query within a React component, call `useTrainingJourneysQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingJourneysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingJourneysQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useTrainingJourneysQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TrainingJourneysQuery,
    TrainingJourneysQueryVariables
  >
) {
  return Apollo.useQuery<TrainingJourneysQuery, TrainingJourneysQueryVariables>(
    TrainingJourneysDocument,
    baseOptions
  )
}
export function useTrainingJourneysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrainingJourneysQuery,
    TrainingJourneysQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    TrainingJourneysQuery,
    TrainingJourneysQueryVariables
  >(TrainingJourneysDocument, baseOptions)
}
export type TrainingJourneysQueryHookResult = ReturnType<
  typeof useTrainingJourneysQuery
>
export type TrainingJourneysLazyQueryHookResult = ReturnType<
  typeof useTrainingJourneysLazyQuery
>
export type TrainingJourneysQueryResult = Apollo.QueryResult<
  TrainingJourneysQuery,
  TrainingJourneysQueryVariables
>
export const QwsBookedEventDocument = gql`
  query qwsBookedEvent(
    $eventId: String!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    qwsBookedEvent(eventId: $eventId, role: $role, _cacheKey: $_cacheKey) {
      ... on QwsBookedEventResult {
        eventDetails {
          event {
            deliveryMethod
            eventName
            eventId
            startDate
            endDate
            timezone
            venueAddress
          }
          details
          schedule
          requirements
          cancellationPolicy
        }
        sessions {
          sessionId
          sessionName
          startTime
          endTime
          spacesAvailable
          programName
          trainers {
            name
          }
          bookedInstructors {
            id
            name
            profileImage {
              url
            }
          }
        }
        bookedInstructors {
          id
          name
          profileImage {
            url
          }
        }
      }
      ... on ErrorResult {
        component
        logGroup
        logStream
        message
        requestId
        errorType: type
      }
    }
  }
`

/**
 * __useQwsBookedEventQuery__
 *
 * To run a query within a React component, call `useQwsBookedEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useQwsBookedEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQwsBookedEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useQwsBookedEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    QwsBookedEventQuery,
    QwsBookedEventQueryVariables
  >
) {
  return Apollo.useQuery<QwsBookedEventQuery, QwsBookedEventQueryVariables>(
    QwsBookedEventDocument,
    baseOptions
  )
}
export function useQwsBookedEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QwsBookedEventQuery,
    QwsBookedEventQueryVariables
  >
) {
  return Apollo.useLazyQuery<QwsBookedEventQuery, QwsBookedEventQueryVariables>(
    QwsBookedEventDocument,
    baseOptions
  )
}
export type QwsBookedEventQueryHookResult = ReturnType<
  typeof useQwsBookedEventQuery
>
export type QwsBookedEventLazyQueryHookResult = ReturnType<
  typeof useQwsBookedEventLazyQuery
>
export type QwsBookedEventQueryResult = Apollo.QueryResult<
  QwsBookedEventQuery,
  QwsBookedEventQueryVariables
>
export const QwsEventDetailsDocument = gql`
  query qwsEventDetails(
    $eventId: String!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    qwsEventDetails(eventId: $eventId, role: $role, _cacheKey: $_cacheKey) {
      ... on QWSEventDetailsResult {
        event {
          eventId
          eventName
          startDate
          endDate
          deliveryMethod
          venueAddress
          timezone
          sessions {
            sessionId
            sessionName
            startTime
            endTime
            programName
            spacesAvailable
            trainerDetails {
              name
              email
            }
          }
        }
        schedule
        details
        requirements
        cancellationPolicy
        tickets {
          ticketId
          name
          currencyIsoCode
          price
          startDate
          endDate
          isDefaultTicket
          canBeUsedWithDiscountCode
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useQwsEventDetailsQuery__
 *
 * To run a query within a React component, call `useQwsEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQwsEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQwsEventDetailsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useQwsEventDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QwsEventDetailsQuery,
    QwsEventDetailsQueryVariables
  >
) {
  return Apollo.useQuery<QwsEventDetailsQuery, QwsEventDetailsQueryVariables>(
    QwsEventDetailsDocument,
    baseOptions
  )
}
export function useQwsEventDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QwsEventDetailsQuery,
    QwsEventDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    QwsEventDetailsQuery,
    QwsEventDetailsQueryVariables
  >(QwsEventDetailsDocument, baseOptions)
}
export type QwsEventDetailsQueryHookResult = ReturnType<
  typeof useQwsEventDetailsQuery
>
export type QwsEventDetailsLazyQueryHookResult = ReturnType<
  typeof useQwsEventDetailsLazyQuery
>
export type QwsEventDetailsQueryResult = Apollo.QueryResult<
  QwsEventDetailsQuery,
  QwsEventDetailsQueryVariables
>
export const QwsImtTrainerBookingsDocument = gql`
  query qwsImtTrainerBookings($_cacheKey: String) {
    qwsImtTrainerBookings(_cacheKey: $_cacheKey) {
      ... on QwsImtTrainerBookingsResult {
        qwsEvents {
          eventId
          eventName
          type
          deliveryMethod
          startDate
          endDate
          venueAddress
          sessionCount
          instructorCount
          eventStatus
          __typename
        }
        trainingJourneys {
          eventName
          deliveryMethod
          endDate
          eventId
          eventStatus
          programName
          startDate
          trainingJourneyCount
          type
          venueAddress
          __typename
        }
        __typename
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useQwsImtTrainerBookingsQuery__
 *
 * To run a query within a React component, call `useQwsImtTrainerBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQwsImtTrainerBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQwsImtTrainerBookingsQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useQwsImtTrainerBookingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QwsImtTrainerBookingsQuery,
    QwsImtTrainerBookingsQueryVariables
  >
) {
  return Apollo.useQuery<
    QwsImtTrainerBookingsQuery,
    QwsImtTrainerBookingsQueryVariables
  >(QwsImtTrainerBookingsDocument, baseOptions)
}
export function useQwsImtTrainerBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QwsImtTrainerBookingsQuery,
    QwsImtTrainerBookingsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    QwsImtTrainerBookingsQuery,
    QwsImtTrainerBookingsQueryVariables
  >(QwsImtTrainerBookingsDocument, baseOptions)
}
export type QwsImtTrainerBookingsQueryHookResult = ReturnType<
  typeof useQwsImtTrainerBookingsQuery
>
export type QwsImtTrainerBookingsLazyQueryHookResult = ReturnType<
  typeof useQwsImtTrainerBookingsLazyQuery
>
export type QwsImtTrainerBookingsQueryResult = Apollo.QueryResult<
  QwsImtTrainerBookingsQuery,
  QwsImtTrainerBookingsQueryVariables
>
export const RequestForExtensionDocument = gql`
  mutation requestForExtension(
    $input: RequestForExtensionInput!
    $_cacheKey: String
  ) {
    requestForExtension(input: $input, _cacheKey: $_cacheKey) {
      ... on RequestForExtensionResult {
        isSuccess
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type RequestForExtensionMutationFn = Apollo.MutationFunction<
  RequestForExtensionMutation,
  RequestForExtensionMutationVariables
>

/**
 * __useRequestForExtensionMutation__
 *
 * To run a mutation, you first call `useRequestForExtensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestForExtensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestForExtensionMutation, { data, loading, error }] = useRequestForExtensionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useRequestForExtensionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestForExtensionMutation,
    RequestForExtensionMutationVariables
  >
) {
  return Apollo.useMutation<
    RequestForExtensionMutation,
    RequestForExtensionMutationVariables
  >(RequestForExtensionDocument, baseOptions)
}
export type RequestForExtensionMutationHookResult = ReturnType<
  typeof useRequestForExtensionMutation
>
export type RequestForExtensionMutationResult =
  Apollo.MutationResult<RequestForExtensionMutation>
export type RequestForExtensionMutationOptions = Apollo.BaseMutationOptions<
  RequestForExtensionMutation,
  RequestForExtensionMutationVariables
>
export const SearchEventsDocument = gql`
  query searchEvents(
    $searchInfo: SearchEventsInput!
    $filterInfo: FilterEventsInput!
    $_cacheKey: String
  ) {
    searchEvents(
      searchInfo: $searchInfo
      filterInfo: $filterInfo
      _cacheKey: $_cacheKey
    ) {
      ... on EventsResult {
        edges {
          cursor
          node {
            eventId
            eventName
            startDate
            endDate
            type
            deliveryMethod
            spacesAvailable
            venueAddress
            programName
            programNames
          }
        }
        pageInfo {
          endCursor {
            eventId
            sortValues
          }
          hasNextPage
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useSearchEventsQuery__
 *
 * To run a query within a React component, call `useSearchEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEventsQuery({
 *   variables: {
 *      searchInfo: // value for 'searchInfo'
 *      filterInfo: // value for 'filterInfo'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSearchEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchEventsQuery,
    SearchEventsQueryVariables
  >
) {
  return Apollo.useQuery<SearchEventsQuery, SearchEventsQueryVariables>(
    SearchEventsDocument,
    baseOptions
  )
}
export function useSearchEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchEventsQuery,
    SearchEventsQueryVariables
  >
) {
  return Apollo.useLazyQuery<SearchEventsQuery, SearchEventsQueryVariables>(
    SearchEventsDocument,
    baseOptions
  )
}
export type SearchEventsQueryHookResult = ReturnType<
  typeof useSearchEventsQuery
>
export type SearchEventsLazyQueryHookResult = ReturnType<
  typeof useSearchEventsLazyQuery
>
export type SearchEventsQueryResult = Apollo.QueryResult<
  SearchEventsQuery,
  SearchEventsQueryVariables
>
export const AddClubListingDocument = gql`
  mutation addClubListing($input: AddClubListingInput!, $_cacheKey: String) {
    addClubListing(input: $input, _cacheKey: $_cacheKey) {
      ... on ClubListingResult {
        results {
          ... on ClubSingleListing {
            ...singleListingFields
          }
          ... on ClubMultiListing {
            ...multiListingFields
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${SingleListingFieldsFragmentDoc}
  ${MultiListingFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type AddClubListingMutationFn = Apollo.MutationFunction<
  AddClubListingMutation,
  AddClubListingMutationVariables
>

/**
 * __useAddClubListingMutation__
 *
 * To run a mutation, you first call `useAddClubListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClubListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClubListingMutation, { data, loading, error }] = useAddClubListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useAddClubListingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddClubListingMutation,
    AddClubListingMutationVariables
  >
) {
  return Apollo.useMutation<
    AddClubListingMutation,
    AddClubListingMutationVariables
  >(AddClubListingDocument, baseOptions)
}
export type AddClubListingMutationHookResult = ReturnType<
  typeof useAddClubListingMutation
>
export type AddClubListingMutationResult =
  Apollo.MutationResult<AddClubListingMutation>
export type AddClubListingMutationOptions = Apollo.BaseMutationOptions<
  AddClubListingMutation,
  AddClubListingMutationVariables
>
export const AddEmergencyContactDocument = gql`
  mutation addEmergencyContact(
    $input: EmergencyContactInput!
    $_cacheKey: String
  ) {
    addEmergencyContact(input: $input, _cacheKey: $_cacheKey) {
      ... on AddEmergencyContactResult {
        result {
          address
          name
          phone
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type AddEmergencyContactMutationFn = Apollo.MutationFunction<
  AddEmergencyContactMutation,
  AddEmergencyContactMutationVariables
>

/**
 * __useAddEmergencyContactMutation__
 *
 * To run a mutation, you first call `useAddEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmergencyContactMutation, { data, loading, error }] = useAddEmergencyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useAddEmergencyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEmergencyContactMutation,
    AddEmergencyContactMutationVariables
  >
) {
  return Apollo.useMutation<
    AddEmergencyContactMutation,
    AddEmergencyContactMutationVariables
  >(AddEmergencyContactDocument, baseOptions)
}
export type AddEmergencyContactMutationHookResult = ReturnType<
  typeof useAddEmergencyContactMutation
>
export type AddEmergencyContactMutationResult =
  Apollo.MutationResult<AddEmergencyContactMutation>
export type AddEmergencyContactMutationOptions = Apollo.BaseMutationOptions<
  AddEmergencyContactMutation,
  AddEmergencyContactMutationVariables
>
export const AddToShortlistDocument = gql`
  mutation addToShortlist(
    $input: AddToShortlistInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    addToShortlist(input: $input, role: $role, _cacheKey: $_cacheKey) {
      ... on AddToShortlistResult {
        shortlist {
          id
          profile {
            ...instructorProfileFields
          }
          user {
            id
            lastName
            firstName
          }
          lastMessageIn48HoursSentAt
          distanceUnit
          distance
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${InstructorProfileFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type AddToShortlistMutationFn = Apollo.MutationFunction<
  AddToShortlistMutation,
  AddToShortlistMutationVariables
>

/**
 * __useAddToShortlistMutation__
 *
 * To run a mutation, you first call `useAddToShortlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToShortlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToShortlistMutation, { data, loading, error }] = useAddToShortlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useAddToShortlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddToShortlistMutation,
    AddToShortlistMutationVariables
  >
) {
  return Apollo.useMutation<
    AddToShortlistMutation,
    AddToShortlistMutationVariables
  >(AddToShortlistDocument, baseOptions)
}
export type AddToShortlistMutationHookResult = ReturnType<
  typeof useAddToShortlistMutation
>
export type AddToShortlistMutationResult =
  Apollo.MutationResult<AddToShortlistMutation>
export type AddToShortlistMutationOptions = Apollo.BaseMutationOptions<
  AddToShortlistMutation,
  AddToShortlistMutationVariables
>
export const CreateAffiliationDocument = gql`
  mutation createAffiliation(
    $input: CreateAffiliationInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    createAffiliation(input: $input, role: $role, _cacheKey: $_cacheKey) {
      ... on AffiliationAsyncResult {
        status
        affilation {
          __typename
          actionedDate
          clubId
          id
          latestActionInitiator
          pendingStatus
          requestedBy
          requestedDate
          role
          status
          userId
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type CreateAffiliationMutationFn = Apollo.MutationFunction<
  CreateAffiliationMutation,
  CreateAffiliationMutationVariables
>

/**
 * __useCreateAffiliationMutation__
 *
 * To run a mutation, you first call `useCreateAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAffiliationMutation, { data, loading, error }] = useCreateAffiliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useCreateAffiliationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAffiliationMutation,
    CreateAffiliationMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateAffiliationMutation,
    CreateAffiliationMutationVariables
  >(CreateAffiliationDocument, baseOptions)
}
export type CreateAffiliationMutationHookResult = ReturnType<
  typeof useCreateAffiliationMutation
>
export type CreateAffiliationMutationResult =
  Apollo.MutationResult<CreateAffiliationMutation>
export type CreateAffiliationMutationOptions = Apollo.BaseMutationOptions<
  CreateAffiliationMutation,
  CreateAffiliationMutationVariables
>
export const CreateClubListingsDocument = gql`
  mutation createClubListings(
    $input: CreateClubListingsInput!
    $_cacheKey: String
  ) {
    createClubListings(input: $input, _cacheKey: $_cacheKey) {
      ... on CreateClubListingsResult {
        successfulListings {
          ... on ClubSingleListing {
            ...singleListingFields
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${SingleListingFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type CreateClubListingsMutationFn = Apollo.MutationFunction<
  CreateClubListingsMutation,
  CreateClubListingsMutationVariables
>

/**
 * __useCreateClubListingsMutation__
 *
 * To run a mutation, you first call `useCreateClubListingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClubListingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClubListingsMutation, { data, loading, error }] = useCreateClubListingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useCreateClubListingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClubListingsMutation,
    CreateClubListingsMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateClubListingsMutation,
    CreateClubListingsMutationVariables
  >(CreateClubListingsDocument, baseOptions)
}
export type CreateClubListingsMutationHookResult = ReturnType<
  typeof useCreateClubListingsMutation
>
export type CreateClubListingsMutationResult =
  Apollo.MutationResult<CreateClubListingsMutation>
export type CreateClubListingsMutationOptions = Apollo.BaseMutationOptions<
  CreateClubListingsMutation,
  CreateClubListingsMutationVariables
>
export const DeleteAffiliationDocument = gql`
  mutation deleteAffiliation(
    $input: DeleteAffiliationInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    deleteAffiliation(input: $input, role: $role, _cacheKey: $_cacheKey) {
      ... on AffiliationAsyncResult {
        status
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type DeleteAffiliationMutationFn = Apollo.MutationFunction<
  DeleteAffiliationMutation,
  DeleteAffiliationMutationVariables
>

/**
 * __useDeleteAffiliationMutation__
 *
 * To run a mutation, you first call `useDeleteAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAffiliationMutation, { data, loading, error }] = useDeleteAffiliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useDeleteAffiliationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAffiliationMutation,
    DeleteAffiliationMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteAffiliationMutation,
    DeleteAffiliationMutationVariables
  >(DeleteAffiliationDocument, baseOptions)
}
export type DeleteAffiliationMutationHookResult = ReturnType<
  typeof useDeleteAffiliationMutation
>
export type DeleteAffiliationMutationResult =
  Apollo.MutationResult<DeleteAffiliationMutation>
export type DeleteAffiliationMutationOptions = Apollo.BaseMutationOptions<
  DeleteAffiliationMutation,
  DeleteAffiliationMutationVariables
>
export const DeleteClubListingDocument = gql`
  mutation deleteClubListing(
    $input: DeleteClubListingInput!
    $_cacheKey: String
  ) {
    deleteClubListing(input: $input, _cacheKey: $_cacheKey) {
      ... on DeleteClubListingResult {
        clubId
        status
        listingId
        results {
          ... on ClubSingleListing {
            ...singleListingFields
          }
          ... on ClubMultiListing {
            ...multiListingFields
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${SingleListingFieldsFragmentDoc}
  ${MultiListingFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type DeleteClubListingMutationFn = Apollo.MutationFunction<
  DeleteClubListingMutation,
  DeleteClubListingMutationVariables
>

/**
 * __useDeleteClubListingMutation__
 *
 * To run a mutation, you first call `useDeleteClubListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClubListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClubListingMutation, { data, loading, error }] = useDeleteClubListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useDeleteClubListingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClubListingMutation,
    DeleteClubListingMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteClubListingMutation,
    DeleteClubListingMutationVariables
  >(DeleteClubListingDocument, baseOptions)
}
export type DeleteClubListingMutationHookResult = ReturnType<
  typeof useDeleteClubListingMutation
>
export type DeleteClubListingMutationResult =
  Apollo.MutationResult<DeleteClubListingMutation>
export type DeleteClubListingMutationOptions = Apollo.BaseMutationOptions<
  DeleteClubListingMutation,
  DeleteClubListingMutationVariables
>
export const DeleteCurrentUserPortfolioImageDocument = gql`
  mutation deleteCurrentUserPortfolioImage(
    $input: DeleteCurrentUserPortfolioImageInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    deleteCurrentUserPortfolioImage(
      input: $input
      role: $role
      _cacheKey: $_cacheKey
    ) {
      ... on DeleteCurrentUserPortfolioImageResult {
        status
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type DeleteCurrentUserPortfolioImageMutationFn = Apollo.MutationFunction<
  DeleteCurrentUserPortfolioImageMutation,
  DeleteCurrentUserPortfolioImageMutationVariables
>

/**
 * __useDeleteCurrentUserPortfolioImageMutation__
 *
 * To run a mutation, you first call `useDeleteCurrentUserPortfolioImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCurrentUserPortfolioImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCurrentUserPortfolioImageMutation, { data, loading, error }] = useDeleteCurrentUserPortfolioImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useDeleteCurrentUserPortfolioImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCurrentUserPortfolioImageMutation,
    DeleteCurrentUserPortfolioImageMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteCurrentUserPortfolioImageMutation,
    DeleteCurrentUserPortfolioImageMutationVariables
  >(DeleteCurrentUserPortfolioImageDocument, baseOptions)
}
export type DeleteCurrentUserPortfolioImageMutationHookResult = ReturnType<
  typeof useDeleteCurrentUserPortfolioImageMutation
>
export type DeleteCurrentUserPortfolioImageMutationResult =
  Apollo.MutationResult<DeleteCurrentUserPortfolioImageMutation>
export type DeleteCurrentUserPortfolioImageMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteCurrentUserPortfolioImageMutation,
    DeleteCurrentUserPortfolioImageMutationVariables
  >
export const DeleteMultipleJobListingsDocument = gql`
  mutation deleteMultipleJobListings(
    $input: DeleteClubListingsInput!
    $_cacheKey: String
  ) {
    deleteClubListings(input: $input, _cacheKey: $_cacheKey) {
      ... on DeleteClubListingsResult {
        success {
          ... on ClubSingleListing {
            ...singleListingFields
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${SingleListingFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type DeleteMultipleJobListingsMutationFn = Apollo.MutationFunction<
  DeleteMultipleJobListingsMutation,
  DeleteMultipleJobListingsMutationVariables
>

/**
 * __useDeleteMultipleJobListingsMutation__
 *
 * To run a mutation, you first call `useDeleteMultipleJobListingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMultipleJobListingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMultipleJobListingsMutation, { data, loading, error }] = useDeleteMultipleJobListingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useDeleteMultipleJobListingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMultipleJobListingsMutation,
    DeleteMultipleJobListingsMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteMultipleJobListingsMutation,
    DeleteMultipleJobListingsMutationVariables
  >(DeleteMultipleJobListingsDocument, baseOptions)
}
export type DeleteMultipleJobListingsMutationHookResult = ReturnType<
  typeof useDeleteMultipleJobListingsMutation
>
export type DeleteMultipleJobListingsMutationResult =
  Apollo.MutationResult<DeleteMultipleJobListingsMutation>
export type DeleteMultipleJobListingsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteMultipleJobListingsMutation,
    DeleteMultipleJobListingsMutationVariables
  >
export const DigitalOnlyInStudioEnquireDocument = gql`
  mutation digitalOnlyInStudioEnquire(
    $input: EnquireAboutInStudioProgramsInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    enquireAboutInStudioPrograms(
      input: $input
      role: $role
      _cacheKey: $_cacheKey
    ) {
      ... on EnquireAboutInStudioProgramsResult {
        isSuccess
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type DigitalOnlyInStudioEnquireMutationFn = Apollo.MutationFunction<
  DigitalOnlyInStudioEnquireMutation,
  DigitalOnlyInStudioEnquireMutationVariables
>

/**
 * __useDigitalOnlyInStudioEnquireMutation__
 *
 * To run a mutation, you first call `useDigitalOnlyInStudioEnquireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDigitalOnlyInStudioEnquireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [digitalOnlyInStudioEnquireMutation, { data, loading, error }] = useDigitalOnlyInStudioEnquireMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useDigitalOnlyInStudioEnquireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DigitalOnlyInStudioEnquireMutation,
    DigitalOnlyInStudioEnquireMutationVariables
  >
) {
  return Apollo.useMutation<
    DigitalOnlyInStudioEnquireMutation,
    DigitalOnlyInStudioEnquireMutationVariables
  >(DigitalOnlyInStudioEnquireDocument, baseOptions)
}
export type DigitalOnlyInStudioEnquireMutationHookResult = ReturnType<
  typeof useDigitalOnlyInStudioEnquireMutation
>
export type DigitalOnlyInStudioEnquireMutationResult =
  Apollo.MutationResult<DigitalOnlyInStudioEnquireMutation>
export type DigitalOnlyInStudioEnquireMutationOptions =
  Apollo.BaseMutationOptions<
    DigitalOnlyInStudioEnquireMutation,
    DigitalOnlyInStudioEnquireMutationVariables
  >
export const EnquireAboutOfferingDocument = gql`
  mutation enquireAboutOffering(
    $input: EnquireAboutOnlineProgramInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    enquireAboutOnlineProgram(
      input: $input
      role: $role
      _cacheKey: $_cacheKey
    ) {
      ... on EnquireAboutOnlineProgramResult {
        lastEnquiredOn
        onlineProgramid
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type EnquireAboutOfferingMutationFn = Apollo.MutationFunction<
  EnquireAboutOfferingMutation,
  EnquireAboutOfferingMutationVariables
>

/**
 * __useEnquireAboutOfferingMutation__
 *
 * To run a mutation, you first call `useEnquireAboutOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnquireAboutOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enquireAboutOfferingMutation, { data, loading, error }] = useEnquireAboutOfferingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useEnquireAboutOfferingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnquireAboutOfferingMutation,
    EnquireAboutOfferingMutationVariables
  >
) {
  return Apollo.useMutation<
    EnquireAboutOfferingMutation,
    EnquireAboutOfferingMutationVariables
  >(EnquireAboutOfferingDocument, baseOptions)
}
export type EnquireAboutOfferingMutationHookResult = ReturnType<
  typeof useEnquireAboutOfferingMutation
>
export type EnquireAboutOfferingMutationResult =
  Apollo.MutationResult<EnquireAboutOfferingMutation>
export type EnquireAboutOfferingMutationOptions = Apollo.BaseMutationOptions<
  EnquireAboutOfferingMutation,
  EnquireAboutOfferingMutationVariables
>
export const EnquireAboutProgramDocument = gql`
  mutation enquireAboutProgram(
    $input: EnquireAboutProgramInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    enquireAboutProgram(input: $input, role: $role, _cacheKey: $_cacheKey) {
      ... on EnquireAboutProgramResult {
        lastEnquiredOn
        programId
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type EnquireAboutProgramMutationFn = Apollo.MutationFunction<
  EnquireAboutProgramMutation,
  EnquireAboutProgramMutationVariables
>

/**
 * __useEnquireAboutProgramMutation__
 *
 * To run a mutation, you first call `useEnquireAboutProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnquireAboutProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enquireAboutProgramMutation, { data, loading, error }] = useEnquireAboutProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useEnquireAboutProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnquireAboutProgramMutation,
    EnquireAboutProgramMutationVariables
  >
) {
  return Apollo.useMutation<
    EnquireAboutProgramMutation,
    EnquireAboutProgramMutationVariables
  >(EnquireAboutProgramDocument, baseOptions)
}
export type EnquireAboutProgramMutationHookResult = ReturnType<
  typeof useEnquireAboutProgramMutation
>
export type EnquireAboutProgramMutationResult =
  Apollo.MutationResult<EnquireAboutProgramMutation>
export type EnquireAboutProgramMutationOptions = Apollo.BaseMutationOptions<
  EnquireAboutProgramMutation,
  EnquireAboutProgramMutationVariables
>
export const EnquireAboutProgramInExploreProgramsDocument = gql`
  mutation enquireAboutProgramInExplorePrograms(
    $input: EnquireAboutExploreProgramsInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    enquireAboutProgramInExplorePrograms(
      input: $input
      role: $role
      _cacheKey: $_cacheKey
    ) {
      ... on EnquireAboutExploreProgramsResult {
        lastEnquiredOn
        programId
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type EnquireAboutProgramInExploreProgramsMutationFn =
  Apollo.MutationFunction<
    EnquireAboutProgramInExploreProgramsMutation,
    EnquireAboutProgramInExploreProgramsMutationVariables
  >

/**
 * __useEnquireAboutProgramInExploreProgramsMutation__
 *
 * To run a mutation, you first call `useEnquireAboutProgramInExploreProgramsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnquireAboutProgramInExploreProgramsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enquireAboutProgramInExploreProgramsMutation, { data, loading, error }] = useEnquireAboutProgramInExploreProgramsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useEnquireAboutProgramInExploreProgramsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnquireAboutProgramInExploreProgramsMutation,
    EnquireAboutProgramInExploreProgramsMutationVariables
  >
) {
  return Apollo.useMutation<
    EnquireAboutProgramInExploreProgramsMutation,
    EnquireAboutProgramInExploreProgramsMutationVariables
  >(EnquireAboutProgramInExploreProgramsDocument, baseOptions)
}
export type EnquireAboutProgramInExploreProgramsMutationHookResult = ReturnType<
  typeof useEnquireAboutProgramInExploreProgramsMutation
>
export type EnquireAboutProgramInExploreProgramsMutationResult =
  Apollo.MutationResult<EnquireAboutProgramInExploreProgramsMutation>
export type EnquireAboutProgramInExploreProgramsMutationOptions =
  Apollo.BaseMutationOptions<
    EnquireAboutProgramInExploreProgramsMutation,
    EnquireAboutProgramInExploreProgramsMutationVariables
  >
export const GetAgreementDocumentDocument = gql`
  query getAgreementDocument(
    $input: GetSignedAgreementInput!
    $_cacheKey: String
    $versionOnly: Boolean = false
  ) {
    getAgreementDocument(input: $input, _cacheKey: $_cacheKey) {
      ... on AgreementDocumentResult {
        version
        content @skip(if: $versionOnly)
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetAgreementDocumentQuery__
 *
 * To run a query within a React component, call `useGetAgreementDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgreementDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgreementDocumentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *      versionOnly: // value for 'versionOnly'
 *   },
 * });
 */
export function useGetAgreementDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgreementDocumentQuery,
    GetAgreementDocumentQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAgreementDocumentQuery,
    GetAgreementDocumentQueryVariables
  >(GetAgreementDocumentDocument, baseOptions)
}
export function useGetAgreementDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgreementDocumentQuery,
    GetAgreementDocumentQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAgreementDocumentQuery,
    GetAgreementDocumentQueryVariables
  >(GetAgreementDocumentDocument, baseOptions)
}
export type GetAgreementDocumentQueryHookResult = ReturnType<
  typeof useGetAgreementDocumentQuery
>
export type GetAgreementDocumentLazyQueryHookResult = ReturnType<
  typeof useGetAgreementDocumentLazyQuery
>
export type GetAgreementDocumentQueryResult = Apollo.QueryResult<
  GetAgreementDocumentQuery,
  GetAgreementDocumentQueryVariables
>
export const GetCertificatesDocument = gql`
  query getCertificates($_cacheKey: String) {
    getCertificates(_cacheKey: $_cacheKey) {
      ... on CertificatesResult {
        certificates {
          id
          assessmentLevel
          name
          programId
          level
          certificateUrl
          certificateDetailUrl
          subscribeStatus
          type
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetCertificatesQuery__
 *
 * To run a query within a React component, call `useGetCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificatesQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetCertificatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCertificatesQuery,
    GetCertificatesQueryVariables
  >
) {
  return Apollo.useQuery<GetCertificatesQuery, GetCertificatesQueryVariables>(
    GetCertificatesDocument,
    baseOptions
  )
}
export function useGetCertificatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCertificatesQuery,
    GetCertificatesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCertificatesQuery,
    GetCertificatesQueryVariables
  >(GetCertificatesDocument, baseOptions)
}
export type GetCertificatesQueryHookResult = ReturnType<
  typeof useGetCertificatesQuery
>
export type GetCertificatesLazyQueryHookResult = ReturnType<
  typeof useGetCertificatesLazyQuery
>
export type GetCertificatesQueryResult = Apollo.QueryResult<
  GetCertificatesQuery,
  GetCertificatesQueryVariables
>
export const GetChainAffiliationDocument = gql`
  query getChainAffiliation(
    $input: GetChainAffiliationsInput!
    $_cacheKey: String
  ) {
    getChainAffiliations(input: $input, _cacheKey: $_cacheKey) {
      ... on GetChainAffiliationsResult {
        clubs {
          ...clubWithAffiliation
        }
      }
      ... on ErrorResult {
        type
        message
      }
    }
  }
  ${ClubWithAffiliationFragmentDoc}
`

/**
 * __useGetChainAffiliationQuery__
 *
 * To run a query within a React component, call `useGetChainAffiliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChainAffiliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChainAffiliationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetChainAffiliationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChainAffiliationQuery,
    GetChainAffiliationQueryVariables
  >
) {
  return Apollo.useQuery<
    GetChainAffiliationQuery,
    GetChainAffiliationQueryVariables
  >(GetChainAffiliationDocument, baseOptions)
}
export function useGetChainAffiliationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChainAffiliationQuery,
    GetChainAffiliationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetChainAffiliationQuery,
    GetChainAffiliationQueryVariables
  >(GetChainAffiliationDocument, baseOptions)
}
export type GetChainAffiliationQueryHookResult = ReturnType<
  typeof useGetChainAffiliationQuery
>
export type GetChainAffiliationLazyQueryHookResult = ReturnType<
  typeof useGetChainAffiliationLazyQuery
>
export type GetChainAffiliationQueryResult = Apollo.QueryResult<
  GetChainAffiliationQuery,
  GetChainAffiliationQueryVariables
>
export const GetChainAffiliatedInstructorDocument = gql`
  query getChainAffiliatedInstructor(
    $input: GetChainInstructorAffiliatesInput
    $_cacheKey: String
  ) {
    getChainInstructorAffiliates(input: $input, _cacheKey: $_cacheKey) {
      ... on GetChainInstructorAffiliatesResult {
        __typename
        affiliates {
          clubAffiliates {
            ... on ClubAffiliates {
              ...managePeopleClubAffiliatesFields
            }
          }
          profile {
            ... on UserProfileTeaching {
              ...instructorProfileFields
            }
          }
          purchaseOrder
          user {
            ... on User {
              ...managePeopleUserAffiliateFields
            }
          }
        }
        hasPendingAffiliations
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ManagePeopleClubAffiliatesFieldsFragmentDoc}
  ${InstructorProfileFieldsFragmentDoc}
  ${ManagePeopleUserAffiliateFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetChainAffiliatedInstructorQuery__
 *
 * To run a query within a React component, call `useGetChainAffiliatedInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChainAffiliatedInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChainAffiliatedInstructorQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetChainAffiliatedInstructorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChainAffiliatedInstructorQuery,
    GetChainAffiliatedInstructorQueryVariables
  >
) {
  return Apollo.useQuery<
    GetChainAffiliatedInstructorQuery,
    GetChainAffiliatedInstructorQueryVariables
  >(GetChainAffiliatedInstructorDocument, baseOptions)
}
export function useGetChainAffiliatedInstructorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChainAffiliatedInstructorQuery,
    GetChainAffiliatedInstructorQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetChainAffiliatedInstructorQuery,
    GetChainAffiliatedInstructorQueryVariables
  >(GetChainAffiliatedInstructorDocument, baseOptions)
}
export type GetChainAffiliatedInstructorQueryHookResult = ReturnType<
  typeof useGetChainAffiliatedInstructorQuery
>
export type GetChainAffiliatedInstructorLazyQueryHookResult = ReturnType<
  typeof useGetChainAffiliatedInstructorLazyQuery
>
export type GetChainAffiliatedInstructorQueryResult = Apollo.QueryResult<
  GetChainAffiliatedInstructorQuery,
  GetChainAffiliatedInstructorQueryVariables
>
export const GetChainInstructorRequestsDocument = gql`
  query getChainInstructorRequests(
    $input: GetChainInstructorAffiliatesInput!
    $_cacheKey: String
  ) {
    getChainInstructorAffiliates(input: $input, _cacheKey: $_cacheKey) {
      ... on GetChainInstructorAffiliatesResult {
        __typename
        requests {
          clubId
          clubName
          distance
          distanceUnit
          id
          pendingStatus
          profile {
            ... on UserProfileTeaching {
              ...instructorProfileFields
            }
          }
          requestedBy
          requestedDate
          status
          user {
            ... on User {
              ...managePeopleUserAffiliateFields
            }
          }
        }
        hasPendingAffiliations
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${InstructorProfileFieldsFragmentDoc}
  ${ManagePeopleUserAffiliateFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetChainInstructorRequestsQuery__
 *
 * To run a query within a React component, call `useGetChainInstructorRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChainInstructorRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChainInstructorRequestsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetChainInstructorRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChainInstructorRequestsQuery,
    GetChainInstructorRequestsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetChainInstructorRequestsQuery,
    GetChainInstructorRequestsQueryVariables
  >(GetChainInstructorRequestsDocument, baseOptions)
}
export function useGetChainInstructorRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChainInstructorRequestsQuery,
    GetChainInstructorRequestsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetChainInstructorRequestsQuery,
    GetChainInstructorRequestsQueryVariables
  >(GetChainInstructorRequestsDocument, baseOptions)
}
export type GetChainInstructorRequestsQueryHookResult = ReturnType<
  typeof useGetChainInstructorRequestsQuery
>
export type GetChainInstructorRequestsLazyQueryHookResult = ReturnType<
  typeof useGetChainInstructorRequestsLazyQuery
>
export type GetChainInstructorRequestsQueryResult = Apollo.QueryResult<
  GetChainInstructorRequestsQuery,
  GetChainInstructorRequestsQueryVariables
>
export const GetChainUserDocument = gql`
  query getChainUser($_cacheKey: String) {
    getChainUser(_cacheKey: $_cacheKey) {
      ... on GetChainUser {
        chainLocations {
          ...clubProfileFields
        }
        isChainUser
      }
      ... on ErrorResult {
        type
        message
      }
    }
  }
  ${ClubProfileFieldsFragmentDoc}
`

/**
 * __useGetChainUserQuery__
 *
 * To run a query within a React component, call `useGetChainUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChainUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChainUserQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetChainUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChainUserQuery,
    GetChainUserQueryVariables
  >
) {
  return Apollo.useQuery<GetChainUserQuery, GetChainUserQueryVariables>(
    GetChainUserDocument,
    baseOptions
  )
}
export function useGetChainUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChainUserQuery,
    GetChainUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetChainUserQuery, GetChainUserQueryVariables>(
    GetChainUserDocument,
    baseOptions
  )
}
export type GetChainUserQueryHookResult = ReturnType<
  typeof useGetChainUserQuery
>
export type GetChainUserLazyQueryHookResult = ReturnType<
  typeof useGetChainUserLazyQuery
>
export type GetChainUserQueryResult = Apollo.QueryResult<
  GetChainUserQuery,
  GetChainUserQueryVariables
>
export const GetChainUserClubListingsDocument = gql`
  query getChainUserClubListings(
    $input: GetChainUserClubListingsInput!
    $_cacheKey: String
  ) {
    getChainUserClubListings(input: $input, _cacheKey: $_cacheKey) {
      ... on GetChainUserClubListingsResult {
        singleListings {
          ... on ClubSingleListing {
            ...singleListingFields
            programId
          }
        }
        multiListings {
          ... on ClubMultiListing {
            ...multiListingFields
            programsId
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${SingleListingFieldsFragmentDoc}
  ${MultiListingFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetChainUserClubListingsQuery__
 *
 * To run a query within a React component, call `useGetChainUserClubListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChainUserClubListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChainUserClubListingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetChainUserClubListingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChainUserClubListingsQuery,
    GetChainUserClubListingsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetChainUserClubListingsQuery,
    GetChainUserClubListingsQueryVariables
  >(GetChainUserClubListingsDocument, baseOptions)
}
export function useGetChainUserClubListingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChainUserClubListingsQuery,
    GetChainUserClubListingsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetChainUserClubListingsQuery,
    GetChainUserClubListingsQueryVariables
  >(GetChainUserClubListingsDocument, baseOptions)
}
export type GetChainUserClubListingsQueryHookResult = ReturnType<
  typeof useGetChainUserClubListingsQuery
>
export type GetChainUserClubListingsLazyQueryHookResult = ReturnType<
  typeof useGetChainUserClubListingsLazyQuery
>
export type GetChainUserClubListingsQueryResult = Apollo.QueryResult<
  GetChainUserClubListingsQuery,
  GetChainUserClubListingsQueryVariables
>
export const GetClubBusinessSupportContentDocument = gql`
  query getClubBusinessSupportContent($input: GetClubByIdInput!) {
    getClubById(input: $input) {
      ... on UserProfileClub {
        businessSupport {
          ...BusinessSupportSectionContent
        }
      }
      ... on ErrorResult {
        type
        message
      }
    }
  }
  ${BusinessSupportSectionContentFragmentDoc}
`

/**
 * __useGetClubBusinessSupportContentQuery__
 *
 * To run a query within a React component, call `useGetClubBusinessSupportContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubBusinessSupportContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubBusinessSupportContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClubBusinessSupportContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubBusinessSupportContentQuery,
    GetClubBusinessSupportContentQueryVariables
  >
) {
  return Apollo.useQuery<
    GetClubBusinessSupportContentQuery,
    GetClubBusinessSupportContentQueryVariables
  >(GetClubBusinessSupportContentDocument, baseOptions)
}
export function useGetClubBusinessSupportContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubBusinessSupportContentQuery,
    GetClubBusinessSupportContentQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetClubBusinessSupportContentQuery,
    GetClubBusinessSupportContentQueryVariables
  >(GetClubBusinessSupportContentDocument, baseOptions)
}
export type GetClubBusinessSupportContentQueryHookResult = ReturnType<
  typeof useGetClubBusinessSupportContentQuery
>
export type GetClubBusinessSupportContentLazyQueryHookResult = ReturnType<
  typeof useGetClubBusinessSupportContentLazyQuery
>
export type GetClubBusinessSupportContentQueryResult = Apollo.QueryResult<
  GetClubBusinessSupportContentQuery,
  GetClubBusinessSupportContentQueryVariables
>
export const GetClubInstructorAffiliatesDocument = gql`
  query getClubInstructorAffiliates(
    $input: GetClubInstructorAffiliatesInput!
    $_cacheKey: String
  ) {
    getClubInstructorAffiliates(input: $input, _cacheKey: $_cacheKey) {
      ... on GetClubInstructorAffiliatesResult {
        __typename
        affiliates {
          purchaseOrder
          actionedDate
          id
          requestedBy
          requestedDate
          status
          user {
            id
            phone
            email
            accountId
            market {
              name
              locale
              distanceUnit
            }
            lastName
            firstName
          }
          clubId
          profile {
            ... on UserProfileTeaching {
              ...instructorProfileFields
            }
          }
        }
        requests {
          id
          distance
          distanceUnit
          requestedDate
          requestedBy
          status
          pendingStatus
          user {
            id
            phone
            email
            accountId
            market {
              name
              locale
              distanceUnit
            }
            lastName
            firstName
          }
          clubId
          profile {
            ... on UserProfileTeaching {
              ...instructorProfileFields
            }
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${InstructorProfileFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetClubInstructorAffiliatesQuery__
 *
 * To run a query within a React component, call `useGetClubInstructorAffiliatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubInstructorAffiliatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubInstructorAffiliatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubInstructorAffiliatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubInstructorAffiliatesQuery,
    GetClubInstructorAffiliatesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetClubInstructorAffiliatesQuery,
    GetClubInstructorAffiliatesQueryVariables
  >(GetClubInstructorAffiliatesDocument, baseOptions)
}
export function useGetClubInstructorAffiliatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubInstructorAffiliatesQuery,
    GetClubInstructorAffiliatesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetClubInstructorAffiliatesQuery,
    GetClubInstructorAffiliatesQueryVariables
  >(GetClubInstructorAffiliatesDocument, baseOptions)
}
export type GetClubInstructorAffiliatesQueryHookResult = ReturnType<
  typeof useGetClubInstructorAffiliatesQuery
>
export type GetClubInstructorAffiliatesLazyQueryHookResult = ReturnType<
  typeof useGetClubInstructorAffiliatesLazyQuery
>
export type GetClubInstructorAffiliatesQueryResult = Apollo.QueryResult<
  GetClubInstructorAffiliatesQuery,
  GetClubInstructorAffiliatesQueryVariables
>
export const GetClubListingByIdDocument = gql`
  query getClubListingById($listingId: String!) {
    getClubListingById(listingId: $listingId) {
      ... on SearchClubJobListingsSingleResult {
        distance
        distanceUnit
        messageSentOn
        enquiredOn
        onWatchlist
        hasBeenReported
        listing {
          ... on ClubSingleListing {
            ...singleListingFields
          }
          ... on ClubMultiListing {
            ...multiListingFields
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${SingleListingFieldsFragmentDoc}
  ${MultiListingFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetClubListingByIdQuery__
 *
 * To run a query within a React component, call `useGetClubListingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubListingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubListingByIdQuery({
 *   variables: {
 *      listingId: // value for 'listingId'
 *   },
 * });
 */
export function useGetClubListingByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubListingByIdQuery,
    GetClubListingByIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetClubListingByIdQuery,
    GetClubListingByIdQueryVariables
  >(GetClubListingByIdDocument, baseOptions)
}
export function useGetClubListingByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubListingByIdQuery,
    GetClubListingByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetClubListingByIdQuery,
    GetClubListingByIdQueryVariables
  >(GetClubListingByIdDocument, baseOptions)
}
export type GetClubListingByIdQueryHookResult = ReturnType<
  typeof useGetClubListingByIdQuery
>
export type GetClubListingByIdLazyQueryHookResult = ReturnType<
  typeof useGetClubListingByIdLazyQuery
>
export type GetClubListingByIdQueryResult = Apollo.QueryResult<
  GetClubListingByIdQuery,
  GetClubListingByIdQueryVariables
>
export const GetClubListingsByClubIdDocument = gql`
  query getClubListingsByClubId(
    $input: GetClubListingsByClubIdInput!
    $_cacheKey: String
  ) {
    getClubListingsByClubId(input: $input, _cacheKey: $_cacheKey) {
      ... on GetClubListingsByClubIdResult {
        results {
          ... on ClubSingleListing {
            clubInfo {
              id
              name
              address {
                ...addressFields
              }
              profileImage {
                url
              }
              programs {
                ... on ClubProgram {
                  name
                }
              }
              hiring
              programIds
            }
            createdAt
            description
            expiringAt
            id
            searchAppearances
            updatedAt
            views
            isSubstituteListing
            program {
              code
              id
              initials
              name
            }
            status
            times {
              ...timePreferences
            }
            enquiriesCount
            enquiries {
              createdAt
            }
            programId
          }
          ... on ClubMultiListing {
            clubInfo {
              id
              name
              address {
                ...addressFields
              }
              profileImage {
                url
              }
              programs {
                ... on ClubProgram {
                  name
                }
              }
              hiring
              programIds
            }
            createdAt
            description
            expiringAt
            id
            searchAppearances
            programs {
              code
              id
              initials
              name
            }
            status
            updatedAt
            views
            enquiriesCount
            enquiries {
              createdAt
            }
            programsId
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
  ${TimePreferencesFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetClubListingsByClubIdQuery__
 *
 * To run a query within a React component, call `useGetClubListingsByClubIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubListingsByClubIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubListingsByClubIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubListingsByClubIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubListingsByClubIdQuery,
    GetClubListingsByClubIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetClubListingsByClubIdQuery,
    GetClubListingsByClubIdQueryVariables
  >(GetClubListingsByClubIdDocument, baseOptions)
}
export function useGetClubListingsByClubIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubListingsByClubIdQuery,
    GetClubListingsByClubIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetClubListingsByClubIdQuery,
    GetClubListingsByClubIdQueryVariables
  >(GetClubListingsByClubIdDocument, baseOptions)
}
export type GetClubListingsByClubIdQueryHookResult = ReturnType<
  typeof useGetClubListingsByClubIdQuery
>
export type GetClubListingsByClubIdLazyQueryHookResult = ReturnType<
  typeof useGetClubListingsByClubIdLazyQuery
>
export type GetClubListingsByClubIdQueryResult = Apollo.QueryResult<
  GetClubListingsByClubIdQuery,
  GetClubListingsByClubIdQueryVariables
>
export const GetClubManageMyPartnershipDocument = gql`
  query getClubManageMyPartnership($clubId: ID!, $_cacheKey: String) {
    getClubPrograms(clubId: $clubId, _cacheKey: $_cacheKey) {
      ... on ClubProgramsResult {
        exploreProgramsDetails {
          program
          instructorsCount
          lastEnquiredOn
        }
        clubProgramsDetails {
          program
          lastEnquiredOn
          status
          instructorsCount
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetClubManageMyPartnershipQuery__
 *
 * To run a query within a React component, call `useGetClubManageMyPartnershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubManageMyPartnershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubManageMyPartnershipQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubManageMyPartnershipQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubManageMyPartnershipQuery,
    GetClubManageMyPartnershipQueryVariables
  >
) {
  return Apollo.useQuery<
    GetClubManageMyPartnershipQuery,
    GetClubManageMyPartnershipQueryVariables
  >(GetClubManageMyPartnershipDocument, baseOptions)
}
export function useGetClubManageMyPartnershipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubManageMyPartnershipQuery,
    GetClubManageMyPartnershipQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetClubManageMyPartnershipQuery,
    GetClubManageMyPartnershipQueryVariables
  >(GetClubManageMyPartnershipDocument, baseOptions)
}
export type GetClubManageMyPartnershipQueryHookResult = ReturnType<
  typeof useGetClubManageMyPartnershipQuery
>
export type GetClubManageMyPartnershipLazyQueryHookResult = ReturnType<
  typeof useGetClubManageMyPartnershipLazyQuery
>
export type GetClubManageMyPartnershipQueryResult = Apollo.QueryResult<
  GetClubManageMyPartnershipQuery,
  GetClubManageMyPartnershipQueryVariables
>
export const GetClubProgramsDocument = gql`
  query getClubPrograms($clubId: ID!, $_cacheKey: String) {
    getClubPrograms(clubId: $clubId, _cacheKey: $_cacheKey) {
      ... on ClubProgramsResult {
        clubProgramsDetails {
          program
          lastEnquiredOn
          status
          instructorsCount
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetClubProgramsQuery__
 *
 * To run a query within a React component, call `useGetClubProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubProgramsQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubProgramsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubProgramsQuery,
    GetClubProgramsQueryVariables
  >
) {
  return Apollo.useQuery<GetClubProgramsQuery, GetClubProgramsQueryVariables>(
    GetClubProgramsDocument,
    baseOptions
  )
}
export function useGetClubProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubProgramsQuery,
    GetClubProgramsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetClubProgramsQuery,
    GetClubProgramsQueryVariables
  >(GetClubProgramsDocument, baseOptions)
}
export type GetClubProgramsQueryHookResult = ReturnType<
  typeof useGetClubProgramsQuery
>
export type GetClubProgramsLazyQueryHookResult = ReturnType<
  typeof useGetClubProgramsLazyQuery
>
export type GetClubProgramsQueryResult = Apollo.QueryResult<
  GetClubProgramsQuery,
  GetClubProgramsQueryVariables
>
export const GetClubSettingsDocument = gql`
  query getClubSettings($input: GetClubSettingsInput!, $_cacheKey: String) {
    getClubSettings(input: $input, _cacheKey: $_cacheKey) {
      ... on GetClubSettingsResult {
        id
        address {
          city
          state
          street
          country
          stateCode
          postalCode
          countryCode
          location {
            lat
            lon
          }
        }
        email {
          main
          affiliations
          jobListings
        }
        id
        name
        phone
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetClubSettingsQuery__
 *
 * To run a query within a React component, call `useGetClubSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubSettingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubSettingsQuery,
    GetClubSettingsQueryVariables
  >
) {
  return Apollo.useQuery<GetClubSettingsQuery, GetClubSettingsQueryVariables>(
    GetClubSettingsDocument,
    baseOptions
  )
}
export function useGetClubSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubSettingsQuery,
    GetClubSettingsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetClubSettingsQuery,
    GetClubSettingsQueryVariables
  >(GetClubSettingsDocument, baseOptions)
}
export type GetClubSettingsQueryHookResult = ReturnType<
  typeof useGetClubSettingsQuery
>
export type GetClubSettingsLazyQueryHookResult = ReturnType<
  typeof useGetClubSettingsLazyQuery
>
export type GetClubSettingsQueryResult = Apollo.QueryResult<
  GetClubSettingsQuery,
  GetClubSettingsQueryVariables
>
export const GetContactUsContentDocument = gql`
  query getContactUsContent(
    $input: GetContactUsContentInput!
    $_cacheKey: String
  ) {
    getContactUsContent(input: $input, _cacheKey: $_cacheKey) {
      ... on GetContactUsContentResult {
        result {
          section
          content {
            title
            subHeading
            contentText
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetContactUsContentQuery__
 *
 * To run a query within a React component, call `useGetContactUsContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactUsContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactUsContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetContactUsContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactUsContentQuery,
    GetContactUsContentQueryVariables
  >
) {
  return Apollo.useQuery<
    GetContactUsContentQuery,
    GetContactUsContentQueryVariables
  >(GetContactUsContentDocument, baseOptions)
}
export function useGetContactUsContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactUsContentQuery,
    GetContactUsContentQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetContactUsContentQuery,
    GetContactUsContentQueryVariables
  >(GetContactUsContentDocument, baseOptions)
}
export type GetContactUsContentQueryHookResult = ReturnType<
  typeof useGetContactUsContentQuery
>
export type GetContactUsContentLazyQueryHookResult = ReturnType<
  typeof useGetContactUsContentLazyQuery
>
export type GetContactUsContentQueryResult = Apollo.QueryResult<
  GetContactUsContentQuery,
  GetContactUsContentQueryVariables
>
export const GetCurrentUserDocument = gql`
  query getCurrentUser($_cacheKey: String) {
    getCurrentUser(_cacheKey: $_cacheKey) {
      ... on UserResult {
        ...fullUserField
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${FullUserFieldFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    baseOptions
  )
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    baseOptions
  )
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>
export const GetCurrentUserPhotoUploadDataDocument = gql`
  query getCurrentUserPhotoUploadData($_cacheKey: String) {
    getCurrentUserPhotoUploadData(_cacheKey: $_cacheKey) {
      ... on GetCurrentUserPhotoUploadDataResult {
        url
        token
        __typename
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetCurrentUserPhotoUploadDataQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserPhotoUploadDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserPhotoUploadDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserPhotoUploadDataQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetCurrentUserPhotoUploadDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserPhotoUploadDataQuery,
    GetCurrentUserPhotoUploadDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCurrentUserPhotoUploadDataQuery,
    GetCurrentUserPhotoUploadDataQueryVariables
  >(GetCurrentUserPhotoUploadDataDocument, baseOptions)
}
export function useGetCurrentUserPhotoUploadDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserPhotoUploadDataQuery,
    GetCurrentUserPhotoUploadDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCurrentUserPhotoUploadDataQuery,
    GetCurrentUserPhotoUploadDataQueryVariables
  >(GetCurrentUserPhotoUploadDataDocument, baseOptions)
}
export type GetCurrentUserPhotoUploadDataQueryHookResult = ReturnType<
  typeof useGetCurrentUserPhotoUploadDataQuery
>
export type GetCurrentUserPhotoUploadDataLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserPhotoUploadDataLazyQuery
>
export type GetCurrentUserPhotoUploadDataQueryResult = Apollo.QueryResult<
  GetCurrentUserPhotoUploadDataQuery,
  GetCurrentUserPhotoUploadDataQueryVariables
>
export const GetCurrentUserPortfolioUploadDataDocument = gql`
  query getCurrentUserPortfolioUploadData(
    $input: GetCurrentUserPortfolioUploadDataInput!
    $_cacheKey: String
  ) {
    getCurrentUserPortfolioUploadData(input: $input, _cacheKey: $_cacheKey) {
      ... on GetCurrentUserPortfolioUploadDataResult {
        items {
          url
          token
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetCurrentUserPortfolioUploadDataQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserPortfolioUploadDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserPortfolioUploadDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserPortfolioUploadDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetCurrentUserPortfolioUploadDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentUserPortfolioUploadDataQuery,
    GetCurrentUserPortfolioUploadDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCurrentUserPortfolioUploadDataQuery,
    GetCurrentUserPortfolioUploadDataQueryVariables
  >(GetCurrentUserPortfolioUploadDataDocument, baseOptions)
}
export function useGetCurrentUserPortfolioUploadDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserPortfolioUploadDataQuery,
    GetCurrentUserPortfolioUploadDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCurrentUserPortfolioUploadDataQuery,
    GetCurrentUserPortfolioUploadDataQueryVariables
  >(GetCurrentUserPortfolioUploadDataDocument, baseOptions)
}
export type GetCurrentUserPortfolioUploadDataQueryHookResult = ReturnType<
  typeof useGetCurrentUserPortfolioUploadDataQuery
>
export type GetCurrentUserPortfolioUploadDataLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserPortfolioUploadDataLazyQuery
>
export type GetCurrentUserPortfolioUploadDataQueryResult = Apollo.QueryResult<
  GetCurrentUserPortfolioUploadDataQuery,
  GetCurrentUserPortfolioUploadDataQueryVariables
>
export const GetDashboardDocument = gql`
  query getDashboard($role: UserRoleInput!, $_cacheKey: String) {
    __typename
    getDashboard(role: $role, _cacheKey: $_cacheKey) {
      __typename
      ... on DashboardPageInstructorResponse {
        id
        translation {
          title
        }
        socialMedia {
          title
          items {
            id
            url
            type
            createdAt
            createdAtLabel
            content {
              type
              value
            }
          }
        }
        links {
          id
          imageSet {
            id
            desktop {
              id
              alt
              url
            }
            mobile {
              id
              alt
              url
            }
          }
          title
          subtitle
          url {
            type
            value
          }
        }
        translation {
          title
        }
        certifications {
          id
          status
          active
          lmqLevel
          link {
            id
            url {
              type
              value
            }
            text
          }
          steps {
            id
            label
            status
            action {
              id
              url {
                value
                type
              }
              text
            }
          }
          program {
            id
            name
            code
            initials
          }
        }
        promotions {
          id
          title
          image {
            id
            desktop {
              id
              alt
              url
              width
              height
            }
            mobile {
              id
              alt
              url
              width
              height
            }
          }
          description
          link {
            id
            text
            url {
              value
              type
            }
          }
        }
      }
      ... on DashboardPageClubResponse {
        id
        links {
          id
          imageSet {
            id
            desktop {
              id
              alt
              url
            }
            mobile {
              id
              alt
              url
            }
          }
          title
          subtitle
          url {
            type
            value
          }
        }
        promotions {
          id
          title
          image {
            id
            desktop {
              id
              alt
              url
              width
              height
            }
            mobile {
              id
              alt
              url
              width
              height
            }
          }
          description
          link {
            id
            text
            url {
              value
              type
            }
          }
        }
        partnership {
          ... on ClubPartnershipProgramStats {
            ...ClubDashboardProgramStats
          }
          ... on ClubPartnershipStandardContent {
            ...ClubDashboardStandardContent
          }
          ... on ClubPartnershipBusinessSupportContent {
            ...ClubDashboardBusinessSupportContent
          }
          ... on ClubPartnershipBusinessSupportContent {
            ...ClubDashboardBusinessSupportContent
          }
        }
        myTools {
          id
          imageSet {
            id
            desktop {
              id
              alt
              url
            }
            mobile {
              id
              alt
              url
            }
          }
          title
          subtitle
          url {
            type
            value
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ClubDashboardProgramStatsFragmentDoc}
  ${ClubDashboardStandardContentFragmentDoc}
  ${ClubDashboardBusinessSupportContentFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDashboardQuery,
    GetDashboardQueryVariables
  >
) {
  return Apollo.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(
    GetDashboardDocument,
    baseOptions
  )
}
export function useGetDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardQuery,
    GetDashboardQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(
    GetDashboardDocument,
    baseOptions
  )
}
export type GetDashboardQueryHookResult = ReturnType<
  typeof useGetDashboardQuery
>
export type GetDashboardLazyQueryHookResult = ReturnType<
  typeof useGetDashboardLazyQuery
>
export type GetDashboardQueryResult = Apollo.QueryResult<
  GetDashboardQuery,
  GetDashboardQueryVariables
>
export const GetEqualOpportunitiesPolicyDocument = gql`
  query getEqualOpportunitiesPolicy(
    $input: GetEqualOpportunitiesPolicyInput!
    $_cacheKey: String
  ) {
    getEqualOpportunitiesPolicy(input: $input, _cacheKey: $_cacheKey) {
      ... on GetEqualOpportunitiesPolicyResult {
        result {
          section
          content {
            title
            subHeading
            contentText
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetEqualOpportunitiesPolicyQuery__
 *
 * To run a query within a React component, call `useGetEqualOpportunitiesPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEqualOpportunitiesPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEqualOpportunitiesPolicyQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetEqualOpportunitiesPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEqualOpportunitiesPolicyQuery,
    GetEqualOpportunitiesPolicyQueryVariables
  >
) {
  return Apollo.useQuery<
    GetEqualOpportunitiesPolicyQuery,
    GetEqualOpportunitiesPolicyQueryVariables
  >(GetEqualOpportunitiesPolicyDocument, baseOptions)
}
export function useGetEqualOpportunitiesPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEqualOpportunitiesPolicyQuery,
    GetEqualOpportunitiesPolicyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetEqualOpportunitiesPolicyQuery,
    GetEqualOpportunitiesPolicyQueryVariables
  >(GetEqualOpportunitiesPolicyDocument, baseOptions)
}
export type GetEqualOpportunitiesPolicyQueryHookResult = ReturnType<
  typeof useGetEqualOpportunitiesPolicyQuery
>
export type GetEqualOpportunitiesPolicyLazyQueryHookResult = ReturnType<
  typeof useGetEqualOpportunitiesPolicyLazyQuery
>
export type GetEqualOpportunitiesPolicyQueryResult = Apollo.QueryResult<
  GetEqualOpportunitiesPolicyQuery,
  GetEqualOpportunitiesPolicyQueryVariables
>
export const GetFrequentlyAskedQuestionsDocument = gql`
  query getFrequentlyAskedQuestions(
    $input: GetFrequentlyAskedQuestionsInput!
    $_cacheKey: String
  ) {
    getFrequentlyAskedQuestions(input: $input, _cacheKey: $_cacheKey) {
      ... on GetFrequentlyAskedQuestionsResult {
        result {
          section
          content {
            title
            subHeading
            contentText
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetFrequentlyAskedQuestionsQuery__
 *
 * To run a query within a React component, call `useGetFrequentlyAskedQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFrequentlyAskedQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFrequentlyAskedQuestionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetFrequentlyAskedQuestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFrequentlyAskedQuestionsQuery,
    GetFrequentlyAskedQuestionsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetFrequentlyAskedQuestionsQuery,
    GetFrequentlyAskedQuestionsQueryVariables
  >(GetFrequentlyAskedQuestionsDocument, baseOptions)
}
export function useGetFrequentlyAskedQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFrequentlyAskedQuestionsQuery,
    GetFrequentlyAskedQuestionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetFrequentlyAskedQuestionsQuery,
    GetFrequentlyAskedQuestionsQueryVariables
  >(GetFrequentlyAskedQuestionsDocument, baseOptions)
}
export type GetFrequentlyAskedQuestionsQueryHookResult = ReturnType<
  typeof useGetFrequentlyAskedQuestionsQuery
>
export type GetFrequentlyAskedQuestionsLazyQueryHookResult = ReturnType<
  typeof useGetFrequentlyAskedQuestionsLazyQuery
>
export type GetFrequentlyAskedQuestionsQueryResult = Apollo.QueryResult<
  GetFrequentlyAskedQuestionsQuery,
  GetFrequentlyAskedQuestionsQueryVariables
>
export const GetHeaderContentDocument = gql`
  query getHeaderContent($input: GetHeaderInput!, $_cacheKey: String) {
    getHeaders(input: $input, _cacheKey: $_cacheKey) {
      ... on GetHeaderResult {
        __typename
        results {
          image {
            id
            desktop {
              id
              alt
              url
            }
            mobile {
              id
              alt
              url
            }
          }
          key
          title
          subHeading
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetHeaderContentQuery__
 *
 * To run a query within a React component, call `useGetHeaderContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeaderContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeaderContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetHeaderContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHeaderContentQuery,
    GetHeaderContentQueryVariables
  >
) {
  return Apollo.useQuery<GetHeaderContentQuery, GetHeaderContentQueryVariables>(
    GetHeaderContentDocument,
    baseOptions
  )
}
export function useGetHeaderContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHeaderContentQuery,
    GetHeaderContentQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetHeaderContentQuery,
    GetHeaderContentQueryVariables
  >(GetHeaderContentDocument, baseOptions)
}
export type GetHeaderContentQueryHookResult = ReturnType<
  typeof useGetHeaderContentQuery
>
export type GetHeaderContentLazyQueryHookResult = ReturnType<
  typeof useGetHeaderContentLazyQuery
>
export type GetHeaderContentQueryResult = Apollo.QueryResult<
  GetHeaderContentQuery,
  GetHeaderContentQueryVariables
>
export const GetInStudioManageOptionsDocument = gql`
  query getInStudioManageOptions($input: GetPickListInput!) {
    getPickLists(input: $input) {
      ... on GetPickListResult {
        __typename
        ...InStudioManageOptions
      }
      ... on ErrorResult {
        __typename
        ...errorResultFields
      }
    }
  }
  ${InStudioManageOptionsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetInStudioManageOptionsQuery__
 *
 * To run a query within a React component, call `useGetInStudioManageOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInStudioManageOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInStudioManageOptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInStudioManageOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInStudioManageOptionsQuery,
    GetInStudioManageOptionsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetInStudioManageOptionsQuery,
    GetInStudioManageOptionsQueryVariables
  >(GetInStudioManageOptionsDocument, baseOptions)
}
export function useGetInStudioManageOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInStudioManageOptionsQuery,
    GetInStudioManageOptionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetInStudioManageOptionsQuery,
    GetInStudioManageOptionsQueryVariables
  >(GetInStudioManageOptionsDocument, baseOptions)
}
export type GetInStudioManageOptionsQueryHookResult = ReturnType<
  typeof useGetInStudioManageOptionsQuery
>
export type GetInStudioManageOptionsLazyQueryHookResult = ReturnType<
  typeof useGetInStudioManageOptionsLazyQuery
>
export type GetInStudioManageOptionsQueryResult = Apollo.QueryResult<
  GetInStudioManageOptionsQuery,
  GetInStudioManageOptionsQueryVariables
>
export const GetUserAccountDocument = gql`
  query getUserAccount(
    $input: GetUserByIdInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    __typename
    getUserAccount(input: $input, role: $role, _cacheKey: $_cacheKey) {
      __typename
      ... on UserAccountSettingsResult {
        id
        address {
          ...addressFields
        }
        dateOfBirth
        email
        firstName
        lastName
        outlinks {
          url
          title
          _metadata {
            urlSource
          }
        }
        phone
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetUserAccountQuery__
 *
 * To run a query within a React component, call `useGetUserAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAccountQuery({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetUserAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAccountQuery,
    GetUserAccountQueryVariables
  >
) {
  return Apollo.useQuery<GetUserAccountQuery, GetUserAccountQueryVariables>(
    GetUserAccountDocument,
    baseOptions
  )
}
export function useGetUserAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAccountQuery,
    GetUserAccountQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetUserAccountQuery, GetUserAccountQueryVariables>(
    GetUserAccountDocument,
    baseOptions
  )
}
export type GetUserAccountQueryHookResult = ReturnType<
  typeof useGetUserAccountQuery
>
export type GetUserAccountLazyQueryHookResult = ReturnType<
  typeof useGetUserAccountLazyQuery
>
export type GetUserAccountQueryResult = Apollo.QueryResult<
  GetUserAccountQuery,
  GetUserAccountQueryVariables
>
export const GetInstructorEnquiriesDocument = gql`
  query getInstructorEnquiries(
    $role: UserRoleInput!
    $filter: UserEnquiriesFilter!
    $_cacheKey: String
  ) {
    getUserEnquiries(role: $role, filter: $filter, _cacheKey: $_cacheKey) {
      ... on GetUserEnquiriesResult {
        enquiries {
          listing {
            ... on EnquiryClubListingSingleResult {
              distance
              distanceUnit
              messageSentOn
              enquiredOn
              onWatchlist
              hasBeenReported
              listing {
                ... on ClubSingleListing {
                  __typename
                  clubInfo {
                    id
                    name
                    hiring
                    address {
                      ...addressFields
                    }
                    profileImage {
                      url
                    }
                    programs {
                      ... on ClubProgram {
                        name
                      }
                    }
                    programIds
                  }
                  createdAt
                  description
                  expiringAt
                  id
                  searchAppearances
                  updatedAt
                  views
                  isSubstituteListing
                  status
                  times {
                    ...timePreferences
                  }
                  enquiriesCount
                  program {
                    code
                    id
                    initials
                    name
                  }
                  programId
                }
                ... on ClubMultiListing {
                  __typename
                  clubInfo {
                    id
                    name
                    hiring
                    address {
                      ...addressFields
                    }
                    profileImage {
                      url
                    }
                    programs {
                      ... on ClubProgram {
                        name
                      }
                    }
                    programIds
                  }
                  createdAt
                  description
                  expiringAt
                  id
                  searchAppearances
                  status
                  updatedAt
                  views
                  enquiriesCount
                  programs {
                    code
                    id
                    initials
                    name
                  }
                  programsId
                }
                ... on ClubListingUnknown {
                  __typename
                  clubInfo {
                    id
                    name
                    hiring
                    address {
                      ...addressFields
                    }
                    profileImage {
                      url
                    }
                    programs {
                      ... on ClubProgram {
                        name
                      }
                    }
                  }
                  id
                }
              }
            }
          }
          message {
            clubId
            clubName
            id
            listingId
            message
            profileId
            readAt
            requestedBy
            sentAt
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
  ${TimePreferencesFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetInstructorEnquiriesQuery__
 *
 * To run a query within a React component, call `useGetInstructorEnquiriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorEnquiriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorEnquiriesQuery({
 *   variables: {
 *      role: // value for 'role'
 *      filter: // value for 'filter'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetInstructorEnquiriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInstructorEnquiriesQuery,
    GetInstructorEnquiriesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetInstructorEnquiriesQuery,
    GetInstructorEnquiriesQueryVariables
  >(GetInstructorEnquiriesDocument, baseOptions)
}
export function useGetInstructorEnquiriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInstructorEnquiriesQuery,
    GetInstructorEnquiriesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetInstructorEnquiriesQuery,
    GetInstructorEnquiriesQueryVariables
  >(GetInstructorEnquiriesDocument, baseOptions)
}
export type GetInstructorEnquiriesQueryHookResult = ReturnType<
  typeof useGetInstructorEnquiriesQuery
>
export type GetInstructorEnquiriesLazyQueryHookResult = ReturnType<
  typeof useGetInstructorEnquiriesLazyQuery
>
export type GetInstructorEnquiriesQueryResult = Apollo.QueryResult<
  GetInstructorEnquiriesQuery,
  GetInstructorEnquiriesQueryVariables
>
export const GetInstructorFacilitiesDocument = gql`
  query getInstructorFacilities(
    $input: GetInstructorClubAffiliatesInput!
    $_cacheKey: String
  ) {
    getInstructorClubAffiliates(input: $input, _cacheKey: $_cacheKey) {
      ... on GetInstructorClubAffiliatesResult {
        __typename
        affiliations {
          actionedDate
          clubId
          distance
          distanceUnit
          id
          profile {
            ... on UserProfileClub {
              ...clubProfileFields
            }
          }
          requestedDate
          requestedBy
          status
          pendingStatus
          isDigitalOnly
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ClubProfileFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetInstructorFacilitiesQuery__
 *
 * To run a query within a React component, call `useGetInstructorFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorFacilitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetInstructorFacilitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInstructorFacilitiesQuery,
    GetInstructorFacilitiesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetInstructorFacilitiesQuery,
    GetInstructorFacilitiesQueryVariables
  >(GetInstructorFacilitiesDocument, baseOptions)
}
export function useGetInstructorFacilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInstructorFacilitiesQuery,
    GetInstructorFacilitiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetInstructorFacilitiesQuery,
    GetInstructorFacilitiesQueryVariables
  >(GetInstructorFacilitiesDocument, baseOptions)
}
export type GetInstructorFacilitiesQueryHookResult = ReturnType<
  typeof useGetInstructorFacilitiesQuery
>
export type GetInstructorFacilitiesLazyQueryHookResult = ReturnType<
  typeof useGetInstructorFacilitiesLazyQuery
>
export type GetInstructorFacilitiesQueryResult = Apollo.QueryResult<
  GetInstructorFacilitiesQuery,
  GetInstructorFacilitiesQueryVariables
>
export const GetInstructorPortfolioDocument = gql`
  query getInstructorPortfolio($_cacheKey: String) {
    getCurrentUser(_cacheKey: $_cacheKey) {
      ... on UserResult {
        __typename
        profiles {
          ... on UserProfileTeaching {
            media {
              id
              url
            }
          }
          ... on UserProfileClub {
            media {
              id
              url
            }
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetInstructorPortfolioQuery__
 *
 * To run a query within a React component, call `useGetInstructorPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorPortfolioQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetInstructorPortfolioQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInstructorPortfolioQuery,
    GetInstructorPortfolioQueryVariables
  >
) {
  return Apollo.useQuery<
    GetInstructorPortfolioQuery,
    GetInstructorPortfolioQueryVariables
  >(GetInstructorPortfolioDocument, baseOptions)
}
export function useGetInstructorPortfolioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInstructorPortfolioQuery,
    GetInstructorPortfolioQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetInstructorPortfolioQuery,
    GetInstructorPortfolioQueryVariables
  >(GetInstructorPortfolioDocument, baseOptions)
}
export type GetInstructorPortfolioQueryHookResult = ReturnType<
  typeof useGetInstructorPortfolioQuery
>
export type GetInstructorPortfolioLazyQueryHookResult = ReturnType<
  typeof useGetInstructorPortfolioLazyQuery
>
export type GetInstructorPortfolioQueryResult = Apollo.QueryResult<
  GetInstructorPortfolioQuery,
  GetInstructorPortfolioQueryVariables
>
export const GetInstructorWatchlistDocument = gql`
  query getInstructorWatchlist($role: UserRoleInput!, $_cacheKey: String) {
    getInstructorWatchlist(role: $role, _cacheKey: $_cacheKey) {
      ... on ErrorResult {
        ...errorResultFields
      }
      ... on SearchClubListingsResult {
        total
        results {
          ... on SearchClubJobListingsSingleResult {
            distance
            distanceUnit
            messageSentOn
            enquiredOn
            onWatchlist
            hasBeenReported
            listing {
              ... on ClubSingleListing {
                ...singleListingFields
                programId
              }
              ... on ClubMultiListing {
                ...multiListingFields
                programsId
              }
            }
          }
          ... on SearchClubProfileSingleResult {
            distance
            distanceUnit
            onWatchlist
            hasBeenReported
            profile {
              ...clubProfileFields
            }
          }
        }
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
  ${SingleListingFieldsFragmentDoc}
  ${MultiListingFieldsFragmentDoc}
  ${ClubProfileFieldsFragmentDoc}
`

/**
 * __useGetInstructorWatchlistQuery__
 *
 * To run a query within a React component, call `useGetInstructorWatchlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorWatchlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorWatchlistQuery({
 *   variables: {
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetInstructorWatchlistQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInstructorWatchlistQuery,
    GetInstructorWatchlistQueryVariables
  >
) {
  return Apollo.useQuery<
    GetInstructorWatchlistQuery,
    GetInstructorWatchlistQueryVariables
  >(GetInstructorWatchlistDocument, baseOptions)
}
export function useGetInstructorWatchlistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInstructorWatchlistQuery,
    GetInstructorWatchlistQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetInstructorWatchlistQuery,
    GetInstructorWatchlistQueryVariables
  >(GetInstructorWatchlistDocument, baseOptions)
}
export type GetInstructorWatchlistQueryHookResult = ReturnType<
  typeof useGetInstructorWatchlistQuery
>
export type GetInstructorWatchlistLazyQueryHookResult = ReturnType<
  typeof useGetInstructorWatchlistLazyQuery
>
export type GetInstructorWatchlistQueryResult = Apollo.QueryResult<
  GetInstructorWatchlistQuery,
  GetInstructorWatchlistQueryVariables
>
export const GetUserLevelDocument = gql`
  query getUserLevel($_cacheKey: String) {
    getCurrentUser(_cacheKey: $_cacheKey) {
      ... on UserResult {
        user {
          isInstructorLead
          recordType
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetUserLevelQuery__
 *
 * To run a query within a React component, call `useGetUserLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLevelQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetUserLevelQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserLevelQuery,
    GetUserLevelQueryVariables
  >
) {
  return Apollo.useQuery<GetUserLevelQuery, GetUserLevelQueryVariables>(
    GetUserLevelDocument,
    baseOptions
  )
}
export function useGetUserLevelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserLevelQuery,
    GetUserLevelQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetUserLevelQuery, GetUserLevelQueryVariables>(
    GetUserLevelDocument,
    baseOptions
  )
}
export type GetUserLevelQueryHookResult = ReturnType<
  typeof useGetUserLevelQuery
>
export type GetUserLevelLazyQueryHookResult = ReturnType<
  typeof useGetUserLevelLazyQuery
>
export type GetUserLevelQueryResult = Apollo.QueryResult<
  GetUserLevelQuery,
  GetUserLevelQueryVariables
>
export const GetJobListingAvailableLocationsDocument = gql`
  query getJobListingAvailableLocations(
    $input: GetClubsAvailabilityOnListingInput!
    $_cacheKey: String
  ) {
    getClubsAvailabilityOnListing(input: $input, _cacheKey: $_cacheKey) {
      ... on GetClubsAvailabilityOnListingResult {
        clubsWithExistingListing {
          ...locations
        }
        allClubs {
          ...locations
        }
      }
      ... on ErrorResult {
        type
        message
      }
    }
  }
  ${LocationsFragmentDoc}
`

/**
 * __useGetJobListingAvailableLocationsQuery__
 *
 * To run a query within a React component, call `useGetJobListingAvailableLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobListingAvailableLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobListingAvailableLocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetJobListingAvailableLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJobListingAvailableLocationsQuery,
    GetJobListingAvailableLocationsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetJobListingAvailableLocationsQuery,
    GetJobListingAvailableLocationsQueryVariables
  >(GetJobListingAvailableLocationsDocument, baseOptions)
}
export function useGetJobListingAvailableLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobListingAvailableLocationsQuery,
    GetJobListingAvailableLocationsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetJobListingAvailableLocationsQuery,
    GetJobListingAvailableLocationsQueryVariables
  >(GetJobListingAvailableLocationsDocument, baseOptions)
}
export type GetJobListingAvailableLocationsQueryHookResult = ReturnType<
  typeof useGetJobListingAvailableLocationsQuery
>
export type GetJobListingAvailableLocationsLazyQueryHookResult = ReturnType<
  typeof useGetJobListingAvailableLocationsLazyQuery
>
export type GetJobListingAvailableLocationsQueryResult = Apollo.QueryResult<
  GetJobListingAvailableLocationsQuery,
  GetJobListingAvailableLocationsQueryVariables
>
export const GetNavigationDocument = gql`
  query getNavigation($input: GetNavigationInput!, $_cacheKey: String) {
    getNavigation(input: $input, _cacheKey: $_cacheKey) {
      __typename
      ... on Navigation {
        __typename
        appMenu {
          id
          name
          items {
            icon
            id
            label
            analyticsEvent
            url {
              type
              value
            }
            items {
              id
              label
              analyticsEvent
              url {
                type
                value
              }
            }
          }
        }
        userMenu {
          icon
          id
          label
          analyticsEvent
          url {
            type
            value
          }
          items {
            id
            label
            analyticsEvent
            url {
              type
              value
            }
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetNavigationQuery__
 *
 * To run a query within a React component, call `useGetNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavigationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetNavigationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNavigationQuery,
    GetNavigationQueryVariables
  >
) {
  return Apollo.useQuery<GetNavigationQuery, GetNavigationQueryVariables>(
    GetNavigationDocument,
    baseOptions
  )
}
export function useGetNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNavigationQuery,
    GetNavigationQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetNavigationQuery, GetNavigationQueryVariables>(
    GetNavigationDocument,
    baseOptions
  )
}
export type GetNavigationQueryHookResult = ReturnType<
  typeof useGetNavigationQuery
>
export type GetNavigationLazyQueryHookResult = ReturnType<
  typeof useGetNavigationLazyQuery
>
export type GetNavigationQueryResult = Apollo.QueryResult<
  GetNavigationQuery,
  GetNavigationQueryVariables
>
export const GetNotificationsDocument = gql`
  query getNotifications(
    $filter: DateTimeFilterInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    getNotifications(filter: $filter, role: $role, _cacheKey: $_cacheKey) {
      ... on GetNotificationsResult {
        ...notificationsResult
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${NotificationsResultFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >
) {
  return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    baseOptions
  )
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >(GetNotificationsDocument, baseOptions)
}
export type GetNotificationsQueryHookResult = ReturnType<
  typeof useGetNotificationsQuery
>
export type GetNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetNotificationsLazyQuery
>
export type GetNotificationsQueryResult = Apollo.QueryResult<
  GetNotificationsQuery,
  GetNotificationsQueryVariables
>
export const GetProfileDocument = gql`
  query getProfile(
    $input: GetProfileByIdInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    getProfileById(input: $input, role: $role, _cacheKey: $_cacheKey) {
      ... on GetProfileByIdResult {
        profile {
          ... on UserProfileTeaching {
            ...instructorProfileFields
          }
          ... on UserProfileClub {
            ...clubProfileFields
            programIds
          }
        }
        hasBeenReported
        lastClubMessageAt
        lastClubMessageIsIn48Hours
        lastMessageIn48HoursSentAt
        hasRecentlyDeclinedAffiliation
        hasExistingAffiliation
        onWatchlist
        instructorAffiliation {
          id
          clubId
          pendingStatus
          actionedDate
          requestedDate
          requestedBy
          role
          userId
          status
        }
        profileUser {
          id
          email
          market {
            name
            locale
            distanceUnit
            programs {
              id
              name
              code
              initials
            }
          }
          lastName
          firstName
          accountId
          phone
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${InstructorProfileFieldsFragmentDoc}
  ${ClubProfileFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >
) {
  return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    baseOptions
  )
}
export function useGetProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    baseOptions
  )
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>
export type GetProfileLazyQueryHookResult = ReturnType<
  typeof useGetProfileLazyQuery
>
export type GetProfileQueryResult = Apollo.QueryResult<
  GetProfileQuery,
  GetProfileQueryVariables
>
export const GetProfileMediaDocument = gql`
  query getProfileMedia(
    $input: GetProfileByIdInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    getProfileById(input: $input, role: $role, _cacheKey: $_cacheKey) {
      ... on GetProfileByIdResult {
        profile {
          ... on UserProfileTeaching {
            media {
              ...imageFields
            }
          }
          ... on UserProfileClub {
            media {
              ...imageFields
            }
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ImageFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetProfileMediaQuery__
 *
 * To run a query within a React component, call `useGetProfileMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileMediaQuery({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetProfileMediaQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProfileMediaQuery,
    GetProfileMediaQueryVariables
  >
) {
  return Apollo.useQuery<GetProfileMediaQuery, GetProfileMediaQueryVariables>(
    GetProfileMediaDocument,
    baseOptions
  )
}
export function useGetProfileMediaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileMediaQuery,
    GetProfileMediaQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetProfileMediaQuery,
    GetProfileMediaQueryVariables
  >(GetProfileMediaDocument, baseOptions)
}
export type GetProfileMediaQueryHookResult = ReturnType<
  typeof useGetProfileMediaQuery
>
export type GetProfileMediaLazyQueryHookResult = ReturnType<
  typeof useGetProfileMediaLazyQuery
>
export type GetProfileMediaQueryResult = Apollo.QueryResult<
  GetProfileMediaQuery,
  GetProfileMediaQueryVariables
>
export const GetProfilesDocument = gql`
  query getProfiles($_cacheKey: String) {
    getCurrentUser(_cacheKey: $_cacheKey) {
      ... on UserResult {
        id
        __typename
        profiles {
          ... on UserProfileTeaching {
            ...instructorProfileFields
          }
          ... on UserProfileClub {
            ...clubProfileFields
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${InstructorProfileFieldsFragmentDoc}
  ${ClubProfileFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetProfilesQuery__
 *
 * To run a query within a React component, call `useGetProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfilesQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProfilesQuery,
    GetProfilesQueryVariables
  >
) {
  return Apollo.useQuery<GetProfilesQuery, GetProfilesQueryVariables>(
    GetProfilesDocument,
    baseOptions
  )
}
export function useGetProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfilesQuery,
    GetProfilesQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetProfilesQuery, GetProfilesQueryVariables>(
    GetProfilesDocument,
    baseOptions
  )
}
export type GetProfilesQueryHookResult = ReturnType<typeof useGetProfilesQuery>
export type GetProfilesLazyQueryHookResult = ReturnType<
  typeof useGetProfilesLazyQuery
>
export type GetProfilesQueryResult = Apollo.QueryResult<
  GetProfilesQuery,
  GetProfilesQueryVariables
>
export const GetSavedInstructorSearchDataDocument = gql`
  query GetSavedInstructorSearchData(
    $input: GetSavedInstructorSearchDataInput!
    $_cacheKey: String
  ) {
    getSavedInstructorSearchData(input: $input, _cacheKey: $_cacheKey) {
      ... on GetSavedInstructorSearchDataResult {
        results {
          ...savedInstructorSearch
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${SavedInstructorSearchFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetSavedInstructorSearchDataQuery__
 *
 * To run a query within a React component, call `useGetSavedInstructorSearchDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedInstructorSearchDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedInstructorSearchDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetSavedInstructorSearchDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSavedInstructorSearchDataQuery,
    GetSavedInstructorSearchDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetSavedInstructorSearchDataQuery,
    GetSavedInstructorSearchDataQueryVariables
  >(GetSavedInstructorSearchDataDocument, baseOptions)
}
export function useGetSavedInstructorSearchDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSavedInstructorSearchDataQuery,
    GetSavedInstructorSearchDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetSavedInstructorSearchDataQuery,
    GetSavedInstructorSearchDataQueryVariables
  >(GetSavedInstructorSearchDataDocument, baseOptions)
}
export type GetSavedInstructorSearchDataQueryHookResult = ReturnType<
  typeof useGetSavedInstructorSearchDataQuery
>
export type GetSavedInstructorSearchDataLazyQueryHookResult = ReturnType<
  typeof useGetSavedInstructorSearchDataLazyQuery
>
export type GetSavedInstructorSearchDataQueryResult = Apollo.QueryResult<
  GetSavedInstructorSearchDataQuery,
  GetSavedInstructorSearchDataQueryVariables
>
export const GetSharedNavigationDocument = gql`
  query getSharedNavigation($_cacheKey: String) {
    getSharedNavigation(_cacheKey: $_cacheKey) {
      ... on SharedNavigation {
        instructorNav {
          role
          navItems {
            key
            title
            description
            url {
              type
              value
            }
            image {
              id
              url
              alt
              height
              width
            }
          }
        }
        clubNav {
          role
          navItems {
            key
            title
            description
            url {
              type
              value
            }
            image {
              id
              url
              alt
              height
              width
            }
          }
        }
        websiteLink {
          type
          value
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetSharedNavigationQuery__
 *
 * To run a query within a React component, call `useGetSharedNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedNavigationQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetSharedNavigationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSharedNavigationQuery,
    GetSharedNavigationQueryVariables
  >
) {
  return Apollo.useQuery<
    GetSharedNavigationQuery,
    GetSharedNavigationQueryVariables
  >(GetSharedNavigationDocument, baseOptions)
}
export function useGetSharedNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSharedNavigationQuery,
    GetSharedNavigationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetSharedNavigationQuery,
    GetSharedNavigationQueryVariables
  >(GetSharedNavigationDocument, baseOptions)
}
export type GetSharedNavigationQueryHookResult = ReturnType<
  typeof useGetSharedNavigationQuery
>
export type GetSharedNavigationLazyQueryHookResult = ReturnType<
  typeof useGetSharedNavigationLazyQuery
>
export type GetSharedNavigationQueryResult = Apollo.QueryResult<
  GetSharedNavigationQuery,
  GetSharedNavigationQueryVariables
>
export const GetClubInstructorShortlistDocument = gql`
  query getClubInstructorShortlist(
    $input: GetProfileByIdInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    getProfileById(input: $input, role: $role, _cacheKey: $_cacheKey) {
      ... on GetProfileByIdResult {
        profile {
          ... on UserProfileClub {
            shortlist {
              id
              profile {
                ...instructorProfileFields
              }
              user {
                id
                lastName
                firstName
              }
              lastMessageIn48HoursSentAt
              distanceUnit
              distance
              hasExistingAffiliation
              hasRecentlyDeclinedAffiliation
              instructorAffiliation {
                id
                clubId
                pendingStatus
                actionedDate
                requestedDate
                requestedBy
                role
                userId
                status
              }
            }
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${InstructorProfileFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetClubInstructorShortlistQuery__
 *
 * To run a query within a React component, call `useGetClubInstructorShortlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubInstructorShortlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubInstructorShortlistQuery({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubInstructorShortlistQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubInstructorShortlistQuery,
    GetClubInstructorShortlistQueryVariables
  >
) {
  return Apollo.useQuery<
    GetClubInstructorShortlistQuery,
    GetClubInstructorShortlistQueryVariables
  >(GetClubInstructorShortlistDocument, baseOptions)
}
export function useGetClubInstructorShortlistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubInstructorShortlistQuery,
    GetClubInstructorShortlistQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetClubInstructorShortlistQuery,
    GetClubInstructorShortlistQueryVariables
  >(GetClubInstructorShortlistDocument, baseOptions)
}
export type GetClubInstructorShortlistQueryHookResult = ReturnType<
  typeof useGetClubInstructorShortlistQuery
>
export type GetClubInstructorShortlistLazyQueryHookResult = ReturnType<
  typeof useGetClubInstructorShortlistLazyQuery
>
export type GetClubInstructorShortlistQueryResult = Apollo.QueryResult<
  GetClubInstructorShortlistQuery,
  GetClubInstructorShortlistQueryVariables
>
export const GetUserDocument = gql`
  query getUser($_cacheKey: String) {
    getCurrentUser(_cacheKey: $_cacheKey) {
      ... on UserResult {
        id
        __typename
        distance
        distanceUnit
        user {
          id
          email
          market {
            name
            locale
            distanceUnit
            programs {
              id
              name
              code
              initials
            }
          }
          lastName
          firstName
          accountId
          phone
          signedAgreements {
            type
            signedOn
            version
          }
        }
        settings {
          locale
        }
        profiles {
          ... on UserProfileTeaching {
            id
            __typename
          }
          ... on UserProfileClub {
            id
            __typename
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    baseOptions
  )
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    baseOptions
  )
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>
export const GetVideoGuidelinesDocument = gql`
  query getVideoGuidelines($_cacheKey: String) {
    getVideoGuidelines(_cacheKey: $_cacheKey) {
      ... on GetVideoGuidelinesResult {
        result {
          section
          content {
            title
            subHeading
            contentText
          }
        }
        contactUsEmail
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetVideoGuidelinesQuery__
 *
 * To run a query within a React component, call `useGetVideoGuidelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoGuidelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoGuidelinesQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetVideoGuidelinesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVideoGuidelinesQuery,
    GetVideoGuidelinesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetVideoGuidelinesQuery,
    GetVideoGuidelinesQueryVariables
  >(GetVideoGuidelinesDocument, baseOptions)
}
export function useGetVideoGuidelinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVideoGuidelinesQuery,
    GetVideoGuidelinesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetVideoGuidelinesQuery,
    GetVideoGuidelinesQueryVariables
  >(GetVideoGuidelinesDocument, baseOptions)
}
export type GetVideoGuidelinesQueryHookResult = ReturnType<
  typeof useGetVideoGuidelinesQuery
>
export type GetVideoGuidelinesLazyQueryHookResult = ReturnType<
  typeof useGetVideoGuidelinesLazyQuery
>
export type GetVideoGuidelinesQueryResult = Apollo.QueryResult<
  GetVideoGuidelinesQuery,
  GetVideoGuidelinesQueryVariables
>
export const GetWalkthroughContentDocument = gql`
  query getWalkthroughContent($role: UserRoleInput!, $_cacheKey: String) {
    getWalkthrough(role: $role, _cacheKey: $_cacheKey) {
      ... on Walkthrough {
        pages {
          title
          body
          image {
            desktop {
              ...imageFields
            }
            mobile {
              ...imageFields
            }
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ImageFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useGetWalkthroughContentQuery__
 *
 * To run a query within a React component, call `useGetWalkthroughContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalkthroughContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalkthroughContentQuery({
 *   variables: {
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetWalkthroughContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWalkthroughContentQuery,
    GetWalkthroughContentQueryVariables
  >
) {
  return Apollo.useQuery<
    GetWalkthroughContentQuery,
    GetWalkthroughContentQueryVariables
  >(GetWalkthroughContentDocument, baseOptions)
}
export function useGetWalkthroughContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalkthroughContentQuery,
    GetWalkthroughContentQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetWalkthroughContentQuery,
    GetWalkthroughContentQueryVariables
  >(GetWalkthroughContentDocument, baseOptions)
}
export type GetWalkthroughContentQueryHookResult = ReturnType<
  typeof useGetWalkthroughContentQuery
>
export type GetWalkthroughContentLazyQueryHookResult = ReturnType<
  typeof useGetWalkthroughContentLazyQuery
>
export type GetWalkthroughContentQueryResult = Apollo.QueryResult<
  GetWalkthroughContentQuery,
  GetWalkthroughContentQueryVariables
>
export const IncrementViewClubListingCountDocument = gql`
  mutation incrementViewClubListingCount(
    $input: IncrementViewClubListingCountInput!
    $_cacheKey: String
  ) {
    incrementViewClubListingCount(input: $input, _cacheKey: $_cacheKey) {
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type IncrementViewClubListingCountMutationFn = Apollo.MutationFunction<
  IncrementViewClubListingCountMutation,
  IncrementViewClubListingCountMutationVariables
>

/**
 * __useIncrementViewClubListingCountMutation__
 *
 * To run a mutation, you first call `useIncrementViewClubListingCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementViewClubListingCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementViewClubListingCountMutation, { data, loading, error }] = useIncrementViewClubListingCountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useIncrementViewClubListingCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncrementViewClubListingCountMutation,
    IncrementViewClubListingCountMutationVariables
  >
) {
  return Apollo.useMutation<
    IncrementViewClubListingCountMutation,
    IncrementViewClubListingCountMutationVariables
  >(IncrementViewClubListingCountDocument, baseOptions)
}
export type IncrementViewClubListingCountMutationHookResult = ReturnType<
  typeof useIncrementViewClubListingCountMutation
>
export type IncrementViewClubListingCountMutationResult =
  Apollo.MutationResult<IncrementViewClubListingCountMutation>
export type IncrementViewClubListingCountMutationOptions =
  Apollo.BaseMutationOptions<
    IncrementViewClubListingCountMutation,
    IncrementViewClubListingCountMutationVariables
  >
export const InitApplicationDocument = gql`
  query initApplication($featureFlags: [String!]!, $_cacheKey: String) {
    getUser: getCurrentUser(_cacheKey: $_cacheKey) {
      ... on UserResult {
        ...fullUserField
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
    getFeatureFlag(
      input: { featureFlags: $featureFlags }
      _cacheKey: $_cacheKey
    ) {
      ... on FeatureFlagResult {
        flags {
          featureFlag
          enabled
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${FullUserFieldFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useInitApplicationQuery__
 *
 * To run a query within a React component, call `useInitApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitApplicationQuery({
 *   variables: {
 *      featureFlags: // value for 'featureFlags'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useInitApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    InitApplicationQuery,
    InitApplicationQueryVariables
  >
) {
  return Apollo.useQuery<InitApplicationQuery, InitApplicationQueryVariables>(
    InitApplicationDocument,
    baseOptions
  )
}
export function useInitApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InitApplicationQuery,
    InitApplicationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    InitApplicationQuery,
    InitApplicationQueryVariables
  >(InitApplicationDocument, baseOptions)
}
export type InitApplicationQueryHookResult = ReturnType<
  typeof useInitApplicationQuery
>
export type InitApplicationLazyQueryHookResult = ReturnType<
  typeof useInitApplicationLazyQuery
>
export type InitApplicationQueryResult = Apollo.QueryResult<
  InitApplicationQuery,
  InitApplicationQueryVariables
>
export const ManagePeopleCreateAffiliationsDocument = gql`
  mutation managePeopleCreateAffiliations(
    $input: CreateAffiliationsInput!
    $_cacheKey: String
  ) {
    createAffiliations(input: $input, _cacheKey: $_cacheKey) {
      ... on ChainAffiliationsResult {
        successfulAffiliations {
          actionedDate
          clubId
          id
          latestActionInitiator
          pendingStatus
          requestedBy
          requestedDate
          role
          status
          userId
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type ManagePeopleCreateAffiliationsMutationFn = Apollo.MutationFunction<
  ManagePeopleCreateAffiliationsMutation,
  ManagePeopleCreateAffiliationsMutationVariables
>

/**
 * __useManagePeopleCreateAffiliationsMutation__
 *
 * To run a mutation, you first call `useManagePeopleCreateAffiliationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagePeopleCreateAffiliationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managePeopleCreateAffiliationsMutation, { data, loading, error }] = useManagePeopleCreateAffiliationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useManagePeopleCreateAffiliationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManagePeopleCreateAffiliationsMutation,
    ManagePeopleCreateAffiliationsMutationVariables
  >
) {
  return Apollo.useMutation<
    ManagePeopleCreateAffiliationsMutation,
    ManagePeopleCreateAffiliationsMutationVariables
  >(ManagePeopleCreateAffiliationsDocument, baseOptions)
}
export type ManagePeopleCreateAffiliationsMutationHookResult = ReturnType<
  typeof useManagePeopleCreateAffiliationsMutation
>
export type ManagePeopleCreateAffiliationsMutationResult =
  Apollo.MutationResult<ManagePeopleCreateAffiliationsMutation>
export type ManagePeopleCreateAffiliationsMutationOptions =
  Apollo.BaseMutationOptions<
    ManagePeopleCreateAffiliationsMutation,
    ManagePeopleCreateAffiliationsMutationVariables
  >
export const ManagePeopleRemoveAffiliationsDocument = gql`
  mutation managePeopleRemoveAffiliations(
    $input: DeleteAffiliationsInput!
    $_cacheKey: String
  ) {
    removeAffiliations(input: $input, _cacheKey: $_cacheKey) {
      ... on ChainAffiliationsRemoveRresult {
        receivedAffiliationIds
        removedAffiliationIds
        removedUserId
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type ManagePeopleRemoveAffiliationsMutationFn = Apollo.MutationFunction<
  ManagePeopleRemoveAffiliationsMutation,
  ManagePeopleRemoveAffiliationsMutationVariables
>

/**
 * __useManagePeopleRemoveAffiliationsMutation__
 *
 * To run a mutation, you first call `useManagePeopleRemoveAffiliationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagePeopleRemoveAffiliationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managePeopleRemoveAffiliationsMutation, { data, loading, error }] = useManagePeopleRemoveAffiliationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useManagePeopleRemoveAffiliationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManagePeopleRemoveAffiliationsMutation,
    ManagePeopleRemoveAffiliationsMutationVariables
  >
) {
  return Apollo.useMutation<
    ManagePeopleRemoveAffiliationsMutation,
    ManagePeopleRemoveAffiliationsMutationVariables
  >(ManagePeopleRemoveAffiliationsDocument, baseOptions)
}
export type ManagePeopleRemoveAffiliationsMutationHookResult = ReturnType<
  typeof useManagePeopleRemoveAffiliationsMutation
>
export type ManagePeopleRemoveAffiliationsMutationResult =
  Apollo.MutationResult<ManagePeopleRemoveAffiliationsMutation>
export type ManagePeopleRemoveAffiliationsMutationOptions =
  Apollo.BaseMutationOptions<
    ManagePeopleRemoveAffiliationsMutation,
    ManagePeopleRemoveAffiliationsMutationVariables
  >
export const MarkNotificationsAsReadDocument = gql`
  mutation markNotificationsAsRead(
    $id: [ID!]!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    toggleNotificationsRead(
      input: { id: $id, read: true }
      role: $role
      _cacheKey: $_cacheKey
    ) {
      __typename
      ... on ToggleNotificationReadResult {
        __typename
        success
        failure
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type MarkNotificationsAsReadMutationFn = Apollo.MutationFunction<
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables
>

/**
 * __useMarkNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsAsReadMutation, { data, loading, error }] = useMarkNotificationsAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useMarkNotificationsAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationsAsReadMutation,
    MarkNotificationsAsReadMutationVariables
  >
) {
  return Apollo.useMutation<
    MarkNotificationsAsReadMutation,
    MarkNotificationsAsReadMutationVariables
  >(MarkNotificationsAsReadDocument, baseOptions)
}
export type MarkNotificationsAsReadMutationHookResult = ReturnType<
  typeof useMarkNotificationsAsReadMutation
>
export type MarkNotificationsAsReadMutationResult =
  Apollo.MutationResult<MarkNotificationsAsReadMutation>
export type MarkNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables
>
export const MarkNotificationsAsUnreadDocument = gql`
  mutation markNotificationsAsUnread(
    $id: [ID!]!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    toggleNotificationsRead(
      input: { id: $id, read: false }
      role: $role
      _cacheKey: $_cacheKey
    ) {
      ... on ToggleNotificationReadResult {
        __typename
        success
        failure
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type MarkNotificationsAsUnreadMutationFn = Apollo.MutationFunction<
  MarkNotificationsAsUnreadMutation,
  MarkNotificationsAsUnreadMutationVariables
>

/**
 * __useMarkNotificationsAsUnreadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsAsUnreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsAsUnreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsAsUnreadMutation, { data, loading, error }] = useMarkNotificationsAsUnreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useMarkNotificationsAsUnreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationsAsUnreadMutation,
    MarkNotificationsAsUnreadMutationVariables
  >
) {
  return Apollo.useMutation<
    MarkNotificationsAsUnreadMutation,
    MarkNotificationsAsUnreadMutationVariables
  >(MarkNotificationsAsUnreadDocument, baseOptions)
}
export type MarkNotificationsAsUnreadMutationHookResult = ReturnType<
  typeof useMarkNotificationsAsUnreadMutation
>
export type MarkNotificationsAsUnreadMutationResult =
  Apollo.MutationResult<MarkNotificationsAsUnreadMutation>
export type MarkNotificationsAsUnreadMutationOptions =
  Apollo.BaseMutationOptions<
    MarkNotificationsAsUnreadMutation,
    MarkNotificationsAsUnreadMutationVariables
  >
export const GetAtHomeItemsDocument = gql`
  query getAtHomeItems($input: GetClubByIdInput!, $_cacheKey: String) {
    getClubById(input: $input, _cacheKey: $_cacheKey) {
      ... on UserProfileClub {
        atHomeProducts {
          active {
            ...AtHomeProductTile
          }
          inactive {
            ...AtHomeProductTile
          }
        }
      }
      ... on ErrorResult {
        type
        message
      }
    }
  }
  ${AtHomeProductTileFragmentDoc}
`

/**
 * __useGetAtHomeItemsQuery__
 *
 * To run a query within a React component, call `useGetAtHomeItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAtHomeItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAtHomeItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetAtHomeItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAtHomeItemsQuery,
    GetAtHomeItemsQueryVariables
  >
) {
  return Apollo.useQuery<GetAtHomeItemsQuery, GetAtHomeItemsQueryVariables>(
    GetAtHomeItemsDocument,
    baseOptions
  )
}
export function useGetAtHomeItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAtHomeItemsQuery,
    GetAtHomeItemsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetAtHomeItemsQuery, GetAtHomeItemsQueryVariables>(
    GetAtHomeItemsDocument,
    baseOptions
  )
}
export type GetAtHomeItemsQueryHookResult = ReturnType<
  typeof useGetAtHomeItemsQuery
>
export type GetAtHomeItemsLazyQueryHookResult = ReturnType<
  typeof useGetAtHomeItemsLazyQuery
>
export type GetAtHomeItemsQueryResult = Apollo.QueryResult<
  GetAtHomeItemsQuery,
  GetAtHomeItemsQueryVariables
>
export const GetExploreProgramsPageDetailedProgramDocument = gql`
  query getExploreProgramsPageDetailedProgram(
    $role: UserRoleInput!
    $programName: ProgramName
    $_cacheKey: String
  ) {
    getExploreProgramsPage(
      role: $role
      programName: $programName
      _cacheKey: $_cacheKey
    ) {
      ... on ExploreProgramsPage {
        products {
          ...DetailedExploreProgramTile
        }
      }
      ... on ErrorResult {
        type
        message
      }
    }
  }
  ${DetailedExploreProgramTileFragmentDoc}
`

/**
 * __useGetExploreProgramsPageDetailedProgramQuery__
 *
 * To run a query within a React component, call `useGetExploreProgramsPageDetailedProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExploreProgramsPageDetailedProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExploreProgramsPageDetailedProgramQuery({
 *   variables: {
 *      role: // value for 'role'
 *      programName: // value for 'programName'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetExploreProgramsPageDetailedProgramQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExploreProgramsPageDetailedProgramQuery,
    GetExploreProgramsPageDetailedProgramQueryVariables
  >
) {
  return Apollo.useQuery<
    GetExploreProgramsPageDetailedProgramQuery,
    GetExploreProgramsPageDetailedProgramQueryVariables
  >(GetExploreProgramsPageDetailedProgramDocument, baseOptions)
}
export function useGetExploreProgramsPageDetailedProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExploreProgramsPageDetailedProgramQuery,
    GetExploreProgramsPageDetailedProgramQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetExploreProgramsPageDetailedProgramQuery,
    GetExploreProgramsPageDetailedProgramQueryVariables
  >(GetExploreProgramsPageDetailedProgramDocument, baseOptions)
}
export type GetExploreProgramsPageDetailedProgramQueryHookResult = ReturnType<
  typeof useGetExploreProgramsPageDetailedProgramQuery
>
export type GetExploreProgramsPageDetailedProgramLazyQueryHookResult =
  ReturnType<typeof useGetExploreProgramsPageDetailedProgramLazyQuery>
export type GetExploreProgramsPageDetailedProgramQueryResult =
  Apollo.QueryResult<
    GetExploreProgramsPageDetailedProgramQuery,
    GetExploreProgramsPageDetailedProgramQueryVariables
  >
export const RemoveFromShortlistDocument = gql`
  mutation removeFromShortlist(
    $input: RemoveFromShortlistInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    removeFromShortlist(input: $input, role: $role, _cacheKey: $_cacheKey) {
      ... on RemoveFromShortlistResult {
        shortlist {
          id
          profile {
            ...instructorProfileFields
          }
          user {
            id
            lastName
            firstName
          }
          lastMessageIn48HoursSentAt
          distanceUnit
          distance
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${InstructorProfileFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type RemoveFromShortlistMutationFn = Apollo.MutationFunction<
  RemoveFromShortlistMutation,
  RemoveFromShortlistMutationVariables
>

/**
 * __useRemoveFromShortlistMutation__
 *
 * To run a mutation, you first call `useRemoveFromShortlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromShortlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromShortlistMutation, { data, loading, error }] = useRemoveFromShortlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useRemoveFromShortlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFromShortlistMutation,
    RemoveFromShortlistMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveFromShortlistMutation,
    RemoveFromShortlistMutationVariables
  >(RemoveFromShortlistDocument, baseOptions)
}
export type RemoveFromShortlistMutationHookResult = ReturnType<
  typeof useRemoveFromShortlistMutation
>
export type RemoveFromShortlistMutationResult =
  Apollo.MutationResult<RemoveFromShortlistMutation>
export type RemoveFromShortlistMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromShortlistMutation,
  RemoveFromShortlistMutationVariables
>
export const ReportClubListingDocument = gql`
  mutation reportClubListing(
    $input: ReportClubListingInput!
    $_cacheKey: String
  ) {
    reportClubListing(input: $input, _cacheKey: $_cacheKey) {
      __typename
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type ReportClubListingMutationFn = Apollo.MutationFunction<
  ReportClubListingMutation,
  ReportClubListingMutationVariables
>

/**
 * __useReportClubListingMutation__
 *
 * To run a mutation, you first call `useReportClubListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportClubListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportClubListingMutation, { data, loading, error }] = useReportClubListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useReportClubListingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportClubListingMutation,
    ReportClubListingMutationVariables
  >
) {
  return Apollo.useMutation<
    ReportClubListingMutation,
    ReportClubListingMutationVariables
  >(ReportClubListingDocument, baseOptions)
}
export type ReportClubListingMutationHookResult = ReturnType<
  typeof useReportClubListingMutation
>
export type ReportClubListingMutationResult =
  Apollo.MutationResult<ReportClubListingMutation>
export type ReportClubListingMutationOptions = Apollo.BaseMutationOptions<
  ReportClubListingMutation,
  ReportClubListingMutationVariables
>
export const ReportClubProfileDocument = gql`
  mutation reportClubProfile(
    $input: ReportClubProfileInput!
    $_cacheKey: String
  ) {
    reportClubProfile(input: $input, _cacheKey: $_cacheKey) {
      __typename
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type ReportClubProfileMutationFn = Apollo.MutationFunction<
  ReportClubProfileMutation,
  ReportClubProfileMutationVariables
>

/**
 * __useReportClubProfileMutation__
 *
 * To run a mutation, you first call `useReportClubProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportClubProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportClubProfileMutation, { data, loading, error }] = useReportClubProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useReportClubProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportClubProfileMutation,
    ReportClubProfileMutationVariables
  >
) {
  return Apollo.useMutation<
    ReportClubProfileMutation,
    ReportClubProfileMutationVariables
  >(ReportClubProfileDocument, baseOptions)
}
export type ReportClubProfileMutationHookResult = ReturnType<
  typeof useReportClubProfileMutation
>
export type ReportClubProfileMutationResult =
  Apollo.MutationResult<ReportClubProfileMutation>
export type ReportClubProfileMutationOptions = Apollo.BaseMutationOptions<
  ReportClubProfileMutation,
  ReportClubProfileMutationVariables
>
export const RevertAppMetadataDocument = gql`
  mutation revertAppMetadata(
    $input: RevertAppMetadataInput!
    $_cacheKey: String
  ) {
    revertAppMetadata(input: $input, _cacheKey: $_cacheKey) {
      ... on AppMetadataResult {
        isSuccess
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type RevertAppMetadataMutationFn = Apollo.MutationFunction<
  RevertAppMetadataMutation,
  RevertAppMetadataMutationVariables
>

/**
 * __useRevertAppMetadataMutation__
 *
 * To run a mutation, you first call `useRevertAppMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertAppMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertAppMetadataMutation, { data, loading, error }] = useRevertAppMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useRevertAppMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevertAppMetadataMutation,
    RevertAppMetadataMutationVariables
  >
) {
  return Apollo.useMutation<
    RevertAppMetadataMutation,
    RevertAppMetadataMutationVariables
  >(RevertAppMetadataDocument, baseOptions)
}
export type RevertAppMetadataMutationHookResult = ReturnType<
  typeof useRevertAppMetadataMutation
>
export type RevertAppMetadataMutationResult =
  Apollo.MutationResult<RevertAppMetadataMutation>
export type RevertAppMetadataMutationOptions = Apollo.BaseMutationOptions<
  RevertAppMetadataMutation,
  RevertAppMetadataMutationVariables
>
export const SearchClubListingsDocument = gql`
  query searchClubListings(
    $filter: SearchClubListingsFilter!
    $options: SearchClubListingsOptions
    $_cacheKey: String
  ) {
    searchClubListings(
      filter: $filter
      options: $options
      _cacheKey: $_cacheKey
    ) {
      ... on ErrorResult {
        ...errorResultFields
      }
      ... on SearchClubListingsResult {
        total
        results {
          ... on SearchClubJobListingsSingleResult {
            distance
            distanceUnit
            messageSentOn
            enquiredOn
            onWatchlist
            hasBeenReported
            listing {
              ... on ClubSingleListing {
                ...singleListingFields
                programId
              }
              ... on ClubMultiListing {
                ...multiListingFields
                programsId
              }
            }
          }
          ... on SearchClubProfileSingleResult {
            distance
            distanceUnit
            onWatchlist
            hasBeenReported
            profile {
              ...clubProfileFields
            }
          }
        }
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
  ${SingleListingFieldsFragmentDoc}
  ${MultiListingFieldsFragmentDoc}
  ${ClubProfileFieldsFragmentDoc}
`

/**
 * __useSearchClubListingsQuery__
 *
 * To run a query within a React component, call `useSearchClubListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchClubListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchClubListingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      options: // value for 'options'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSearchClubListingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchClubListingsQuery,
    SearchClubListingsQueryVariables
  >
) {
  return Apollo.useQuery<
    SearchClubListingsQuery,
    SearchClubListingsQueryVariables
  >(SearchClubListingsDocument, baseOptions)
}
export function useSearchClubListingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchClubListingsQuery,
    SearchClubListingsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SearchClubListingsQuery,
    SearchClubListingsQueryVariables
  >(SearchClubListingsDocument, baseOptions)
}
export type SearchClubListingsQueryHookResult = ReturnType<
  typeof useSearchClubListingsQuery
>
export type SearchClubListingsLazyQueryHookResult = ReturnType<
  typeof useSearchClubListingsLazyQuery
>
export type SearchClubListingsQueryResult = Apollo.QueryResult<
  SearchClubListingsQuery,
  SearchClubListingsQueryVariables
>
export const SearchClubsDocument = gql`
  query searchClubs(
    $filter: SearchClubsFilter!
    $options: SearchClubsOptions
    $_cacheKey: String
  ) {
    searchClubs(filter: $filter, options: $options, _cacheKey: $_cacheKey) {
      ... on ErrorResult {
        ...errorResultFields
      }
      ... on SearchClubsResult {
        total
        results {
          ... on SearchClubsSingleResult {
            clubId
            profile {
              ...clubProfileFields
            }
            instructorAffiliation {
              id
              userId
              clubId
              requestedBy
              actionedDate
              status
              pendingStatus
              requestedDate
              latestActionInitiator
            }
            distance
            distanceUnit
          }
        }
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
  ${ClubProfileFieldsFragmentDoc}
`

/**
 * __useSearchClubsQuery__
 *
 * To run a query within a React component, call `useSearchClubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchClubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchClubsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      options: // value for 'options'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSearchClubsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchClubsQuery,
    SearchClubsQueryVariables
  >
) {
  return Apollo.useQuery<SearchClubsQuery, SearchClubsQueryVariables>(
    SearchClubsDocument,
    baseOptions
  )
}
export function useSearchClubsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchClubsQuery,
    SearchClubsQueryVariables
  >
) {
  return Apollo.useLazyQuery<SearchClubsQuery, SearchClubsQueryVariables>(
    SearchClubsDocument,
    baseOptions
  )
}
export type SearchClubsQueryHookResult = ReturnType<typeof useSearchClubsQuery>
export type SearchClubsLazyQueryHookResult = ReturnType<
  typeof useSearchClubsLazyQuery
>
export type SearchClubsQueryResult = Apollo.QueryResult<
  SearchClubsQuery,
  SearchClubsQueryVariables
>
export const SearchInstructorsDocument = gql`
  query searchInstructors(
    $filter: SearchInstructorsFilter!
    $options: SearchInstructorsOptions
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    searchInstructors(
      filter: $filter
      options: $options
      role: $role
      _cacheKey: $_cacheKey
    ) {
      ... on SearchInstructorsResult {
        ...searchInstructorsResult
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${SearchInstructorsResultFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`

/**
 * __useSearchInstructorsQuery__
 *
 * To run a query within a React component, call `useSearchInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInstructorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      options: // value for 'options'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSearchInstructorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchInstructorsQuery,
    SearchInstructorsQueryVariables
  >
) {
  return Apollo.useQuery<
    SearchInstructorsQuery,
    SearchInstructorsQueryVariables
  >(SearchInstructorsDocument, baseOptions)
}
export function useSearchInstructorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchInstructorsQuery,
    SearchInstructorsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SearchInstructorsQuery,
    SearchInstructorsQueryVariables
  >(SearchInstructorsDocument, baseOptions)
}
export type SearchInstructorsQueryHookResult = ReturnType<
  typeof useSearchInstructorsQuery
>
export type SearchInstructorsLazyQueryHookResult = ReturnType<
  typeof useSearchInstructorsLazyQuery
>
export type SearchInstructorsQueryResult = Apollo.QueryResult<
  SearchInstructorsQuery,
  SearchInstructorsQueryVariables
>
export const SendMessageDocument = gql`
  mutation sendMessage($input: SendMessageInput!, $_cacheKey: String) {
    sendMessage(input: $input, _cacheKey: $_cacheKey) {
      ... on SendMessageResult {
        sentAt
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type SendMessageMutationFn = Apollo.MutationFunction<
  SendMessageMutation,
  SendMessageMutationVariables
>

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMessageMutation,
    SendMessageMutationVariables
  >
) {
  return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(
    SendMessageDocument,
    baseOptions
  )
}
export type SendMessageMutationHookResult = ReturnType<
  typeof useSendMessageMutation
>
export type SendMessageMutationResult =
  Apollo.MutationResult<SendMessageMutation>
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
  SendMessageMutation,
  SendMessageMutationVariables
>
export const SetClubSettingsDocument = gql`
  mutation setClubSettings($input: SetClubSettingsInput!, $_cacheKey: String) {
    setClubSettings(input: $input, _cacheKey: $_cacheKey) {
      ... on SetClubSettingsResult {
        id
        address {
          ...addressFields
        }
        email {
          main
          affiliations
          jobListings
        }
        id
        name
        phone
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type SetClubSettingsMutationFn = Apollo.MutationFunction<
  SetClubSettingsMutation,
  SetClubSettingsMutationVariables
>

/**
 * __useSetClubSettingsMutation__
 *
 * To run a mutation, you first call `useSetClubSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClubSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClubSettingsMutation, { data, loading, error }] = useSetClubSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSetClubSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetClubSettingsMutation,
    SetClubSettingsMutationVariables
  >
) {
  return Apollo.useMutation<
    SetClubSettingsMutation,
    SetClubSettingsMutationVariables
  >(SetClubSettingsDocument, baseOptions)
}
export type SetClubSettingsMutationHookResult = ReturnType<
  typeof useSetClubSettingsMutation
>
export type SetClubSettingsMutationResult =
  Apollo.MutationResult<SetClubSettingsMutation>
export type SetClubSettingsMutationOptions = Apollo.BaseMutationOptions<
  SetClubSettingsMutation,
  SetClubSettingsMutationVariables
>
export const SetInstructorAvailabilityDocument = gql`
  mutation setInstructorAvailability(
    $input: SetInstructorAvailabilityInput!
    $_cacheKey: String
  ) {
    setInstructorAvailability(input: $input, _cacheKey: $_cacheKey) {
      ... on InstructorAvailability {
        value
        availableToSubstitute
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type SetInstructorAvailabilityMutationFn = Apollo.MutationFunction<
  SetInstructorAvailabilityMutation,
  SetInstructorAvailabilityMutationVariables
>

/**
 * __useSetInstructorAvailabilityMutation__
 *
 * To run a mutation, you first call `useSetInstructorAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInstructorAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInstructorAvailabilityMutation, { data, loading, error }] = useSetInstructorAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSetInstructorAvailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetInstructorAvailabilityMutation,
    SetInstructorAvailabilityMutationVariables
  >
) {
  return Apollo.useMutation<
    SetInstructorAvailabilityMutation,
    SetInstructorAvailabilityMutationVariables
  >(SetInstructorAvailabilityDocument, baseOptions)
}
export type SetInstructorAvailabilityMutationHookResult = ReturnType<
  typeof useSetInstructorAvailabilityMutation
>
export type SetInstructorAvailabilityMutationResult =
  Apollo.MutationResult<SetInstructorAvailabilityMutation>
export type SetInstructorAvailabilityMutationOptions =
  Apollo.BaseMutationOptions<
    SetInstructorAvailabilityMutation,
    SetInstructorAvailabilityMutationVariables
  >
export const SetInstructorWatchlistDocument = gql`
  mutation setInstructorWatchlist(
    $input: SetInstructorWatchlistInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    setInstructorWatchlist(input: $input, role: $role, _cacheKey: $_cacheKey) {
      ... on InstructorWatchlist {
        watchlist {
          clubId
          listingId
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type SetInstructorWatchlistMutationFn = Apollo.MutationFunction<
  SetInstructorWatchlistMutation,
  SetInstructorWatchlistMutationVariables
>

/**
 * __useSetInstructorWatchlistMutation__
 *
 * To run a mutation, you first call `useSetInstructorWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInstructorWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInstructorWatchlistMutation, { data, loading, error }] = useSetInstructorWatchlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSetInstructorWatchlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetInstructorWatchlistMutation,
    SetInstructorWatchlistMutationVariables
  >
) {
  return Apollo.useMutation<
    SetInstructorWatchlistMutation,
    SetInstructorWatchlistMutationVariables
  >(SetInstructorWatchlistDocument, baseOptions)
}
export type SetInstructorWatchlistMutationHookResult = ReturnType<
  typeof useSetInstructorWatchlistMutation
>
export type SetInstructorWatchlistMutationResult =
  Apollo.MutationResult<SetInstructorWatchlistMutation>
export type SetInstructorWatchlistMutationOptions = Apollo.BaseMutationOptions<
  SetInstructorWatchlistMutation,
  SetInstructorWatchlistMutationVariables
>
export const SetSavedInstructorSearchDataDocument = gql`
  mutation SetSavedInstructorSearchData(
    $input: SetSavedInstructorSearchDataInput!
    $_cacheKey: String
  ) {
    setSavedInstructorSearchData(input: $input, _cacheKey: $_cacheKey) {
      ... on SetSavedInstructorSearchDataResult {
        results {
          ...savedInstructorSearch
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${SavedInstructorSearchFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type SetSavedInstructorSearchDataMutationFn = Apollo.MutationFunction<
  SetSavedInstructorSearchDataMutation,
  SetSavedInstructorSearchDataMutationVariables
>

/**
 * __useSetSavedInstructorSearchDataMutation__
 *
 * To run a mutation, you first call `useSetSavedInstructorSearchDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSavedInstructorSearchDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSavedInstructorSearchDataMutation, { data, loading, error }] = useSetSavedInstructorSearchDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSetSavedInstructorSearchDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSavedInstructorSearchDataMutation,
    SetSavedInstructorSearchDataMutationVariables
  >
) {
  return Apollo.useMutation<
    SetSavedInstructorSearchDataMutation,
    SetSavedInstructorSearchDataMutationVariables
  >(SetSavedInstructorSearchDataDocument, baseOptions)
}
export type SetSavedInstructorSearchDataMutationHookResult = ReturnType<
  typeof useSetSavedInstructorSearchDataMutation
>
export type SetSavedInstructorSearchDataMutationResult =
  Apollo.MutationResult<SetSavedInstructorSearchDataMutation>
export type SetSavedInstructorSearchDataMutationOptions =
  Apollo.BaseMutationOptions<
    SetSavedInstructorSearchDataMutation,
    SetSavedInstructorSearchDataMutationVariables
  >
export const SetSignedAgreementDataDocument = gql`
  mutation setSignedAgreementData(
    $input: SetSignedAgreementDataInput!
    $_cacheKey: String
  ) {
    setSignedAgreementData(input: $input, _cacheKey: $_cacheKey) {
      ... on AgreementDocumentResult {
        agreementType: type
        signedOn
        version
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type SetSignedAgreementDataMutationFn = Apollo.MutationFunction<
  SetSignedAgreementDataMutation,
  SetSignedAgreementDataMutationVariables
>

/**
 * __useSetSignedAgreementDataMutation__
 *
 * To run a mutation, you first call `useSetSignedAgreementDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSignedAgreementDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSignedAgreementDataMutation, { data, loading, error }] = useSetSignedAgreementDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSetSignedAgreementDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSignedAgreementDataMutation,
    SetSignedAgreementDataMutationVariables
  >
) {
  return Apollo.useMutation<
    SetSignedAgreementDataMutation,
    SetSignedAgreementDataMutationVariables
  >(SetSignedAgreementDataDocument, baseOptions)
}
export type SetSignedAgreementDataMutationHookResult = ReturnType<
  typeof useSetSignedAgreementDataMutation
>
export type SetSignedAgreementDataMutationResult =
  Apollo.MutationResult<SetSignedAgreementDataMutation>
export type SetSignedAgreementDataMutationOptions = Apollo.BaseMutationOptions<
  SetSignedAgreementDataMutation,
  SetSignedAgreementDataMutationVariables
>
export const SetWalkthroughToSeenDocument = gql`
  mutation setWalkthroughToSeen($role: UserRoleInput!, $_cacheKey: String) {
    setWalkthroughToSeen(role: $role, _cacheKey: $_cacheKey) {
      ... on SetWalkthroughToSeenResult {
        success
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type SetWalkthroughToSeenMutationFn = Apollo.MutationFunction<
  SetWalkthroughToSeenMutation,
  SetWalkthroughToSeenMutationVariables
>

/**
 * __useSetWalkthroughToSeenMutation__
 *
 * To run a mutation, you first call `useSetWalkthroughToSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWalkthroughToSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWalkthroughToSeenMutation, { data, loading, error }] = useSetWalkthroughToSeenMutation({
 *   variables: {
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSetWalkthroughToSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetWalkthroughToSeenMutation,
    SetWalkthroughToSeenMutationVariables
  >
) {
  return Apollo.useMutation<
    SetWalkthroughToSeenMutation,
    SetWalkthroughToSeenMutationVariables
  >(SetWalkthroughToSeenDocument, baseOptions)
}
export type SetWalkthroughToSeenMutationHookResult = ReturnType<
  typeof useSetWalkthroughToSeenMutation
>
export type SetWalkthroughToSeenMutationResult =
  Apollo.MutationResult<SetWalkthroughToSeenMutation>
export type SetWalkthroughToSeenMutationOptions = Apollo.BaseMutationOptions<
  SetWalkthroughToSeenMutation,
  SetWalkthroughToSeenMutationVariables
>
export const UpdateAffiliateBillingDocument = gql`
  mutation updateAffiliateBilling(
    $input: PayForCertificationInput!
    $_cacheKey: String
  ) {
    payForCertification(input: $input, _cacheKey: $_cacheKey) {
      ... on PayForCertificationResult {
        results {
          certificate {
            active
            billedClubId
            id
            lastPurchase
            lmqLevel
            pricePerQuarter {
              amount
              currency
            }
            program {
              code
              id
              initials
              name
            }
            transactionStatus
            releaseStatus
            status
          }
          instructorName
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type UpdateAffiliateBillingMutationFn = Apollo.MutationFunction<
  UpdateAffiliateBillingMutation,
  UpdateAffiliateBillingMutationVariables
>

/**
 * __useUpdateAffiliateBillingMutation__
 *
 * To run a mutation, you first call `useUpdateAffiliateBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAffiliateBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAffiliateBillingMutation, { data, loading, error }] = useUpdateAffiliateBillingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateAffiliateBillingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAffiliateBillingMutation,
    UpdateAffiliateBillingMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateAffiliateBillingMutation,
    UpdateAffiliateBillingMutationVariables
  >(UpdateAffiliateBillingDocument, baseOptions)
}
export type UpdateAffiliateBillingMutationHookResult = ReturnType<
  typeof useUpdateAffiliateBillingMutation
>
export type UpdateAffiliateBillingMutationResult =
  Apollo.MutationResult<UpdateAffiliateBillingMutation>
export type UpdateAffiliateBillingMutationOptions = Apollo.BaseMutationOptions<
  UpdateAffiliateBillingMutation,
  UpdateAffiliateBillingMutationVariables
>
export const UpdateAffiliationDocument = gql`
  mutation updateAffiliation(
    $input: UpdateAffiliationInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    updateAffiliation(input: $input, role: $role, _cacheKey: $_cacheKey) {
      ... on AffiliationAsyncResult {
        status
        hasPendingAffiliations
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${ErrorResultFieldsFragmentDoc}
`
export type UpdateAffiliationMutationFn = Apollo.MutationFunction<
  UpdateAffiliationMutation,
  UpdateAffiliationMutationVariables
>

/**
 * __useUpdateAffiliationMutation__
 *
 * To run a mutation, you first call `useUpdateAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAffiliationMutation, { data, loading, error }] = useUpdateAffiliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateAffiliationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAffiliationMutation,
    UpdateAffiliationMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateAffiliationMutation,
    UpdateAffiliationMutationVariables
  >(UpdateAffiliationDocument, baseOptions)
}
export type UpdateAffiliationMutationHookResult = ReturnType<
  typeof useUpdateAffiliationMutation
>
export type UpdateAffiliationMutationResult =
  Apollo.MutationResult<UpdateAffiliationMutation>
export type UpdateAffiliationMutationOptions = Apollo.BaseMutationOptions<
  UpdateAffiliationMutation,
  UpdateAffiliationMutationVariables
>
export const UpdateClubListingDocument = gql`
  mutation updateClubListing(
    $input: UpdateClubListingInput!
    $_cacheKey: String
  ) {
    updateClubListing(input: $input, _cacheKey: $_cacheKey) {
      ... on ClubListingResult {
        results {
          ... on ClubSingleListing {
            ...singleListingFields
          }
          ... on ClubMultiListing {
            ...multiListingFields
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${SingleListingFieldsFragmentDoc}
  ${MultiListingFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type UpdateClubListingMutationFn = Apollo.MutationFunction<
  UpdateClubListingMutation,
  UpdateClubListingMutationVariables
>

/**
 * __useUpdateClubListingMutation__
 *
 * To run a mutation, you first call `useUpdateClubListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClubListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClubListingMutation, { data, loading, error }] = useUpdateClubListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateClubListingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClubListingMutation,
    UpdateClubListingMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateClubListingMutation,
    UpdateClubListingMutationVariables
  >(UpdateClubListingDocument, baseOptions)
}
export type UpdateClubListingMutationHookResult = ReturnType<
  typeof useUpdateClubListingMutation
>
export type UpdateClubListingMutationResult =
  Apollo.MutationResult<UpdateClubListingMutation>
export type UpdateClubListingMutationOptions = Apollo.BaseMutationOptions<
  UpdateClubListingMutation,
  UpdateClubListingMutationVariables
>
export const UpdateClubProfileDocument = gql`
  mutation updateClubProfile(
    $input: UpdateClubProfileInput!
    $_cacheKey: String
  ) {
    updateClubProfile(input: $input, _cacheKey: $_cacheKey) {
      ... on UserResult {
        ...fullUserField
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${FullUserFieldFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type UpdateClubProfileMutationFn = Apollo.MutationFunction<
  UpdateClubProfileMutation,
  UpdateClubProfileMutationVariables
>

/**
 * __useUpdateClubProfileMutation__
 *
 * To run a mutation, you first call `useUpdateClubProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClubProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClubProfileMutation, { data, loading, error }] = useUpdateClubProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateClubProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClubProfileMutation,
    UpdateClubProfileMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateClubProfileMutation,
    UpdateClubProfileMutationVariables
  >(UpdateClubProfileDocument, baseOptions)
}
export type UpdateClubProfileMutationHookResult = ReturnType<
  typeof useUpdateClubProfileMutation
>
export type UpdateClubProfileMutationResult =
  Apollo.MutationResult<UpdateClubProfileMutation>
export type UpdateClubProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateClubProfileMutation,
  UpdateClubProfileMutationVariables
>
export const FinishPortfolioUpdateDocument = gql`
  mutation finishPortfolioUpdate(
    $input: UpdateCurrentUserPortfolioUploadInput!
    $role: UserRoleInput!
    $_cacheKey: String
  ) {
    updateCurrentUserPortfolioUpload(
      input: $input
      role: $role
      _cacheKey: $_cacheKey
    ) {
      ... on UpdateCurrentUserPortfolioUploadResult {
        url
        profile {
          ... on UserProfileTeaching {
            ...instructorProfileFields
          }
          ... on UserProfileClub {
            ...clubProfileFields
          }
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${InstructorProfileFieldsFragmentDoc}
  ${ClubProfileFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type FinishPortfolioUpdateMutationFn = Apollo.MutationFunction<
  FinishPortfolioUpdateMutation,
  FinishPortfolioUpdateMutationVariables
>

/**
 * __useFinishPortfolioUpdateMutation__
 *
 * To run a mutation, you first call `useFinishPortfolioUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishPortfolioUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishPortfolioUpdateMutation, { data, loading, error }] = useFinishPortfolioUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useFinishPortfolioUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinishPortfolioUpdateMutation,
    FinishPortfolioUpdateMutationVariables
  >
) {
  return Apollo.useMutation<
    FinishPortfolioUpdateMutation,
    FinishPortfolioUpdateMutationVariables
  >(FinishPortfolioUpdateDocument, baseOptions)
}
export type FinishPortfolioUpdateMutationHookResult = ReturnType<
  typeof useFinishPortfolioUpdateMutation
>
export type FinishPortfolioUpdateMutationResult =
  Apollo.MutationResult<FinishPortfolioUpdateMutation>
export type FinishPortfolioUpdateMutationOptions = Apollo.BaseMutationOptions<
  FinishPortfolioUpdateMutation,
  FinishPortfolioUpdateMutationVariables
>
export const UpdateTeachingProfileDocument = gql`
  mutation updateTeachingProfile(
    $input: UpdateTeachingProfileInput!
    $_cacheKey: String
  ) {
    updateTeachingProfile(input: $input, _cacheKey: $_cacheKey) {
      ... on UserResult {
        ...fullUserField
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${FullUserFieldFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type UpdateTeachingProfileMutationFn = Apollo.MutationFunction<
  UpdateTeachingProfileMutation,
  UpdateTeachingProfileMutationVariables
>

/**
 * __useUpdateTeachingProfileMutation__
 *
 * To run a mutation, you first call `useUpdateTeachingProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeachingProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeachingProfileMutation, { data, loading, error }] = useUpdateTeachingProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateTeachingProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeachingProfileMutation,
    UpdateTeachingProfileMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateTeachingProfileMutation,
    UpdateTeachingProfileMutationVariables
  >(UpdateTeachingProfileDocument, baseOptions)
}
export type UpdateTeachingProfileMutationHookResult = ReturnType<
  typeof useUpdateTeachingProfileMutation
>
export type UpdateTeachingProfileMutationResult =
  Apollo.MutationResult<UpdateTeachingProfileMutation>
export type UpdateTeachingProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeachingProfileMutation,
  UpdateTeachingProfileMutationVariables
>
export const UpdateUserAccountDocument = gql`
  mutation updateUserAccount(
    $input: UpdateUserAccountInput!
    $_cacheKey: String
  ) {
    updateUserAccount(input: $input, _cacheKey: $_cacheKey) {
      ... on UserAccountSettingsResult {
        address {
          ...addressFields
        }
        dateOfBirth
        email
        firstName
        id
        lastName
        phone
        outlinks {
          url
          title
        }
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type UpdateUserAccountMutationFn = Apollo.MutationFunction<
  UpdateUserAccountMutation,
  UpdateUserAccountMutationVariables
>

/**
 * __useUpdateUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountMutation, { data, loading, error }] = useUpdateUserAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAccountMutation,
    UpdateUserAccountMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserAccountMutation,
    UpdateUserAccountMutationVariables
  >(UpdateUserAccountDocument, baseOptions)
}
export type UpdateUserAccountMutationHookResult = ReturnType<
  typeof useUpdateUserAccountMutation
>
export type UpdateUserAccountMutationResult =
  Apollo.MutationResult<UpdateUserAccountMutation>
export type UpdateUserAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserAccountMutation,
  UpdateUserAccountMutationVariables
>
export const UpdateUserPhotoDocument = gql`
  mutation updateUserPhoto($input: UpdateUserPhotoInput!, $_cacheKey: String) {
    updateUserPhoto(input: $input, _cacheKey: $_cacheKey) {
      ... on UpdateUserPhotoResult {
        profile {
          ... on UserProfileTeaching {
            ...instructorProfileFields
          }
          ... on UserProfileClub {
            ...clubProfileFields
          }
        }
        url
      }
      ... on ErrorResult {
        ...errorResultFields
      }
    }
  }
  ${InstructorProfileFieldsFragmentDoc}
  ${ClubProfileFieldsFragmentDoc}
  ${ErrorResultFieldsFragmentDoc}
`
export type UpdateUserPhotoMutationFn = Apollo.MutationFunction<
  UpdateUserPhotoMutation,
  UpdateUserPhotoMutationVariables
>

/**
 * __useUpdateUserPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateUserPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPhotoMutation, { data, loading, error }] = useUpdateUserPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateUserPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPhotoMutation,
    UpdateUserPhotoMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserPhotoMutation,
    UpdateUserPhotoMutationVariables
  >(UpdateUserPhotoDocument, baseOptions)
}
export type UpdateUserPhotoMutationHookResult = ReturnType<
  typeof useUpdateUserPhotoMutation
>
export type UpdateUserPhotoMutationResult =
  Apollo.MutationResult<UpdateUserPhotoMutation>
export type UpdateUserPhotoMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPhotoMutation,
  UpdateUserPhotoMutationVariables
>
export const FinalizeUploadDocument = gql`
  mutation finalizeUpload($input: FinalizeUploadInput!, $_cacheKey: String) {
    finalizeUpload(input: $input, _cacheKey: $_cacheKey) {
      ... on FinalizeUploadResult {
        isSuccess
        comment
        playback {
          playbackUrl
          token
        }
      }
      ... on ErrorResult {
        message
      }
    }
  }
`
export type FinalizeUploadMutationFn = Apollo.MutationFunction<
  FinalizeUploadMutation,
  FinalizeUploadMutationVariables
>

/**
 * __useFinalizeUploadMutation__
 *
 * To run a mutation, you first call `useFinalizeUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeUploadMutation, { data, loading, error }] = useFinalizeUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useFinalizeUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalizeUploadMutation,
    FinalizeUploadMutationVariables
  >
) {
  return Apollo.useMutation<
    FinalizeUploadMutation,
    FinalizeUploadMutationVariables
  >(FinalizeUploadDocument, baseOptions)
}
export type FinalizeUploadMutationHookResult = ReturnType<
  typeof useFinalizeUploadMutation
>
export type FinalizeUploadMutationResult =
  Apollo.MutationResult<FinalizeUploadMutation>
export type FinalizeUploadMutationOptions = Apollo.BaseMutationOptions<
  FinalizeUploadMutation,
  FinalizeUploadMutationVariables
>
export const GetPresignedUrlDocument = gql`
  query getPresignedUrl($input: GetPresignedUrlInput!, $_cacheKey: String) {
    getPresignedUrl(input: $input, _cacheKey: $_cacheKey) {
      ... on GetPresignedUrlResult {
        partSignedUrlList {
          partNumber
          url
        }
      }
      ... on ErrorResult {
        message
      }
    }
  }
`

/**
 * __useGetPresignedUrlQuery__
 *
 * To run a query within a React component, call `useGetPresignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresignedUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetPresignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPresignedUrlQuery,
    GetPresignedUrlQueryVariables
  >
) {
  return Apollo.useQuery<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>(
    GetPresignedUrlDocument,
    baseOptions
  )
}
export function useGetPresignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPresignedUrlQuery,
    GetPresignedUrlQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPresignedUrlQuery,
    GetPresignedUrlQueryVariables
  >(GetPresignedUrlDocument, baseOptions)
}
export type GetPresignedUrlQueryHookResult = ReturnType<
  typeof useGetPresignedUrlQuery
>
export type GetPresignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetPresignedUrlLazyQuery
>
export type GetPresignedUrlQueryResult = Apollo.QueryResult<
  GetPresignedUrlQuery,
  GetPresignedUrlQueryVariables
>
export const InitializeUploadDocument = gql`
  mutation initializeUpload(
    $input: InitializeUploadInput!
    $_cacheKey: String
  ) {
    initializeUpload(input: $input, _cacheKey: $_cacheKey) {
      ... on InitializeUploadResult {
        uploadId
      }
      ... on ErrorResult {
        message
      }
    }
  }
`
export type InitializeUploadMutationFn = Apollo.MutationFunction<
  InitializeUploadMutation,
  InitializeUploadMutationVariables
>

/**
 * __useInitializeUploadMutation__
 *
 * To run a mutation, you first call `useInitializeUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeUploadMutation, { data, loading, error }] = useInitializeUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useInitializeUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitializeUploadMutation,
    InitializeUploadMutationVariables
  >
) {
  return Apollo.useMutation<
    InitializeUploadMutation,
    InitializeUploadMutationVariables
  >(InitializeUploadDocument, baseOptions)
}
export type InitializeUploadMutationHookResult = ReturnType<
  typeof useInitializeUploadMutation
>
export type InitializeUploadMutationResult =
  Apollo.MutationResult<InitializeUploadMutation>
export type InitializeUploadMutationOptions = Apollo.BaseMutationOptions<
  InitializeUploadMutation,
  InitializeUploadMutationVariables
>

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AddClubListingResponse: ['ClubListingResult', 'ErrorResult'],
    AddEmergencyContactResponse: ['AddEmergencyContactResult', 'ErrorResult'],
    AddToShortlistResponse: ['AddToShortlistResult', 'ErrorResult'],
    AddTrainingOutcomeResponse: ['AddTrainingOutcomeResult', 'ErrorResult'],
    AffiliationAsyncResponse: ['AffiliationAsyncResult', 'ErrorResult'],
    AgreementDocumentResponse: ['AgreementDocumentResult', 'ErrorResult'],
    AppMetadataResponse: ['AppMetadataResult', 'ErrorResult'],
    BookedEventResponse: ['BookedEventResult', 'ErrorResult'],
    BookedEventsResponse: ['BookedEventsResult', 'ErrorResult'],
    BookEventResponse: ['BookEventResult', 'ErrorResult'],
    CertificatesResponse: ['CertificatesResult', 'ErrorResult'],
    ChainAffiliationsRemoveResponse: [
      'ChainAffiliationsRemoveRresult',
      'ErrorResult',
    ],
    ChainAffiliationsResponse: ['ChainAffiliationsResult', 'ErrorResult'],
    ChainUserResponse: ['ErrorResult', 'GetChainUser'],
    ClubListing: ['ClubMultiListing', 'ClubSingleListing'],
    ClubListingCommon: ['ClubMultiListing', 'ClubSingleListing'],
    ClubProgramContent: ['ClubProgram', 'ClubProgramInStudioEmptyState'],
    CreateClubListingsResponse: ['CreateClubListingsResult', 'ErrorResult'],
    CreateInstructorAccountResponse: [
      'CreateInstructorAccountResult',
      'ErrorResult',
    ],
    DateTimeFilteredResponse: ['GetNotificationsResult'],
    DeleteClubListingResponse: ['DeleteClubListingResult', 'ErrorResult'],
    DeleteClubListingsResponse: ['DeleteClubListingsResult', 'ErrorResult'],
    DeleteCurrentUserPortfolioImageResponse: [
      'DeleteCurrentUserPortfolioImageResult',
      'ErrorResult',
    ],
    DeleteNotificationResponse: ['DeleteNotificationResult', 'ErrorResult'],
    DynamoMigrateResponse: ['DynamoMigrateResult', 'ErrorResult'],
    EnquireAboutExploreProgramsResponse: [
      'EnquireAboutExploreProgramsResult',
      'ErrorResult',
    ],
    EnquireAboutInStudioProgramsResponse: [
      'EnquireAboutInStudioProgramsResult',
      'ErrorResult',
    ],
    EnquireAboutOnlineProgramResponse: [
      'EnquireAboutOnlineProgramResult',
      'ErrorResult',
    ],
    EnquireAboutProgramResponse: ['EnquireAboutProgramResult', 'ErrorResult'],
    EnquiryHistoryClubListing: [
      'ClubListingUnknown',
      'ClubMultiListing',
      'ClubSingleListing',
    ],
    EnsureUserProfileExistsResponse: ['ErrorResult', 'UserResult'],
    EventDetailsResponse: ['ErrorResult', 'EventDetailsResult'],
    FeatureFlagResponse: ['ErrorResult', 'FeatureFlagResult'],
    FinalizeUploadResponse: ['ErrorResult', 'FinalizeUploadResult'],
    GetAccountSettingsResponse: ['ErrorResult', 'UserAccountSettingsResult'],
    GetChainAffiliationsResponse: ['ErrorResult', 'GetChainAffiliationsResult'],
    GetChainInstructorAffiliatesResponse: [
      'ErrorResult',
      'GetChainInstructorAffiliatesResult',
    ],
    GetChainUserClubListingsResponse: [
      'ErrorResult',
      'GetChainUserClubListingsResult',
    ],
    GetClubAffiliationsResponse: ['ClubAffiliationsResult', 'ErrorResult'],
    GetClubByIdResponse: ['ErrorResult', 'UserProfileClub'],
    GetClubInstructorAffiliatesResponse: [
      'ErrorResult',
      'GetClubInstructorAffiliatesResult',
    ],
    GetClubListingByIdResponse: [
      'ErrorResult',
      'SearchClubJobListingsSingleResult',
    ],
    GetClubListingsByClubIdResponse: [
      'ErrorResult',
      'GetClubListingsByClubIdResult',
    ],
    GetClubNotificationsResponse: ['ErrorResult', 'GetClubNotificationsResult'],
    GetClubProgramsResponse: ['ClubProgramsResult', 'ErrorResult'],
    GetClubsAvailabilityOnListingResponse: [
      'ErrorResult',
      'GetClubsAvailabilityOnListingResult',
    ],
    GetClubSettingsResponse: ['ErrorResult', 'GetClubSettingsResult'],
    GetContactUsContentResponse: ['ErrorResult', 'GetContactUsContentResult'],
    GetCurrentUserPhotoUploadDataResponse: [
      'ErrorResult',
      'GetCurrentUserPhotoUploadDataResult',
    ],
    GetCurrentUserPortfolioUploadDataResponse: [
      'ErrorResult',
      'GetCurrentUserPortfolioUploadDataResult',
    ],
    GetDashboardResponse: [
      'DashboardPageClubResponse',
      'DashboardPageInstructorResponse',
      'ErrorResult',
    ],
    GetEqualOpportunitiesPolicyResponse: [
      'ErrorResult',
      'GetEqualOpportunitiesPolicyResult',
    ],
    GetExploreProgramsPageResult: ['ErrorResult', 'ExploreProgramsPage'],
    GetFrequentlyAskedQuestionsResponse: [
      'ErrorResult',
      'GetFrequentlyAskedQuestionsResult',
    ],
    GetHeaderResponse: ['ErrorResult', 'GetHeaderResult'],
    GetInstructorClubAffiliatesResponse: [
      'ErrorResult',
      'GetInstructorClubAffiliatesResult',
    ],
    GetNavigationResponse: ['ErrorResult', 'Navigation'],
    GetNotificationResponse: ['ErrorResult', 'Notification'],
    GetNotificationsResponse: ['ErrorResult', 'GetNotificationsResult'],
    GetPickListResponse: ['ErrorResult', 'GetPickListResult'],
    GetPresignedUrlResponse: ['ErrorResult', 'GetPresignedUrlResult'],
    GetPrivacySettingValuesResponse: [
      'ErrorResult',
      'GetPrivacySettingValuesResult',
    ],
    GetProfileByIdResponse: ['ErrorResult', 'GetProfileByIdResult'],
    GetSavedInstructorSearchDataResponse: [
      'ErrorResult',
      'GetSavedInstructorSearchDataResult',
    ],
    GetSharedNavigation: ['ErrorResult', 'SharedNavigation'],
    GetUserEnquiriesResponse: ['ErrorResult', 'GetUserEnquiriesResult'],
    GetUserResponse: ['ErrorResult', 'UserResult'],
    GetUserSessionResponse: ['ErrorResult', 'GetUserSessionResult'],
    GetVideoGuidelinesResponse: ['ErrorResult', 'GetVideoGuidelinesResult'],
    GetWalkthroughResponse: ['ErrorResult', 'Walkthrough'],
    IncrementViewClubListingCountResponse: ['ClubListingResult', 'ErrorResult'],
    InitializeUploadResponse: ['ErrorResult', 'InitializeUploadResult'],
    PartnershipContent: [
      'ClubPartnershipBusinessSupportContent',
      'ClubPartnershipProgramStats',
      'ClubPartnershipStandardContent',
    ],
    PayForCertificationResponse: ['ErrorResult', 'PayForCertificationResult'],
    ProgramInterface: ['ClubProgram', 'Program'],
    QwsBookedEventResponse: ['ErrorResult', 'QwsBookedEventResult'],
    QwsEventDetailsResponse: ['ErrorResult', 'QWSEventDetailsResult'],
    QwsImtInstructorBookingsResponse: [
      'ErrorResult',
      'QwsImtInstructorBookingsResult',
    ],
    QwsImtTrainerBookingsResponse: [
      'ErrorResult',
      'QwsImtTrainerBookingsResult',
    ],
    QwsTrainerBookedEventResponse: [
      'ErrorResult',
      'QwsTrainerBookedEventResult',
    ],
    RemoveFromShortlistResponse: ['ErrorResult', 'RemoveFromShortlistResult'],
    ReportClubListingResponse: [
      'ClubMultiListing',
      'ClubSingleListing',
      'ErrorResult',
    ],
    ReportClubProfileResponse: ['ClubProfile', 'ErrorResult'],
    RequestForExtensionResponse: ['ErrorResult', 'RequestForExtensionResult'],
    ResendPasswordResetEmailResponse: [
      'ErrorResult',
      'ResendPasswordResetEmailResult',
    ],
    SearchClubListingsResponse: ['ErrorResult', 'SearchClubListingsResult'],
    SearchClubListingsResultCommon: [
      'EnquiryClubListingSingleResult',
      'SearchClubJobListingsSingleResult',
      'SearchClubProfileSingleResult',
    ],
    SearchClubListingsSingleResult: [
      'SearchClubJobListingsSingleResult',
      'SearchClubProfileSingleResult',
    ],
    SearchClubListingsWithFilterResponse: [
      'ErrorResult',
      'SearchClubListingsWithFilterResult',
    ],
    SearchClubsResponse: ['ErrorResult', 'SearchClubsResult'],
    SearchClubsWithFilterResponse: [
      'ErrorResult',
      'SearchClubsWithFilterResult',
    ],
    SearchEventsResponse: ['ErrorResult', 'EventsResult'],
    SearchInstructorsResponse: ['ErrorResult', 'SearchInstructorsResult'],
    SendMessageResponse: ['ErrorResult', 'SendMessageResult'],
    SetClubSettingsResponse: ['ErrorResult', 'SetClubSettingsResult'],
    SetInstructorAvailabilityResponse: [
      'ErrorResult',
      'InstructorAvailability',
    ],
    SetInstructorTravelPreferencesResponse: [
      'ErrorResult',
      'InstructorPreferences',
    ],
    SetInstructorWatchlistResponse: ['ErrorResult', 'InstructorWatchlist'],
    SetPrivacySettingsResponse: ['ErrorResult', 'SetPrivacySettingsResult'],
    SetQwsSessionAttendancesResponse: [
      'ErrorResult',
      'SetQwsSessionAttendancesResult',
    ],
    SetSavedClubSearchDataResponse: [
      'ErrorResult',
      'SetSavedClubSearchDataResult',
    ],
    SetSavedInstructorSearchDataResponse: [
      'ErrorResult',
      'SetSavedInstructorSearchDataResult',
    ],
    SetWalkthroughToSeenResponse: ['ErrorResult', 'SetWalkthroughToSeenResult'],
    Step: [
      'AttendTrainingStep',
      'PreworkStep',
      'ReceivedCertificationOutcomeStep',
      'ReceivedTrainingOutcomeStep',
      'SubmitCertificationVideoStep',
      'SubmitOutcomeTrackStep',
      'TrainingBookedStep',
    ],
    TestPostUpsertClubResponse: ['ErrorResult', 'TestPostUpsertClubResult'],
    TestPostUpsertUserResponse: ['ErrorResult', 'TestPostUpsertUserResult'],
    ToggleNotificationReadResponse: [
      'ErrorResult',
      'ToggleNotificationReadResult',
    ],
    TrainerBookingResponse: ['ErrorResult', 'TrainerBookingResult'],
    TrainerBookingsResponse: ['ErrorResult', 'TrainerBookingsResult'],
    TrainingJourneyResponse: ['ErrorResult', 'TrainingJourneyResult'],
    TrainingJourneysResponse: ['ErrorResult', 'TrainingJourneysResult'],
    TrainingStep: [
      'AttendTrainingStep',
      'CertifiedStep',
      'ContactUsStep',
      'PreworkStep',
      'ReceivedCertificationOutcomeStep',
      'ReceivedTrainingOutcomeStep',
      'SubmitCertificationVideoStep',
      'SubmitOutcomeTrackStep',
      'TrainingBookedStep',
    ],
    UpdateAccountSettingsResponse: ['ErrorResult', 'UserAccountSettingsResult'],
    UpdateClubListingResponse: ['ClubListingResult', 'ErrorResult'],
    UpdateClubProfileResponse: ['ErrorResult', 'UserResult'],
    UpdateCurrentUserPortfolioUploadResponse: [
      'ErrorResult',
      'UpdateCurrentUserPortfolioUploadResult',
    ],
    UpdateProfileResponse: ['ErrorResult', 'UserResult'],
    UpdateTeachingProfileResponse: ['ErrorResult', 'UserResult'],
    UpdateUserPhotoResponse: ['ErrorResult', 'UpdateUserPhotoResult'],
    UpsertAgreementResponse: ['ErrorResult', 'UpsertAgreementResult'],
    UpsertClubResponse: ['ErrorResult', 'UpsertClubResult'],
    UpsertPricebookResponse: ['ErrorResult', 'UpsertPricebookResult'],
    UpsertUserResponse: ['ErrorResult', 'UpsertUserResult'],
    UserProfile: ['UserProfileClub', 'UserProfileTeaching'],
  },
}
export default result
