import React from 'react'
import { useMarket } from 'src/hooks/useMarket'
import { LMJP } from 'src/utils/constants'
import { AgreementType } from 'src/generated/graphql'
import { isClubProfile, isTeachingProfile } from 'src/utils/typeGuards'
import { AgreementGuardViewProps } from './AgreementGuard.types'
import { AgreementGuardContainer } from './AgreementGuard.container'

export const AgreementGuardView: React.FC<AgreementGuardViewProps> = ({
  fullUser,
  profile,
  children,
}) => {
  const { isMarketMatched: isLMJP } = useMarket(LMJP)

  const isClubView = !!fullUser?.user && !!profile && isClubProfile(profile)
  const isInstructorView =
    !!fullUser?.user && !!profile && isTeachingProfile(profile)

  const notApplicable =
    (!isClubView && !isInstructorView) || (isInstructorView && isLMJP)

  if (notApplicable) return <>{children}</>

  const agreementType = isClubView
    ? AgreementType.DataPrivacy
    : AgreementType.InstructorAgreement

  return (
    <AgreementGuardContainer agreementType={agreementType}>
      {children}
    </AgreementGuardContainer>
  )
}
