import React, { useEffect } from 'react'

const LoadCookieDeclarationScript: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.id = 'CookieDeclaration'
    script.src = import.meta.env.REACT_APP_COOKIE_DECLARATION_SCRIPT_URL
    script.type = 'text/javascript'
    script.async = true
    document.getElementById('cookie-declaration')?.appendChild(script)
  }, [])

  return <div id="cookie-declaration"></div>
}

export default LoadCookieDeclarationScript
