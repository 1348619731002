import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useMarket } from 'src/hooks/useMarket'

declare global {
  interface Window {
    // eslint-disable-next-line camelcase
    embedded_svc: any
  }

  // eslint-disable-next-line camelcase, no-var
  var embedded_svc: any
}

const SalesforceChat = () => {
  const { getUserMarket, getUserLocale } = useMarket()
  const market = getUserMarket()
  const locale = getUserLocale()

  const salesforceURL = import.meta.env.REACT_APP_SALESFORCE_URL
  const salesforceSitesUrl = import.meta.env.REACT_APP_SALESFORCE_SITES_URL
  const salesforceOrgId = import.meta.env.REACT_APP_SALESFORCE_ORG_ID
  const salesforceScriptUrl = import.meta.env.REACT_APP_SCRIPT_URL
  const salesforceLiveAgentUrl = import.meta.env.REACT_APP_LIVE_AGENT_URL
  useEffect(() => {
    if (market === 'Nordic') {
      const initESW = (gslbBaseURL: string | null) => {
        embedded_svc.settings.displayHelpButton = true
        embedded_svc.settings.language = locale
        embedded_svc.settings.extraPrechatInfo = [
          {
            entityFieldMaps: [
              {
                doCreate: false,
                doFind: true,
                fieldName: 'LastName',
                isExactMatch: true,
                label: 'Last Name',
              },
              {
                doCreate: false,
                doFind: true,
                fieldName: 'FirstName',
                isExactMatch: true,
                label: 'First Name',
              },
              {
                doCreate: false,
                doFind: true,
                fieldName: 'Email',
                isExactMatch: true,
                label: 'Email',
              },
            ],
            entityName: 'Contact',
            linkToEntityName: '',
          },
          {
            entityName: 'Case',
            entityFieldMaps: [
              {
                isExactMatch: false,
                fieldName: 'Chat_Last_Name__c',
                doCreate: true,
                doFind: false,
                label: 'Last Name',
              },
              {
                isExactMatch: false,
                fieldName: 'Chat_First_Name__c',
                doCreate: true,
                doFind: false,
                label: 'First Name',
              },
              {
                isExactMatch: false,
                fieldName: 'Chat_Email__c',
                doCreate: true,
                doFind: false,
                label: 'Email',
              },
            ],
          },
        ]
        embedded_svc.settings.enabledFeatures = ['LiveAgent']
        embedded_svc.settings.entryFeature = 'LiveAgent'
        embedded_svc.init(
          salesforceURL,
          salesforceSitesUrl,
          gslbBaseURL,
          salesforceOrgId,
          'Nordic_Web_Chat_Agents',
          {
            baseLiveAgentContentURL: `${salesforceLiveAgentUrl}/content`,
            deploymentId: '5722L000000XZAq',
            buttonId: '5732L000000TSTF',
            baseLiveAgentURL: `${salesforceLiveAgentUrl}/chat`,
            eswLiveAgentDevName:
              'EmbeddedServiceLiveAgent_Parent04I2L0000008OJ3UAM_175f508fda5',
            isOfflineSupportEnabled: true,
          }
        )
      }

      if (!window.embedded_svc) {
        const script = document.createElement('script')
        script.setAttribute('src', salesforceScriptUrl)
        script.onload = () => {
          initESW(null)
        }
        document.body.appendChild(script)
      } else {
        initESW(salesforceURL)
      }
    }
    // disable eslint since salesforce vars are prcocess vars
    // and won't change until the app is reloaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market])

  return (
    <>
      {market === 'nordic' && (
        <Helmet>
          <style type="text/css">
            {`
              .embeddedServiceHelpButton .helpButton .uiButton {
                background-color: #00a077;
                font-family: 'Arial', sans-serif;
              }
              .embeddedServiceHelpButton .helpButton .uiButton:focus {
                outline: 1px solid #00a077;
              }
            `}
          </style>
          <script type="text/javascript" src={salesforceScriptUrl}></script>
        </Helmet>
      )}
    </>
  )
}

export default SalesforceChat
