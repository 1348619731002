import * as Types from 'src/generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export const InStudioClubProgramFragmentDoc = gql`
    fragment InStudioClubProgram on ClubProgram {
  id
  code
  name
  status
  initials
  programType
  instructorCount {
    numberOfInstructors
    belowRecommendedAmount
    lastEnquiredOn
  }
}
    `;
export const UrlFieldsFragmentDoc = gql`
    fragment urlFields on URL {
  type
  value
}
    `;
export const LinkFieldsFragmentDoc = gql`
    fragment linkFields on Link {
  id
  text
  url {
    ...urlFields
  }
}
    ${UrlFieldsFragmentDoc}`;
export const InStudioEmptyStateFragmentDoc = gql`
    fragment InStudioEmptyState on ClubProgramInStudioEmptyState {
  programType
  content {
    subtitle
    title
    link {
      ...linkFields
    }
  }
}
    ${LinkFieldsFragmentDoc}`;
export const ImageFieldsFragmentDoc = gql`
    fragment imageFields on Image {
  __typename
  id
  url
  alt
  height
  width
}
    `;
export const InStudioToolkitTileFragmentDoc = gql`
    fragment InStudioToolkitTile on InStudioToolkitTile {
  image {
    ...imageFields
  }
  link {
    ...linkFields
  }
  subTitle
  title
}
    ${ImageFieldsFragmentDoc}
${LinkFieldsFragmentDoc}`;
export const EventSummaryFieldsFragmentDoc = gql`
    fragment eventSummaryFields on EventSummary {
  deliveryMethod
  endDate
  eventId
  eventName
  eventStatus
  programName
  startDate
  trainingJourneyCount
  venueAddress
}
    `;
export const DevelopmentSheetFieldsFragmentDoc = gql`
    fragment developmentSheetFields on DevelopmentSheet {
  filename
  url
}
    `;
export const EmergencyContactFieldsFragmentDoc = gql`
    fragment emergencyContactFields on EmergencyContact {
  address
  name
  phone
}
    `;
export const InstructorInfoFieldsFragmentDoc = gql`
    fragment instructorInfoFields on InstructorInformation {
  address
  email
  name
  phone
}
    `;
export const PlaybackFieldsFragmentDoc = gql`
    fragment playbackFields on Playback {
  playbackUrl
  token
}
    `;
export const OutcomeTrackFieldsFragmentDoc = gql`
    fragment outcomeTrackFields on OutcomeTrack {
  comment
  submittedAt
  playback {
    ...playbackFields
  }
}
    ${PlaybackFieldsFragmentDoc}`;
export const PresentationTrackFieldsFragmentDoc = gql`
    fragment presentationTrackFields on PresentationTrack {
  comment
  submittedAt
  playback {
    ...playbackFields
  }
}
    ${PlaybackFieldsFragmentDoc}`;
export const EventAssessmentFieldsFragmentDoc = gql`
    fragment eventAssessmentFields on EventAssessments {
  attended
  developmentSheet {
    ...developmentSheetFields
  }
  emergencyContact {
    ...emergencyContactFields
  }
  instructorInfo {
    ...instructorInfoFields
  }
  outcome
  outcomeTrack {
    ...outcomeTrackFields
  }
  presentationTrack {
    ...presentationTrackFields
  }
  sessionRegistrationId
  trainingJourneyId
  trackAllocation
}
    ${DevelopmentSheetFieldsFragmentDoc}
${EmergencyContactFieldsFragmentDoc}
${InstructorInfoFieldsFragmentDoc}
${OutcomeTrackFieldsFragmentDoc}
${PresentationTrackFieldsFragmentDoc}`;
export const StepFieldsFragmentDoc = gql`
    fragment stepFields on Step {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  venueAddress
  contactUsEmail
}
    `;
export const TrainingBookedStepFieldsFragmentDoc = gql`
    fragment trainingBookedStepFields on TrainingBookedStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  parqForm
  venueAddress
  contactUsEmail
  emergencyContact {
    ...emergencyContactFields
  }
}
    ${EmergencyContactFieldsFragmentDoc}`;
export const AssessmentVideoFieldsFragmentDoc = gql`
    fragment assessmentVideoFields on AssessmentVideo {
  assessmentId
  comment
  fileKey
  submitted
  submittedDate
  recordType
  videoNotWorking
  playback {
    ...playbackFields
  }
  extensionRequested
}
    ${PlaybackFieldsFragmentDoc}`;
export const PreworkContentItemFieldsFragmentDoc = gql`
    fragment PreworkContentItemFields on PreworkContentItem {
  title
  type
  url {
    ...urlFields
  }
}
    ${UrlFieldsFragmentDoc}`;
export const PreworkContentFieldsFragmentDoc = gql`
    fragment preworkContentFields on PreworkContent {
  contentList {
    ...PreworkContentItemFields
  }
}
    ${PreworkContentItemFieldsFragmentDoc}`;
export const PreworkStepFieldsFragmentDoc = gql`
    fragment preworkStepFields on PreworkStep {
  activeStepName
  activeStepNumber
  assessmentVideo {
    ...assessmentVideoFields
  }
  attended
  cancellationPolicy
  passPendingGuide
  preworkContent {
    ...preworkContentFields
  }
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  releaseUrl {
    ...linkFields
  }
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  venueAddress
  contactUsEmail
}
    ${AssessmentVideoFieldsFragmentDoc}
${PreworkContentFieldsFragmentDoc}
${LinkFieldsFragmentDoc}`;
export const AttendTrainingStepFieldsFragmentDoc = gql`
    fragment attendTrainingStepFields on AttendTrainingStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  cecCertificationUrl
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  venueAddress
  contactUsEmail
}
    `;
export const SubmitOutcomeTrackStepFieldsFragmentDoc = gql`
    fragment submitOutcomeTrackStepFields on SubmitOutcomeTrackStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  extendedDueDate
  venueAddress
  contactUsEmail
  assessmentVideo {
    ...assessmentVideoFields
  }
}
    ${AssessmentVideoFieldsFragmentDoc}`;
export const TrainingOutcomeFieldsFragmentDoc = gql`
    fragment trainingOutcomeFields on TrainingOutcome {
  developmentSheet
  outcome
  submitDate
  trainingNotes
  assessmentVideo {
    ...assessmentVideoFields
  }
}
    ${AssessmentVideoFieldsFragmentDoc}`;
export const ReceivedTrainingOutcomeStepFieldsFragmentDoc = gql`
    fragment receivedTrainingOutcomeStepFields on ReceivedTrainingOutcomeStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  cecCertificationUrl
  extendedDueDate
  venueAddress
  contactUsEmail
  assessmentOutcomes {
    ...trainingOutcomeFields
  }
}
    ${TrainingOutcomeFieldsFragmentDoc}`;
export const SubmitCertificationVideoStepFieldsFragmentDoc = gql`
    fragment submitCertificationVideoStepFields on SubmitCertificationVideoStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  extendedDueDate
  venueAddress
  contactUsEmail
  assessmentVideo {
    ...assessmentVideoFields
  }
}
    ${AssessmentVideoFieldsFragmentDoc}`;
export const ReceivedCertificationOutcomeStepFieldsFragmentDoc = gql`
    fragment receivedCertificationOutcomeStepFields on ReceivedCertificationOutcomeStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  resubmitDueDate
  certificationUrl
  venueAddress
  contactUsEmail
  certificationOutcomes {
    ...trainingOutcomeFields
  }
}
    ${TrainingOutcomeFieldsFragmentDoc}`;
export const CertifiedStepFieldsFragmentDoc = gql`
    fragment certifiedStepFields on CertifiedStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  passPendingGuide
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  contactUsEmail
  venueAddress
}
    `;
export const InstructorAvailabilityFieldsFragmentDoc = gql`
    fragment instructorAvailabilityFields on InstructorAvailability {
  description
  title
  value
}
    `;
export const AddressFieldsFragmentDoc = gql`
    fragment addressFields on Address {
  city
  state
  street
  country
  stateCode
  postalCode
  streetName
  countryCode
  streetNumber
  location {
    lat
    lon
  }
}
    `;
export const ProgramFieldsFragmentDoc = gql`
    fragment programFields on Program {
  code
  id
  initials
  name
}
    `;
export const TimePreferencesFragmentDoc = gql`
    fragment timePreferences on TimePreference {
  morning
  afternoon
  evening
}
    `;
export const TravelPreferencesFragmentDoc = gql`
    fragment travelPreferences on TravelPreference {
  address {
    ...addressFields
  }
  distance
  distanceUnit
}
    ${AddressFieldsFragmentDoc}`;
export const PreferenceFieldsFragmentDoc = gql`
    fragment preferenceFields on InstructorPreferences {
  programs {
    ...programFields
  }
  times {
    ...timePreferences
  }
  travel {
    ...travelPreferences
  }
}
    ${ProgramFieldsFragmentDoc}
${TimePreferencesFragmentDoc}
${TravelPreferencesFragmentDoc}`;
export const PrivacyFieldsFragmentDoc = gql`
    fragment privacyFields on PrivacySettingsInstructor {
  EMAIL
  PHONE_NUMBER
  PROFILE_PHOTO
  SOCIAL_LINKS
  LMQ_LEVEL
  HIDDEN
}
    `;
export const InstructorProfileFieldsFragmentDoc = gql`
    fragment instructorProfileFields on UserProfileTeaching {
  id
  __typename
  id
  bio
  address {
    ...addressFields
  }
  shippingAddress {
    ...addressFields
  }
  roles
  strength
  interests
  createdAt
  availability
  createdAtLabel
  availableToSubstitute
  preferences {
    ...preferenceFields
  }
  privacySettings {
    ...privacyFields
  }
  media {
    ...imageFields
  }
  profileImage {
    ...imageFields
  }
  links {
    url
    type
  }
  certifications {
    id
    lastPurchase
    pricePerQuarter {
      amount
      currency
    }
    transactionStatus
    transactionTimestamp
    billedClubId
    releaseStatus
    status
    active
    lmqLevel
    link {
      ...linkFields
    }
    program {
      id
      name
      code
      initials
    }
  }
  watchlist {
    clubId
    listingId
  }
}
    ${AddressFieldsFragmentDoc}
${PreferenceFieldsFragmentDoc}
${PrivacyFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${LinkFieldsFragmentDoc}`;
export const ClubProfileFieldsFragmentDoc = gql`
    fragment clubProfileFields on UserProfileClub {
  id
  __typename
  bio
  name
  email
  jobListingEmail
  phone
  hiring
  address {
    ...addressFields
  }
  strength
  createdAt
  createdAtLabel
  programs {
    ... on ClubProgram {
      __typename
      id
      name
      code
      initials
      programType
    }
  }
  media {
    ...imageFields
  }
  profileImage {
    ...imageFields
  }
  links {
    url
    type
  }
  isDigitalOnly
  openToNewPrograms
  programIds
}
    ${AddressFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const FullUserFieldFragmentDoc = gql`
    fragment fullUserField on UserResult {
  __typename
  id
  distance
  distanceUnit
  user {
    id
    email
    market {
      isSupported
      name
      locale
      distanceUnit
      programs {
        id
        name
        code
        initials
      }
    }
    lastName
    firstName
    accountId
    phone
    signedAgreements {
      type
      signedOn
      version
    }
    userMetaData {
      walkthroughRequired {
        club
        instructor
      }
    }
  }
  profiles {
    ... on UserProfileTeaching {
      ...instructorProfileFields
    }
    ... on UserProfileClub {
      ...clubProfileFields
    }
  }
  settings {
    locale
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}`;
export const SingleListingFieldsFragmentDoc = gql`
    fragment singleListingFields on ClubSingleListing {
  clubInfo {
    id
    name
    address {
      ...addressFields
    }
    profileImage {
      url
    }
    programs {
      ... on ClubProgram {
        name
      }
    }
    hiring
  }
  createdAt
  description
  expiringAt
  id
  searchAppearances
  updatedAt
  views
  isSubstituteListing
  program {
    code
    id
    initials
    name
  }
  status
  times {
    ...timePreferences
  }
  enquiriesCount
}
    ${AddressFieldsFragmentDoc}
${TimePreferencesFragmentDoc}`;
export const MultiListingFieldsFragmentDoc = gql`
    fragment multiListingFields on ClubMultiListing {
  clubInfo {
    id
    name
    address {
      ...addressFields
    }
    profileImage {
      url
    }
    programs {
      ... on ClubProgram {
        name
      }
    }
    hiring
  }
  createdAt
  description
  expiringAt
  id
  searchAppearances
  programs {
    code
    id
    initials
    name
  }
  status
  updatedAt
  views
  enquiriesCount
}
    ${AddressFieldsFragmentDoc}`;
export const SavedInstructorSearchFragmentDoc = gql`
    fragment savedInstructorSearch on SavedInstructorSearch {
  filter {
    name
    programs
    substituteListingFilter
    location {
      within {
        address {
          ...addressFields
        }
        area {
          points {
            lat
            lon
          }
        }
      }
      near {
        address {
          ...addressFields
        }
        distance
        distanceUnit
      }
      point {
        lat
        lon
      }
    }
  }
  sort
}
    ${AddressFieldsFragmentDoc}`;
export const ErrorResultFieldsFragmentDoc = gql`
    fragment errorResultFields on ErrorResult {
  type
  message
  requestId
  logGroup
  logStream
  __typename
}
    `;
export const ClubFieldsFragmentDoc = gql`
    fragment clubFields on Club {
  accountId
  createdAt
  currency
  email {
    affiliations
    jobListings
    main
  }
  id
  market {
    name
    locale
    distanceUnit
    programs {
      id
      name
      code
      initials
    }
  }
  name
  parent
  phone
  profile {
    ... on UserProfileClub {
      ...clubProfileFields
    }
  }
}
    ${ClubProfileFieldsFragmentDoc}`;
export const ManagePeopleUserAffiliateFieldsFragmentDoc = gql`
    fragment managePeopleUserAffiliateFields on User {
  id
  phone
  email
  accountId
  market {
    name
    locale
    distanceUnit
  }
  lastName
  firstName
}
    `;
export const AffiliationFieldsFragmentDoc = gql`
    fragment affiliationFields on Affiliation {
  actionedDate
  clubId
  id
  latestActionInitiator
  pendingStatus
  requestedBy
  requestedDate
  role
  status
  userId
}
    `;
export const ClubWithAffiliationFragmentDoc = gql`
    fragment clubWithAffiliation on ClubWithAffiliation {
  clubId
  clubName
  latestAffiliation {
    ...affiliationFields
  }
  isDigitalOnly
}
    ${AffiliationFieldsFragmentDoc}`;
export const ManagePeopleClubAffiliatesFieldsFragmentDoc = gql`
    fragment managePeopleClubAffiliatesFields on ClubAffiliates {
  actionedDate
  clubId
  distance
  distanceUnit
  id
  pendingStatus
  profile {
    ... on UserProfileClub {
      ...clubProfileFields
    }
  }
  requestedBy
  requestedDate
  status
}
    ${ClubProfileFieldsFragmentDoc}`;
export const BusinessSupportSectionContentFragmentDoc = gql`
    fragment BusinessSupportSectionContent on BusinessSupportSection {
  id
  sectionTitle
  sectionTiles {
    id
    title
    subtitle
    image {
      id
      alt
      url
    }
    url {
      type
      value
    }
  }
}
    `;
export const ClubDashboardBusinessSupportContentFragmentDoc = gql`
    fragment ClubDashboardBusinessSupportContent on ClubPartnershipBusinessSupportContent {
  link {
    text
    url {
      type
      value
    }
  }
  title
  description
}
    `;
export const ClubDashboardStandardContentFragmentDoc = gql`
    fragment ClubDashboardStandardContent on ClubPartnershipStandardContent {
  title
  subtitle
  link {
    url {
      type
      value
    }
    text
  }
  image {
    mobile {
      alt
      url
    }
    desktop {
      alt
      url
    }
  }
}
    `;
export const ClubDashboardProgramInfoFragmentDoc = gql`
    fragment ClubDashboardProgramInfo on ClubPartnershipProgramStat {
  total
  type
  icon {
    alt
    url
  }
}
    `;
export const ClubDashboardProgramStatsFragmentDoc = gql`
    fragment ClubDashboardProgramStats on ClubPartnershipProgramStats {
  programsStats {
    ...ClubDashboardProgramInfo
  }
}
    ${ClubDashboardProgramInfoFragmentDoc}`;
export const InStudioManageOptionsFragmentDoc = gql`
    fragment InStudioManageOptions on GetPickListResult {
  results {
    items
    key
  }
}
    `;
export const LocationsFragmentDoc = gql`
    fragment locations on ClubAvailabilityOnListing {
  clubId
  clubName
  isAlreadyListed
  isClubLicensed
  clubProfileImage {
    ...imageFields
  }
  clubAddress {
    ...addressFields
  }
}
    ${ImageFieldsFragmentDoc}
${AddressFieldsFragmentDoc}`;
export const NotificationItemFragmentDoc = gql`
    fragment notificationItem on Notification {
  id
  type
  body
  title
  createdAt
  createdAtLabel
  readAt
  isNew
  contentType
  params {
    userName
  }
  link {
    id
    text
    url {
      type
      value
    }
  }
}
    `;
export const NotificationsResultFragmentDoc = gql`
    fragment notificationsResult on GetNotificationsResult {
  filter {
    type
    value
    limit
  }
  totalCount
  items {
    ...notificationItem
  }
}
    ${NotificationItemFragmentDoc}`;
export const AtHomeProductTileFragmentDoc = gql`
    fragment AtHomeProductTile on AtHomeProductTile {
  title
  description
  onlineProgram {
    id
    activeSince
    lastEnquiredOn
  }
  buttons {
    isPrimary
    id
    text
    url {
      type
      value
    }
  }
  image {
    id
    desktop {
      id
      alt
      url
    }
    mobile {
      id
      alt
      url
    }
  }
  programs {
    id
    code
    name
  }
}
    `;
export const ExploreProgramTileFragmentDoc = gql`
    fragment ExploreProgramTile on InStudioExploreProgramsTile {
  productId
  name
  genre
  productCategories
  numberOfCertifiedInstructors
  enquirySent
  image {
    id
    alt
    url
  }
}
    `;
export const InStudioDetailsViewFragmentDoc = gql`
    fragment InStudioDetailsView on InStudioDetailsView {
  typeInfo {
    description
    title
  }
  description
  videoContentId
  moreURL {
    type
    value
  }
}
    `;
export const DetailedExploreProgramTileFragmentDoc = gql`
    fragment DetailedExploreProgramTile on InStudioExploreProgramsTile {
  productId
  name
  genre
  productCategories
  numberOfCertifiedInstructors
  enquirySent
  image {
    id
    alt
    url
  }
  detailView {
    ...InStudioDetailsView
  }
}
    ${InStudioDetailsViewFragmentDoc}`;
export const SearchInstructorsSingleResultFragmentDoc = gql`
    fragment searchInstructorsSingleResult on SearchInstructorsSingleResult {
  distance
  distanceUnit
  messageSentOn
  inTravelZone
  hasExistingAffiliation
  hasRecentlyDeclinedAffiliation
  lastMessageSentAt
  lastMessageIn48HoursSentAt
  instructorAffiliation {
    id
    clubId
    pendingStatus
    actionedDate
    requestedDate
    requestedBy
    role
    userId
    status
  }
  profile {
    ... on UserProfileTeaching {
      ...instructorProfileFields
    }
  }
  user {
    id
    email
    lastName
    firstName
    accountId
    market {
      name
      locale
      distanceUnit
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}`;
export const SearchInstructorsResultFragmentDoc = gql`
    fragment searchInstructorsResult on SearchInstructorsResult {
  isMatchingFilter
  total
  results {
    ...searchInstructorsSingleResult
  }
}
    ${SearchInstructorsSingleResultFragmentDoc}`;
export const SearchClubListingsWithFilterDocument = gql`
    query searchClubListingsWithFilter($searchInfo: SearchClubListingsWithFilterInput!, $filterInfo: FilterClubListingsWithFilterInput!) {
  searchClubListingsWithFilter(searchInfo: $searchInfo, filterInfo: $filterInfo) {
    ... on SearchClubListingsWithFilterResult {
      edges {
        cursor
        node {
          distance
          distanceUnit
          messageSentOn
          enquiredOn
          onWatchlist
          hasBeenReported
          listing {
            ... on ClubSingleListing {
              ...singleListingFields
              programId
            }
            ... on ClubMultiListing {
              ...multiListingFields
              programsId
            }
          }
        }
      }
      pageInfo {
        endCursor {
          listingId
          sortValues
        }
        hasNextPage
      }
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}`;

/**
 * __useSearchClubListingsWithFilterQuery__
 *
 * To run a query within a React component, call `useSearchClubListingsWithFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchClubListingsWithFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchClubListingsWithFilterQuery({
 *   variables: {
 *      searchInfo: // value for 'searchInfo'
 *      filterInfo: // value for 'filterInfo'
 *   },
 * });
 */
export function useSearchClubListingsWithFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchClubListingsWithFilterQuery, Types.SearchClubListingsWithFilterQueryVariables>) {
        return Apollo.useQuery<Types.SearchClubListingsWithFilterQuery, Types.SearchClubListingsWithFilterQueryVariables>(SearchClubListingsWithFilterDocument, baseOptions);
      }
export function useSearchClubListingsWithFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchClubListingsWithFilterQuery, Types.SearchClubListingsWithFilterQueryVariables>) {
          return Apollo.useLazyQuery<Types.SearchClubListingsWithFilterQuery, Types.SearchClubListingsWithFilterQueryVariables>(SearchClubListingsWithFilterDocument, baseOptions);
        }
export type SearchClubListingsWithFilterQueryHookResult = ReturnType<typeof useSearchClubListingsWithFilterQuery>;
export type SearchClubListingsWithFilterLazyQueryHookResult = ReturnType<typeof useSearchClubListingsWithFilterLazyQuery>;
export type SearchClubListingsWithFilterQueryResult = Apollo.QueryResult<Types.SearchClubListingsWithFilterQuery, Types.SearchClubListingsWithFilterQueryVariables>;
export const SearchClubsWithFilterDocument = gql`
    query searchClubsWithFilter($searchInfo: SearchClubsWithFilterInput!, $filterInfo: FilterClubsInput!) {
  searchClubsWithFilter(searchInfo: $searchInfo, filterInfo: $filterInfo) {
    ... on SearchClubsWithFilterResult {
      edges {
        cursor
        node {
          distance
          distanceUnit
          onWatchlist
          hasBeenReported
          profile {
            ...clubProfileFields
            programIds
          }
        }
      }
      pageInfo {
        endCursor {
          clubId
          sortValues
        }
        hasNextPage
      }
    }
  }
}
    ${ClubProfileFieldsFragmentDoc}`;

/**
 * __useSearchClubsWithFilterQuery__
 *
 * To run a query within a React component, call `useSearchClubsWithFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchClubsWithFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchClubsWithFilterQuery({
 *   variables: {
 *      searchInfo: // value for 'searchInfo'
 *      filterInfo: // value for 'filterInfo'
 *   },
 * });
 */
export function useSearchClubsWithFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchClubsWithFilterQuery, Types.SearchClubsWithFilterQueryVariables>) {
        return Apollo.useQuery<Types.SearchClubsWithFilterQuery, Types.SearchClubsWithFilterQueryVariables>(SearchClubsWithFilterDocument, baseOptions);
      }
export function useSearchClubsWithFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchClubsWithFilterQuery, Types.SearchClubsWithFilterQueryVariables>) {
          return Apollo.useLazyQuery<Types.SearchClubsWithFilterQuery, Types.SearchClubsWithFilterQueryVariables>(SearchClubsWithFilterDocument, baseOptions);
        }
export type SearchClubsWithFilterQueryHookResult = ReturnType<typeof useSearchClubsWithFilterQuery>;
export type SearchClubsWithFilterLazyQueryHookResult = ReturnType<typeof useSearchClubsWithFilterLazyQuery>;
export type SearchClubsWithFilterQueryResult = Apollo.QueryResult<Types.SearchClubsWithFilterQuery, Types.SearchClubsWithFilterQueryVariables>;
export const GetClubProgramsForInStudioPageDocument = gql`
    query getClubProgramsForInStudioPage($input: GetClubByIdInput!, $_cacheKey: String) {
  getClubById(input: $input, _cacheKey: $_cacheKey) {
    ... on UserProfileClub {
      programs {
        ... on ClubProgram {
          ...InStudioClubProgram
        }
        ... on ClubProgramInStudioEmptyState {
          ...InStudioEmptyState
        }
      }
      inStudioToolkit {
        tiles {
          ...InStudioToolkitTile
        }
      }
      exploreProgramsCard {
        image {
          ...imageFields
        }
      }
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${InStudioClubProgramFragmentDoc}
${InStudioEmptyStateFragmentDoc}
${InStudioToolkitTileFragmentDoc}
${ImageFieldsFragmentDoc}`;

/**
 * __useGetClubProgramsForInStudioPageQuery__
 *
 * To run a query within a React component, call `useGetClubProgramsForInStudioPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubProgramsForInStudioPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubProgramsForInStudioPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubProgramsForInStudioPageQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClubProgramsForInStudioPageQuery, Types.GetClubProgramsForInStudioPageQueryVariables>) {
        return Apollo.useQuery<Types.GetClubProgramsForInStudioPageQuery, Types.GetClubProgramsForInStudioPageQueryVariables>(GetClubProgramsForInStudioPageDocument, baseOptions);
      }
export function useGetClubProgramsForInStudioPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClubProgramsForInStudioPageQuery, Types.GetClubProgramsForInStudioPageQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetClubProgramsForInStudioPageQuery, Types.GetClubProgramsForInStudioPageQueryVariables>(GetClubProgramsForInStudioPageDocument, baseOptions);
        }
export type GetClubProgramsForInStudioPageQueryHookResult = ReturnType<typeof useGetClubProgramsForInStudioPageQuery>;
export type GetClubProgramsForInStudioPageLazyQueryHookResult = ReturnType<typeof useGetClubProgramsForInStudioPageLazyQuery>;
export type GetClubProgramsForInStudioPageQueryResult = Apollo.QueryResult<Types.GetClubProgramsForInStudioPageQuery, Types.GetClubProgramsForInStudioPageQueryVariables>;
export const AddTrainingOutcomeDocument = gql`
    mutation addTrainingOutcome($input: AddTrainingOutcomeInput!, $_cacheKey: String) {
  addTrainingOutcome(input: $input, _cacheKey: $_cacheKey) {
    ... on AddTrainingOutcomeResult {
      isSuccess
      developmentSheet {
        filename
        url
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type AddTrainingOutcomeMutationFn = Apollo.MutationFunction<Types.AddTrainingOutcomeMutation, Types.AddTrainingOutcomeMutationVariables>;

/**
 * __useAddTrainingOutcomeMutation__
 *
 * To run a mutation, you first call `useAddTrainingOutcomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrainingOutcomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrainingOutcomeMutation, { data, loading, error }] = useAddTrainingOutcomeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useAddTrainingOutcomeMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddTrainingOutcomeMutation, Types.AddTrainingOutcomeMutationVariables>) {
        return Apollo.useMutation<Types.AddTrainingOutcomeMutation, Types.AddTrainingOutcomeMutationVariables>(AddTrainingOutcomeDocument, baseOptions);
      }
export type AddTrainingOutcomeMutationHookResult = ReturnType<typeof useAddTrainingOutcomeMutation>;
export type AddTrainingOutcomeMutationResult = Apollo.MutationResult<Types.AddTrainingOutcomeMutation>;
export type AddTrainingOutcomeMutationOptions = Apollo.BaseMutationOptions<Types.AddTrainingOutcomeMutation, Types.AddTrainingOutcomeMutationVariables>;
export const BookEventDocument = gql`
    mutation bookEvent($input: BookEventInput!, $role: UserRoleInput!) {
  bookEvent(input: $input, role: $role) {
    ... on BookEventResult {
      magentoLink
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type BookEventMutationFn = Apollo.MutationFunction<Types.BookEventMutation, Types.BookEventMutationVariables>;

/**
 * __useBookEventMutation__
 *
 * To run a mutation, you first call `useBookEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookEventMutation, { data, loading, error }] = useBookEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useBookEventMutation(baseOptions?: Apollo.MutationHookOptions<Types.BookEventMutation, Types.BookEventMutationVariables>) {
        return Apollo.useMutation<Types.BookEventMutation, Types.BookEventMutationVariables>(BookEventDocument, baseOptions);
      }
export type BookEventMutationHookResult = ReturnType<typeof useBookEventMutation>;
export type BookEventMutationResult = Apollo.MutationResult<Types.BookEventMutation>;
export type BookEventMutationOptions = Apollo.BaseMutationOptions<Types.BookEventMutation, Types.BookEventMutationVariables>;
export const BookQwsEventDocument = gql`
    mutation bookQWSEvent($input: BookQWSInput!, $role: UserRoleInput!) {
  bookQuarterlyWorkshopEvent(input: $input, role: $role) {
    ... on BookEventResult {
      magentoLink
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type BookQwsEventMutationFn = Apollo.MutationFunction<Types.BookQwsEventMutation, Types.BookQwsEventMutationVariables>;

/**
 * __useBookQwsEventMutation__
 *
 * To run a mutation, you first call `useBookQwsEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookQwsEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookQwsEventMutation, { data, loading, error }] = useBookQwsEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useBookQwsEventMutation(baseOptions?: Apollo.MutationHookOptions<Types.BookQwsEventMutation, Types.BookQwsEventMutationVariables>) {
        return Apollo.useMutation<Types.BookQwsEventMutation, Types.BookQwsEventMutationVariables>(BookQwsEventDocument, baseOptions);
      }
export type BookQwsEventMutationHookResult = ReturnType<typeof useBookQwsEventMutation>;
export type BookQwsEventMutationResult = Apollo.MutationResult<Types.BookQwsEventMutation>;
export type BookQwsEventMutationOptions = Apollo.BaseMutationOptions<Types.BookQwsEventMutation, Types.BookQwsEventMutationVariables>;
export const BookedEventDocument = gql`
    query bookedEvent($eventId: String!, $role: UserRoleInput!, $_cacheKey: String) {
  bookedEvent(eventId: $eventId, role: $role, _cacheKey: $_cacheKey) {
    ... on BookedEventResult {
      eventDetails {
        event {
          eventId
          type
          eventName
          startDate
          endDate
          deliveryMethod
          spacesAvailable
          venueAddress
          programName
          timezone
        }
        trainerDetails {
          name
          email
        }
        schedule
        details
        requirements
        cancellationPolicy
      }
      bookedInstructors {
        name
        profileImage {
          url
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useBookedEventQuery__
 *
 * To run a query within a React component, call `useBookedEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookedEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookedEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useBookedEventQuery(baseOptions: Apollo.QueryHookOptions<Types.BookedEventQuery, Types.BookedEventQueryVariables>) {
        return Apollo.useQuery<Types.BookedEventQuery, Types.BookedEventQueryVariables>(BookedEventDocument, baseOptions);
      }
export function useBookedEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BookedEventQuery, Types.BookedEventQueryVariables>) {
          return Apollo.useLazyQuery<Types.BookedEventQuery, Types.BookedEventQueryVariables>(BookedEventDocument, baseOptions);
        }
export type BookedEventQueryHookResult = ReturnType<typeof useBookedEventQuery>;
export type BookedEventLazyQueryHookResult = ReturnType<typeof useBookedEventLazyQuery>;
export type BookedEventQueryResult = Apollo.QueryResult<Types.BookedEventQuery, Types.BookedEventQueryVariables>;
export const BookedEventsDocument = gql`
    query bookedEvents($clubId: String!, $_cacheKey: String) {
  bookedEvents(clubId: $clubId, _cacheKey: $_cacheKey) {
    ... on BookedEventsResult {
      bookedEvents {
        event {
          eventId
          type
          eventName
          startDate
          endDate
          deliveryMethod
          spacesAvailable
          venueAddress
          programName
        }
        numberOfBookedInstructors
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useBookedEventsQuery__
 *
 * To run a query within a React component, call `useBookedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookedEventsQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useBookedEventsQuery(baseOptions: Apollo.QueryHookOptions<Types.BookedEventsQuery, Types.BookedEventsQueryVariables>) {
        return Apollo.useQuery<Types.BookedEventsQuery, Types.BookedEventsQueryVariables>(BookedEventsDocument, baseOptions);
      }
export function useBookedEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BookedEventsQuery, Types.BookedEventsQueryVariables>) {
          return Apollo.useLazyQuery<Types.BookedEventsQuery, Types.BookedEventsQueryVariables>(BookedEventsDocument, baseOptions);
        }
export type BookedEventsQueryHookResult = ReturnType<typeof useBookedEventsQuery>;
export type BookedEventsLazyQueryHookResult = ReturnType<typeof useBookedEventsLazyQuery>;
export type BookedEventsQueryResult = Apollo.QueryResult<Types.BookedEventsQuery, Types.BookedEventsQueryVariables>;
export const EventDetailsDocument = gql`
    query eventDetails($eventId: String!, $role: UserRoleInput!, $_cacheKey: String) {
  eventDetails(eventId: $eventId, role: $role, _cacheKey: $_cacheKey) {
    ... on EventDetailsResult {
      event {
        eventId
        eventName
        startDate
        endDate
        deliveryMethod
        spacesAvailable
        venueAddress
        programName
        type
        timezone
      }
      trainerDetails {
        name
        email
      }
      schedule
      details
      requirements
      cancellationPolicy
      tickets {
        ticketId
        name
        currencyIsoCode
        price
        startDate
        endDate
        isDefaultTicket
        canBeUsedWithDiscountCode
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useEventDetailsQuery__
 *
 * To run a query within a React component, call `useEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useEventDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.EventDetailsQuery, Types.EventDetailsQueryVariables>) {
        return Apollo.useQuery<Types.EventDetailsQuery, Types.EventDetailsQueryVariables>(EventDetailsDocument, baseOptions);
      }
export function useEventDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EventDetailsQuery, Types.EventDetailsQueryVariables>) {
          return Apollo.useLazyQuery<Types.EventDetailsQuery, Types.EventDetailsQueryVariables>(EventDetailsDocument, baseOptions);
        }
export type EventDetailsQueryHookResult = ReturnType<typeof useEventDetailsQuery>;
export type EventDetailsLazyQueryHookResult = ReturnType<typeof useEventDetailsLazyQuery>;
export type EventDetailsQueryResult = Apollo.QueryResult<Types.EventDetailsQuery, Types.EventDetailsQueryVariables>;
export const QwsTrainerBookedEventDocument = gql`
    query qwsTrainerBookedEvent($eventId: String!, $_cacheKey: String) {
  qwsTrainerBookedEvent(eventId: $eventId, _cacheKey: $_cacheKey) {
    ... on QwsTrainerBookedEventResult {
      qwsTrainerBookedEventSummary {
        eventName
        deliveryMethod
        sessionCount
        instructorCount
        venueAddress
        startDate
        endDate
      }
      sessions {
        sessionId
        sessionName
        programName
        startTime
        endTime
        timezone
        instructorCount
        instructors {
          registrationId
          email
          firstName
          lastName
          profileImage {
            ...imageFields
          }
          hasAttended
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ImageFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useQwsTrainerBookedEventQuery__
 *
 * To run a query within a React component, call `useQwsTrainerBookedEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useQwsTrainerBookedEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQwsTrainerBookedEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useQwsTrainerBookedEventQuery(baseOptions: Apollo.QueryHookOptions<Types.QwsTrainerBookedEventQuery, Types.QwsTrainerBookedEventQueryVariables>) {
        return Apollo.useQuery<Types.QwsTrainerBookedEventQuery, Types.QwsTrainerBookedEventQueryVariables>(QwsTrainerBookedEventDocument, baseOptions);
      }
export function useQwsTrainerBookedEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QwsTrainerBookedEventQuery, Types.QwsTrainerBookedEventQueryVariables>) {
          return Apollo.useLazyQuery<Types.QwsTrainerBookedEventQuery, Types.QwsTrainerBookedEventQueryVariables>(QwsTrainerBookedEventDocument, baseOptions);
        }
export type QwsTrainerBookedEventQueryHookResult = ReturnType<typeof useQwsTrainerBookedEventQuery>;
export type QwsTrainerBookedEventLazyQueryHookResult = ReturnType<typeof useQwsTrainerBookedEventLazyQuery>;
export type QwsTrainerBookedEventQueryResult = Apollo.QueryResult<Types.QwsTrainerBookedEventQuery, Types.QwsTrainerBookedEventQueryVariables>;
export const TrainerBookingDocument = gql`
    query trainerBooking($input: TrainerBookingInput!, $_cacheKey: String) {
  trainerBooking(input: $input, _cacheKey: $_cacheKey) {
    ... on TrainerBookingResult {
      eventAssessments {
        ...eventAssessmentFields
      }
      eventSummary {
        ... on EventSummary {
          type
          deliveryMethod
          endDate
          eventId
          eventName
          eventStatus
          programName
          startDate
          trainingJourneyCount
          venueAddress
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${EventAssessmentFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useTrainerBookingQuery__
 *
 * To run a query within a React component, call `useTrainerBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainerBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainerBookingQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useTrainerBookingQuery(baseOptions: Apollo.QueryHookOptions<Types.TrainerBookingQuery, Types.TrainerBookingQueryVariables>) {
        return Apollo.useQuery<Types.TrainerBookingQuery, Types.TrainerBookingQueryVariables>(TrainerBookingDocument, baseOptions);
      }
export function useTrainerBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TrainerBookingQuery, Types.TrainerBookingQueryVariables>) {
          return Apollo.useLazyQuery<Types.TrainerBookingQuery, Types.TrainerBookingQueryVariables>(TrainerBookingDocument, baseOptions);
        }
export type TrainerBookingQueryHookResult = ReturnType<typeof useTrainerBookingQuery>;
export type TrainerBookingLazyQueryHookResult = ReturnType<typeof useTrainerBookingLazyQuery>;
export type TrainerBookingQueryResult = Apollo.QueryResult<Types.TrainerBookingQuery, Types.TrainerBookingQueryVariables>;
export const TrainerBookingsDocument = gql`
    query trainerBookings($_cacheKey: String) {
  trainerBookings(_cacheKey: $_cacheKey) {
    ... on TrainerBookingsResult {
      result {
        deliveryMethod
        type
        endDate
        eventId
        eventName
        programName
        startDate
        eventStatus
        trainingJourneyCount
        venueAddress
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useTrainerBookingsQuery__
 *
 * To run a query within a React component, call `useTrainerBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainerBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainerBookingsQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useTrainerBookingsQuery(baseOptions?: Apollo.QueryHookOptions<Types.TrainerBookingsQuery, Types.TrainerBookingsQueryVariables>) {
        return Apollo.useQuery<Types.TrainerBookingsQuery, Types.TrainerBookingsQueryVariables>(TrainerBookingsDocument, baseOptions);
      }
export function useTrainerBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TrainerBookingsQuery, Types.TrainerBookingsQueryVariables>) {
          return Apollo.useLazyQuery<Types.TrainerBookingsQuery, Types.TrainerBookingsQueryVariables>(TrainerBookingsDocument, baseOptions);
        }
export type TrainerBookingsQueryHookResult = ReturnType<typeof useTrainerBookingsQuery>;
export type TrainerBookingsLazyQueryHookResult = ReturnType<typeof useTrainerBookingsLazyQuery>;
export type TrainerBookingsQueryResult = Apollo.QueryResult<Types.TrainerBookingsQuery, Types.TrainerBookingsQueryVariables>;
export const TrainingJourneyDocument = gql`
    query trainingJourney($input: TrainingJourneyInput!, $role: UserRoleInput, $_cacheKey: String) {
  trainingJourney(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on TrainingJourneyResult {
      result {
        steps {
          ... on TrainingBookedStep {
            ...trainingBookedStepFields
          }
          ... on PreworkStep {
            ...preworkStepFields
          }
          ... on AttendTrainingStep {
            ...attendTrainingStepFields
          }
          ... on SubmitOutcomeTrackStep {
            ...submitOutcomeTrackStepFields
          }
          ... on ReceivedTrainingOutcomeStep {
            ...receivedTrainingOutcomeStepFields
          }
          ... on SubmitCertificationVideoStep {
            ...submitCertificationVideoStepFields
          }
          ... on ReceivedCertificationOutcomeStep {
            ...receivedCertificationOutcomeStepFields
          }
          ... on CertifiedStep {
            ...certifiedStepFields
          }
          ... on ContactUsStep {
            activeStepName
            activeStepNumber
            stepName
            stepNumber
            contactUsEmail
          }
        }
        type
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${TrainingBookedStepFieldsFragmentDoc}
${PreworkStepFieldsFragmentDoc}
${AttendTrainingStepFieldsFragmentDoc}
${SubmitOutcomeTrackStepFieldsFragmentDoc}
${ReceivedTrainingOutcomeStepFieldsFragmentDoc}
${SubmitCertificationVideoStepFieldsFragmentDoc}
${ReceivedCertificationOutcomeStepFieldsFragmentDoc}
${CertifiedStepFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useTrainingJourneyQuery__
 *
 * To run a query within a React component, call `useTrainingJourneyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingJourneyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingJourneyQuery({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useTrainingJourneyQuery(baseOptions: Apollo.QueryHookOptions<Types.TrainingJourneyQuery, Types.TrainingJourneyQueryVariables>) {
        return Apollo.useQuery<Types.TrainingJourneyQuery, Types.TrainingJourneyQueryVariables>(TrainingJourneyDocument, baseOptions);
      }
export function useTrainingJourneyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TrainingJourneyQuery, Types.TrainingJourneyQueryVariables>) {
          return Apollo.useLazyQuery<Types.TrainingJourneyQuery, Types.TrainingJourneyQueryVariables>(TrainingJourneyDocument, baseOptions);
        }
export type TrainingJourneyQueryHookResult = ReturnType<typeof useTrainingJourneyQuery>;
export type TrainingJourneyLazyQueryHookResult = ReturnType<typeof useTrainingJourneyLazyQuery>;
export type TrainingJourneyQueryResult = Apollo.QueryResult<Types.TrainingJourneyQuery, Types.TrainingJourneyQueryVariables>;
export const TrainingJourneysDocument = gql`
    query trainingJourneys($_cacheKey: String) {
  trainingJourneys(_cacheKey: $_cacheKey) {
    ... on TrainingJourneysResult {
      trainings {
        id
        eventName
        type
        deliveryMethod
        startDate
        endDate
        timezone
        cecCertificationUrl
        venueAddress
        activeStepName
        activeStepNumber
        dueDate
        programName
        isContactUs
        dateCertified
      }
      eventsAndTrainingUrl
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useTrainingJourneysQuery__
 *
 * To run a query within a React component, call `useTrainingJourneysQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingJourneysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingJourneysQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useTrainingJourneysQuery(baseOptions?: Apollo.QueryHookOptions<Types.TrainingJourneysQuery, Types.TrainingJourneysQueryVariables>) {
        return Apollo.useQuery<Types.TrainingJourneysQuery, Types.TrainingJourneysQueryVariables>(TrainingJourneysDocument, baseOptions);
      }
export function useTrainingJourneysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TrainingJourneysQuery, Types.TrainingJourneysQueryVariables>) {
          return Apollo.useLazyQuery<Types.TrainingJourneysQuery, Types.TrainingJourneysQueryVariables>(TrainingJourneysDocument, baseOptions);
        }
export type TrainingJourneysQueryHookResult = ReturnType<typeof useTrainingJourneysQuery>;
export type TrainingJourneysLazyQueryHookResult = ReturnType<typeof useTrainingJourneysLazyQuery>;
export type TrainingJourneysQueryResult = Apollo.QueryResult<Types.TrainingJourneysQuery, Types.TrainingJourneysQueryVariables>;
export const QwsBookedEventDocument = gql`
    query qwsBookedEvent($eventId: String!, $role: UserRoleInput!, $_cacheKey: String) {
  qwsBookedEvent(eventId: $eventId, role: $role, _cacheKey: $_cacheKey) {
    ... on QwsBookedEventResult {
      eventDetails {
        event {
          deliveryMethod
          eventName
          eventId
          startDate
          endDate
          timezone
          venueAddress
        }
        details
        schedule
        requirements
        cancellationPolicy
      }
      sessions {
        sessionId
        sessionName
        startTime
        endTime
        spacesAvailable
        programName
        trainers {
          name
        }
        bookedInstructors {
          id
          name
          profileImage {
            url
          }
        }
      }
      bookedInstructors {
        id
        name
        profileImage {
          url
        }
      }
    }
    ... on ErrorResult {
      component
      logGroup
      logStream
      message
      requestId
      errorType: type
    }
  }
}
    `;

/**
 * __useQwsBookedEventQuery__
 *
 * To run a query within a React component, call `useQwsBookedEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useQwsBookedEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQwsBookedEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useQwsBookedEventQuery(baseOptions: Apollo.QueryHookOptions<Types.QwsBookedEventQuery, Types.QwsBookedEventQueryVariables>) {
        return Apollo.useQuery<Types.QwsBookedEventQuery, Types.QwsBookedEventQueryVariables>(QwsBookedEventDocument, baseOptions);
      }
export function useQwsBookedEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QwsBookedEventQuery, Types.QwsBookedEventQueryVariables>) {
          return Apollo.useLazyQuery<Types.QwsBookedEventQuery, Types.QwsBookedEventQueryVariables>(QwsBookedEventDocument, baseOptions);
        }
export type QwsBookedEventQueryHookResult = ReturnType<typeof useQwsBookedEventQuery>;
export type QwsBookedEventLazyQueryHookResult = ReturnType<typeof useQwsBookedEventLazyQuery>;
export type QwsBookedEventQueryResult = Apollo.QueryResult<Types.QwsBookedEventQuery, Types.QwsBookedEventQueryVariables>;
export const QwsEventDetailsDocument = gql`
    query qwsEventDetails($eventId: String!, $role: UserRoleInput!, $_cacheKey: String) {
  qwsEventDetails(eventId: $eventId, role: $role, _cacheKey: $_cacheKey) {
    ... on QWSEventDetailsResult {
      event {
        eventId
        eventName
        startDate
        endDate
        deliveryMethod
        venueAddress
        timezone
        sessions {
          sessionId
          sessionName
          startTime
          endTime
          programName
          spacesAvailable
          trainerDetails {
            name
            email
          }
        }
      }
      schedule
      details
      requirements
      cancellationPolicy
      tickets {
        ticketId
        name
        currencyIsoCode
        price
        startDate
        endDate
        isDefaultTicket
        canBeUsedWithDiscountCode
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useQwsEventDetailsQuery__
 *
 * To run a query within a React component, call `useQwsEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQwsEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQwsEventDetailsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useQwsEventDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.QwsEventDetailsQuery, Types.QwsEventDetailsQueryVariables>) {
        return Apollo.useQuery<Types.QwsEventDetailsQuery, Types.QwsEventDetailsQueryVariables>(QwsEventDetailsDocument, baseOptions);
      }
export function useQwsEventDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QwsEventDetailsQuery, Types.QwsEventDetailsQueryVariables>) {
          return Apollo.useLazyQuery<Types.QwsEventDetailsQuery, Types.QwsEventDetailsQueryVariables>(QwsEventDetailsDocument, baseOptions);
        }
export type QwsEventDetailsQueryHookResult = ReturnType<typeof useQwsEventDetailsQuery>;
export type QwsEventDetailsLazyQueryHookResult = ReturnType<typeof useQwsEventDetailsLazyQuery>;
export type QwsEventDetailsQueryResult = Apollo.QueryResult<Types.QwsEventDetailsQuery, Types.QwsEventDetailsQueryVariables>;
export const QwsImtTrainerBookingsDocument = gql`
    query qwsImtTrainerBookings($_cacheKey: String) {
  qwsImtTrainerBookings(_cacheKey: $_cacheKey) {
    ... on QwsImtTrainerBookingsResult {
      qwsEvents {
        eventId
        eventName
        type
        deliveryMethod
        startDate
        endDate
        venueAddress
        sessionCount
        instructorCount
        eventStatus
        __typename
      }
      trainingJourneys {
        eventName
        deliveryMethod
        endDate
        eventId
        eventStatus
        programName
        startDate
        trainingJourneyCount
        type
        venueAddress
        __typename
      }
      __typename
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useQwsImtTrainerBookingsQuery__
 *
 * To run a query within a React component, call `useQwsImtTrainerBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQwsImtTrainerBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQwsImtTrainerBookingsQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useQwsImtTrainerBookingsQuery(baseOptions?: Apollo.QueryHookOptions<Types.QwsImtTrainerBookingsQuery, Types.QwsImtTrainerBookingsQueryVariables>) {
        return Apollo.useQuery<Types.QwsImtTrainerBookingsQuery, Types.QwsImtTrainerBookingsQueryVariables>(QwsImtTrainerBookingsDocument, baseOptions);
      }
export function useQwsImtTrainerBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QwsImtTrainerBookingsQuery, Types.QwsImtTrainerBookingsQueryVariables>) {
          return Apollo.useLazyQuery<Types.QwsImtTrainerBookingsQuery, Types.QwsImtTrainerBookingsQueryVariables>(QwsImtTrainerBookingsDocument, baseOptions);
        }
export type QwsImtTrainerBookingsQueryHookResult = ReturnType<typeof useQwsImtTrainerBookingsQuery>;
export type QwsImtTrainerBookingsLazyQueryHookResult = ReturnType<typeof useQwsImtTrainerBookingsLazyQuery>;
export type QwsImtTrainerBookingsQueryResult = Apollo.QueryResult<Types.QwsImtTrainerBookingsQuery, Types.QwsImtTrainerBookingsQueryVariables>;
export const RequestForExtensionDocument = gql`
    mutation requestForExtension($input: RequestForExtensionInput!, $_cacheKey: String) {
  requestForExtension(input: $input, _cacheKey: $_cacheKey) {
    ... on RequestForExtensionResult {
      isSuccess
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type RequestForExtensionMutationFn = Apollo.MutationFunction<Types.RequestForExtensionMutation, Types.RequestForExtensionMutationVariables>;

/**
 * __useRequestForExtensionMutation__
 *
 * To run a mutation, you first call `useRequestForExtensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestForExtensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestForExtensionMutation, { data, loading, error }] = useRequestForExtensionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useRequestForExtensionMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestForExtensionMutation, Types.RequestForExtensionMutationVariables>) {
        return Apollo.useMutation<Types.RequestForExtensionMutation, Types.RequestForExtensionMutationVariables>(RequestForExtensionDocument, baseOptions);
      }
export type RequestForExtensionMutationHookResult = ReturnType<typeof useRequestForExtensionMutation>;
export type RequestForExtensionMutationResult = Apollo.MutationResult<Types.RequestForExtensionMutation>;
export type RequestForExtensionMutationOptions = Apollo.BaseMutationOptions<Types.RequestForExtensionMutation, Types.RequestForExtensionMutationVariables>;
export const SearchEventsDocument = gql`
    query searchEvents($searchInfo: SearchEventsInput!, $filterInfo: FilterEventsInput!, $_cacheKey: String) {
  searchEvents(
    searchInfo: $searchInfo
    filterInfo: $filterInfo
    _cacheKey: $_cacheKey
  ) {
    ... on EventsResult {
      edges {
        cursor
        node {
          eventId
          eventName
          startDate
          endDate
          type
          deliveryMethod
          spacesAvailable
          venueAddress
          programName
          programNames
        }
      }
      pageInfo {
        endCursor {
          eventId
          sortValues
        }
        hasNextPage
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useSearchEventsQuery__
 *
 * To run a query within a React component, call `useSearchEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEventsQuery({
 *   variables: {
 *      searchInfo: // value for 'searchInfo'
 *      filterInfo: // value for 'filterInfo'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSearchEventsQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchEventsQuery, Types.SearchEventsQueryVariables>) {
        return Apollo.useQuery<Types.SearchEventsQuery, Types.SearchEventsQueryVariables>(SearchEventsDocument, baseOptions);
      }
export function useSearchEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchEventsQuery, Types.SearchEventsQueryVariables>) {
          return Apollo.useLazyQuery<Types.SearchEventsQuery, Types.SearchEventsQueryVariables>(SearchEventsDocument, baseOptions);
        }
export type SearchEventsQueryHookResult = ReturnType<typeof useSearchEventsQuery>;
export type SearchEventsLazyQueryHookResult = ReturnType<typeof useSearchEventsLazyQuery>;
export type SearchEventsQueryResult = Apollo.QueryResult<Types.SearchEventsQuery, Types.SearchEventsQueryVariables>;
export const AddClubListingDocument = gql`
    mutation addClubListing($input: AddClubListingInput!, $_cacheKey: String) {
  addClubListing(input: $input, _cacheKey: $_cacheKey) {
    ... on ClubListingResult {
      results {
        ... on ClubSingleListing {
          ...singleListingFields
        }
        ... on ClubMultiListing {
          ...multiListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type AddClubListingMutationFn = Apollo.MutationFunction<Types.AddClubListingMutation, Types.AddClubListingMutationVariables>;

/**
 * __useAddClubListingMutation__
 *
 * To run a mutation, you first call `useAddClubListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClubListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClubListingMutation, { data, loading, error }] = useAddClubListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useAddClubListingMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddClubListingMutation, Types.AddClubListingMutationVariables>) {
        return Apollo.useMutation<Types.AddClubListingMutation, Types.AddClubListingMutationVariables>(AddClubListingDocument, baseOptions);
      }
export type AddClubListingMutationHookResult = ReturnType<typeof useAddClubListingMutation>;
export type AddClubListingMutationResult = Apollo.MutationResult<Types.AddClubListingMutation>;
export type AddClubListingMutationOptions = Apollo.BaseMutationOptions<Types.AddClubListingMutation, Types.AddClubListingMutationVariables>;
export const AddEmergencyContactDocument = gql`
    mutation addEmergencyContact($input: EmergencyContactInput!, $_cacheKey: String) {
  addEmergencyContact(input: $input, _cacheKey: $_cacheKey) {
    ... on AddEmergencyContactResult {
      result {
        address
        name
        phone
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type AddEmergencyContactMutationFn = Apollo.MutationFunction<Types.AddEmergencyContactMutation, Types.AddEmergencyContactMutationVariables>;

/**
 * __useAddEmergencyContactMutation__
 *
 * To run a mutation, you first call `useAddEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmergencyContactMutation, { data, loading, error }] = useAddEmergencyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useAddEmergencyContactMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddEmergencyContactMutation, Types.AddEmergencyContactMutationVariables>) {
        return Apollo.useMutation<Types.AddEmergencyContactMutation, Types.AddEmergencyContactMutationVariables>(AddEmergencyContactDocument, baseOptions);
      }
export type AddEmergencyContactMutationHookResult = ReturnType<typeof useAddEmergencyContactMutation>;
export type AddEmergencyContactMutationResult = Apollo.MutationResult<Types.AddEmergencyContactMutation>;
export type AddEmergencyContactMutationOptions = Apollo.BaseMutationOptions<Types.AddEmergencyContactMutation, Types.AddEmergencyContactMutationVariables>;
export const AddToShortlistDocument = gql`
    mutation addToShortlist($input: AddToShortlistInput!, $role: UserRoleInput!, $_cacheKey: String) {
  addToShortlist(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on AddToShortlistResult {
      shortlist {
        id
        profile {
          ...instructorProfileFields
        }
        user {
          id
          lastName
          firstName
        }
        lastMessageIn48HoursSentAt
        distanceUnit
        distance
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type AddToShortlistMutationFn = Apollo.MutationFunction<Types.AddToShortlistMutation, Types.AddToShortlistMutationVariables>;

/**
 * __useAddToShortlistMutation__
 *
 * To run a mutation, you first call `useAddToShortlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToShortlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToShortlistMutation, { data, loading, error }] = useAddToShortlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useAddToShortlistMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddToShortlistMutation, Types.AddToShortlistMutationVariables>) {
        return Apollo.useMutation<Types.AddToShortlistMutation, Types.AddToShortlistMutationVariables>(AddToShortlistDocument, baseOptions);
      }
export type AddToShortlistMutationHookResult = ReturnType<typeof useAddToShortlistMutation>;
export type AddToShortlistMutationResult = Apollo.MutationResult<Types.AddToShortlistMutation>;
export type AddToShortlistMutationOptions = Apollo.BaseMutationOptions<Types.AddToShortlistMutation, Types.AddToShortlistMutationVariables>;
export const CreateAffiliationDocument = gql`
    mutation createAffiliation($input: CreateAffiliationInput!, $role: UserRoleInput!, $_cacheKey: String) {
  createAffiliation(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on AffiliationAsyncResult {
      status
      affilation {
        __typename
        actionedDate
        clubId
        id
        latestActionInitiator
        pendingStatus
        requestedBy
        requestedDate
        role
        status
        userId
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type CreateAffiliationMutationFn = Apollo.MutationFunction<Types.CreateAffiliationMutation, Types.CreateAffiliationMutationVariables>;

/**
 * __useCreateAffiliationMutation__
 *
 * To run a mutation, you first call `useCreateAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAffiliationMutation, { data, loading, error }] = useCreateAffiliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useCreateAffiliationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAffiliationMutation, Types.CreateAffiliationMutationVariables>) {
        return Apollo.useMutation<Types.CreateAffiliationMutation, Types.CreateAffiliationMutationVariables>(CreateAffiliationDocument, baseOptions);
      }
export type CreateAffiliationMutationHookResult = ReturnType<typeof useCreateAffiliationMutation>;
export type CreateAffiliationMutationResult = Apollo.MutationResult<Types.CreateAffiliationMutation>;
export type CreateAffiliationMutationOptions = Apollo.BaseMutationOptions<Types.CreateAffiliationMutation, Types.CreateAffiliationMutationVariables>;
export const CreateClubListingsDocument = gql`
    mutation createClubListings($input: CreateClubListingsInput!, $_cacheKey: String) {
  createClubListings(input: $input, _cacheKey: $_cacheKey) {
    ... on CreateClubListingsResult {
      successfulListings {
        ... on ClubSingleListing {
          ...singleListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type CreateClubListingsMutationFn = Apollo.MutationFunction<Types.CreateClubListingsMutation, Types.CreateClubListingsMutationVariables>;

/**
 * __useCreateClubListingsMutation__
 *
 * To run a mutation, you first call `useCreateClubListingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClubListingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClubListingsMutation, { data, loading, error }] = useCreateClubListingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useCreateClubListingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateClubListingsMutation, Types.CreateClubListingsMutationVariables>) {
        return Apollo.useMutation<Types.CreateClubListingsMutation, Types.CreateClubListingsMutationVariables>(CreateClubListingsDocument, baseOptions);
      }
export type CreateClubListingsMutationHookResult = ReturnType<typeof useCreateClubListingsMutation>;
export type CreateClubListingsMutationResult = Apollo.MutationResult<Types.CreateClubListingsMutation>;
export type CreateClubListingsMutationOptions = Apollo.BaseMutationOptions<Types.CreateClubListingsMutation, Types.CreateClubListingsMutationVariables>;
export const DeleteAffiliationDocument = gql`
    mutation deleteAffiliation($input: DeleteAffiliationInput!, $role: UserRoleInput!, $_cacheKey: String) {
  deleteAffiliation(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on AffiliationAsyncResult {
      status
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type DeleteAffiliationMutationFn = Apollo.MutationFunction<Types.DeleteAffiliationMutation, Types.DeleteAffiliationMutationVariables>;

/**
 * __useDeleteAffiliationMutation__
 *
 * To run a mutation, you first call `useDeleteAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAffiliationMutation, { data, loading, error }] = useDeleteAffiliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useDeleteAffiliationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteAffiliationMutation, Types.DeleteAffiliationMutationVariables>) {
        return Apollo.useMutation<Types.DeleteAffiliationMutation, Types.DeleteAffiliationMutationVariables>(DeleteAffiliationDocument, baseOptions);
      }
export type DeleteAffiliationMutationHookResult = ReturnType<typeof useDeleteAffiliationMutation>;
export type DeleteAffiliationMutationResult = Apollo.MutationResult<Types.DeleteAffiliationMutation>;
export type DeleteAffiliationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteAffiliationMutation, Types.DeleteAffiliationMutationVariables>;
export const DeleteClubListingDocument = gql`
    mutation deleteClubListing($input: DeleteClubListingInput!, $_cacheKey: String) {
  deleteClubListing(input: $input, _cacheKey: $_cacheKey) {
    ... on DeleteClubListingResult {
      clubId
      status
      listingId
      results {
        ... on ClubSingleListing {
          ...singleListingFields
        }
        ... on ClubMultiListing {
          ...multiListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type DeleteClubListingMutationFn = Apollo.MutationFunction<Types.DeleteClubListingMutation, Types.DeleteClubListingMutationVariables>;

/**
 * __useDeleteClubListingMutation__
 *
 * To run a mutation, you first call `useDeleteClubListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClubListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClubListingMutation, { data, loading, error }] = useDeleteClubListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useDeleteClubListingMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteClubListingMutation, Types.DeleteClubListingMutationVariables>) {
        return Apollo.useMutation<Types.DeleteClubListingMutation, Types.DeleteClubListingMutationVariables>(DeleteClubListingDocument, baseOptions);
      }
export type DeleteClubListingMutationHookResult = ReturnType<typeof useDeleteClubListingMutation>;
export type DeleteClubListingMutationResult = Apollo.MutationResult<Types.DeleteClubListingMutation>;
export type DeleteClubListingMutationOptions = Apollo.BaseMutationOptions<Types.DeleteClubListingMutation, Types.DeleteClubListingMutationVariables>;
export const DeleteCurrentUserPortfolioImageDocument = gql`
    mutation deleteCurrentUserPortfolioImage($input: DeleteCurrentUserPortfolioImageInput!, $role: UserRoleInput!, $_cacheKey: String) {
  deleteCurrentUserPortfolioImage(
    input: $input
    role: $role
    _cacheKey: $_cacheKey
  ) {
    ... on DeleteCurrentUserPortfolioImageResult {
      status
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type DeleteCurrentUserPortfolioImageMutationFn = Apollo.MutationFunction<Types.DeleteCurrentUserPortfolioImageMutation, Types.DeleteCurrentUserPortfolioImageMutationVariables>;

/**
 * __useDeleteCurrentUserPortfolioImageMutation__
 *
 * To run a mutation, you first call `useDeleteCurrentUserPortfolioImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCurrentUserPortfolioImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCurrentUserPortfolioImageMutation, { data, loading, error }] = useDeleteCurrentUserPortfolioImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useDeleteCurrentUserPortfolioImageMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCurrentUserPortfolioImageMutation, Types.DeleteCurrentUserPortfolioImageMutationVariables>) {
        return Apollo.useMutation<Types.DeleteCurrentUserPortfolioImageMutation, Types.DeleteCurrentUserPortfolioImageMutationVariables>(DeleteCurrentUserPortfolioImageDocument, baseOptions);
      }
export type DeleteCurrentUserPortfolioImageMutationHookResult = ReturnType<typeof useDeleteCurrentUserPortfolioImageMutation>;
export type DeleteCurrentUserPortfolioImageMutationResult = Apollo.MutationResult<Types.DeleteCurrentUserPortfolioImageMutation>;
export type DeleteCurrentUserPortfolioImageMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCurrentUserPortfolioImageMutation, Types.DeleteCurrentUserPortfolioImageMutationVariables>;
export const DeleteMultipleJobListingsDocument = gql`
    mutation deleteMultipleJobListings($input: DeleteClubListingsInput!, $_cacheKey: String) {
  deleteClubListings(input: $input, _cacheKey: $_cacheKey) {
    ... on DeleteClubListingsResult {
      success {
        ... on ClubSingleListing {
          ...singleListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type DeleteMultipleJobListingsMutationFn = Apollo.MutationFunction<Types.DeleteMultipleJobListingsMutation, Types.DeleteMultipleJobListingsMutationVariables>;

/**
 * __useDeleteMultipleJobListingsMutation__
 *
 * To run a mutation, you first call `useDeleteMultipleJobListingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMultipleJobListingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMultipleJobListingsMutation, { data, loading, error }] = useDeleteMultipleJobListingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useDeleteMultipleJobListingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteMultipleJobListingsMutation, Types.DeleteMultipleJobListingsMutationVariables>) {
        return Apollo.useMutation<Types.DeleteMultipleJobListingsMutation, Types.DeleteMultipleJobListingsMutationVariables>(DeleteMultipleJobListingsDocument, baseOptions);
      }
export type DeleteMultipleJobListingsMutationHookResult = ReturnType<typeof useDeleteMultipleJobListingsMutation>;
export type DeleteMultipleJobListingsMutationResult = Apollo.MutationResult<Types.DeleteMultipleJobListingsMutation>;
export type DeleteMultipleJobListingsMutationOptions = Apollo.BaseMutationOptions<Types.DeleteMultipleJobListingsMutation, Types.DeleteMultipleJobListingsMutationVariables>;
export const DigitalOnlyInStudioEnquireDocument = gql`
    mutation digitalOnlyInStudioEnquire($input: EnquireAboutInStudioProgramsInput!, $role: UserRoleInput!, $_cacheKey: String) {
  enquireAboutInStudioPrograms(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on EnquireAboutInStudioProgramsResult {
      isSuccess
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type DigitalOnlyInStudioEnquireMutationFn = Apollo.MutationFunction<Types.DigitalOnlyInStudioEnquireMutation, Types.DigitalOnlyInStudioEnquireMutationVariables>;

/**
 * __useDigitalOnlyInStudioEnquireMutation__
 *
 * To run a mutation, you first call `useDigitalOnlyInStudioEnquireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDigitalOnlyInStudioEnquireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [digitalOnlyInStudioEnquireMutation, { data, loading, error }] = useDigitalOnlyInStudioEnquireMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useDigitalOnlyInStudioEnquireMutation(baseOptions?: Apollo.MutationHookOptions<Types.DigitalOnlyInStudioEnquireMutation, Types.DigitalOnlyInStudioEnquireMutationVariables>) {
        return Apollo.useMutation<Types.DigitalOnlyInStudioEnquireMutation, Types.DigitalOnlyInStudioEnquireMutationVariables>(DigitalOnlyInStudioEnquireDocument, baseOptions);
      }
export type DigitalOnlyInStudioEnquireMutationHookResult = ReturnType<typeof useDigitalOnlyInStudioEnquireMutation>;
export type DigitalOnlyInStudioEnquireMutationResult = Apollo.MutationResult<Types.DigitalOnlyInStudioEnquireMutation>;
export type DigitalOnlyInStudioEnquireMutationOptions = Apollo.BaseMutationOptions<Types.DigitalOnlyInStudioEnquireMutation, Types.DigitalOnlyInStudioEnquireMutationVariables>;
export const EnquireAboutOfferingDocument = gql`
    mutation enquireAboutOffering($input: EnquireAboutOnlineProgramInput!, $role: UserRoleInput!, $_cacheKey: String) {
  enquireAboutOnlineProgram(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on EnquireAboutOnlineProgramResult {
      lastEnquiredOn
      onlineProgramid
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type EnquireAboutOfferingMutationFn = Apollo.MutationFunction<Types.EnquireAboutOfferingMutation, Types.EnquireAboutOfferingMutationVariables>;

/**
 * __useEnquireAboutOfferingMutation__
 *
 * To run a mutation, you first call `useEnquireAboutOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnquireAboutOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enquireAboutOfferingMutation, { data, loading, error }] = useEnquireAboutOfferingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useEnquireAboutOfferingMutation(baseOptions?: Apollo.MutationHookOptions<Types.EnquireAboutOfferingMutation, Types.EnquireAboutOfferingMutationVariables>) {
        return Apollo.useMutation<Types.EnquireAboutOfferingMutation, Types.EnquireAboutOfferingMutationVariables>(EnquireAboutOfferingDocument, baseOptions);
      }
export type EnquireAboutOfferingMutationHookResult = ReturnType<typeof useEnquireAboutOfferingMutation>;
export type EnquireAboutOfferingMutationResult = Apollo.MutationResult<Types.EnquireAboutOfferingMutation>;
export type EnquireAboutOfferingMutationOptions = Apollo.BaseMutationOptions<Types.EnquireAboutOfferingMutation, Types.EnquireAboutOfferingMutationVariables>;
export const EnquireAboutProgramDocument = gql`
    mutation enquireAboutProgram($input: EnquireAboutProgramInput!, $role: UserRoleInput!, $_cacheKey: String) {
  enquireAboutProgram(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on EnquireAboutProgramResult {
      lastEnquiredOn
      programId
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type EnquireAboutProgramMutationFn = Apollo.MutationFunction<Types.EnquireAboutProgramMutation, Types.EnquireAboutProgramMutationVariables>;

/**
 * __useEnquireAboutProgramMutation__
 *
 * To run a mutation, you first call `useEnquireAboutProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnquireAboutProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enquireAboutProgramMutation, { data, loading, error }] = useEnquireAboutProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useEnquireAboutProgramMutation(baseOptions?: Apollo.MutationHookOptions<Types.EnquireAboutProgramMutation, Types.EnquireAboutProgramMutationVariables>) {
        return Apollo.useMutation<Types.EnquireAboutProgramMutation, Types.EnquireAboutProgramMutationVariables>(EnquireAboutProgramDocument, baseOptions);
      }
export type EnquireAboutProgramMutationHookResult = ReturnType<typeof useEnquireAboutProgramMutation>;
export type EnquireAboutProgramMutationResult = Apollo.MutationResult<Types.EnquireAboutProgramMutation>;
export type EnquireAboutProgramMutationOptions = Apollo.BaseMutationOptions<Types.EnquireAboutProgramMutation, Types.EnquireAboutProgramMutationVariables>;
export const EnquireAboutProgramInExploreProgramsDocument = gql`
    mutation enquireAboutProgramInExplorePrograms($input: EnquireAboutExploreProgramsInput!, $role: UserRoleInput!, $_cacheKey: String) {
  enquireAboutProgramInExplorePrograms(
    input: $input
    role: $role
    _cacheKey: $_cacheKey
  ) {
    ... on EnquireAboutExploreProgramsResult {
      lastEnquiredOn
      programId
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type EnquireAboutProgramInExploreProgramsMutationFn = Apollo.MutationFunction<Types.EnquireAboutProgramInExploreProgramsMutation, Types.EnquireAboutProgramInExploreProgramsMutationVariables>;

/**
 * __useEnquireAboutProgramInExploreProgramsMutation__
 *
 * To run a mutation, you first call `useEnquireAboutProgramInExploreProgramsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnquireAboutProgramInExploreProgramsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enquireAboutProgramInExploreProgramsMutation, { data, loading, error }] = useEnquireAboutProgramInExploreProgramsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useEnquireAboutProgramInExploreProgramsMutation(baseOptions?: Apollo.MutationHookOptions<Types.EnquireAboutProgramInExploreProgramsMutation, Types.EnquireAboutProgramInExploreProgramsMutationVariables>) {
        return Apollo.useMutation<Types.EnquireAboutProgramInExploreProgramsMutation, Types.EnquireAboutProgramInExploreProgramsMutationVariables>(EnquireAboutProgramInExploreProgramsDocument, baseOptions);
      }
export type EnquireAboutProgramInExploreProgramsMutationHookResult = ReturnType<typeof useEnquireAboutProgramInExploreProgramsMutation>;
export type EnquireAboutProgramInExploreProgramsMutationResult = Apollo.MutationResult<Types.EnquireAboutProgramInExploreProgramsMutation>;
export type EnquireAboutProgramInExploreProgramsMutationOptions = Apollo.BaseMutationOptions<Types.EnquireAboutProgramInExploreProgramsMutation, Types.EnquireAboutProgramInExploreProgramsMutationVariables>;
export const GetAgreementDocumentDocument = gql`
    query getAgreementDocument($input: GetSignedAgreementInput!, $_cacheKey: String, $versionOnly: Boolean = false) {
  getAgreementDocument(input: $input, _cacheKey: $_cacheKey) {
    ... on AgreementDocumentResult {
      version
      content @skip(if: $versionOnly)
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetAgreementDocumentQuery__
 *
 * To run a query within a React component, call `useGetAgreementDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgreementDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgreementDocumentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *      versionOnly: // value for 'versionOnly'
 *   },
 * });
 */
export function useGetAgreementDocumentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAgreementDocumentQuery, Types.GetAgreementDocumentQueryVariables>) {
        return Apollo.useQuery<Types.GetAgreementDocumentQuery, Types.GetAgreementDocumentQueryVariables>(GetAgreementDocumentDocument, baseOptions);
      }
export function useGetAgreementDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAgreementDocumentQuery, Types.GetAgreementDocumentQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetAgreementDocumentQuery, Types.GetAgreementDocumentQueryVariables>(GetAgreementDocumentDocument, baseOptions);
        }
export type GetAgreementDocumentQueryHookResult = ReturnType<typeof useGetAgreementDocumentQuery>;
export type GetAgreementDocumentLazyQueryHookResult = ReturnType<typeof useGetAgreementDocumentLazyQuery>;
export type GetAgreementDocumentQueryResult = Apollo.QueryResult<Types.GetAgreementDocumentQuery, Types.GetAgreementDocumentQueryVariables>;
export const GetCertificatesDocument = gql`
    query getCertificates($_cacheKey: String) {
  getCertificates(_cacheKey: $_cacheKey) {
    ... on CertificatesResult {
      certificates {
        id
        assessmentLevel
        name
        programId
        level
        certificateUrl
        certificateDetailUrl
        subscribeStatus
        type
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetCertificatesQuery__
 *
 * To run a query within a React component, call `useGetCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificatesQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetCertificatesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCertificatesQuery, Types.GetCertificatesQueryVariables>) {
        return Apollo.useQuery<Types.GetCertificatesQuery, Types.GetCertificatesQueryVariables>(GetCertificatesDocument, baseOptions);
      }
export function useGetCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCertificatesQuery, Types.GetCertificatesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetCertificatesQuery, Types.GetCertificatesQueryVariables>(GetCertificatesDocument, baseOptions);
        }
export type GetCertificatesQueryHookResult = ReturnType<typeof useGetCertificatesQuery>;
export type GetCertificatesLazyQueryHookResult = ReturnType<typeof useGetCertificatesLazyQuery>;
export type GetCertificatesQueryResult = Apollo.QueryResult<Types.GetCertificatesQuery, Types.GetCertificatesQueryVariables>;
export const GetChainAffiliationDocument = gql`
    query getChainAffiliation($input: GetChainAffiliationsInput!, $_cacheKey: String) {
  getChainAffiliations(input: $input, _cacheKey: $_cacheKey) {
    ... on GetChainAffiliationsResult {
      clubs {
        ...clubWithAffiliation
      }
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${ClubWithAffiliationFragmentDoc}`;

/**
 * __useGetChainAffiliationQuery__
 *
 * To run a query within a React component, call `useGetChainAffiliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChainAffiliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChainAffiliationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetChainAffiliationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChainAffiliationQuery, Types.GetChainAffiliationQueryVariables>) {
        return Apollo.useQuery<Types.GetChainAffiliationQuery, Types.GetChainAffiliationQueryVariables>(GetChainAffiliationDocument, baseOptions);
      }
export function useGetChainAffiliationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChainAffiliationQuery, Types.GetChainAffiliationQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetChainAffiliationQuery, Types.GetChainAffiliationQueryVariables>(GetChainAffiliationDocument, baseOptions);
        }
export type GetChainAffiliationQueryHookResult = ReturnType<typeof useGetChainAffiliationQuery>;
export type GetChainAffiliationLazyQueryHookResult = ReturnType<typeof useGetChainAffiliationLazyQuery>;
export type GetChainAffiliationQueryResult = Apollo.QueryResult<Types.GetChainAffiliationQuery, Types.GetChainAffiliationQueryVariables>;
export const GetChainAffiliatedInstructorDocument = gql`
    query getChainAffiliatedInstructor($input: GetChainInstructorAffiliatesInput, $_cacheKey: String) {
  getChainInstructorAffiliates(input: $input, _cacheKey: $_cacheKey) {
    ... on GetChainInstructorAffiliatesResult {
      __typename
      affiliates {
        clubAffiliates {
          ... on ClubAffiliates {
            ...managePeopleClubAffiliatesFields
          }
        }
        profile {
          ... on UserProfileTeaching {
            ...instructorProfileFields
          }
        }
        purchaseOrder
        user {
          ... on User {
            ...managePeopleUserAffiliateFields
          }
        }
      }
      hasPendingAffiliations
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ManagePeopleClubAffiliatesFieldsFragmentDoc}
${InstructorProfileFieldsFragmentDoc}
${ManagePeopleUserAffiliateFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetChainAffiliatedInstructorQuery__
 *
 * To run a query within a React component, call `useGetChainAffiliatedInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChainAffiliatedInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChainAffiliatedInstructorQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetChainAffiliatedInstructorQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetChainAffiliatedInstructorQuery, Types.GetChainAffiliatedInstructorQueryVariables>) {
        return Apollo.useQuery<Types.GetChainAffiliatedInstructorQuery, Types.GetChainAffiliatedInstructorQueryVariables>(GetChainAffiliatedInstructorDocument, baseOptions);
      }
export function useGetChainAffiliatedInstructorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChainAffiliatedInstructorQuery, Types.GetChainAffiliatedInstructorQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetChainAffiliatedInstructorQuery, Types.GetChainAffiliatedInstructorQueryVariables>(GetChainAffiliatedInstructorDocument, baseOptions);
        }
export type GetChainAffiliatedInstructorQueryHookResult = ReturnType<typeof useGetChainAffiliatedInstructorQuery>;
export type GetChainAffiliatedInstructorLazyQueryHookResult = ReturnType<typeof useGetChainAffiliatedInstructorLazyQuery>;
export type GetChainAffiliatedInstructorQueryResult = Apollo.QueryResult<Types.GetChainAffiliatedInstructorQuery, Types.GetChainAffiliatedInstructorQueryVariables>;
export const GetChainInstructorRequestsDocument = gql`
    query getChainInstructorRequests($input: GetChainInstructorAffiliatesInput!, $_cacheKey: String) {
  getChainInstructorAffiliates(input: $input, _cacheKey: $_cacheKey) {
    ... on GetChainInstructorAffiliatesResult {
      __typename
      requests {
        clubId
        clubName
        distance
        distanceUnit
        id
        pendingStatus
        profile {
          ... on UserProfileTeaching {
            ...instructorProfileFields
          }
        }
        requestedBy
        requestedDate
        status
        user {
          ... on User {
            ...managePeopleUserAffiliateFields
          }
        }
      }
      hasPendingAffiliations
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ManagePeopleUserAffiliateFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetChainInstructorRequestsQuery__
 *
 * To run a query within a React component, call `useGetChainInstructorRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChainInstructorRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChainInstructorRequestsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetChainInstructorRequestsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChainInstructorRequestsQuery, Types.GetChainInstructorRequestsQueryVariables>) {
        return Apollo.useQuery<Types.GetChainInstructorRequestsQuery, Types.GetChainInstructorRequestsQueryVariables>(GetChainInstructorRequestsDocument, baseOptions);
      }
export function useGetChainInstructorRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChainInstructorRequestsQuery, Types.GetChainInstructorRequestsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetChainInstructorRequestsQuery, Types.GetChainInstructorRequestsQueryVariables>(GetChainInstructorRequestsDocument, baseOptions);
        }
export type GetChainInstructorRequestsQueryHookResult = ReturnType<typeof useGetChainInstructorRequestsQuery>;
export type GetChainInstructorRequestsLazyQueryHookResult = ReturnType<typeof useGetChainInstructorRequestsLazyQuery>;
export type GetChainInstructorRequestsQueryResult = Apollo.QueryResult<Types.GetChainInstructorRequestsQuery, Types.GetChainInstructorRequestsQueryVariables>;
export const GetChainUserDocument = gql`
    query getChainUser($_cacheKey: String) {
  getChainUser(_cacheKey: $_cacheKey) {
    ... on GetChainUser {
      chainLocations {
        ...clubProfileFields
      }
      isChainUser
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${ClubProfileFieldsFragmentDoc}`;

/**
 * __useGetChainUserQuery__
 *
 * To run a query within a React component, call `useGetChainUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChainUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChainUserQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetChainUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetChainUserQuery, Types.GetChainUserQueryVariables>) {
        return Apollo.useQuery<Types.GetChainUserQuery, Types.GetChainUserQueryVariables>(GetChainUserDocument, baseOptions);
      }
export function useGetChainUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChainUserQuery, Types.GetChainUserQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetChainUserQuery, Types.GetChainUserQueryVariables>(GetChainUserDocument, baseOptions);
        }
export type GetChainUserQueryHookResult = ReturnType<typeof useGetChainUserQuery>;
export type GetChainUserLazyQueryHookResult = ReturnType<typeof useGetChainUserLazyQuery>;
export type GetChainUserQueryResult = Apollo.QueryResult<Types.GetChainUserQuery, Types.GetChainUserQueryVariables>;
export const GetChainUserClubListingsDocument = gql`
    query getChainUserClubListings($input: GetChainUserClubListingsInput!, $_cacheKey: String) {
  getChainUserClubListings(input: $input, _cacheKey: $_cacheKey) {
    ... on GetChainUserClubListingsResult {
      singleListings {
        ... on ClubSingleListing {
          ...singleListingFields
          programId
        }
      }
      multiListings {
        ... on ClubMultiListing {
          ...multiListingFields
          programsId
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetChainUserClubListingsQuery__
 *
 * To run a query within a React component, call `useGetChainUserClubListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChainUserClubListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChainUserClubListingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetChainUserClubListingsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChainUserClubListingsQuery, Types.GetChainUserClubListingsQueryVariables>) {
        return Apollo.useQuery<Types.GetChainUserClubListingsQuery, Types.GetChainUserClubListingsQueryVariables>(GetChainUserClubListingsDocument, baseOptions);
      }
export function useGetChainUserClubListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChainUserClubListingsQuery, Types.GetChainUserClubListingsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetChainUserClubListingsQuery, Types.GetChainUserClubListingsQueryVariables>(GetChainUserClubListingsDocument, baseOptions);
        }
export type GetChainUserClubListingsQueryHookResult = ReturnType<typeof useGetChainUserClubListingsQuery>;
export type GetChainUserClubListingsLazyQueryHookResult = ReturnType<typeof useGetChainUserClubListingsLazyQuery>;
export type GetChainUserClubListingsQueryResult = Apollo.QueryResult<Types.GetChainUserClubListingsQuery, Types.GetChainUserClubListingsQueryVariables>;
export const GetClubBusinessSupportContentDocument = gql`
    query getClubBusinessSupportContent($input: GetClubByIdInput!) {
  getClubById(input: $input) {
    ... on UserProfileClub {
      businessSupport {
        ...BusinessSupportSectionContent
      }
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${BusinessSupportSectionContentFragmentDoc}`;

/**
 * __useGetClubBusinessSupportContentQuery__
 *
 * To run a query within a React component, call `useGetClubBusinessSupportContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubBusinessSupportContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubBusinessSupportContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClubBusinessSupportContentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClubBusinessSupportContentQuery, Types.GetClubBusinessSupportContentQueryVariables>) {
        return Apollo.useQuery<Types.GetClubBusinessSupportContentQuery, Types.GetClubBusinessSupportContentQueryVariables>(GetClubBusinessSupportContentDocument, baseOptions);
      }
export function useGetClubBusinessSupportContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClubBusinessSupportContentQuery, Types.GetClubBusinessSupportContentQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetClubBusinessSupportContentQuery, Types.GetClubBusinessSupportContentQueryVariables>(GetClubBusinessSupportContentDocument, baseOptions);
        }
export type GetClubBusinessSupportContentQueryHookResult = ReturnType<typeof useGetClubBusinessSupportContentQuery>;
export type GetClubBusinessSupportContentLazyQueryHookResult = ReturnType<typeof useGetClubBusinessSupportContentLazyQuery>;
export type GetClubBusinessSupportContentQueryResult = Apollo.QueryResult<Types.GetClubBusinessSupportContentQuery, Types.GetClubBusinessSupportContentQueryVariables>;
export const GetClubInstructorAffiliatesDocument = gql`
    query getClubInstructorAffiliates($input: GetClubInstructorAffiliatesInput!, $_cacheKey: String) {
  getClubInstructorAffiliates(input: $input, _cacheKey: $_cacheKey) {
    ... on GetClubInstructorAffiliatesResult {
      __typename
      affiliates {
        purchaseOrder
        actionedDate
        id
        requestedBy
        requestedDate
        status
        user {
          id
          phone
          email
          accountId
          market {
            name
            locale
            distanceUnit
          }
          lastName
          firstName
        }
        clubId
        profile {
          ... on UserProfileTeaching {
            ...instructorProfileFields
          }
        }
      }
      requests {
        id
        distance
        distanceUnit
        requestedDate
        requestedBy
        status
        pendingStatus
        user {
          id
          phone
          email
          accountId
          market {
            name
            locale
            distanceUnit
          }
          lastName
          firstName
        }
        clubId
        profile {
          ... on UserProfileTeaching {
            ...instructorProfileFields
          }
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetClubInstructorAffiliatesQuery__
 *
 * To run a query within a React component, call `useGetClubInstructorAffiliatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubInstructorAffiliatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubInstructorAffiliatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubInstructorAffiliatesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClubInstructorAffiliatesQuery, Types.GetClubInstructorAffiliatesQueryVariables>) {
        return Apollo.useQuery<Types.GetClubInstructorAffiliatesQuery, Types.GetClubInstructorAffiliatesQueryVariables>(GetClubInstructorAffiliatesDocument, baseOptions);
      }
export function useGetClubInstructorAffiliatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClubInstructorAffiliatesQuery, Types.GetClubInstructorAffiliatesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetClubInstructorAffiliatesQuery, Types.GetClubInstructorAffiliatesQueryVariables>(GetClubInstructorAffiliatesDocument, baseOptions);
        }
export type GetClubInstructorAffiliatesQueryHookResult = ReturnType<typeof useGetClubInstructorAffiliatesQuery>;
export type GetClubInstructorAffiliatesLazyQueryHookResult = ReturnType<typeof useGetClubInstructorAffiliatesLazyQuery>;
export type GetClubInstructorAffiliatesQueryResult = Apollo.QueryResult<Types.GetClubInstructorAffiliatesQuery, Types.GetClubInstructorAffiliatesQueryVariables>;
export const GetClubListingByIdDocument = gql`
    query getClubListingById($listingId: String!) {
  getClubListingById(listingId: $listingId) {
    ... on SearchClubJobListingsSingleResult {
      distance
      distanceUnit
      messageSentOn
      enquiredOn
      onWatchlist
      hasBeenReported
      listing {
        ... on ClubSingleListing {
          ...singleListingFields
        }
        ... on ClubMultiListing {
          ...multiListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetClubListingByIdQuery__
 *
 * To run a query within a React component, call `useGetClubListingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubListingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubListingByIdQuery({
 *   variables: {
 *      listingId: // value for 'listingId'
 *   },
 * });
 */
export function useGetClubListingByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClubListingByIdQuery, Types.GetClubListingByIdQueryVariables>) {
        return Apollo.useQuery<Types.GetClubListingByIdQuery, Types.GetClubListingByIdQueryVariables>(GetClubListingByIdDocument, baseOptions);
      }
export function useGetClubListingByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClubListingByIdQuery, Types.GetClubListingByIdQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetClubListingByIdQuery, Types.GetClubListingByIdQueryVariables>(GetClubListingByIdDocument, baseOptions);
        }
export type GetClubListingByIdQueryHookResult = ReturnType<typeof useGetClubListingByIdQuery>;
export type GetClubListingByIdLazyQueryHookResult = ReturnType<typeof useGetClubListingByIdLazyQuery>;
export type GetClubListingByIdQueryResult = Apollo.QueryResult<Types.GetClubListingByIdQuery, Types.GetClubListingByIdQueryVariables>;
export const GetClubListingsByClubIdDocument = gql`
    query getClubListingsByClubId($input: GetClubListingsByClubIdInput!, $_cacheKey: String) {
  getClubListingsByClubId(input: $input, _cacheKey: $_cacheKey) {
    ... on GetClubListingsByClubIdResult {
      results {
        ... on ClubSingleListing {
          clubInfo {
            id
            name
            address {
              ...addressFields
            }
            profileImage {
              url
            }
            programs {
              ... on ClubProgram {
                name
              }
            }
            hiring
            programIds
          }
          createdAt
          description
          expiringAt
          id
          searchAppearances
          updatedAt
          views
          isSubstituteListing
          program {
            code
            id
            initials
            name
          }
          status
          times {
            ...timePreferences
          }
          enquiriesCount
          enquiries {
            createdAt
          }
          programId
        }
        ... on ClubMultiListing {
          clubInfo {
            id
            name
            address {
              ...addressFields
            }
            profileImage {
              url
            }
            programs {
              ... on ClubProgram {
                name
              }
            }
            hiring
            programIds
          }
          createdAt
          description
          expiringAt
          id
          searchAppearances
          programs {
            code
            id
            initials
            name
          }
          status
          updatedAt
          views
          enquiriesCount
          enquiries {
            createdAt
          }
          programsId
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${AddressFieldsFragmentDoc}
${TimePreferencesFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetClubListingsByClubIdQuery__
 *
 * To run a query within a React component, call `useGetClubListingsByClubIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubListingsByClubIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubListingsByClubIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubListingsByClubIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClubListingsByClubIdQuery, Types.GetClubListingsByClubIdQueryVariables>) {
        return Apollo.useQuery<Types.GetClubListingsByClubIdQuery, Types.GetClubListingsByClubIdQueryVariables>(GetClubListingsByClubIdDocument, baseOptions);
      }
export function useGetClubListingsByClubIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClubListingsByClubIdQuery, Types.GetClubListingsByClubIdQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetClubListingsByClubIdQuery, Types.GetClubListingsByClubIdQueryVariables>(GetClubListingsByClubIdDocument, baseOptions);
        }
export type GetClubListingsByClubIdQueryHookResult = ReturnType<typeof useGetClubListingsByClubIdQuery>;
export type GetClubListingsByClubIdLazyQueryHookResult = ReturnType<typeof useGetClubListingsByClubIdLazyQuery>;
export type GetClubListingsByClubIdQueryResult = Apollo.QueryResult<Types.GetClubListingsByClubIdQuery, Types.GetClubListingsByClubIdQueryVariables>;
export const GetClubManageMyPartnershipDocument = gql`
    query getClubManageMyPartnership($clubId: ID!, $_cacheKey: String) {
  getClubPrograms(clubId: $clubId, _cacheKey: $_cacheKey) {
    ... on ClubProgramsResult {
      exploreProgramsDetails {
        program
        instructorsCount
        lastEnquiredOn
      }
      clubProgramsDetails {
        program
        lastEnquiredOn
        status
        instructorsCount
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetClubManageMyPartnershipQuery__
 *
 * To run a query within a React component, call `useGetClubManageMyPartnershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubManageMyPartnershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubManageMyPartnershipQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubManageMyPartnershipQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClubManageMyPartnershipQuery, Types.GetClubManageMyPartnershipQueryVariables>) {
        return Apollo.useQuery<Types.GetClubManageMyPartnershipQuery, Types.GetClubManageMyPartnershipQueryVariables>(GetClubManageMyPartnershipDocument, baseOptions);
      }
export function useGetClubManageMyPartnershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClubManageMyPartnershipQuery, Types.GetClubManageMyPartnershipQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetClubManageMyPartnershipQuery, Types.GetClubManageMyPartnershipQueryVariables>(GetClubManageMyPartnershipDocument, baseOptions);
        }
export type GetClubManageMyPartnershipQueryHookResult = ReturnType<typeof useGetClubManageMyPartnershipQuery>;
export type GetClubManageMyPartnershipLazyQueryHookResult = ReturnType<typeof useGetClubManageMyPartnershipLazyQuery>;
export type GetClubManageMyPartnershipQueryResult = Apollo.QueryResult<Types.GetClubManageMyPartnershipQuery, Types.GetClubManageMyPartnershipQueryVariables>;
export const GetClubProgramsDocument = gql`
    query getClubPrograms($clubId: ID!, $_cacheKey: String) {
  getClubPrograms(clubId: $clubId, _cacheKey: $_cacheKey) {
    ... on ClubProgramsResult {
      clubProgramsDetails {
        program
        lastEnquiredOn
        status
        instructorsCount
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetClubProgramsQuery__
 *
 * To run a query within a React component, call `useGetClubProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubProgramsQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubProgramsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClubProgramsQuery, Types.GetClubProgramsQueryVariables>) {
        return Apollo.useQuery<Types.GetClubProgramsQuery, Types.GetClubProgramsQueryVariables>(GetClubProgramsDocument, baseOptions);
      }
export function useGetClubProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClubProgramsQuery, Types.GetClubProgramsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetClubProgramsQuery, Types.GetClubProgramsQueryVariables>(GetClubProgramsDocument, baseOptions);
        }
export type GetClubProgramsQueryHookResult = ReturnType<typeof useGetClubProgramsQuery>;
export type GetClubProgramsLazyQueryHookResult = ReturnType<typeof useGetClubProgramsLazyQuery>;
export type GetClubProgramsQueryResult = Apollo.QueryResult<Types.GetClubProgramsQuery, Types.GetClubProgramsQueryVariables>;
export const GetClubSettingsDocument = gql`
    query getClubSettings($input: GetClubSettingsInput!, $_cacheKey: String) {
  getClubSettings(input: $input, _cacheKey: $_cacheKey) {
    ... on GetClubSettingsResult {
      id
      address {
        city
        state
        street
        country
        stateCode
        postalCode
        countryCode
        location {
          lat
          lon
        }
      }
      email {
        main
        affiliations
        jobListings
      }
      id
      name
      phone
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetClubSettingsQuery__
 *
 * To run a query within a React component, call `useGetClubSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubSettingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubSettingsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClubSettingsQuery, Types.GetClubSettingsQueryVariables>) {
        return Apollo.useQuery<Types.GetClubSettingsQuery, Types.GetClubSettingsQueryVariables>(GetClubSettingsDocument, baseOptions);
      }
export function useGetClubSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClubSettingsQuery, Types.GetClubSettingsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetClubSettingsQuery, Types.GetClubSettingsQueryVariables>(GetClubSettingsDocument, baseOptions);
        }
export type GetClubSettingsQueryHookResult = ReturnType<typeof useGetClubSettingsQuery>;
export type GetClubSettingsLazyQueryHookResult = ReturnType<typeof useGetClubSettingsLazyQuery>;
export type GetClubSettingsQueryResult = Apollo.QueryResult<Types.GetClubSettingsQuery, Types.GetClubSettingsQueryVariables>;
export const GetContactUsContentDocument = gql`
    query getContactUsContent($input: GetContactUsContentInput!, $_cacheKey: String) {
  getContactUsContent(input: $input, _cacheKey: $_cacheKey) {
    ... on GetContactUsContentResult {
      result {
        section
        content {
          title
          subHeading
          contentText
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetContactUsContentQuery__
 *
 * To run a query within a React component, call `useGetContactUsContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactUsContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactUsContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetContactUsContentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetContactUsContentQuery, Types.GetContactUsContentQueryVariables>) {
        return Apollo.useQuery<Types.GetContactUsContentQuery, Types.GetContactUsContentQueryVariables>(GetContactUsContentDocument, baseOptions);
      }
export function useGetContactUsContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContactUsContentQuery, Types.GetContactUsContentQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetContactUsContentQuery, Types.GetContactUsContentQueryVariables>(GetContactUsContentDocument, baseOptions);
        }
export type GetContactUsContentQueryHookResult = ReturnType<typeof useGetContactUsContentQuery>;
export type GetContactUsContentLazyQueryHookResult = ReturnType<typeof useGetContactUsContentLazyQuery>;
export type GetContactUsContentQueryResult = Apollo.QueryResult<Types.GetContactUsContentQuery, Types.GetContactUsContentQueryVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser($_cacheKey: String) {
  getCurrentUser(_cacheKey: $_cacheKey) {
    ... on UserResult {
      ...fullUserField
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${FullUserFieldFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCurrentUserQuery, Types.GetCurrentUserQueryVariables>) {
        return Apollo.useQuery<Types.GetCurrentUserQuery, Types.GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCurrentUserQuery, Types.GetCurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetCurrentUserQuery, Types.GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<Types.GetCurrentUserQuery, Types.GetCurrentUserQueryVariables>;
export const GetCurrentUserPhotoUploadDataDocument = gql`
    query getCurrentUserPhotoUploadData($_cacheKey: String) {
  getCurrentUserPhotoUploadData(_cacheKey: $_cacheKey) {
    ... on GetCurrentUserPhotoUploadDataResult {
      url
      token
      __typename
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetCurrentUserPhotoUploadDataQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserPhotoUploadDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserPhotoUploadDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserPhotoUploadDataQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetCurrentUserPhotoUploadDataQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCurrentUserPhotoUploadDataQuery, Types.GetCurrentUserPhotoUploadDataQueryVariables>) {
        return Apollo.useQuery<Types.GetCurrentUserPhotoUploadDataQuery, Types.GetCurrentUserPhotoUploadDataQueryVariables>(GetCurrentUserPhotoUploadDataDocument, baseOptions);
      }
export function useGetCurrentUserPhotoUploadDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCurrentUserPhotoUploadDataQuery, Types.GetCurrentUserPhotoUploadDataQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetCurrentUserPhotoUploadDataQuery, Types.GetCurrentUserPhotoUploadDataQueryVariables>(GetCurrentUserPhotoUploadDataDocument, baseOptions);
        }
export type GetCurrentUserPhotoUploadDataQueryHookResult = ReturnType<typeof useGetCurrentUserPhotoUploadDataQuery>;
export type GetCurrentUserPhotoUploadDataLazyQueryHookResult = ReturnType<typeof useGetCurrentUserPhotoUploadDataLazyQuery>;
export type GetCurrentUserPhotoUploadDataQueryResult = Apollo.QueryResult<Types.GetCurrentUserPhotoUploadDataQuery, Types.GetCurrentUserPhotoUploadDataQueryVariables>;
export const GetCurrentUserPortfolioUploadDataDocument = gql`
    query getCurrentUserPortfolioUploadData($input: GetCurrentUserPortfolioUploadDataInput!, $_cacheKey: String) {
  getCurrentUserPortfolioUploadData(input: $input, _cacheKey: $_cacheKey) {
    ... on GetCurrentUserPortfolioUploadDataResult {
      items {
        url
        token
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetCurrentUserPortfolioUploadDataQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserPortfolioUploadDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserPortfolioUploadDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserPortfolioUploadDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetCurrentUserPortfolioUploadDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCurrentUserPortfolioUploadDataQuery, Types.GetCurrentUserPortfolioUploadDataQueryVariables>) {
        return Apollo.useQuery<Types.GetCurrentUserPortfolioUploadDataQuery, Types.GetCurrentUserPortfolioUploadDataQueryVariables>(GetCurrentUserPortfolioUploadDataDocument, baseOptions);
      }
export function useGetCurrentUserPortfolioUploadDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCurrentUserPortfolioUploadDataQuery, Types.GetCurrentUserPortfolioUploadDataQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetCurrentUserPortfolioUploadDataQuery, Types.GetCurrentUserPortfolioUploadDataQueryVariables>(GetCurrentUserPortfolioUploadDataDocument, baseOptions);
        }
export type GetCurrentUserPortfolioUploadDataQueryHookResult = ReturnType<typeof useGetCurrentUserPortfolioUploadDataQuery>;
export type GetCurrentUserPortfolioUploadDataLazyQueryHookResult = ReturnType<typeof useGetCurrentUserPortfolioUploadDataLazyQuery>;
export type GetCurrentUserPortfolioUploadDataQueryResult = Apollo.QueryResult<Types.GetCurrentUserPortfolioUploadDataQuery, Types.GetCurrentUserPortfolioUploadDataQueryVariables>;
export const GetDashboardDocument = gql`
    query getDashboard($role: UserRoleInput!, $_cacheKey: String) {
  __typename
  getDashboard(role: $role, _cacheKey: $_cacheKey) {
    __typename
    ... on DashboardPageInstructorResponse {
      id
      translation {
        title
      }
      socialMedia {
        title
        items {
          id
          url
          type
          createdAt
          createdAtLabel
          content {
            type
            value
          }
        }
      }
      links {
        id
        imageSet {
          id
          desktop {
            id
            alt
            url
          }
          mobile {
            id
            alt
            url
          }
        }
        title
        subtitle
        url {
          type
          value
        }
      }
      translation {
        title
      }
      certifications {
        id
        status
        active
        lmqLevel
        link {
          id
          url {
            type
            value
          }
          text
        }
        steps {
          id
          label
          status
          action {
            id
            url {
              value
              type
            }
            text
          }
        }
        program {
          id
          name
          code
          initials
        }
      }
      promotions {
        id
        title
        image {
          id
          desktop {
            id
            alt
            url
            width
            height
          }
          mobile {
            id
            alt
            url
            width
            height
          }
        }
        description
        link {
          id
          text
          url {
            value
            type
          }
        }
      }
    }
    ... on DashboardPageClubResponse {
      id
      links {
        id
        imageSet {
          id
          desktop {
            id
            alt
            url
          }
          mobile {
            id
            alt
            url
          }
        }
        title
        subtitle
        url {
          type
          value
        }
      }
      promotions {
        id
        title
        image {
          id
          desktop {
            id
            alt
            url
            width
            height
          }
          mobile {
            id
            alt
            url
            width
            height
          }
        }
        description
        link {
          id
          text
          url {
            value
            type
          }
        }
      }
      partnership {
        ... on ClubPartnershipProgramStats {
          ...ClubDashboardProgramStats
        }
        ... on ClubPartnershipStandardContent {
          ...ClubDashboardStandardContent
        }
        ... on ClubPartnershipBusinessSupportContent {
          ...ClubDashboardBusinessSupportContent
        }
        ... on ClubPartnershipBusinessSupportContent {
          ...ClubDashboardBusinessSupportContent
        }
      }
      myTools {
        id
        imageSet {
          id
          desktop {
            id
            alt
            url
          }
          mobile {
            id
            alt
            url
          }
        }
        title
        subtitle
        url {
          type
          value
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ClubDashboardProgramStatsFragmentDoc}
${ClubDashboardStandardContentFragmentDoc}
${ClubDashboardBusinessSupportContentFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetDashboardQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDashboardQuery, Types.GetDashboardQueryVariables>) {
        return Apollo.useQuery<Types.GetDashboardQuery, Types.GetDashboardQueryVariables>(GetDashboardDocument, baseOptions);
      }
export function useGetDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDashboardQuery, Types.GetDashboardQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetDashboardQuery, Types.GetDashboardQueryVariables>(GetDashboardDocument, baseOptions);
        }
export type GetDashboardQueryHookResult = ReturnType<typeof useGetDashboardQuery>;
export type GetDashboardLazyQueryHookResult = ReturnType<typeof useGetDashboardLazyQuery>;
export type GetDashboardQueryResult = Apollo.QueryResult<Types.GetDashboardQuery, Types.GetDashboardQueryVariables>;
export const GetEqualOpportunitiesPolicyDocument = gql`
    query getEqualOpportunitiesPolicy($input: GetEqualOpportunitiesPolicyInput!, $_cacheKey: String) {
  getEqualOpportunitiesPolicy(input: $input, _cacheKey: $_cacheKey) {
    ... on GetEqualOpportunitiesPolicyResult {
      result {
        section
        content {
          title
          subHeading
          contentText
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetEqualOpportunitiesPolicyQuery__
 *
 * To run a query within a React component, call `useGetEqualOpportunitiesPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEqualOpportunitiesPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEqualOpportunitiesPolicyQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetEqualOpportunitiesPolicyQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEqualOpportunitiesPolicyQuery, Types.GetEqualOpportunitiesPolicyQueryVariables>) {
        return Apollo.useQuery<Types.GetEqualOpportunitiesPolicyQuery, Types.GetEqualOpportunitiesPolicyQueryVariables>(GetEqualOpportunitiesPolicyDocument, baseOptions);
      }
export function useGetEqualOpportunitiesPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEqualOpportunitiesPolicyQuery, Types.GetEqualOpportunitiesPolicyQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetEqualOpportunitiesPolicyQuery, Types.GetEqualOpportunitiesPolicyQueryVariables>(GetEqualOpportunitiesPolicyDocument, baseOptions);
        }
export type GetEqualOpportunitiesPolicyQueryHookResult = ReturnType<typeof useGetEqualOpportunitiesPolicyQuery>;
export type GetEqualOpportunitiesPolicyLazyQueryHookResult = ReturnType<typeof useGetEqualOpportunitiesPolicyLazyQuery>;
export type GetEqualOpportunitiesPolicyQueryResult = Apollo.QueryResult<Types.GetEqualOpportunitiesPolicyQuery, Types.GetEqualOpportunitiesPolicyQueryVariables>;
export const GetFrequentlyAskedQuestionsDocument = gql`
    query getFrequentlyAskedQuestions($input: GetFrequentlyAskedQuestionsInput!, $_cacheKey: String) {
  getFrequentlyAskedQuestions(input: $input, _cacheKey: $_cacheKey) {
    ... on GetFrequentlyAskedQuestionsResult {
      result {
        section
        content {
          title
          subHeading
          contentText
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetFrequentlyAskedQuestionsQuery__
 *
 * To run a query within a React component, call `useGetFrequentlyAskedQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFrequentlyAskedQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFrequentlyAskedQuestionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetFrequentlyAskedQuestionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFrequentlyAskedQuestionsQuery, Types.GetFrequentlyAskedQuestionsQueryVariables>) {
        return Apollo.useQuery<Types.GetFrequentlyAskedQuestionsQuery, Types.GetFrequentlyAskedQuestionsQueryVariables>(GetFrequentlyAskedQuestionsDocument, baseOptions);
      }
export function useGetFrequentlyAskedQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFrequentlyAskedQuestionsQuery, Types.GetFrequentlyAskedQuestionsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetFrequentlyAskedQuestionsQuery, Types.GetFrequentlyAskedQuestionsQueryVariables>(GetFrequentlyAskedQuestionsDocument, baseOptions);
        }
export type GetFrequentlyAskedQuestionsQueryHookResult = ReturnType<typeof useGetFrequentlyAskedQuestionsQuery>;
export type GetFrequentlyAskedQuestionsLazyQueryHookResult = ReturnType<typeof useGetFrequentlyAskedQuestionsLazyQuery>;
export type GetFrequentlyAskedQuestionsQueryResult = Apollo.QueryResult<Types.GetFrequentlyAskedQuestionsQuery, Types.GetFrequentlyAskedQuestionsQueryVariables>;
export const GetHeaderContentDocument = gql`
    query getHeaderContent($input: GetHeaderInput!, $_cacheKey: String) {
  getHeaders(input: $input, _cacheKey: $_cacheKey) {
    ... on GetHeaderResult {
      __typename
      results {
        image {
          id
          desktop {
            id
            alt
            url
          }
          mobile {
            id
            alt
            url
          }
        }
        key
        title
        subHeading
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetHeaderContentQuery__
 *
 * To run a query within a React component, call `useGetHeaderContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeaderContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeaderContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetHeaderContentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetHeaderContentQuery, Types.GetHeaderContentQueryVariables>) {
        return Apollo.useQuery<Types.GetHeaderContentQuery, Types.GetHeaderContentQueryVariables>(GetHeaderContentDocument, baseOptions);
      }
export function useGetHeaderContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetHeaderContentQuery, Types.GetHeaderContentQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetHeaderContentQuery, Types.GetHeaderContentQueryVariables>(GetHeaderContentDocument, baseOptions);
        }
export type GetHeaderContentQueryHookResult = ReturnType<typeof useGetHeaderContentQuery>;
export type GetHeaderContentLazyQueryHookResult = ReturnType<typeof useGetHeaderContentLazyQuery>;
export type GetHeaderContentQueryResult = Apollo.QueryResult<Types.GetHeaderContentQuery, Types.GetHeaderContentQueryVariables>;
export const GetInStudioManageOptionsDocument = gql`
    query getInStudioManageOptions($input: GetPickListInput!) {
  getPickLists(input: $input) {
    ... on GetPickListResult {
      __typename
      ...InStudioManageOptions
    }
    ... on ErrorResult {
      __typename
      ...errorResultFields
    }
  }
}
    ${InStudioManageOptionsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetInStudioManageOptionsQuery__
 *
 * To run a query within a React component, call `useGetInStudioManageOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInStudioManageOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInStudioManageOptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInStudioManageOptionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetInStudioManageOptionsQuery, Types.GetInStudioManageOptionsQueryVariables>) {
        return Apollo.useQuery<Types.GetInStudioManageOptionsQuery, Types.GetInStudioManageOptionsQueryVariables>(GetInStudioManageOptionsDocument, baseOptions);
      }
export function useGetInStudioManageOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInStudioManageOptionsQuery, Types.GetInStudioManageOptionsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetInStudioManageOptionsQuery, Types.GetInStudioManageOptionsQueryVariables>(GetInStudioManageOptionsDocument, baseOptions);
        }
export type GetInStudioManageOptionsQueryHookResult = ReturnType<typeof useGetInStudioManageOptionsQuery>;
export type GetInStudioManageOptionsLazyQueryHookResult = ReturnType<typeof useGetInStudioManageOptionsLazyQuery>;
export type GetInStudioManageOptionsQueryResult = Apollo.QueryResult<Types.GetInStudioManageOptionsQuery, Types.GetInStudioManageOptionsQueryVariables>;
export const GetUserAccountDocument = gql`
    query getUserAccount($input: GetUserByIdInput!, $role: UserRoleInput!, $_cacheKey: String) {
  __typename
  getUserAccount(input: $input, role: $role, _cacheKey: $_cacheKey) {
    __typename
    ... on UserAccountSettingsResult {
      id
      address {
        ...addressFields
      }
      dateOfBirth
      email
      firstName
      lastName
      outlinks {
        url
        title
        _metadata {
          urlSource
        }
      }
      phone
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${AddressFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetUserAccountQuery__
 *
 * To run a query within a React component, call `useGetUserAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAccountQuery({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetUserAccountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserAccountQuery, Types.GetUserAccountQueryVariables>) {
        return Apollo.useQuery<Types.GetUserAccountQuery, Types.GetUserAccountQueryVariables>(GetUserAccountDocument, baseOptions);
      }
export function useGetUserAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserAccountQuery, Types.GetUserAccountQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetUserAccountQuery, Types.GetUserAccountQueryVariables>(GetUserAccountDocument, baseOptions);
        }
export type GetUserAccountQueryHookResult = ReturnType<typeof useGetUserAccountQuery>;
export type GetUserAccountLazyQueryHookResult = ReturnType<typeof useGetUserAccountLazyQuery>;
export type GetUserAccountQueryResult = Apollo.QueryResult<Types.GetUserAccountQuery, Types.GetUserAccountQueryVariables>;
export const GetInstructorEnquiriesDocument = gql`
    query getInstructorEnquiries($role: UserRoleInput!, $filter: UserEnquiriesFilter!, $_cacheKey: String) {
  getUserEnquiries(role: $role, filter: $filter, _cacheKey: $_cacheKey) {
    ... on GetUserEnquiriesResult {
      enquiries {
        listing {
          ... on EnquiryClubListingSingleResult {
            distance
            distanceUnit
            messageSentOn
            enquiredOn
            onWatchlist
            hasBeenReported
            listing {
              ... on ClubSingleListing {
                __typename
                clubInfo {
                  id
                  name
                  hiring
                  address {
                    ...addressFields
                  }
                  profileImage {
                    url
                  }
                  programs {
                    ... on ClubProgram {
                      name
                    }
                  }
                  programIds
                }
                createdAt
                description
                expiringAt
                id
                searchAppearances
                updatedAt
                views
                isSubstituteListing
                status
                times {
                  ...timePreferences
                }
                enquiriesCount
                program {
                  code
                  id
                  initials
                  name
                }
                programId
              }
              ... on ClubMultiListing {
                __typename
                clubInfo {
                  id
                  name
                  hiring
                  address {
                    ...addressFields
                  }
                  profileImage {
                    url
                  }
                  programs {
                    ... on ClubProgram {
                      name
                    }
                  }
                  programIds
                }
                createdAt
                description
                expiringAt
                id
                searchAppearances
                status
                updatedAt
                views
                enquiriesCount
                programs {
                  code
                  id
                  initials
                  name
                }
                programsId
              }
              ... on ClubListingUnknown {
                __typename
                clubInfo {
                  id
                  name
                  hiring
                  address {
                    ...addressFields
                  }
                  profileImage {
                    url
                  }
                  programs {
                    ... on ClubProgram {
                      name
                    }
                  }
                }
                id
              }
            }
          }
        }
        message {
          clubId
          clubName
          id
          listingId
          message
          profileId
          readAt
          requestedBy
          sentAt
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${AddressFieldsFragmentDoc}
${TimePreferencesFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetInstructorEnquiriesQuery__
 *
 * To run a query within a React component, call `useGetInstructorEnquiriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorEnquiriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorEnquiriesQuery({
 *   variables: {
 *      role: // value for 'role'
 *      filter: // value for 'filter'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetInstructorEnquiriesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetInstructorEnquiriesQuery, Types.GetInstructorEnquiriesQueryVariables>) {
        return Apollo.useQuery<Types.GetInstructorEnquiriesQuery, Types.GetInstructorEnquiriesQueryVariables>(GetInstructorEnquiriesDocument, baseOptions);
      }
export function useGetInstructorEnquiriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInstructorEnquiriesQuery, Types.GetInstructorEnquiriesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetInstructorEnquiriesQuery, Types.GetInstructorEnquiriesQueryVariables>(GetInstructorEnquiriesDocument, baseOptions);
        }
export type GetInstructorEnquiriesQueryHookResult = ReturnType<typeof useGetInstructorEnquiriesQuery>;
export type GetInstructorEnquiriesLazyQueryHookResult = ReturnType<typeof useGetInstructorEnquiriesLazyQuery>;
export type GetInstructorEnquiriesQueryResult = Apollo.QueryResult<Types.GetInstructorEnquiriesQuery, Types.GetInstructorEnquiriesQueryVariables>;
export const GetInstructorFacilitiesDocument = gql`
    query getInstructorFacilities($input: GetInstructorClubAffiliatesInput!, $_cacheKey: String) {
  getInstructorClubAffiliates(input: $input, _cacheKey: $_cacheKey) {
    ... on GetInstructorClubAffiliatesResult {
      __typename
      affiliations {
        actionedDate
        clubId
        distance
        distanceUnit
        id
        profile {
          ... on UserProfileClub {
            ...clubProfileFields
          }
        }
        requestedDate
        requestedBy
        status
        pendingStatus
        isDigitalOnly
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ClubProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetInstructorFacilitiesQuery__
 *
 * To run a query within a React component, call `useGetInstructorFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorFacilitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetInstructorFacilitiesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetInstructorFacilitiesQuery, Types.GetInstructorFacilitiesQueryVariables>) {
        return Apollo.useQuery<Types.GetInstructorFacilitiesQuery, Types.GetInstructorFacilitiesQueryVariables>(GetInstructorFacilitiesDocument, baseOptions);
      }
export function useGetInstructorFacilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInstructorFacilitiesQuery, Types.GetInstructorFacilitiesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetInstructorFacilitiesQuery, Types.GetInstructorFacilitiesQueryVariables>(GetInstructorFacilitiesDocument, baseOptions);
        }
export type GetInstructorFacilitiesQueryHookResult = ReturnType<typeof useGetInstructorFacilitiesQuery>;
export type GetInstructorFacilitiesLazyQueryHookResult = ReturnType<typeof useGetInstructorFacilitiesLazyQuery>;
export type GetInstructorFacilitiesQueryResult = Apollo.QueryResult<Types.GetInstructorFacilitiesQuery, Types.GetInstructorFacilitiesQueryVariables>;
export const GetInstructorPortfolioDocument = gql`
    query getInstructorPortfolio($_cacheKey: String) {
  getCurrentUser(_cacheKey: $_cacheKey) {
    ... on UserResult {
      __typename
      profiles {
        ... on UserProfileTeaching {
          media {
            id
            url
          }
        }
        ... on UserProfileClub {
          media {
            id
            url
          }
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetInstructorPortfolioQuery__
 *
 * To run a query within a React component, call `useGetInstructorPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorPortfolioQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetInstructorPortfolioQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetInstructorPortfolioQuery, Types.GetInstructorPortfolioQueryVariables>) {
        return Apollo.useQuery<Types.GetInstructorPortfolioQuery, Types.GetInstructorPortfolioQueryVariables>(GetInstructorPortfolioDocument, baseOptions);
      }
export function useGetInstructorPortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInstructorPortfolioQuery, Types.GetInstructorPortfolioQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetInstructorPortfolioQuery, Types.GetInstructorPortfolioQueryVariables>(GetInstructorPortfolioDocument, baseOptions);
        }
export type GetInstructorPortfolioQueryHookResult = ReturnType<typeof useGetInstructorPortfolioQuery>;
export type GetInstructorPortfolioLazyQueryHookResult = ReturnType<typeof useGetInstructorPortfolioLazyQuery>;
export type GetInstructorPortfolioQueryResult = Apollo.QueryResult<Types.GetInstructorPortfolioQuery, Types.GetInstructorPortfolioQueryVariables>;
export const GetInstructorWatchlistDocument = gql`
    query getInstructorWatchlist($role: UserRoleInput!, $_cacheKey: String) {
  getInstructorWatchlist(role: $role, _cacheKey: $_cacheKey) {
    ... on ErrorResult {
      ...errorResultFields
    }
    ... on SearchClubListingsResult {
      total
      results {
        ... on SearchClubJobListingsSingleResult {
          distance
          distanceUnit
          messageSentOn
          enquiredOn
          onWatchlist
          hasBeenReported
          listing {
            ... on ClubSingleListing {
              ...singleListingFields
              programId
            }
            ... on ClubMultiListing {
              ...multiListingFields
              programsId
            }
          }
        }
        ... on SearchClubProfileSingleResult {
          distance
          distanceUnit
          onWatchlist
          hasBeenReported
          profile {
            ...clubProfileFields
          }
        }
      }
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}
${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}`;

/**
 * __useGetInstructorWatchlistQuery__
 *
 * To run a query within a React component, call `useGetInstructorWatchlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorWatchlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorWatchlistQuery({
 *   variables: {
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetInstructorWatchlistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetInstructorWatchlistQuery, Types.GetInstructorWatchlistQueryVariables>) {
        return Apollo.useQuery<Types.GetInstructorWatchlistQuery, Types.GetInstructorWatchlistQueryVariables>(GetInstructorWatchlistDocument, baseOptions);
      }
export function useGetInstructorWatchlistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInstructorWatchlistQuery, Types.GetInstructorWatchlistQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetInstructorWatchlistQuery, Types.GetInstructorWatchlistQueryVariables>(GetInstructorWatchlistDocument, baseOptions);
        }
export type GetInstructorWatchlistQueryHookResult = ReturnType<typeof useGetInstructorWatchlistQuery>;
export type GetInstructorWatchlistLazyQueryHookResult = ReturnType<typeof useGetInstructorWatchlistLazyQuery>;
export type GetInstructorWatchlistQueryResult = Apollo.QueryResult<Types.GetInstructorWatchlistQuery, Types.GetInstructorWatchlistQueryVariables>;
export const GetUserLevelDocument = gql`
    query getUserLevel($_cacheKey: String) {
  getCurrentUser(_cacheKey: $_cacheKey) {
    ... on UserResult {
      user {
        isInstructorLead
        recordType
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetUserLevelQuery__
 *
 * To run a query within a React component, call `useGetUserLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLevelQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetUserLevelQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUserLevelQuery, Types.GetUserLevelQueryVariables>) {
        return Apollo.useQuery<Types.GetUserLevelQuery, Types.GetUserLevelQueryVariables>(GetUserLevelDocument, baseOptions);
      }
export function useGetUserLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserLevelQuery, Types.GetUserLevelQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetUserLevelQuery, Types.GetUserLevelQueryVariables>(GetUserLevelDocument, baseOptions);
        }
export type GetUserLevelQueryHookResult = ReturnType<typeof useGetUserLevelQuery>;
export type GetUserLevelLazyQueryHookResult = ReturnType<typeof useGetUserLevelLazyQuery>;
export type GetUserLevelQueryResult = Apollo.QueryResult<Types.GetUserLevelQuery, Types.GetUserLevelQueryVariables>;
export const GetJobListingAvailableLocationsDocument = gql`
    query getJobListingAvailableLocations($input: GetClubsAvailabilityOnListingInput!, $_cacheKey: String) {
  getClubsAvailabilityOnListing(input: $input, _cacheKey: $_cacheKey) {
    ... on GetClubsAvailabilityOnListingResult {
      clubsWithExistingListing {
        ...locations
      }
      allClubs {
        ...locations
      }
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${LocationsFragmentDoc}`;

/**
 * __useGetJobListingAvailableLocationsQuery__
 *
 * To run a query within a React component, call `useGetJobListingAvailableLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobListingAvailableLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobListingAvailableLocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetJobListingAvailableLocationsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetJobListingAvailableLocationsQuery, Types.GetJobListingAvailableLocationsQueryVariables>) {
        return Apollo.useQuery<Types.GetJobListingAvailableLocationsQuery, Types.GetJobListingAvailableLocationsQueryVariables>(GetJobListingAvailableLocationsDocument, baseOptions);
      }
export function useGetJobListingAvailableLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobListingAvailableLocationsQuery, Types.GetJobListingAvailableLocationsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetJobListingAvailableLocationsQuery, Types.GetJobListingAvailableLocationsQueryVariables>(GetJobListingAvailableLocationsDocument, baseOptions);
        }
export type GetJobListingAvailableLocationsQueryHookResult = ReturnType<typeof useGetJobListingAvailableLocationsQuery>;
export type GetJobListingAvailableLocationsLazyQueryHookResult = ReturnType<typeof useGetJobListingAvailableLocationsLazyQuery>;
export type GetJobListingAvailableLocationsQueryResult = Apollo.QueryResult<Types.GetJobListingAvailableLocationsQuery, Types.GetJobListingAvailableLocationsQueryVariables>;
export const GetNavigationDocument = gql`
    query getNavigation($input: GetNavigationInput!, $_cacheKey: String) {
  getNavigation(input: $input, _cacheKey: $_cacheKey) {
    __typename
    ... on Navigation {
      __typename
      appMenu {
        id
        name
        items {
          icon
          id
          label
          analyticsEvent
          url {
            type
            value
          }
          items {
            id
            label
            analyticsEvent
            url {
              type
              value
            }
          }
        }
      }
      userMenu {
        icon
        id
        label
        analyticsEvent
        url {
          type
          value
        }
        items {
          id
          label
          analyticsEvent
          url {
            type
            value
          }
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetNavigationQuery__
 *
 * To run a query within a React component, call `useGetNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavigationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetNavigationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNavigationQuery, Types.GetNavigationQueryVariables>) {
        return Apollo.useQuery<Types.GetNavigationQuery, Types.GetNavigationQueryVariables>(GetNavigationDocument, baseOptions);
      }
export function useGetNavigationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNavigationQuery, Types.GetNavigationQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetNavigationQuery, Types.GetNavigationQueryVariables>(GetNavigationDocument, baseOptions);
        }
export type GetNavigationQueryHookResult = ReturnType<typeof useGetNavigationQuery>;
export type GetNavigationLazyQueryHookResult = ReturnType<typeof useGetNavigationLazyQuery>;
export type GetNavigationQueryResult = Apollo.QueryResult<Types.GetNavigationQuery, Types.GetNavigationQueryVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications($filter: DateTimeFilterInput!, $role: UserRoleInput!, $_cacheKey: String) {
  getNotifications(filter: $filter, role: $role, _cacheKey: $_cacheKey) {
    ... on GetNotificationsResult {
      ...notificationsResult
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${NotificationsResultFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNotificationsQuery, Types.GetNotificationsQueryVariables>) {
        return Apollo.useQuery<Types.GetNotificationsQuery, Types.GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNotificationsQuery, Types.GetNotificationsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetNotificationsQuery, Types.GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<Types.GetNotificationsQuery, Types.GetNotificationsQueryVariables>;
export const GetProfileDocument = gql`
    query getProfile($input: GetProfileByIdInput!, $role: UserRoleInput!, $_cacheKey: String) {
  getProfileById(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on GetProfileByIdResult {
      profile {
        ... on UserProfileTeaching {
          ...instructorProfileFields
        }
        ... on UserProfileClub {
          ...clubProfileFields
          programIds
        }
      }
      hasBeenReported
      lastClubMessageAt
      lastClubMessageIsIn48Hours
      lastMessageIn48HoursSentAt
      hasRecentlyDeclinedAffiliation
      hasExistingAffiliation
      onWatchlist
      instructorAffiliation {
        id
        clubId
        pendingStatus
        actionedDate
        requestedDate
        requestedBy
        role
        userId
        status
      }
      profileUser {
        id
        email
        market {
          name
          locale
          distanceUnit
          programs {
            id
            name
            code
            initials
          }
        }
        lastName
        firstName
        accountId
        phone
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileQuery, Types.GetProfileQueryVariables>) {
        return Apollo.useQuery<Types.GetProfileQuery, Types.GetProfileQueryVariables>(GetProfileDocument, baseOptions);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileQuery, Types.GetProfileQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetProfileQuery, Types.GetProfileQueryVariables>(GetProfileDocument, baseOptions);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<Types.GetProfileQuery, Types.GetProfileQueryVariables>;
export const GetProfileMediaDocument = gql`
    query getProfileMedia($input: GetProfileByIdInput!, $role: UserRoleInput!, $_cacheKey: String) {
  getProfileById(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on GetProfileByIdResult {
      profile {
        ... on UserProfileTeaching {
          media {
            ...imageFields
          }
        }
        ... on UserProfileClub {
          media {
            ...imageFields
          }
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ImageFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetProfileMediaQuery__
 *
 * To run a query within a React component, call `useGetProfileMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileMediaQuery({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetProfileMediaQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileMediaQuery, Types.GetProfileMediaQueryVariables>) {
        return Apollo.useQuery<Types.GetProfileMediaQuery, Types.GetProfileMediaQueryVariables>(GetProfileMediaDocument, baseOptions);
      }
export function useGetProfileMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileMediaQuery, Types.GetProfileMediaQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetProfileMediaQuery, Types.GetProfileMediaQueryVariables>(GetProfileMediaDocument, baseOptions);
        }
export type GetProfileMediaQueryHookResult = ReturnType<typeof useGetProfileMediaQuery>;
export type GetProfileMediaLazyQueryHookResult = ReturnType<typeof useGetProfileMediaLazyQuery>;
export type GetProfileMediaQueryResult = Apollo.QueryResult<Types.GetProfileMediaQuery, Types.GetProfileMediaQueryVariables>;
export const GetProfilesDocument = gql`
    query getProfiles($_cacheKey: String) {
  getCurrentUser(_cacheKey: $_cacheKey) {
    ... on UserResult {
      id
      __typename
      profiles {
        ... on UserProfileTeaching {
          ...instructorProfileFields
        }
        ... on UserProfileClub {
          ...clubProfileFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetProfilesQuery__
 *
 * To run a query within a React component, call `useGetProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfilesQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetProfilesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetProfilesQuery, Types.GetProfilesQueryVariables>) {
        return Apollo.useQuery<Types.GetProfilesQuery, Types.GetProfilesQueryVariables>(GetProfilesDocument, baseOptions);
      }
export function useGetProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfilesQuery, Types.GetProfilesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetProfilesQuery, Types.GetProfilesQueryVariables>(GetProfilesDocument, baseOptions);
        }
export type GetProfilesQueryHookResult = ReturnType<typeof useGetProfilesQuery>;
export type GetProfilesLazyQueryHookResult = ReturnType<typeof useGetProfilesLazyQuery>;
export type GetProfilesQueryResult = Apollo.QueryResult<Types.GetProfilesQuery, Types.GetProfilesQueryVariables>;
export const GetSavedInstructorSearchDataDocument = gql`
    query GetSavedInstructorSearchData($input: GetSavedInstructorSearchDataInput!, $_cacheKey: String) {
  getSavedInstructorSearchData(input: $input, _cacheKey: $_cacheKey) {
    ... on GetSavedInstructorSearchDataResult {
      results {
        ...savedInstructorSearch
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SavedInstructorSearchFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetSavedInstructorSearchDataQuery__
 *
 * To run a query within a React component, call `useGetSavedInstructorSearchDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedInstructorSearchDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedInstructorSearchDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetSavedInstructorSearchDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSavedInstructorSearchDataQuery, Types.GetSavedInstructorSearchDataQueryVariables>) {
        return Apollo.useQuery<Types.GetSavedInstructorSearchDataQuery, Types.GetSavedInstructorSearchDataQueryVariables>(GetSavedInstructorSearchDataDocument, baseOptions);
      }
export function useGetSavedInstructorSearchDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSavedInstructorSearchDataQuery, Types.GetSavedInstructorSearchDataQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetSavedInstructorSearchDataQuery, Types.GetSavedInstructorSearchDataQueryVariables>(GetSavedInstructorSearchDataDocument, baseOptions);
        }
export type GetSavedInstructorSearchDataQueryHookResult = ReturnType<typeof useGetSavedInstructorSearchDataQuery>;
export type GetSavedInstructorSearchDataLazyQueryHookResult = ReturnType<typeof useGetSavedInstructorSearchDataLazyQuery>;
export type GetSavedInstructorSearchDataQueryResult = Apollo.QueryResult<Types.GetSavedInstructorSearchDataQuery, Types.GetSavedInstructorSearchDataQueryVariables>;
export const GetSharedNavigationDocument = gql`
    query getSharedNavigation($_cacheKey: String) {
  getSharedNavigation(_cacheKey: $_cacheKey) {
    ... on SharedNavigation {
      instructorNav {
        role
        navItems {
          key
          title
          description
          url {
            type
            value
          }
          image {
            id
            url
            alt
            height
            width
          }
        }
      }
      clubNav {
        role
        navItems {
          key
          title
          description
          url {
            type
            value
          }
          image {
            id
            url
            alt
            height
            width
          }
        }
      }
      websiteLink {
        type
        value
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetSharedNavigationQuery__
 *
 * To run a query within a React component, call `useGetSharedNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedNavigationQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetSharedNavigationQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSharedNavigationQuery, Types.GetSharedNavigationQueryVariables>) {
        return Apollo.useQuery<Types.GetSharedNavigationQuery, Types.GetSharedNavigationQueryVariables>(GetSharedNavigationDocument, baseOptions);
      }
export function useGetSharedNavigationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSharedNavigationQuery, Types.GetSharedNavigationQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetSharedNavigationQuery, Types.GetSharedNavigationQueryVariables>(GetSharedNavigationDocument, baseOptions);
        }
export type GetSharedNavigationQueryHookResult = ReturnType<typeof useGetSharedNavigationQuery>;
export type GetSharedNavigationLazyQueryHookResult = ReturnType<typeof useGetSharedNavigationLazyQuery>;
export type GetSharedNavigationQueryResult = Apollo.QueryResult<Types.GetSharedNavigationQuery, Types.GetSharedNavigationQueryVariables>;
export const GetClubInstructorShortlistDocument = gql`
    query getClubInstructorShortlist($input: GetProfileByIdInput!, $role: UserRoleInput!, $_cacheKey: String) {
  getProfileById(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on GetProfileByIdResult {
      profile {
        ... on UserProfileClub {
          shortlist {
            id
            profile {
              ...instructorProfileFields
            }
            user {
              id
              lastName
              firstName
            }
            lastMessageIn48HoursSentAt
            distanceUnit
            distance
            hasExistingAffiliation
            hasRecentlyDeclinedAffiliation
            instructorAffiliation {
              id
              clubId
              pendingStatus
              actionedDate
              requestedDate
              requestedBy
              role
              userId
              status
            }
          }
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetClubInstructorShortlistQuery__
 *
 * To run a query within a React component, call `useGetClubInstructorShortlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubInstructorShortlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubInstructorShortlistQuery({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetClubInstructorShortlistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClubInstructorShortlistQuery, Types.GetClubInstructorShortlistQueryVariables>) {
        return Apollo.useQuery<Types.GetClubInstructorShortlistQuery, Types.GetClubInstructorShortlistQueryVariables>(GetClubInstructorShortlistDocument, baseOptions);
      }
export function useGetClubInstructorShortlistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClubInstructorShortlistQuery, Types.GetClubInstructorShortlistQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetClubInstructorShortlistQuery, Types.GetClubInstructorShortlistQueryVariables>(GetClubInstructorShortlistDocument, baseOptions);
        }
export type GetClubInstructorShortlistQueryHookResult = ReturnType<typeof useGetClubInstructorShortlistQuery>;
export type GetClubInstructorShortlistLazyQueryHookResult = ReturnType<typeof useGetClubInstructorShortlistLazyQuery>;
export type GetClubInstructorShortlistQueryResult = Apollo.QueryResult<Types.GetClubInstructorShortlistQuery, Types.GetClubInstructorShortlistQueryVariables>;
export const GetUserDocument = gql`
    query getUser($_cacheKey: String) {
  getCurrentUser(_cacheKey: $_cacheKey) {
    ... on UserResult {
      id
      __typename
      distance
      distanceUnit
      user {
        id
        email
        market {
          name
          locale
          distanceUnit
          programs {
            id
            name
            code
            initials
          }
        }
        lastName
        firstName
        accountId
        phone
        signedAgreements {
          type
          signedOn
          version
        }
      }
      settings {
        locale
      }
      profiles {
        ... on UserProfileTeaching {
          id
          __typename
        }
        ... on UserProfileClub {
          id
          __typename
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUserQuery, Types.GetUserQueryVariables>) {
        return Apollo.useQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserQuery, Types.GetUserQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<Types.GetUserQuery, Types.GetUserQueryVariables>;
export const GetVideoGuidelinesDocument = gql`
    query getVideoGuidelines($_cacheKey: String) {
  getVideoGuidelines(_cacheKey: $_cacheKey) {
    ... on GetVideoGuidelinesResult {
      result {
        section
        content {
          title
          subHeading
          contentText
        }
      }
      contactUsEmail
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetVideoGuidelinesQuery__
 *
 * To run a query within a React component, call `useGetVideoGuidelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoGuidelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoGuidelinesQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetVideoGuidelinesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetVideoGuidelinesQuery, Types.GetVideoGuidelinesQueryVariables>) {
        return Apollo.useQuery<Types.GetVideoGuidelinesQuery, Types.GetVideoGuidelinesQueryVariables>(GetVideoGuidelinesDocument, baseOptions);
      }
export function useGetVideoGuidelinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVideoGuidelinesQuery, Types.GetVideoGuidelinesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetVideoGuidelinesQuery, Types.GetVideoGuidelinesQueryVariables>(GetVideoGuidelinesDocument, baseOptions);
        }
export type GetVideoGuidelinesQueryHookResult = ReturnType<typeof useGetVideoGuidelinesQuery>;
export type GetVideoGuidelinesLazyQueryHookResult = ReturnType<typeof useGetVideoGuidelinesLazyQuery>;
export type GetVideoGuidelinesQueryResult = Apollo.QueryResult<Types.GetVideoGuidelinesQuery, Types.GetVideoGuidelinesQueryVariables>;
export const GetWalkthroughContentDocument = gql`
    query getWalkthroughContent($role: UserRoleInput!, $_cacheKey: String) {
  getWalkthrough(role: $role, _cacheKey: $_cacheKey) {
    ... on Walkthrough {
      pages {
        title
        body
        image {
          desktop {
            ...imageFields
          }
          mobile {
            ...imageFields
          }
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ImageFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useGetWalkthroughContentQuery__
 *
 * To run a query within a React component, call `useGetWalkthroughContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalkthroughContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalkthroughContentQuery({
 *   variables: {
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetWalkthroughContentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetWalkthroughContentQuery, Types.GetWalkthroughContentQueryVariables>) {
        return Apollo.useQuery<Types.GetWalkthroughContentQuery, Types.GetWalkthroughContentQueryVariables>(GetWalkthroughContentDocument, baseOptions);
      }
export function useGetWalkthroughContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWalkthroughContentQuery, Types.GetWalkthroughContentQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetWalkthroughContentQuery, Types.GetWalkthroughContentQueryVariables>(GetWalkthroughContentDocument, baseOptions);
        }
export type GetWalkthroughContentQueryHookResult = ReturnType<typeof useGetWalkthroughContentQuery>;
export type GetWalkthroughContentLazyQueryHookResult = ReturnType<typeof useGetWalkthroughContentLazyQuery>;
export type GetWalkthroughContentQueryResult = Apollo.QueryResult<Types.GetWalkthroughContentQuery, Types.GetWalkthroughContentQueryVariables>;
export const IncrementViewClubListingCountDocument = gql`
    mutation incrementViewClubListingCount($input: IncrementViewClubListingCountInput!, $_cacheKey: String) {
  incrementViewClubListingCount(input: $input, _cacheKey: $_cacheKey) {
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type IncrementViewClubListingCountMutationFn = Apollo.MutationFunction<Types.IncrementViewClubListingCountMutation, Types.IncrementViewClubListingCountMutationVariables>;

/**
 * __useIncrementViewClubListingCountMutation__
 *
 * To run a mutation, you first call `useIncrementViewClubListingCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementViewClubListingCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementViewClubListingCountMutation, { data, loading, error }] = useIncrementViewClubListingCountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useIncrementViewClubListingCountMutation(baseOptions?: Apollo.MutationHookOptions<Types.IncrementViewClubListingCountMutation, Types.IncrementViewClubListingCountMutationVariables>) {
        return Apollo.useMutation<Types.IncrementViewClubListingCountMutation, Types.IncrementViewClubListingCountMutationVariables>(IncrementViewClubListingCountDocument, baseOptions);
      }
export type IncrementViewClubListingCountMutationHookResult = ReturnType<typeof useIncrementViewClubListingCountMutation>;
export type IncrementViewClubListingCountMutationResult = Apollo.MutationResult<Types.IncrementViewClubListingCountMutation>;
export type IncrementViewClubListingCountMutationOptions = Apollo.BaseMutationOptions<Types.IncrementViewClubListingCountMutation, Types.IncrementViewClubListingCountMutationVariables>;
export const InitApplicationDocument = gql`
    query initApplication($featureFlags: [String!]!, $_cacheKey: String) {
  getUser: getCurrentUser(_cacheKey: $_cacheKey) {
    ... on UserResult {
      ...fullUserField
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
  getFeatureFlag(input: {featureFlags: $featureFlags}, _cacheKey: $_cacheKey) {
    ... on FeatureFlagResult {
      flags {
        featureFlag
        enabled
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${FullUserFieldFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useInitApplicationQuery__
 *
 * To run a query within a React component, call `useInitApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitApplicationQuery({
 *   variables: {
 *      featureFlags: // value for 'featureFlags'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useInitApplicationQuery(baseOptions: Apollo.QueryHookOptions<Types.InitApplicationQuery, Types.InitApplicationQueryVariables>) {
        return Apollo.useQuery<Types.InitApplicationQuery, Types.InitApplicationQueryVariables>(InitApplicationDocument, baseOptions);
      }
export function useInitApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InitApplicationQuery, Types.InitApplicationQueryVariables>) {
          return Apollo.useLazyQuery<Types.InitApplicationQuery, Types.InitApplicationQueryVariables>(InitApplicationDocument, baseOptions);
        }
export type InitApplicationQueryHookResult = ReturnType<typeof useInitApplicationQuery>;
export type InitApplicationLazyQueryHookResult = ReturnType<typeof useInitApplicationLazyQuery>;
export type InitApplicationQueryResult = Apollo.QueryResult<Types.InitApplicationQuery, Types.InitApplicationQueryVariables>;
export const ManagePeopleCreateAffiliationsDocument = gql`
    mutation managePeopleCreateAffiliations($input: CreateAffiliationsInput!, $_cacheKey: String) {
  createAffiliations(input: $input, _cacheKey: $_cacheKey) {
    ... on ChainAffiliationsResult {
      successfulAffiliations {
        actionedDate
        clubId
        id
        latestActionInitiator
        pendingStatus
        requestedBy
        requestedDate
        role
        status
        userId
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type ManagePeopleCreateAffiliationsMutationFn = Apollo.MutationFunction<Types.ManagePeopleCreateAffiliationsMutation, Types.ManagePeopleCreateAffiliationsMutationVariables>;

/**
 * __useManagePeopleCreateAffiliationsMutation__
 *
 * To run a mutation, you first call `useManagePeopleCreateAffiliationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagePeopleCreateAffiliationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managePeopleCreateAffiliationsMutation, { data, loading, error }] = useManagePeopleCreateAffiliationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useManagePeopleCreateAffiliationsMutation(baseOptions?: Apollo.MutationHookOptions<Types.ManagePeopleCreateAffiliationsMutation, Types.ManagePeopleCreateAffiliationsMutationVariables>) {
        return Apollo.useMutation<Types.ManagePeopleCreateAffiliationsMutation, Types.ManagePeopleCreateAffiliationsMutationVariables>(ManagePeopleCreateAffiliationsDocument, baseOptions);
      }
export type ManagePeopleCreateAffiliationsMutationHookResult = ReturnType<typeof useManagePeopleCreateAffiliationsMutation>;
export type ManagePeopleCreateAffiliationsMutationResult = Apollo.MutationResult<Types.ManagePeopleCreateAffiliationsMutation>;
export type ManagePeopleCreateAffiliationsMutationOptions = Apollo.BaseMutationOptions<Types.ManagePeopleCreateAffiliationsMutation, Types.ManagePeopleCreateAffiliationsMutationVariables>;
export const ManagePeopleRemoveAffiliationsDocument = gql`
    mutation managePeopleRemoveAffiliations($input: DeleteAffiliationsInput!, $_cacheKey: String) {
  removeAffiliations(input: $input, _cacheKey: $_cacheKey) {
    ... on ChainAffiliationsRemoveRresult {
      receivedAffiliationIds
      removedAffiliationIds
      removedUserId
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type ManagePeopleRemoveAffiliationsMutationFn = Apollo.MutationFunction<Types.ManagePeopleRemoveAffiliationsMutation, Types.ManagePeopleRemoveAffiliationsMutationVariables>;

/**
 * __useManagePeopleRemoveAffiliationsMutation__
 *
 * To run a mutation, you first call `useManagePeopleRemoveAffiliationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagePeopleRemoveAffiliationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managePeopleRemoveAffiliationsMutation, { data, loading, error }] = useManagePeopleRemoveAffiliationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useManagePeopleRemoveAffiliationsMutation(baseOptions?: Apollo.MutationHookOptions<Types.ManagePeopleRemoveAffiliationsMutation, Types.ManagePeopleRemoveAffiliationsMutationVariables>) {
        return Apollo.useMutation<Types.ManagePeopleRemoveAffiliationsMutation, Types.ManagePeopleRemoveAffiliationsMutationVariables>(ManagePeopleRemoveAffiliationsDocument, baseOptions);
      }
export type ManagePeopleRemoveAffiliationsMutationHookResult = ReturnType<typeof useManagePeopleRemoveAffiliationsMutation>;
export type ManagePeopleRemoveAffiliationsMutationResult = Apollo.MutationResult<Types.ManagePeopleRemoveAffiliationsMutation>;
export type ManagePeopleRemoveAffiliationsMutationOptions = Apollo.BaseMutationOptions<Types.ManagePeopleRemoveAffiliationsMutation, Types.ManagePeopleRemoveAffiliationsMutationVariables>;
export const MarkNotificationsAsReadDocument = gql`
    mutation markNotificationsAsRead($id: [ID!]!, $role: UserRoleInput!, $_cacheKey: String) {
  toggleNotificationsRead(
    input: {id: $id, read: true}
    role: $role
    _cacheKey: $_cacheKey
  ) {
    __typename
    ... on ToggleNotificationReadResult {
      __typename
      success
      failure
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type MarkNotificationsAsReadMutationFn = Apollo.MutationFunction<Types.MarkNotificationsAsReadMutation, Types.MarkNotificationsAsReadMutationVariables>;

/**
 * __useMarkNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsAsReadMutation, { data, loading, error }] = useMarkNotificationsAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useMarkNotificationsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkNotificationsAsReadMutation, Types.MarkNotificationsAsReadMutationVariables>) {
        return Apollo.useMutation<Types.MarkNotificationsAsReadMutation, Types.MarkNotificationsAsReadMutationVariables>(MarkNotificationsAsReadDocument, baseOptions);
      }
export type MarkNotificationsAsReadMutationHookResult = ReturnType<typeof useMarkNotificationsAsReadMutation>;
export type MarkNotificationsAsReadMutationResult = Apollo.MutationResult<Types.MarkNotificationsAsReadMutation>;
export type MarkNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<Types.MarkNotificationsAsReadMutation, Types.MarkNotificationsAsReadMutationVariables>;
export const MarkNotificationsAsUnreadDocument = gql`
    mutation markNotificationsAsUnread($id: [ID!]!, $role: UserRoleInput!, $_cacheKey: String) {
  toggleNotificationsRead(
    input: {id: $id, read: false}
    role: $role
    _cacheKey: $_cacheKey
  ) {
    ... on ToggleNotificationReadResult {
      __typename
      success
      failure
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type MarkNotificationsAsUnreadMutationFn = Apollo.MutationFunction<Types.MarkNotificationsAsUnreadMutation, Types.MarkNotificationsAsUnreadMutationVariables>;

/**
 * __useMarkNotificationsAsUnreadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsAsUnreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsAsUnreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsAsUnreadMutation, { data, loading, error }] = useMarkNotificationsAsUnreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useMarkNotificationsAsUnreadMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkNotificationsAsUnreadMutation, Types.MarkNotificationsAsUnreadMutationVariables>) {
        return Apollo.useMutation<Types.MarkNotificationsAsUnreadMutation, Types.MarkNotificationsAsUnreadMutationVariables>(MarkNotificationsAsUnreadDocument, baseOptions);
      }
export type MarkNotificationsAsUnreadMutationHookResult = ReturnType<typeof useMarkNotificationsAsUnreadMutation>;
export type MarkNotificationsAsUnreadMutationResult = Apollo.MutationResult<Types.MarkNotificationsAsUnreadMutation>;
export type MarkNotificationsAsUnreadMutationOptions = Apollo.BaseMutationOptions<Types.MarkNotificationsAsUnreadMutation, Types.MarkNotificationsAsUnreadMutationVariables>;
export const GetAtHomeItemsDocument = gql`
    query getAtHomeItems($input: GetClubByIdInput!, $_cacheKey: String) {
  getClubById(input: $input, _cacheKey: $_cacheKey) {
    ... on UserProfileClub {
      atHomeProducts {
        active {
          ...AtHomeProductTile
        }
        inactive {
          ...AtHomeProductTile
        }
      }
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${AtHomeProductTileFragmentDoc}`;

/**
 * __useGetAtHomeItemsQuery__
 *
 * To run a query within a React component, call `useGetAtHomeItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAtHomeItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAtHomeItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetAtHomeItemsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAtHomeItemsQuery, Types.GetAtHomeItemsQueryVariables>) {
        return Apollo.useQuery<Types.GetAtHomeItemsQuery, Types.GetAtHomeItemsQueryVariables>(GetAtHomeItemsDocument, baseOptions);
      }
export function useGetAtHomeItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAtHomeItemsQuery, Types.GetAtHomeItemsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetAtHomeItemsQuery, Types.GetAtHomeItemsQueryVariables>(GetAtHomeItemsDocument, baseOptions);
        }
export type GetAtHomeItemsQueryHookResult = ReturnType<typeof useGetAtHomeItemsQuery>;
export type GetAtHomeItemsLazyQueryHookResult = ReturnType<typeof useGetAtHomeItemsLazyQuery>;
export type GetAtHomeItemsQueryResult = Apollo.QueryResult<Types.GetAtHomeItemsQuery, Types.GetAtHomeItemsQueryVariables>;
export const GetExploreProgramsPageDetailedProgramDocument = gql`
    query getExploreProgramsPageDetailedProgram($role: UserRoleInput!, $programName: ProgramName, $_cacheKey: String) {
  getExploreProgramsPage(
    role: $role
    programName: $programName
    _cacheKey: $_cacheKey
  ) {
    ... on ExploreProgramsPage {
      products {
        ...DetailedExploreProgramTile
      }
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${DetailedExploreProgramTileFragmentDoc}`;

/**
 * __useGetExploreProgramsPageDetailedProgramQuery__
 *
 * To run a query within a React component, call `useGetExploreProgramsPageDetailedProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExploreProgramsPageDetailedProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExploreProgramsPageDetailedProgramQuery({
 *   variables: {
 *      role: // value for 'role'
 *      programName: // value for 'programName'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetExploreProgramsPageDetailedProgramQuery(baseOptions: Apollo.QueryHookOptions<Types.GetExploreProgramsPageDetailedProgramQuery, Types.GetExploreProgramsPageDetailedProgramQueryVariables>) {
        return Apollo.useQuery<Types.GetExploreProgramsPageDetailedProgramQuery, Types.GetExploreProgramsPageDetailedProgramQueryVariables>(GetExploreProgramsPageDetailedProgramDocument, baseOptions);
      }
export function useGetExploreProgramsPageDetailedProgramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetExploreProgramsPageDetailedProgramQuery, Types.GetExploreProgramsPageDetailedProgramQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetExploreProgramsPageDetailedProgramQuery, Types.GetExploreProgramsPageDetailedProgramQueryVariables>(GetExploreProgramsPageDetailedProgramDocument, baseOptions);
        }
export type GetExploreProgramsPageDetailedProgramQueryHookResult = ReturnType<typeof useGetExploreProgramsPageDetailedProgramQuery>;
export type GetExploreProgramsPageDetailedProgramLazyQueryHookResult = ReturnType<typeof useGetExploreProgramsPageDetailedProgramLazyQuery>;
export type GetExploreProgramsPageDetailedProgramQueryResult = Apollo.QueryResult<Types.GetExploreProgramsPageDetailedProgramQuery, Types.GetExploreProgramsPageDetailedProgramQueryVariables>;
export const RemoveFromShortlistDocument = gql`
    mutation removeFromShortlist($input: RemoveFromShortlistInput!, $role: UserRoleInput!, $_cacheKey: String) {
  removeFromShortlist(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on RemoveFromShortlistResult {
      shortlist {
        id
        profile {
          ...instructorProfileFields
        }
        user {
          id
          lastName
          firstName
        }
        lastMessageIn48HoursSentAt
        distanceUnit
        distance
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type RemoveFromShortlistMutationFn = Apollo.MutationFunction<Types.RemoveFromShortlistMutation, Types.RemoveFromShortlistMutationVariables>;

/**
 * __useRemoveFromShortlistMutation__
 *
 * To run a mutation, you first call `useRemoveFromShortlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromShortlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromShortlistMutation, { data, loading, error }] = useRemoveFromShortlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useRemoveFromShortlistMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveFromShortlistMutation, Types.RemoveFromShortlistMutationVariables>) {
        return Apollo.useMutation<Types.RemoveFromShortlistMutation, Types.RemoveFromShortlistMutationVariables>(RemoveFromShortlistDocument, baseOptions);
      }
export type RemoveFromShortlistMutationHookResult = ReturnType<typeof useRemoveFromShortlistMutation>;
export type RemoveFromShortlistMutationResult = Apollo.MutationResult<Types.RemoveFromShortlistMutation>;
export type RemoveFromShortlistMutationOptions = Apollo.BaseMutationOptions<Types.RemoveFromShortlistMutation, Types.RemoveFromShortlistMutationVariables>;
export const ReportClubListingDocument = gql`
    mutation reportClubListing($input: ReportClubListingInput!, $_cacheKey: String) {
  reportClubListing(input: $input, _cacheKey: $_cacheKey) {
    __typename
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type ReportClubListingMutationFn = Apollo.MutationFunction<Types.ReportClubListingMutation, Types.ReportClubListingMutationVariables>;

/**
 * __useReportClubListingMutation__
 *
 * To run a mutation, you first call `useReportClubListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportClubListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportClubListingMutation, { data, loading, error }] = useReportClubListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useReportClubListingMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReportClubListingMutation, Types.ReportClubListingMutationVariables>) {
        return Apollo.useMutation<Types.ReportClubListingMutation, Types.ReportClubListingMutationVariables>(ReportClubListingDocument, baseOptions);
      }
export type ReportClubListingMutationHookResult = ReturnType<typeof useReportClubListingMutation>;
export type ReportClubListingMutationResult = Apollo.MutationResult<Types.ReportClubListingMutation>;
export type ReportClubListingMutationOptions = Apollo.BaseMutationOptions<Types.ReportClubListingMutation, Types.ReportClubListingMutationVariables>;
export const ReportClubProfileDocument = gql`
    mutation reportClubProfile($input: ReportClubProfileInput!, $_cacheKey: String) {
  reportClubProfile(input: $input, _cacheKey: $_cacheKey) {
    __typename
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type ReportClubProfileMutationFn = Apollo.MutationFunction<Types.ReportClubProfileMutation, Types.ReportClubProfileMutationVariables>;

/**
 * __useReportClubProfileMutation__
 *
 * To run a mutation, you first call `useReportClubProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportClubProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportClubProfileMutation, { data, loading, error }] = useReportClubProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useReportClubProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReportClubProfileMutation, Types.ReportClubProfileMutationVariables>) {
        return Apollo.useMutation<Types.ReportClubProfileMutation, Types.ReportClubProfileMutationVariables>(ReportClubProfileDocument, baseOptions);
      }
export type ReportClubProfileMutationHookResult = ReturnType<typeof useReportClubProfileMutation>;
export type ReportClubProfileMutationResult = Apollo.MutationResult<Types.ReportClubProfileMutation>;
export type ReportClubProfileMutationOptions = Apollo.BaseMutationOptions<Types.ReportClubProfileMutation, Types.ReportClubProfileMutationVariables>;
export const RevertAppMetadataDocument = gql`
    mutation revertAppMetadata($input: RevertAppMetadataInput!, $_cacheKey: String) {
  revertAppMetadata(input: $input, _cacheKey: $_cacheKey) {
    ... on AppMetadataResult {
      isSuccess
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type RevertAppMetadataMutationFn = Apollo.MutationFunction<Types.RevertAppMetadataMutation, Types.RevertAppMetadataMutationVariables>;

/**
 * __useRevertAppMetadataMutation__
 *
 * To run a mutation, you first call `useRevertAppMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertAppMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertAppMetadataMutation, { data, loading, error }] = useRevertAppMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useRevertAppMetadataMutation(baseOptions?: Apollo.MutationHookOptions<Types.RevertAppMetadataMutation, Types.RevertAppMetadataMutationVariables>) {
        return Apollo.useMutation<Types.RevertAppMetadataMutation, Types.RevertAppMetadataMutationVariables>(RevertAppMetadataDocument, baseOptions);
      }
export type RevertAppMetadataMutationHookResult = ReturnType<typeof useRevertAppMetadataMutation>;
export type RevertAppMetadataMutationResult = Apollo.MutationResult<Types.RevertAppMetadataMutation>;
export type RevertAppMetadataMutationOptions = Apollo.BaseMutationOptions<Types.RevertAppMetadataMutation, Types.RevertAppMetadataMutationVariables>;
export const SearchClubListingsDocument = gql`
    query searchClubListings($filter: SearchClubListingsFilter!, $options: SearchClubListingsOptions, $_cacheKey: String) {
  searchClubListings(filter: $filter, options: $options, _cacheKey: $_cacheKey) {
    ... on ErrorResult {
      ...errorResultFields
    }
    ... on SearchClubListingsResult {
      total
      results {
        ... on SearchClubJobListingsSingleResult {
          distance
          distanceUnit
          messageSentOn
          enquiredOn
          onWatchlist
          hasBeenReported
          listing {
            ... on ClubSingleListing {
              ...singleListingFields
              programId
            }
            ... on ClubMultiListing {
              ...multiListingFields
              programsId
            }
          }
        }
        ... on SearchClubProfileSingleResult {
          distance
          distanceUnit
          onWatchlist
          hasBeenReported
          profile {
            ...clubProfileFields
          }
        }
      }
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}
${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}`;

/**
 * __useSearchClubListingsQuery__
 *
 * To run a query within a React component, call `useSearchClubListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchClubListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchClubListingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      options: // value for 'options'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSearchClubListingsQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchClubListingsQuery, Types.SearchClubListingsQueryVariables>) {
        return Apollo.useQuery<Types.SearchClubListingsQuery, Types.SearchClubListingsQueryVariables>(SearchClubListingsDocument, baseOptions);
      }
export function useSearchClubListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchClubListingsQuery, Types.SearchClubListingsQueryVariables>) {
          return Apollo.useLazyQuery<Types.SearchClubListingsQuery, Types.SearchClubListingsQueryVariables>(SearchClubListingsDocument, baseOptions);
        }
export type SearchClubListingsQueryHookResult = ReturnType<typeof useSearchClubListingsQuery>;
export type SearchClubListingsLazyQueryHookResult = ReturnType<typeof useSearchClubListingsLazyQuery>;
export type SearchClubListingsQueryResult = Apollo.QueryResult<Types.SearchClubListingsQuery, Types.SearchClubListingsQueryVariables>;
export const SearchClubsDocument = gql`
    query searchClubs($filter: SearchClubsFilter!, $options: SearchClubsOptions, $_cacheKey: String) {
  searchClubs(filter: $filter, options: $options, _cacheKey: $_cacheKey) {
    ... on ErrorResult {
      ...errorResultFields
    }
    ... on SearchClubsResult {
      total
      results {
        ... on SearchClubsSingleResult {
          clubId
          profile {
            ...clubProfileFields
          }
          instructorAffiliation {
            id
            userId
            clubId
            requestedBy
            actionedDate
            status
            pendingStatus
            requestedDate
            latestActionInitiator
          }
          distance
          distanceUnit
        }
      }
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}`;

/**
 * __useSearchClubsQuery__
 *
 * To run a query within a React component, call `useSearchClubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchClubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchClubsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      options: // value for 'options'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSearchClubsQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchClubsQuery, Types.SearchClubsQueryVariables>) {
        return Apollo.useQuery<Types.SearchClubsQuery, Types.SearchClubsQueryVariables>(SearchClubsDocument, baseOptions);
      }
export function useSearchClubsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchClubsQuery, Types.SearchClubsQueryVariables>) {
          return Apollo.useLazyQuery<Types.SearchClubsQuery, Types.SearchClubsQueryVariables>(SearchClubsDocument, baseOptions);
        }
export type SearchClubsQueryHookResult = ReturnType<typeof useSearchClubsQuery>;
export type SearchClubsLazyQueryHookResult = ReturnType<typeof useSearchClubsLazyQuery>;
export type SearchClubsQueryResult = Apollo.QueryResult<Types.SearchClubsQuery, Types.SearchClubsQueryVariables>;
export const SearchInstructorsDocument = gql`
    query searchInstructors($filter: SearchInstructorsFilter!, $options: SearchInstructorsOptions, $role: UserRoleInput!, $_cacheKey: String) {
  searchInstructors(
    filter: $filter
    options: $options
    role: $role
    _cacheKey: $_cacheKey
  ) {
    ... on SearchInstructorsResult {
      ...searchInstructorsResult
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SearchInstructorsResultFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;

/**
 * __useSearchInstructorsQuery__
 *
 * To run a query within a React component, call `useSearchInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInstructorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      options: // value for 'options'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSearchInstructorsQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchInstructorsQuery, Types.SearchInstructorsQueryVariables>) {
        return Apollo.useQuery<Types.SearchInstructorsQuery, Types.SearchInstructorsQueryVariables>(SearchInstructorsDocument, baseOptions);
      }
export function useSearchInstructorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchInstructorsQuery, Types.SearchInstructorsQueryVariables>) {
          return Apollo.useLazyQuery<Types.SearchInstructorsQuery, Types.SearchInstructorsQueryVariables>(SearchInstructorsDocument, baseOptions);
        }
export type SearchInstructorsQueryHookResult = ReturnType<typeof useSearchInstructorsQuery>;
export type SearchInstructorsLazyQueryHookResult = ReturnType<typeof useSearchInstructorsLazyQuery>;
export type SearchInstructorsQueryResult = Apollo.QueryResult<Types.SearchInstructorsQuery, Types.SearchInstructorsQueryVariables>;
export const SendMessageDocument = gql`
    mutation sendMessage($input: SendMessageInput!, $_cacheKey: String) {
  sendMessage(input: $input, _cacheKey: $_cacheKey) {
    ... on SendMessageResult {
      sentAt
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type SendMessageMutationFn = Apollo.MutationFunction<Types.SendMessageMutation, Types.SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendMessageMutation, Types.SendMessageMutationVariables>) {
        return Apollo.useMutation<Types.SendMessageMutation, Types.SendMessageMutationVariables>(SendMessageDocument, baseOptions);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<Types.SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<Types.SendMessageMutation, Types.SendMessageMutationVariables>;
export const SetClubSettingsDocument = gql`
    mutation setClubSettings($input: SetClubSettingsInput!, $_cacheKey: String) {
  setClubSettings(input: $input, _cacheKey: $_cacheKey) {
    ... on SetClubSettingsResult {
      id
      address {
        ...addressFields
      }
      email {
        main
        affiliations
        jobListings
      }
      id
      name
      phone
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${AddressFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type SetClubSettingsMutationFn = Apollo.MutationFunction<Types.SetClubSettingsMutation, Types.SetClubSettingsMutationVariables>;

/**
 * __useSetClubSettingsMutation__
 *
 * To run a mutation, you first call `useSetClubSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClubSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClubSettingsMutation, { data, loading, error }] = useSetClubSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSetClubSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetClubSettingsMutation, Types.SetClubSettingsMutationVariables>) {
        return Apollo.useMutation<Types.SetClubSettingsMutation, Types.SetClubSettingsMutationVariables>(SetClubSettingsDocument, baseOptions);
      }
export type SetClubSettingsMutationHookResult = ReturnType<typeof useSetClubSettingsMutation>;
export type SetClubSettingsMutationResult = Apollo.MutationResult<Types.SetClubSettingsMutation>;
export type SetClubSettingsMutationOptions = Apollo.BaseMutationOptions<Types.SetClubSettingsMutation, Types.SetClubSettingsMutationVariables>;
export const SetInstructorAvailabilityDocument = gql`
    mutation setInstructorAvailability($input: SetInstructorAvailabilityInput!, $_cacheKey: String) {
  setInstructorAvailability(input: $input, _cacheKey: $_cacheKey) {
    ... on InstructorAvailability {
      value
      availableToSubstitute
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type SetInstructorAvailabilityMutationFn = Apollo.MutationFunction<Types.SetInstructorAvailabilityMutation, Types.SetInstructorAvailabilityMutationVariables>;

/**
 * __useSetInstructorAvailabilityMutation__
 *
 * To run a mutation, you first call `useSetInstructorAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInstructorAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInstructorAvailabilityMutation, { data, loading, error }] = useSetInstructorAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSetInstructorAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetInstructorAvailabilityMutation, Types.SetInstructorAvailabilityMutationVariables>) {
        return Apollo.useMutation<Types.SetInstructorAvailabilityMutation, Types.SetInstructorAvailabilityMutationVariables>(SetInstructorAvailabilityDocument, baseOptions);
      }
export type SetInstructorAvailabilityMutationHookResult = ReturnType<typeof useSetInstructorAvailabilityMutation>;
export type SetInstructorAvailabilityMutationResult = Apollo.MutationResult<Types.SetInstructorAvailabilityMutation>;
export type SetInstructorAvailabilityMutationOptions = Apollo.BaseMutationOptions<Types.SetInstructorAvailabilityMutation, Types.SetInstructorAvailabilityMutationVariables>;
export const SetInstructorWatchlistDocument = gql`
    mutation setInstructorWatchlist($input: SetInstructorWatchlistInput!, $role: UserRoleInput!, $_cacheKey: String) {
  setInstructorWatchlist(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on InstructorWatchlist {
      watchlist {
        clubId
        listingId
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type SetInstructorWatchlistMutationFn = Apollo.MutationFunction<Types.SetInstructorWatchlistMutation, Types.SetInstructorWatchlistMutationVariables>;

/**
 * __useSetInstructorWatchlistMutation__
 *
 * To run a mutation, you first call `useSetInstructorWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInstructorWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInstructorWatchlistMutation, { data, loading, error }] = useSetInstructorWatchlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSetInstructorWatchlistMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetInstructorWatchlistMutation, Types.SetInstructorWatchlistMutationVariables>) {
        return Apollo.useMutation<Types.SetInstructorWatchlistMutation, Types.SetInstructorWatchlistMutationVariables>(SetInstructorWatchlistDocument, baseOptions);
      }
export type SetInstructorWatchlistMutationHookResult = ReturnType<typeof useSetInstructorWatchlistMutation>;
export type SetInstructorWatchlistMutationResult = Apollo.MutationResult<Types.SetInstructorWatchlistMutation>;
export type SetInstructorWatchlistMutationOptions = Apollo.BaseMutationOptions<Types.SetInstructorWatchlistMutation, Types.SetInstructorWatchlistMutationVariables>;
export const SetSavedInstructorSearchDataDocument = gql`
    mutation SetSavedInstructorSearchData($input: SetSavedInstructorSearchDataInput!, $_cacheKey: String) {
  setSavedInstructorSearchData(input: $input, _cacheKey: $_cacheKey) {
    ... on SetSavedInstructorSearchDataResult {
      results {
        ...savedInstructorSearch
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SavedInstructorSearchFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type SetSavedInstructorSearchDataMutationFn = Apollo.MutationFunction<Types.SetSavedInstructorSearchDataMutation, Types.SetSavedInstructorSearchDataMutationVariables>;

/**
 * __useSetSavedInstructorSearchDataMutation__
 *
 * To run a mutation, you first call `useSetSavedInstructorSearchDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSavedInstructorSearchDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSavedInstructorSearchDataMutation, { data, loading, error }] = useSetSavedInstructorSearchDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSetSavedInstructorSearchDataMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetSavedInstructorSearchDataMutation, Types.SetSavedInstructorSearchDataMutationVariables>) {
        return Apollo.useMutation<Types.SetSavedInstructorSearchDataMutation, Types.SetSavedInstructorSearchDataMutationVariables>(SetSavedInstructorSearchDataDocument, baseOptions);
      }
export type SetSavedInstructorSearchDataMutationHookResult = ReturnType<typeof useSetSavedInstructorSearchDataMutation>;
export type SetSavedInstructorSearchDataMutationResult = Apollo.MutationResult<Types.SetSavedInstructorSearchDataMutation>;
export type SetSavedInstructorSearchDataMutationOptions = Apollo.BaseMutationOptions<Types.SetSavedInstructorSearchDataMutation, Types.SetSavedInstructorSearchDataMutationVariables>;
export const SetSignedAgreementDataDocument = gql`
    mutation setSignedAgreementData($input: SetSignedAgreementDataInput!, $_cacheKey: String) {
  setSignedAgreementData(input: $input, _cacheKey: $_cacheKey) {
    ... on AgreementDocumentResult {
      agreementType: type
      signedOn
      version
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type SetSignedAgreementDataMutationFn = Apollo.MutationFunction<Types.SetSignedAgreementDataMutation, Types.SetSignedAgreementDataMutationVariables>;

/**
 * __useSetSignedAgreementDataMutation__
 *
 * To run a mutation, you first call `useSetSignedAgreementDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSignedAgreementDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSignedAgreementDataMutation, { data, loading, error }] = useSetSignedAgreementDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSetSignedAgreementDataMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetSignedAgreementDataMutation, Types.SetSignedAgreementDataMutationVariables>) {
        return Apollo.useMutation<Types.SetSignedAgreementDataMutation, Types.SetSignedAgreementDataMutationVariables>(SetSignedAgreementDataDocument, baseOptions);
      }
export type SetSignedAgreementDataMutationHookResult = ReturnType<typeof useSetSignedAgreementDataMutation>;
export type SetSignedAgreementDataMutationResult = Apollo.MutationResult<Types.SetSignedAgreementDataMutation>;
export type SetSignedAgreementDataMutationOptions = Apollo.BaseMutationOptions<Types.SetSignedAgreementDataMutation, Types.SetSignedAgreementDataMutationVariables>;
export const SetWalkthroughToSeenDocument = gql`
    mutation setWalkthroughToSeen($role: UserRoleInput!, $_cacheKey: String) {
  setWalkthroughToSeen(role: $role, _cacheKey: $_cacheKey) {
    ... on SetWalkthroughToSeenResult {
      success
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type SetWalkthroughToSeenMutationFn = Apollo.MutationFunction<Types.SetWalkthroughToSeenMutation, Types.SetWalkthroughToSeenMutationVariables>;

/**
 * __useSetWalkthroughToSeenMutation__
 *
 * To run a mutation, you first call `useSetWalkthroughToSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWalkthroughToSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWalkthroughToSeenMutation, { data, loading, error }] = useSetWalkthroughToSeenMutation({
 *   variables: {
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useSetWalkthroughToSeenMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetWalkthroughToSeenMutation, Types.SetWalkthroughToSeenMutationVariables>) {
        return Apollo.useMutation<Types.SetWalkthroughToSeenMutation, Types.SetWalkthroughToSeenMutationVariables>(SetWalkthroughToSeenDocument, baseOptions);
      }
export type SetWalkthroughToSeenMutationHookResult = ReturnType<typeof useSetWalkthroughToSeenMutation>;
export type SetWalkthroughToSeenMutationResult = Apollo.MutationResult<Types.SetWalkthroughToSeenMutation>;
export type SetWalkthroughToSeenMutationOptions = Apollo.BaseMutationOptions<Types.SetWalkthroughToSeenMutation, Types.SetWalkthroughToSeenMutationVariables>;
export const UpdateAffiliateBillingDocument = gql`
    mutation updateAffiliateBilling($input: PayForCertificationInput!, $_cacheKey: String) {
  payForCertification(input: $input, _cacheKey: $_cacheKey) {
    ... on PayForCertificationResult {
      results {
        certificate {
          active
          billedClubId
          id
          lastPurchase
          lmqLevel
          pricePerQuarter {
            amount
            currency
          }
          program {
            code
            id
            initials
            name
          }
          transactionStatus
          releaseStatus
          status
        }
        instructorName
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type UpdateAffiliateBillingMutationFn = Apollo.MutationFunction<Types.UpdateAffiliateBillingMutation, Types.UpdateAffiliateBillingMutationVariables>;

/**
 * __useUpdateAffiliateBillingMutation__
 *
 * To run a mutation, you first call `useUpdateAffiliateBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAffiliateBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAffiliateBillingMutation, { data, loading, error }] = useUpdateAffiliateBillingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateAffiliateBillingMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAffiliateBillingMutation, Types.UpdateAffiliateBillingMutationVariables>) {
        return Apollo.useMutation<Types.UpdateAffiliateBillingMutation, Types.UpdateAffiliateBillingMutationVariables>(UpdateAffiliateBillingDocument, baseOptions);
      }
export type UpdateAffiliateBillingMutationHookResult = ReturnType<typeof useUpdateAffiliateBillingMutation>;
export type UpdateAffiliateBillingMutationResult = Apollo.MutationResult<Types.UpdateAffiliateBillingMutation>;
export type UpdateAffiliateBillingMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAffiliateBillingMutation, Types.UpdateAffiliateBillingMutationVariables>;
export const UpdateAffiliationDocument = gql`
    mutation updateAffiliation($input: UpdateAffiliationInput!, $role: UserRoleInput!, $_cacheKey: String) {
  updateAffiliation(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on AffiliationAsyncResult {
      status
      hasPendingAffiliations
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export type UpdateAffiliationMutationFn = Apollo.MutationFunction<Types.UpdateAffiliationMutation, Types.UpdateAffiliationMutationVariables>;

/**
 * __useUpdateAffiliationMutation__
 *
 * To run a mutation, you first call `useUpdateAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAffiliationMutation, { data, loading, error }] = useUpdateAffiliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateAffiliationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAffiliationMutation, Types.UpdateAffiliationMutationVariables>) {
        return Apollo.useMutation<Types.UpdateAffiliationMutation, Types.UpdateAffiliationMutationVariables>(UpdateAffiliationDocument, baseOptions);
      }
export type UpdateAffiliationMutationHookResult = ReturnType<typeof useUpdateAffiliationMutation>;
export type UpdateAffiliationMutationResult = Apollo.MutationResult<Types.UpdateAffiliationMutation>;
export type UpdateAffiliationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAffiliationMutation, Types.UpdateAffiliationMutationVariables>;
export const UpdateClubListingDocument = gql`
    mutation updateClubListing($input: UpdateClubListingInput!, $_cacheKey: String) {
  updateClubListing(input: $input, _cacheKey: $_cacheKey) {
    ... on ClubListingResult {
      results {
        ... on ClubSingleListing {
          ...singleListingFields
        }
        ... on ClubMultiListing {
          ...multiListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type UpdateClubListingMutationFn = Apollo.MutationFunction<Types.UpdateClubListingMutation, Types.UpdateClubListingMutationVariables>;

/**
 * __useUpdateClubListingMutation__
 *
 * To run a mutation, you first call `useUpdateClubListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClubListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClubListingMutation, { data, loading, error }] = useUpdateClubListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateClubListingMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateClubListingMutation, Types.UpdateClubListingMutationVariables>) {
        return Apollo.useMutation<Types.UpdateClubListingMutation, Types.UpdateClubListingMutationVariables>(UpdateClubListingDocument, baseOptions);
      }
export type UpdateClubListingMutationHookResult = ReturnType<typeof useUpdateClubListingMutation>;
export type UpdateClubListingMutationResult = Apollo.MutationResult<Types.UpdateClubListingMutation>;
export type UpdateClubListingMutationOptions = Apollo.BaseMutationOptions<Types.UpdateClubListingMutation, Types.UpdateClubListingMutationVariables>;
export const UpdateClubProfileDocument = gql`
    mutation updateClubProfile($input: UpdateClubProfileInput!, $_cacheKey: String) {
  updateClubProfile(input: $input, _cacheKey: $_cacheKey) {
    ... on UserResult {
      ...fullUserField
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${FullUserFieldFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type UpdateClubProfileMutationFn = Apollo.MutationFunction<Types.UpdateClubProfileMutation, Types.UpdateClubProfileMutationVariables>;

/**
 * __useUpdateClubProfileMutation__
 *
 * To run a mutation, you first call `useUpdateClubProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClubProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClubProfileMutation, { data, loading, error }] = useUpdateClubProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateClubProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateClubProfileMutation, Types.UpdateClubProfileMutationVariables>) {
        return Apollo.useMutation<Types.UpdateClubProfileMutation, Types.UpdateClubProfileMutationVariables>(UpdateClubProfileDocument, baseOptions);
      }
export type UpdateClubProfileMutationHookResult = ReturnType<typeof useUpdateClubProfileMutation>;
export type UpdateClubProfileMutationResult = Apollo.MutationResult<Types.UpdateClubProfileMutation>;
export type UpdateClubProfileMutationOptions = Apollo.BaseMutationOptions<Types.UpdateClubProfileMutation, Types.UpdateClubProfileMutationVariables>;
export const FinishPortfolioUpdateDocument = gql`
    mutation finishPortfolioUpdate($input: UpdateCurrentUserPortfolioUploadInput!, $role: UserRoleInput!, $_cacheKey: String) {
  updateCurrentUserPortfolioUpload(
    input: $input
    role: $role
    _cacheKey: $_cacheKey
  ) {
    ... on UpdateCurrentUserPortfolioUploadResult {
      url
      profile {
        ... on UserProfileTeaching {
          ...instructorProfileFields
        }
        ... on UserProfileClub {
          ...clubProfileFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type FinishPortfolioUpdateMutationFn = Apollo.MutationFunction<Types.FinishPortfolioUpdateMutation, Types.FinishPortfolioUpdateMutationVariables>;

/**
 * __useFinishPortfolioUpdateMutation__
 *
 * To run a mutation, you first call `useFinishPortfolioUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishPortfolioUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishPortfolioUpdateMutation, { data, loading, error }] = useFinishPortfolioUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      role: // value for 'role'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useFinishPortfolioUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.FinishPortfolioUpdateMutation, Types.FinishPortfolioUpdateMutationVariables>) {
        return Apollo.useMutation<Types.FinishPortfolioUpdateMutation, Types.FinishPortfolioUpdateMutationVariables>(FinishPortfolioUpdateDocument, baseOptions);
      }
export type FinishPortfolioUpdateMutationHookResult = ReturnType<typeof useFinishPortfolioUpdateMutation>;
export type FinishPortfolioUpdateMutationResult = Apollo.MutationResult<Types.FinishPortfolioUpdateMutation>;
export type FinishPortfolioUpdateMutationOptions = Apollo.BaseMutationOptions<Types.FinishPortfolioUpdateMutation, Types.FinishPortfolioUpdateMutationVariables>;
export const UpdateTeachingProfileDocument = gql`
    mutation updateTeachingProfile($input: UpdateTeachingProfileInput!, $_cacheKey: String) {
  updateTeachingProfile(input: $input, _cacheKey: $_cacheKey) {
    ... on UserResult {
      ...fullUserField
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${FullUserFieldFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type UpdateTeachingProfileMutationFn = Apollo.MutationFunction<Types.UpdateTeachingProfileMutation, Types.UpdateTeachingProfileMutationVariables>;

/**
 * __useUpdateTeachingProfileMutation__
 *
 * To run a mutation, you first call `useUpdateTeachingProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeachingProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeachingProfileMutation, { data, loading, error }] = useUpdateTeachingProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateTeachingProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTeachingProfileMutation, Types.UpdateTeachingProfileMutationVariables>) {
        return Apollo.useMutation<Types.UpdateTeachingProfileMutation, Types.UpdateTeachingProfileMutationVariables>(UpdateTeachingProfileDocument, baseOptions);
      }
export type UpdateTeachingProfileMutationHookResult = ReturnType<typeof useUpdateTeachingProfileMutation>;
export type UpdateTeachingProfileMutationResult = Apollo.MutationResult<Types.UpdateTeachingProfileMutation>;
export type UpdateTeachingProfileMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTeachingProfileMutation, Types.UpdateTeachingProfileMutationVariables>;
export const UpdateUserAccountDocument = gql`
    mutation updateUserAccount($input: UpdateUserAccountInput!, $_cacheKey: String) {
  updateUserAccount(input: $input, _cacheKey: $_cacheKey) {
    ... on UserAccountSettingsResult {
      address {
        ...addressFields
      }
      dateOfBirth
      email
      firstName
      id
      lastName
      phone
      outlinks {
        url
        title
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${AddressFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type UpdateUserAccountMutationFn = Apollo.MutationFunction<Types.UpdateUserAccountMutation, Types.UpdateUserAccountMutationVariables>;

/**
 * __useUpdateUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountMutation, { data, loading, error }] = useUpdateUserAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateUserAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserAccountMutation, Types.UpdateUserAccountMutationVariables>) {
        return Apollo.useMutation<Types.UpdateUserAccountMutation, Types.UpdateUserAccountMutationVariables>(UpdateUserAccountDocument, baseOptions);
      }
export type UpdateUserAccountMutationHookResult = ReturnType<typeof useUpdateUserAccountMutation>;
export type UpdateUserAccountMutationResult = Apollo.MutationResult<Types.UpdateUserAccountMutation>;
export type UpdateUserAccountMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserAccountMutation, Types.UpdateUserAccountMutationVariables>;
export const UpdateUserPhotoDocument = gql`
    mutation updateUserPhoto($input: UpdateUserPhotoInput!, $_cacheKey: String) {
  updateUserPhoto(input: $input, _cacheKey: $_cacheKey) {
    ... on UpdateUserPhotoResult {
      profile {
        ... on UserProfileTeaching {
          ...instructorProfileFields
        }
        ... on UserProfileClub {
          ...clubProfileFields
        }
      }
      url
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export type UpdateUserPhotoMutationFn = Apollo.MutationFunction<Types.UpdateUserPhotoMutation, Types.UpdateUserPhotoMutationVariables>;

/**
 * __useUpdateUserPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateUserPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPhotoMutation, { data, loading, error }] = useUpdateUserPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useUpdateUserPhotoMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserPhotoMutation, Types.UpdateUserPhotoMutationVariables>) {
        return Apollo.useMutation<Types.UpdateUserPhotoMutation, Types.UpdateUserPhotoMutationVariables>(UpdateUserPhotoDocument, baseOptions);
      }
export type UpdateUserPhotoMutationHookResult = ReturnType<typeof useUpdateUserPhotoMutation>;
export type UpdateUserPhotoMutationResult = Apollo.MutationResult<Types.UpdateUserPhotoMutation>;
export type UpdateUserPhotoMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserPhotoMutation, Types.UpdateUserPhotoMutationVariables>;
export const FinalizeUploadDocument = gql`
    mutation finalizeUpload($input: FinalizeUploadInput!, $_cacheKey: String) {
  finalizeUpload(input: $input, _cacheKey: $_cacheKey) {
    ... on FinalizeUploadResult {
      isSuccess
      comment
      playback {
        playbackUrl
        token
      }
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export type FinalizeUploadMutationFn = Apollo.MutationFunction<Types.FinalizeUploadMutation, Types.FinalizeUploadMutationVariables>;

/**
 * __useFinalizeUploadMutation__
 *
 * To run a mutation, you first call `useFinalizeUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeUploadMutation, { data, loading, error }] = useFinalizeUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useFinalizeUploadMutation(baseOptions?: Apollo.MutationHookOptions<Types.FinalizeUploadMutation, Types.FinalizeUploadMutationVariables>) {
        return Apollo.useMutation<Types.FinalizeUploadMutation, Types.FinalizeUploadMutationVariables>(FinalizeUploadDocument, baseOptions);
      }
export type FinalizeUploadMutationHookResult = ReturnType<typeof useFinalizeUploadMutation>;
export type FinalizeUploadMutationResult = Apollo.MutationResult<Types.FinalizeUploadMutation>;
export type FinalizeUploadMutationOptions = Apollo.BaseMutationOptions<Types.FinalizeUploadMutation, Types.FinalizeUploadMutationVariables>;
export const GetPresignedUrlDocument = gql`
    query getPresignedUrl($input: GetPresignedUrlInput!, $_cacheKey: String) {
  getPresignedUrl(input: $input, _cacheKey: $_cacheKey) {
    ... on GetPresignedUrlResult {
      partSignedUrlList {
        partNumber
        url
      }
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;

/**
 * __useGetPresignedUrlQuery__
 *
 * To run a query within a React component, call `useGetPresignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresignedUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetPresignedUrlQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPresignedUrlQuery, Types.GetPresignedUrlQueryVariables>) {
        return Apollo.useQuery<Types.GetPresignedUrlQuery, Types.GetPresignedUrlQueryVariables>(GetPresignedUrlDocument, baseOptions);
      }
export function useGetPresignedUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPresignedUrlQuery, Types.GetPresignedUrlQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetPresignedUrlQuery, Types.GetPresignedUrlQueryVariables>(GetPresignedUrlDocument, baseOptions);
        }
export type GetPresignedUrlQueryHookResult = ReturnType<typeof useGetPresignedUrlQuery>;
export type GetPresignedUrlLazyQueryHookResult = ReturnType<typeof useGetPresignedUrlLazyQuery>;
export type GetPresignedUrlQueryResult = Apollo.QueryResult<Types.GetPresignedUrlQuery, Types.GetPresignedUrlQueryVariables>;
export const InitializeUploadDocument = gql`
    mutation initializeUpload($input: InitializeUploadInput!, $_cacheKey: String) {
  initializeUpload(input: $input, _cacheKey: $_cacheKey) {
    ... on InitializeUploadResult {
      uploadId
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export type InitializeUploadMutationFn = Apollo.MutationFunction<Types.InitializeUploadMutation, Types.InitializeUploadMutationVariables>;

/**
 * __useInitializeUploadMutation__
 *
 * To run a mutation, you first call `useInitializeUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeUploadMutation, { data, loading, error }] = useInitializeUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useInitializeUploadMutation(baseOptions?: Apollo.MutationHookOptions<Types.InitializeUploadMutation, Types.InitializeUploadMutationVariables>) {
        return Apollo.useMutation<Types.InitializeUploadMutation, Types.InitializeUploadMutationVariables>(InitializeUploadDocument, baseOptions);
      }
export type InitializeUploadMutationHookResult = ReturnType<typeof useInitializeUploadMutation>;
export type InitializeUploadMutationResult = Apollo.MutationResult<Types.InitializeUploadMutation>;
export type InitializeUploadMutationOptions = Apollo.BaseMutationOptions<Types.InitializeUploadMutation, Types.InitializeUploadMutationVariables>;