import { createContext, useContext, PropsWithChildren } from 'react'
import {
  Program,
  ProgramConfig,
  programsToCategoryShortCodeMap,
} from 'src/adaptor/programConfig'
import useProgramConfig from 'src/hooks/useProgramConfig'
import useProgramConfigAdaptor from 'src/hooks/useProgramConfigAdaptor'

export const ProgramProviderContext = createContext<ProgramData | null>(null)
export type ProgramConfigDic = { [key: string]: ProgramConfig }

export type GetProgramByShortCode = (
  category: 'VIRTUAL' | 'LIVE',
  programShortCode: string
) => ProgramConfig | null

export type ProgramData = {
  marketPrograms: Program[] | null | undefined
  programConfig: ProgramConfigDic | null
  getProgramByShortCode: GetProgramByShortCode
  getProgramEditonType: (programShortCode: string) => string | null
}

export const ProgramProvider = ({ children }: PropsWithChildren<{}>) => {
  const { programConfig } = useProgramConfig()
  const adaptedPrograms = useProgramConfigAdaptor(programConfig)

  const categorizedProgramsShortCodeMap =
    programsToCategoryShortCodeMap(programConfig)

  const getProgramByShortCode: GetProgramByShortCode = (
    category,
    programShortCode
  ) => {
    return (
      categorizedProgramsShortCodeMap?.[category]?.[programShortCode] || null
    )
  }

  const getProgramEditonType = (programid: string) => {
    const program = programConfig?.[programid]
    return program?.programType || null
  }

  const value: ProgramData = {
    marketPrograms: adaptedPrograms,
    programConfig,
    getProgramByShortCode,
    getProgramEditonType,
  }

  return (
    <ProgramProviderContext.Provider value={value}>
      {children}
    </ProgramProviderContext.Provider>
  )
}

export const useProgramData = () => {
  const context = useContext(ProgramProviderContext)
  if (!context) {
    throw new Error('useProgramData must be used within a ProgramProvider')
  }
  return context
}
