import { CSSProperties } from '@material-ui/styles'
import i18n from 'i18next'

import {
  User,
  Address,
  AffiliationStatus,
  InstructorAvailabilityValue,
  PrivacySettingValue,
  ProfileLinkType,
  TimePreference,
  UserRoleType,
  ProgramCodeEnum,
  ProgramTypeEnum,
  InstructorProfileFieldsFragment,
  ClubProfileFieldsFragment,
  ClubProgram,
  ProgramName,
  UserProfileType,
  UserProfileClub,
  ClubAvailabilityOnListing,
  Currency,
  GeoPointInput,
  CertificationStatusEnum,
  Certificate,
} from 'src/generated/graphql'
import { Certification } from 'src/generated/graphql-react-query'
import { UserProfile } from 'src/hooks/useProfile'
import {
  UserProfileTypeName,
  userProfileTypenameToUserProfileType,
} from 'src/hooks/useProfiles'
import { Option } from 'src/components/inputs/MultiSelectSearchDropDown/DropItem'
import { Status } from 'src/components/inputs/StatusDropdown/StatusDot'
import {
  addressGeocodingAddressTypesMap,
  THE_TRIP,
  THE_TRIP_IMMERSIVE,
} from 'src/utils/constants'

import { isClubProfile } from './typeGuards'
import { FlagsProps } from 'src/hooks/featureFlag/types'
import { CertificationWithLogo } from 'src/components/modals/profile/[profileType]/TeachingProfilePreview'
import { ProgramConfigDic } from 'src/contexts/ProgramProvider'

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const clubHiringToStatus = (hiring?: boolean | null) => {
  if (hiring === null || hiring === undefined) {
    return Status.Neutral
  }
  return hiring ? Status.Positive : Status.Negative
}
export const instructorAvailabilityToStatus = (
  availability: InstructorAvailabilityValue
): Status =>
  availability === InstructorAvailabilityValue.Available
    ? Status.Positive
    : availability === InstructorAvailabilityValue.Unavailable
    ? Status.Negative
    : Status.Neutral

export const convertLinkEnumToLink = (LinkType: ProfileLinkType) => {
  switch (LinkType) {
    case ProfileLinkType.Facebook:
      return 'https://www.facebook.com/'
    case ProfileLinkType.Youtube:
      return 'https://www.youtube.com/'
    case ProfileLinkType.Instagram:
      return 'https://www.instagram.com/'
    case ProfileLinkType.Tiktok:
      return 'https://www.tiktok.com/@'
    case ProfileLinkType.PersonalWebsite:
      return ''
    default:
  }
}

type ObjectWithTypename = {
  __typename?: string
  [key: string]: any
}

export const removeTypename = (obj: ObjectWithTypename) => {
  const { __typename, ...rest } = obj
  return rest
}

export const removeTypenameDeep = <T = ObjectWithTypename>(
  obj: T
): Omit<T, '__typename'> => {
  return Object.keys(obj as { [key: string]: any })
    .filter((key): boolean => key !== '__typename')
    .reduce((acc: Omit<T, '__typename'>, key): Omit<T, '__typename'> => {
      const v = obj[key as Exclude<keyof T, '__typename'>]
      if (Array.isArray(v)) {
        return {
          ...acc,
          [key]: v.map(vv =>
            vv && typeof vv === 'object' ? removeTypenameDeep(vv) : vv
          ),
        }
      }
      if (v && typeof v === 'object') {
        return {
          ...acc,
          [key]: removeTypenameDeep(v),
        }
      }
      return {
        ...acc,
        [key]: v,
      }
    }, {} as Omit<T, '__typename'>)
}

export const removePropsWithEmptyValue = (obj: { [k: string]: any }) =>
  Object.keys(obj).reduce((acc, key) => {
    const v = obj[key]
    if (
      (Array.isArray(v) && v.length === 0) ||
      v === '' ||
      v === null ||
      v === undefined
    ) {
      return acc
    }
    return {
      ...acc,
      [key]: v,
    }
  }, {})

export const shouldDisplayField = (
  privacySetting: PrivacySettingValue | undefined
): boolean => !!privacySetting && privacySetting !== PrivacySettingValue.Private

export const distanceRounder = (distance: number) =>
  distance >= 1 ? Math.round(distance) : Number(distance.toFixed(2))

export function profileTypenameToUserRoleType(
  typename: string
): UserRoleType | undefined {
  switch (typename) {
    case UserProfileTypeName.Club:
      return UserRoleType.Club
    case UserProfileTypeName.Teaching:
      return UserRoleType.Instructor
    default:
      return undefined
  }
}

export const addressIsAreaByGeo = (
  geocoderResult: google.maps.GeocoderResult
): boolean => {
  if (
    geocoderResult.geometry.location_type ===
    google.maps.GeocoderLocationType.ROOFTOP
  ) {
    return false
  }
  return true
}

export const getAreaPoints = (
  geocoderResult: google.maps.GeocoderResult
): GeoPointInput[] => {
  const northEastBounds = geocoderResult.geometry.viewport?.getNorthEast()
  const southWestBounds = geocoderResult.geometry.viewport?.getSouthWest()
  let areaPoints: GeoPointInput[] = []
  if (northEastBounds && southWestBounds) {
    areaPoints = areaPoints.concat([
      {
        lat: northEastBounds.lat(),
        lon: northEastBounds.lng(),
      },
      {
        lat: northEastBounds.lat(),
        lon: southWestBounds.lng(),
      },
      {
        lat: southWestBounds.lat(),
        lon: southWestBounds.lng(),
      },
      {
        lat: southWestBounds.lat(),
        lon: northEastBounds.lng(),
      },
    ])
  }
  return areaPoints
}

export const googleMapsPlaceResultToAddress = (
  // eslint-disable-next-line no-undef
  placeResult: google.maps.GeocoderResult
): Address => {
  const addressComponents = placeResult.address_components
  const address = Object.keys(addressGeocodingAddressTypesMap).reduce(
    (acc: Address, key) => {
      const type = addressGeocodingAddressTypesMap[key as keyof Address]
      if (!type) {
        return acc
      }
      if (key === 'street') {
        const streetName = addressComponents?.find(ac =>
          ac.types.includes(addressGeocodingAddressTypesMap.streetName!)
        )
        const streetNumber = addressComponents?.find(ac =>
          ac.types.includes(addressGeocodingAddressTypesMap.streetNumber!)
        )

        const street =
          streetName && streetNumber
            ? i18n.t('Global.address.street', {
                streetName: streetName?.long_name,
                streetNumber: streetNumber?.long_name,
              })
            : streetName?.long_name || streetNumber?.long_name
        return {
          ...acc,
          street,
        }
      }
      if (key === 'state') {
        const county = addressComponents?.find(ac =>
          ac.types.includes('administrative_area_level_2')
        )
        const stateField = addressComponents?.find(ac =>
          ac.types.includes('administrative_area_level_1')
        )

        const state =
          county && stateField
            ? i18n.t('Global.address.state', {
                county: county?.long_name,
                state: stateField?.long_name,
              })
            : county?.long_name || stateField?.long_name
        return {
          ...acc,
          state,
        }
      }
      const value = addressComponents?.find(ac =>
        ac.types.includes(type as string)
      )?.long_name
      return {
        ...acc,
        [key]: value,
      }
    },
    {}
  )
  const lat = placeResult.geometry?.location.lat()
  const lon = placeResult.geometry?.location.lng()
  if (lat !== undefined && lon !== undefined) {
    address.location = {
      lat,
      lon,
    }
  }
  return address
}

export const sameAddress = (address1: Address, address2: Address): boolean => {
  const { location: location1, ...addr1 } = address1
  const { location: location2, ...addr2 } = address2
  const keys1 = Object.keys(addr1)
  const keys2 = Object.keys(addr2)
  const keys = [...keys1, keys2.filter(key => !keys1.includes(key))]
  return (
    keys.every(
      key =>
        addr1[key as keyof Omit<Address, 'location'>] ===
        addr2[key as keyof Omit<Address, 'location'>]
    ) &&
    location1?.lat === location2?.lat &&
    location1?.lon === location2?.lon
  )
}

export const addressIsArea = (address: Address): boolean => {
  return !address.streetName && !address.street
}

export const isAlwaysOrNeverAvailable = (
  timePreference: TimePreference[] | undefined | null,
  available: boolean
) =>
  timePreference?.every(e => {
    return Object.values(removeTypenameDeep(e)).every(f => f === available)
  })

export const hasAffiliationInProgress = (
  instructorAffiliationStatus?: AffiliationStatus
) => {
  return [
    AffiliationStatus.Setup,
    AffiliationStatus.Pending,
    AffiliationStatus.Accepted,
    AffiliationStatus.Declined,
    AffiliationStatus.Removed,
  ].includes(instructorAffiliationStatus as AffiliationStatus)
}

export const isStringJsonObject = (bodyString: string) => {
  try {
    JSON.parse(bodyString)
  } catch (err) {
    return false
  }
  return true
}

export const truncateString = (input: string, maxLength: number) =>
  input.length > maxLength
    ? `${input.substring(0, maxLength).trim()}...`
    : input

export const priceFormatter = (
  amount: number,
  currency: Currency,
  language: string
) =>
  new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
  }).format(amount)

export const allowNewline = {
  style: {
    whiteSpace: 'pre-line',
  } as unknown as CSSProperties,
}

// FEATURE FLAG
export const isUnreleasedNordicMarket = (
  user?: User,
  featureFlags?: FlagsProps
) => {
  return (
    user?.market.name === 'Nordic' &&
    featureFlags &&
    !featureFlags?.nordicMarketRelease
  )
}
// FEATURE FLAG

export const isUnsupportedMarket = (user?: User) =>
  user?.market?.isSupported === false

export const returnCorePrograms = (programs?: ClubProgram[] | null) => {
  if (!programs) {
    return []
  }
  return programs.reduce((acc: ClubProgram[], program: ClubProgram) => {
    if (
      program.programType !== ProgramTypeEnum.Live ||
      !Object.values(ProgramCodeEnum).includes(program.code as ProgramCodeEnum)
    ) {
      return acc
    }
    if (program.name === THE_TRIP_IMMERSIVE) {
      return [...acc, { ...program, name: THE_TRIP }]
    }
    return [...acc, program]
  }, [])
}

export const filterNonLiveProgramsFromProfile = (
  profile: InstructorProfileFieldsFragment | ClubProfileFieldsFragment
) => {
  if (isClubProfile(profile)) {
    return {
      ...profile,
      programs: returnCorePrograms(profile?.programs),
    }
  }
  return profile
}

export const filterProfilesByProfileType = (
  profiles: UserProfile[],
  profileType: UserProfileType
) =>
  profiles.filter(
    profile =>
      userProfileTypenameToUserProfileType(profile.__typename) === profileType
  )

// Display tooltip if more than one location selected or the logged in location is not selected
export const getEditDisableStatus = (
  options: Option[],
  profile: UserProfileClub
) => {
  const selectedLocationsCount = options.filter(location => location.isSelected)

  return selectedLocationsCount.length === 1
    ? profile.id !== selectedLocationsCount[0].value
    : true
}

// puts on hold certs last and sort everything by alphabetical order
export function compareCertification(
  certA: Certification,
  certB: Certification
) {
  if (certA.releaseStatus !== 'ON_HOLD' && certB.releaseStatus === 'ON_HOLD')
    return -1

  if (certA.releaseStatus === 'ON_HOLD' && certB.releaseStatus !== 'ON_HOLD')
    return 1

  if (
    certA.releaseStatus !== 'UNSUBSCRIBED' &&
    certB.releaseStatus === 'UNSUBSCRIBED'
  )
    return -1

  if (
    certA.releaseStatus === 'UNSUBSCRIBED' &&
    certB.releaseStatus !== 'UNSUBSCRIBED'
  )
    return 1

  if (certA.program.name < certB.program.name) return -1

  if (certA.program.name > certB.program.name) return 1

  return 0
}

// chunkedClubProfileIds = [[club1, club2],[club3,club4],[club5,club6]]
export const getChunkedClubProfileIds = (
  selectedClubLocation: string[],
  itemsPerBatch: number
) =>
  selectedClubLocation.reduce((acc, curr, index) => {
    const chunkIndex = Math.floor(index / itemsPerBatch)
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = []
    }
    acc[chunkIndex].push(curr)
    return acc
  }, [] as string[][])

export function compareAvailableClubs(
  locA: ClubAvailabilityOnListing,
  locB: ClubAvailabilityOnListing,
  loggedInClubId?: string
) {
  if (loggedInClubId) {
    if (locA.clubId === loggedInClubId && locB.clubId !== loggedInClubId)
      return -1

    if (locA.clubId !== loggedInClubId && locB.clubId === loggedInClubId)
      return 1
  }
  return locA.clubName
    .toLocaleLowerCase()
    .localeCompare(locB.clubName.toLocaleLowerCase())
}

export const getProgramName = (programName: string) =>
  programName.replace(/\s+/g, '_').replace(/'/g, '') as ProgramName

export const deviceType = () => {
  const ua = navigator.userAgent
  const isMobile =
    /IEMobile|Windows Phone|Lumia|iPhone|Android|BlackBerry|PlayBook|BB10|Mobile Safari|webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(
      ua
    )

  const isTablet = /Tablet|iPad/i.test(ua)

  const isPhone = isMobile && !isTablet

  return { isPhone, isTablet, isMobile }
}

export const getAddressFromProfile = (
  profile: UserProfile
): Address | undefined => {
  return isClubProfile(profile)
    ? profile.address
    : profile?.preferences?.travel?.address || profile?.address
}

export const getCertificationsWithProgramConfig = (
  certificates: [] | Certificate[],
  programConfig: ProgramConfigDic | null
): Array<Certification | CertificationWithLogo> | undefined => {
  return certificates
    .map(certificate => {
      if (!programConfig || !programConfig[certificate.programId]) {
        console.warn(`cannot find program ${certificate.programId}`)
        return undefined
      }

      if (certificate.level === CertificationStatusEnum.NotCertified) {
        return undefined
      }
      const program = programConfig[certificate.programId]

      return {
        __typename: 'Certification',
        id: certificate.id,
        lmqLevel: certificate.assessmentLevel,
        active: certificate.subscribeStatus,
        status: CertificationStatusEnum.Certified,
        program: {
          id: `program-${program.programShortCode}`,
          code: program.programShortCode,
          displayName: program.displayName,
          name: certificate.programId,
        },
        logoImage: {
          src: program.logoImage.url,
          alt: program.displayName,
        },
      } as CertificationWithLogo
    })
    .filter(item => item) as CertificationWithLogo[]
}
