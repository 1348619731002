import { useState, useEffect } from 'react'
import axios from 'axios'
import { ProgramConfigDic } from 'src/contexts/ProgramProvider'
import { useSessionStorage } from './useSessionStorage'
import { datadogRum } from '@datadog/browser-rum'

const useProgramConfig = () => {
  const [programConfig, setProgramConfig] = useState<ProgramConfigDic | null>(
    null
  )
  const [error, setError] = useState(null)

  const [sessionData, setSessionData] = useSessionStorage(
    'programConfig',
    '',
    5 * 60
  )

  useEffect(() => {
    const programConfigUrl = `${
      import.meta.env.REACT_APP_TRANSLATION_BASE_URL
    }/program-config.json`

    if (!sessionData) {
      axios
        .get(programConfigUrl)
        .then(response => {
          setProgramConfig(response.data)
          setSessionData(response.data)
        })
        .catch(err => {
          setError(err)
          datadogRum.addError(err)
        })
    } else {
      setProgramConfig(sessionData)
    }
  }, [sessionData])

  return { programConfig, error }
}

export default useProgramConfig
