import { useTranslationHelpers } from 'src/hooks/useTranslationHelpers'

export const useCookieManagementTranslations = () => {
  const { translate } = useTranslationHelpers(
    'AccountSettings.cookieManagement'
  )

  return {
    MANAGE_YOUR_COOKIE: translate('manageYourCookie'),
    CHANGE_COOKIE_SETTING: translate('changeCookieSetting'),
  }
}
