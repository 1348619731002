import { Typography } from '@material-ui/core'
import { Box } from '@mui/material'
import React from 'react'
import { useCookieManagementTranslations } from 'src/components/accountSettings/cookieManagement/CookieManagement.translations'
import LoadCookieDeclarationScript from 'src/components/accountSettings/cookieManagement/LoadCookieDeclarationScript'

const CookieDeclaration: React.FC = () => {
  const { MANAGE_YOUR_COOKIE } = useCookieManagementTranslations()

  return (
    <Box p={6}>
      <Typography variant="h3">{MANAGE_YOUR_COOKIE}</Typography>
      <LoadCookieDeclarationScript />
    </Box>
  )
}

export default CookieDeclaration
