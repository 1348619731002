import { Address, LocationDistanceUnit } from 'src/generated/graphql'

export const SAVED_SEARCHES_LIMIT = 3

// Match the default distance value on upsertUser, if it's missing on the user
// TODO: use market default (BE schema update needed)
export const DEFAULT_DISTANCE = 30

export const DEFAULT_OPTIONS = [10, 20, 30, 50, 75, 100]

// initial release markets use miles
export const DEFAULT_DISTANCE_UNIT = LocationDistanceUnit.Miles

export const DESCRIPTION_FIELD_MAX_LENGTH = 1000

export const MAX_PROGRAMS = 3

export const MAX_FILE_SIZE =
  import.meta.env.REACT_APP_MAX_FILE_SIZE &&
  !isNaN(Number(import.meta.env.REACT_APP_MAX_FILE_SIZE))
    ? Number(import.meta.env.REACT_APP_MAX_FILE_SIZE)
    : 10485760 // 10MB

export const PORTFOLIO_FILE_LIMIT =
  import.meta.env.REACT_APP_PORTFOLIO_FILE_LIMIT &&
  !isNaN(Number(import.meta.env.REACT_APP_PORTFOLIO_FILE_LIMIT))
    ? Number(import.meta.env.REACT_APP_PORTFOLIO_FILE_LIMIT)
    : 10

export const MISSING_VALUE = '-'

export const addressGeocodingAddressTypesMap: {
  [k in keyof Address]: string
} = {
  street: 'route',
  city: 'locality',
  country: 'country',
  streetName: 'route',
  postalCode: 'postal_code',
  streetNumber: 'street_number',
  state: 'administrative_area_level_2, administrative_area_level_1',
}

// TODO? Set default email or placeholder if not defined in env
export const SUPPORT_EMAIL = import.meta.env.SUPPORT_EMAIL || ''

export const LIMIT_REACHED = 'LIMIT_REACHED'

export const THE_TRIP_IMMERSIVE = 'THE TRIP - IMMERSIVE'
export const THE_TRIP = 'THE TRIP'

export const LMJP = 'LMJP'
export const LMME = 'LMME'
export const LMUK = 'LMUK'
export const LMUS = 'LMUS'
export const LMGE = 'LMGE'
export const NORDIC = 'Nordic'

export const DEFAULT_API_DATE_FORMAT = 'yyyy-MM-DD'
export const DEFAULT_DISPLAY_DATE_FORMAT = 'DD/MM/yyyy'

export const CTA_TILES = 'cta-tiles'
export const ANNOUNCEMENT_BANNERS = 'announcement-banners'
export const POST_WORK_CONTENT_URL = 'content/post-work'

export const LIMITED_EDITION = 'Limited Edition'
