import { Locale } from 'src/generated/graphql'

import i18n from 'i18next'

import { NotGuardedPaths } from 'src/routes'

export const localeToLanguage = (locale: Locale): string =>
  locale.replace('_', '-')

export const languageToLocale = (lang: string): Locale =>
  lang.replace('-', '_') as Locale

const availableLocales = Object.values(Locale)
export const isSupportedLocale = (code: Locale | string) =>
  availableLocales.includes(code as Locale)

export const languageParamRegExp = /^[a-z]{2}-[A-Z]{2}$/
export const isValidLanguageParamFormat = (language: string) =>
  languageParamRegExp.test(language)

export const localeTypeRegExp = /^[a-z]{2}_[A-Z]{2}$/
export const isValidLocaleFormat = (locale: string) =>
  localeTypeRegExp.test(locale)

export const replaceBlankSpaceWithUnicode = (suffix: string) => {
  const [, commaWithSpace] = suffix.split('"')
  const isCommaWithSpace = /\s/g.test(suffix)
  const currentSuffix =
    !!commaWithSpace && !!isCommaWithSpace
      ? commaWithSpace.replace(/\s/g, '\u0020') // replace blank space with unicode
      : suffix
  return currentSuffix
}

const langParamRegexp = new RegExp(
  `^/(${Object.values(Locale).join('|')})`,
  'i'
)
export const removeLangParamFromPathStr = (path: string) =>
  path.replace(langParamRegexp, '')

/**
 * Here you can pass a path for a Route element
 * to set the language parameter in the URL
 * @param path string | string[]
 * @param lang Languages
 */
export const localizeRoutePath = (
  path: string | readonly string[],
  lang: Locale = i18n.language.replace('_', '-') as Locale
) => {
  switch (typeof path) {
    case 'object':
      return path.map(key =>
        NotGuardedPaths.includes(key)
          ? key
          : `/${lang}${removeLangParamFromPathStr(key)}`
      )
    default: {
      if (NotGuardedPaths.includes(path)) {
        return path
      }
      return path === '*' ? path : `/${lang}${removeLangParamFromPathStr(path)}`
    }
  }
}

export const convertLocaleToLanguage = (locale?: Locale) =>
  locale ? locale.replace('_', '-').toLocaleLowerCase() : 'en-us'
